<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-stage-caution"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content px-3 py-2">
        <div class="modal-body">
          <h3 class="text-center font-weight-bold">
            You're about to progress {{ orderNumber }} {{ message }}
          </h3>
          <div class="form-group mt-3 row justify-content-center">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >Progress Date</label
            >
            <div class="col-12 col-sm-7 col-lg-6">
              <date-picker
                class="form-control"
                :class="{ 'is-invalid': $v.progressDate.$error }"
                v-model="progressDate"
                :config="options"
              />
              <div class="invalid-feedback" v-if="!$v.progressDate.required">
                Field is required
              </div>
            </div>
          </div>
        </div>
        <div
          class="
            modal-footer
            border-0
            d-flex
            justify-content-center
            align-items-center
          "
        >
          <button
            @click.prevent="onCancelClick"
            class="btn btn-danger"
            type="button"
          >
            No, Cancel
          </button>
          <button
            :disabled="isDisabled"
            class="btn btn-success"
            type="button"
            @click.prevent="onSubmitClick"
          >
            <span
              v-if="isDisabled"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else> Yes, Proceed </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import datePicker from "vue-bootstrap-datetimepicker";

export default {
  name: "ProgressStageCaution",

  components: {
    datePicker,
  },

  props: ["orderNumber", "cautionData"],

  data() {
    return {
      isDisabled: false,
      progressDate: "",

      options: {
        format: "YYYY-MM-DD",
        useCurrent: true,
      },
    };
  },

  validations: {
    progressDate: {
      required,
    },
  },

  created() {
    this.orderId = this.$route.params.id;
  },

  computed: {
    message: function () {
      return this.cautionData.current
        ? `from ${this.cautionData.current} to ${this.cautionData.next}`
        : `to ${this.cautionData.next}`;
    },

    user: function () {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    async onSubmitClick() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isDisabled = true;
        try {
          const response = await this.$http.put(
            "/PurchaseOrders/stage/" +
              this.orderId +
              "/" +
              this.cautionData.stageId +
              "/" +
              this.progressDate +
              "/" +
              this.user.id
          );

          if (response.ok) this.$emit("stageChanged");

          this.isDisabled = false;
        } catch (error) {
          this.isDisabled = false;
        }
        this.reset();
      }
    },

    reset() {
      this.progressDate = "";
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },

    onCancelClick() {
      this.reset();
      this.$emit("onCancelClick");
    },
  },
};
</script>

<style>
</style>