<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <input class="search-distro" type="text" placeholder="Search" v-model="search" />
          <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <i class="feather icon-filter text-white"></i> Filters
          </button>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 25%">Business name</th>
                      <th style="width: 10%">Phone Number</th>
                      <th style="width: 25%">Business description</th>
                      <th style="width: 30%">Location</th>
                      <th style="width: 10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="o in distributors" :key="o.id">
                      <td>{{ o.name }}</td>

                      <td>{{ o.phoneNumber }}</td>
                      <td>{{ o.desc }}</td>

                      <td>
                        {{ o.location }}
                      </td>
                      <td>
                        <button
                          class="btn btn-outline-primary btn-sm dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div class="dropdown-menu px-1 " aria-labelledby="dropdownMenu2">
                          <a class="dropdown-item" href="#" @click.prevent="details(o.id)">
                            View Product List
                          </a>
                          <div class="dropdown-divider"></div>
                          <a
                            class="dropdown-item"
                            href="#"
                            @click.prevent="connectDistributors(o.id)"
                          >
                            Connect
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!distributors.length">
                      <td colspan="10">
                        <div class="text-center" style="padding-top: 50px">
                          <span style="font-size: 4.615rem" class="mdi mdi-basket"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          No distributor available
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="pageNumber"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5" v-if="distributors.length">
                <div class="dataTables_info">
                  {{ count }} distributor{{ count != 1 ? "s" : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">State</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select class="form-control" placeholder="Select an option" v-model="filter.state">
                  <option disabled selected>Select State</option>
                  <option v-for="(el, index) in states" :key="index" :value="el">{{ el }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">LGA</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select class="form-control" placeholder="Select an option" v-model="filter.lga">
                  <option v-for="(el, index) in lgas" :key="index" :value="el">{{ el }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getDistributors"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import Pagination from "../components/Pagination.vue";

export default {
  name: "addDistributor",
  components: {
    Pagination,
  },

  mounted: function() {},

  created() {
    // if (!this.hasPermission("Orders_View")) {
    //   this.$router.push("/restricted");
    // }

    const state = this.$route.query.state ? this.$route.query.state : "";
    const lga = this.$route.query.lga ? this.$route.query.lga : "";

    this.filter = {
      state,
      lga,
    };

    this.getDistributors();
  },

  data() {
    return {
      filter: {
        state: "",
        lga: "",
      },

      isBusy: false,
      isBusyModal: false,
      count: 0,
      pageNumber: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,

      distributors: [],
      states: helper.getStates(),
      search: "",
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },

    lgas: function() {
      return helper.getLgas(this.filter.state);
    },
  },

  watch: {
    search: function(newVal, oldVal) {
      this.getDistributors();
    },
  },

  methods: {
    reset() {
      this.filter = {
        state: "",
        lga: "",
      };
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    details(id) {
      this.$router.push("/merchant/distributors/products/" + id);
    },

    prev() {
      this.pageNumber -= 1;
      this.getDistributors();
    },

    next() {
      this.pageNumber += 1;
      this.getDistributors();
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    gotoPage(page) {
      this.pageNumber = page;
      this.getDistributors();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getDistributors();
    },

    async getDistributors() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Merchant/distributor?pageNumber=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            (this.search !== "" ? "&DistributorName=" + this.search : "") +
            (this.filter.state !== "" ? "&state=" + this.filter.state : "") +
            (this.filter.lga !== "" ? "&lga=" + this.filter.lga : "")
        )
        .then(function(res) {
          console.log(res);
          this.distributors = res.body.data ? res.body.data : [];
          this.pageNumber = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    async connectDistributors(id) {
      this.isBusy = true;

      await this.$http
        .post("/Merchant/assign", { distributorId: id })
        .then(function(res) {
          if (res.ok) {
            this.$toast.success("Distributor added successfully", {
              icon: true,
              rtl: false,
            });
            this.getDistributors();
          }
          this.isBusy = false;
        })
        .catch(function() {});
      this.isBusy = false;
    },
  },
};
</script>

<style scoped>
.search-distro {
  width: 238px;
  height: 38px;
  background: #fcfcfc;
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding: 0 10px;
}
</style>
