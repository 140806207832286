const AuthPlugin = {
  setToken(
    token,
    expiration,
    refreshToken,
    authRole,
    authUser,
    authPermissions,
    authSalesUserRole
  ) {
    localStorage.setItem("authToken", token);
    localStorage.setItem("authTokenExpiration", expiration);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("authRole", authRole);
    localStorage.setItem("authUser", authUser);
    localStorage.setItem("authPermissions", JSON.stringify(authPermissions));
    localStorage.setItem("authSalesUserRole", JSON.stringify(authSalesUserRole));
  },

  setPreferredViewForCustomer(preferredView, userId) {
    localStorage.setItem("preferredView_Customer_" + userId, preferredView);
  },
  setPreferredViewForOrder(preferredView, userId) {
    localStorage.setItem("preferredView_Order_" + userId, preferredView);
  },
  setPreferredViewForPayment(preferredView, userId) {
    localStorage.setItem("preferredView_Payment_" + userId, preferredView);
  },
  setPreferredViewForStockTransfer(preferredView, userId) {
    localStorage.setItem("preferredView_StockTransfer_" + userId, preferredView);
  },

  destroyToken() {
    localStorage.removeItem("authToken");
    localStorage.removeItem("authTokenExpiration");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("authRole");
    localStorage.removeItem("authUser");
    localStorage.removeItem("authPermissions");
    localStorage.removeItem("authSalesAccessId");
  },

  getPreferredViewForCustomer(userId) {
    return localStorage.getItem("preferredView_Customer_" + userId);
  },
  getPreferredViewForOrder(userId) {
    return localStorage.getItem("preferredView_Order_" + userId);
  },
  getPreferredViewForPayment(userId) {
    return localStorage.getItem("preferredView_Payment_" + userId);
  },
  getPreferredViewForStockTransfer(userId) {
    return localStorage.getItem("preferredView_StockTransfer_" + userId);
  },

  getToken() {
    let token = localStorage.getItem("authToken");
    let expiration = localStorage.getItem("authTokenExpiration");

    if (!token || !expiration) return null;

    if (Date.now() > parseInt(expiration)) {
      this.destroyToken();
      return null;
    } else {
      return token;
    }
  },

  getRefreshToken() {
    return localStorage.getItem("refreshToken");
  },

  getRole() {
    return localStorage.getItem("authRole");
  },

  getSalesUserRole() {
    var retrievedData = localStorage.getItem("authSalesUserRole");
    return JSON.parse(retrievedData);
  },

  getPermissions() {
    var retrievedData = localStorage.getItem("authPermissions");
    return JSON.parse(retrievedData);
  },

  getUserType() {
    return localStorage.getItem("authUser");
  },

  loggedIn() {
    if (this.getToken()) return true;
    else return false;
  },

  // set and get roles and userType
};

export default function(Vue) {
  Vue.auth = AuthPlugin;

  Object.defineProperties(Vue.prototype, {
    $auth: {
      get() {
        return Vue.auth;
      },
    },
  });
}
