<template>
  <div>
    <div v-if="user.reservedAccountNumber" class="growtrade-div">
      <iframe
        src="https://www.growtrade.chat/"
        title="My Money"
        width="100%"
        height="100%"
        frameborder="0"
      >
      </iframe>
    </div>
    <div v-else>
      <CreateBankAccount @closeModal="closeBankModal" />
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import CreateBankAccount from "@/components/BankAccount/CreateBankAccount.vue";

export default {
  name: "growTrade",

  components: {
    CreateBankAccount,
  },

  data() {
    return {};
  },

  mounted() {
    if (!this.user.reservedAccountNumber) {
      $("#mod-add-onepipe-account").modal({ backdrop: "static", keyboard: false });
    }
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    closeBankModal() {
      $("#mod-add-onepipe-account").modal("hide");
    },
  },
};
</script>

<style scoped>
.growtrade-div {
  height: 100vh;
  @media screen and (max-width: 767px) {
    width: 90%;
    margin: 0 auto;
  }
}
</style>
