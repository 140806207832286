import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import NotFound from "./views/NotFound.vue";
import Restricted from "./views/Restricted.vue";
import Dashboard from "./views/Dashboard.vue";
import OrdersRecieved from "./views/OrdersRecieved.vue";
import OrdersSent from "./views/OrdersSent.vue";
import OrdersDraft from "./views/OrdersDraft.vue";
import OrdersCreate from "./views/OrdersCreate.vue";
import MyDistributors from "./views/MyDistributors.vue";
import AddDistributor from "./views/AddDistributor.vue";
import DistributorProducts from "./views/ViewDistributorProducts.vue";
import GrowtradeOnboarding from "./views/GrowtradeOnboarding.vue";
import GrowTradeForgotPassword from "./views/GrowTradeForgotPassword.vue";
import GrowtradeLogin from "./views/GrowtradeLogin.vue";
import GrowtradeResetPassword from "./views/GrowtradeResetPassword.vue";
import GrowtradeRegister from "./views/GrowtradeRegister.vue";
import OrdersDetails from "./views/OrdersDetails.vue";
import Distributor from "./views/Distributor.vue";
import DistributorCreate from "./views/DistributorCreate.vue";
import OrdersCreateAlt from "./views/OrdersCreateAlt.vue";
import OrdersEdit from "./views/OrdersEdit.vue";
import DistributorEdit from "./views/DistributorEdit.vue";
import DistributorParents from "./views/DistributorParents.vue";
import DistributorDetails from "./views/DistributorDetails.vue";
import Statistics from "./views/Statistics.vue";
import Manufacturer from "./views/Manufacturer.vue";
import ManufacturerCreate from "./views/ManufacturerCreate.vue";
import ManufacturerEdit from "./views/ManufacturerEdit.vue";
import ManufacturerDetails from "./views/ManufacturerDetails.vue";
import Customers from "./views/Customers.vue";
import CustomersDetails from "./views/CustomersDetails.vue";
import CustomersEdit from "./views/CustomersEdit.vue";
import Campaigns from "./views/Campaigns.vue";
import CampaignsMembers from "./views/CampaignsMembers.vue";
import CampaignsCreate from "./views/CampaignsCreate.vue";
import CampaignsEdit from "./views/CampaignsEdit.vue";
import CampaignsDetails from "./views/CampaignsDetails.vue";
import Products from "./views/Products.vue";
import ProductUnits from "./views/ProductUnits.vue";
import ProductCategories from "./views/ProductCategories.vue";
import Warehouses from "./views/Locations.vue";
import StorageAreas from "./views/SubLocations.vue";
import ManageStock from "./views/ManageStock.vue";
import StockTransfer from "./views/StockTransfer.vue";
import StockTransferView from "./views/StockTransferView.vue";
import StockTransferCreate from "./views/StockTransferCreate.vue";
import ReconcileOrdersToStock from "./views/ReconcileOrdersToStock.vue";
import PurchaseOrders from "./views/PurchaseOrder/PurchaseOrders";
import NewPurchaseOrder from "./views/PurchaseOrder/NewPurchaseOrder.vue";
import ViewPurchaseOrder from "./views/PurchaseOrder/ViewPurchaseOrder.vue";
import EditPurchaseOrder from "./views/PurchaseOrder/EditPurchaseOrder.vue";

import DistributorReports from "./views/ReportsDistributor.vue";
import DistributorReportsOld from "./views/ReportsDistributorOld.vue";
import ManufacturerReports from "./views/ReportsManufacturer.vue";
import DistributorAnalytics from "./views/AnalyticsDistributor.vue";
import ManufacturerAnalytics from "./views/AnalyticsManufacturer.vue";
import SalesAnalytics from "./views/AnalyticsSales.vue";

import Business from "./views/SettingsBusiness.vue";
import Discounts from "./views/SettingsDiscounts.vue";
import DiscountMembers from "./views/SettingsDiscountMembers.vue";
import Groups from "./views/SettingsGroups.vue";
import GroupMembers from "./views/SettingsGroupMembers.vue";
import Payment from "./views/SettingsPaymentChannel.vue";
import Team from "./views/SettingsTeam.vue";
import Account from "./views/Account.vue";
import Join from "./views/Join.vue";
import LinkAccount from "./views/LinkAccount.vue";
import Login from "./views/Login.vue";
import ForgotPassword from "./views/ForgotPassword.vue";
import ChangePassword from "./views/ChangePassword.vue";
import VolumeByCustomerReport from "./views/VolumeByCustomerReport.vue";
import VolumeByProductReport from "./views/VolumeByProductReport.vue";
import VolumeByProductCategoryReport from "./views/VolumeByProductCategoryReport.vue";
import DailySalesByOrderReport from "./views/DailySalesByOrderReport.vue";
import DailySalesByOrderStatusReport from "./views/DailySalesByOrderStatusReport.vue";
import DailySalesByDateReport from "./views/DailySalesByDateReport.vue";
import InventoryByBatchReport from "./views/InventoryByBatchReport.vue";
import InventoryByProductReport from "./views/InventoryByProductReport.vue";
import InventoryByWarehouseReport from "./views/InventoryByWarehouseReport.vue";
import InventoryByProductCategoryReport from "./views/InventoryByProductCategoryReport.vue";
import ReconciliationByStatusReport from "./views/ReconciliationByStatusReport.vue";
import ReconciliationByOrderNumberReport from "./views/ReconciliationByOrderNumberReport.vue";
import TotalCreditSalesYTDReport from "./views/TotalCreditSalesYTDReport.vue";
import TotalSalesCollectionsYTDReport from "./views/TotalSalesCollectionsYTDReport.vue";
import TotalCashCreditRatioReport from "./views/TotalCashCreditRatioReport.vue";
import NewCustomerAcquisitionYTDReport from "./views/MyCustomersReport.vue";
import DelistedCustomersYTDReport from "./views/DelistedCustomersYTDReport.vue";
import ActiveCreditSalesByDateReport from "./views/ActiveCreditSalesByDateReport.vue";
import ActiveCashCollectionsByDateReport from "./views/ActiveCashCollectionsByDateReport.vue";
import ActiveCashCreditRatioGrpByBranch from "./views/ActiveCashCreditRatioGrpByBranch.vue";
import ActiveCashCreditRatioGrpByCustomer from "./views/ActiveCashCreditRatioGrpByCustomer.vue";
import Total30DayCreditListofOutletsReport from "./views/Total30DayCreditListofOutletsReport.vue";
import Total60DayCreditListofOutletsReport from "./views/Total60DayCreditListofOutletsReport.vue";
import Total90DayCreditListofOutletsReport from "./views/Total90DayCreditListofOutletsReport.vue";
import ActiveCashCollectionsByIntroducerReport from "./views/ActiveCashCollectionsByIntroducerReport.vue";
import ActiveCashCollectionsByBranchReport from "./views/ActiveCashCollectionsByBranchReport.vue";
import ActiveCreditSalesByBranchReport from "./views/ActiveCreditSalesByBranchReport.vue";
import ActiveCreditSalesByIntroducerReport from "./views/ActiveCreditSalesByIntroducerReport.vue";
import ActiveOrdersByCustomerReport from "./views/ActiveOrdersByCustomerReport.vue";
import ActiveOrdersByOrderStatusReport from "./views/ActiveOrdersByOrderStatusReport.vue";
import AvgMonthlySalesByIntroducerReport from "./views/AvgMonthlySalesByIntroducerReport.vue";
import AvgMonthlyCollectionsByIntroducerReport from "./views/AvgMonthlyCollectionsByIntroducerReport.vue";
import ProductPerformanceByPercentageReport from "./views/ProductPerformanceByPercentageReport.vue";

import ManufacturerRetailerReport from "./views/ManufacturerRetailerReport.vue";
import ManufacturerSalesByProduct from "./views/ManufacturerSalesByProduct.vue";
import ManufacturerTotalOrdersByDistributor from "./views/ManufacturerTotalOrdersByDistributor.vue";
import ManufacturerTotalOrdersByState from "./views/ManufacturerTotalOrdersByState.vue";
import ManufacturerTotalOrdersByStatus from "./views/ManufacturerTotalOrdersByStatus.vue";
import ManufacturerRetailersByAgingCredit from "./views/ManufacturerRetailerByAgingCredit.vue";
import ManufacturerProductPerformance from "./views/ManufacturerProductPerformance.vue";
import ManufacturerProductPerformanceByDistributor from "./views/ManufacturerProductPerformanceDistributor.vue";
import ManufacturerProductPerformanceByState from "./views/ManufacturerProductPerformanceState.vue";
import ManufacturerMarginProfileByDistributor from "./views/ManufacturerMarginProfileDistributor.vue";
import ManufacturerExpensesByDistributor from "./views/ManufacturerExpensesDistributor.vue";
import SalesMarginsReport from "./views/SalesMarginsReport.vue";
import StockPurchaseReportAllReport from "./views/StockPurchaseReportAllReport.vue";
import StockPurchaseReportConsolidated from "./views/StockPurchaseReportConsolidated.vue";

import Branches from "./views/Branches.vue";
import ManageCashChequeTransactions from "./views/ManageCashChequeTransactions.vue";
import DistributorList from "./views/DistributorList.vue";
import Register from "./views/Register.vue";
import SystemSettings from "./views/SystemSettings.vue";
import ManufacturerSystemSettings from "./views/ManufacturerSystemSettings.vue";
import ProductsManufacturer from "./views/ProductsManufacturer.vue";
import ManufacturerDistributor from "./views/ManufacturerDistributor.vue";
import ManufacturerDistributorDetails from "./views/ManufacturerDistributorDetails.vue";
import ManufacturerDistributorCreate from "./views/ManufacturerDistributorCreate.vue";
import ExpenseCategory from "./views/ExpenseCategory.vue";
import PaymentChannel from "./views/PaymentChannel.vue";
import ManageExpenses from "./views/ManageExpenses.vue";
import AdjustExpenses from "./views/AdjustExpenses.vue";
import SetPreference from "./views/SetPreference.vue";
import DistributorGroups from "./views/Group/DistributorGroups.vue";
import ManagePriceOption from "./views/ManagePriceOption.vue";
import DistributorAssociation from "./views/Group/DistributorAssociation.vue";
import PriceOption from "./views/Group/PriceOption.vue";
import ManageGroup from "./views/ManageGroup.vue";
import Roles from "./views/Roles.vue";
import RoleUsers from "./views/RoleUsers.vue";
import ManageExpensesUpload from "./views/ManageExpensesUpload.vue";
import ProductsUpload from "./views/ProductsUpload.vue";
import PurchaseOrderProcess from "./views/SettingPurchaseOrder/PurchaseOrderProcess.vue";
import PurchaseOrderItemSetup from "./views/SettingPurchaseOrder/PurchaseOrderItemSetup.vue";
import PurchaseOrderStageSetup from "./views/SettingPurchaseOrder/PurchaseOrderStageSetup.vue";
import ManufacturerEditPurchaseorder from "./views/SettingPurchaseOrder/ManufacturerEditPurchaseOrder.vue";
import ManufacturerViewPurchaseOrder from "./views/SettingPurchaseOrder/ManufacturerViewPurchaseOrder.vue";
import ProcessSalesOrder from "./views/SettingPurchaseOrder/ProcessSalesOrder.vue";
import UploadSalesOrder from "./views/SettingPurchaseOrder/UploadSalesOrder.vue";
import Notifications from "./views/Notifications.vue";
import SalesRoles from "./views/SalesRoles.vue";
import SalesRoleUsers from "./views/SalesRoleUsers.vue";
import SalesTeam from "./views/SalesTeam.vue";
import JoinSalesUser from "./views/JoinSalesUser.vue";
import SalesUserDistributor from "./views/SalesUserDistributor.vue";
import SalesUserDistributorDetails from "./views/SalesUserDistributorDetails.vue";
import SalesUserDistributorProducts from "./views/SalesUserDistributorProducts.vue";
import SalesForceSaleOrder from "./views/SalesForceSaleOrder.vue";
import SalesForceSaleOrderView from "./views/SalesForceSaleOrderView.vue";
import GeoFencing from "./views/GeoFencing.vue";
import CustomerTransaction from "./views/CustomerTransaction.vue";
import CustomerAccount from "./views/Account/AccountPosting.vue";
import PostPayment from "./views/Account/PostPayment.vue";
import GrowTrade from "./views/GrowTrade.vue";

Vue.use(Router);

const dashboardRouteInfo = { route: "/dashboard", name: "Dashboard" };
const distributorReportRouteInfo = { route: "/reports/distributor", name: "Distributor reports" };

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      component: Home,
      redirect: "/dashboard",
      children: [
        {
          path: "not-found",
          component: NotFound,
          meta: {
            requiresAuth: true,
            title: "Resource not Found",
            roles: [
              "Administrator",
              "Support Specialist",
              "Distributor Manager",
              "View Only",
              "Customer",
              "Sales",
            ],
            userCategories: ["System", "Distributor", "Manufacturer"],
          },
        },
        {
          path: "restricted",
          component: Restricted,
          meta: {
            requiresAuth: true,
            title: "Authorization Restricted",
            roles: [
              "Administrator",
              "Support Specialist",
              "Distributor Manager",
              "View Only",
              "Customer",
              "Sales",
            ],
            userCategories: ["System", "Distributor", "Manufacturer"],
          },
        },
        {
          path: "dashboard",
          component: Dashboard,
          meta: {
            requiresAuth: true,
            title: "Dashboard",
            roles: [
              "Administrator",
              "Support Specialist",
              "Distributor Manager",
              "View Only",
              "Customer",
              "Sales",
            ],
            userCategories: ["System", "Distributor", "Manufacturer"],
          },
        },
        {
          path: "orders",
          component: OrdersRecieved,
          meta: {
            requiresAuth: true,
            title: "Orders",
            roles: ["Administrator", "Support Specialist", "View Only", "Sales"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "merchant/orders",
          component: OrdersSent,
          meta: {
            requiresAuth: true,
            title: "My Orders",
            roles: ["Customer"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "merchant/drafts",
          component: OrdersDraft,
          meta: {
            requiresAuth: true,
            title: "My Drafts",
            roles: ["Customer"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "merchant/distributors/add",
          component: AddDistributor,
          meta: {
            requiresAuth: true,
            title: "Add Distributor",
            roles: ["Customer"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "merchant/distributors/view",
          component: MyDistributors,
          meta: {
            requiresAuth: true,
            title: "My Distributors",
            roles: ["Customer"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "merchant/distributors/products/:id",
          component: DistributorProducts,
          meta: {
            requiresAuth: true,
            title: "Product List",
            roles: ["Customer"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "merchant/growtrade",
          component: GrowTrade,
          meta: {
            requiresAuth: true,
            title: "GrowTrade",
            roles: ["Customer"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "merchant/orders/create",
          component: OrdersCreate,
          meta: {
            requiresAuth: true,
            title: "Create a new Order",
            roles: ["Customer"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "merchant/orders/edit/:id",
          component: OrdersEdit,
          meta: {
            requiresAuth: true,
            title: "Edit your Order",
            roles: ["Customer"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "merchant/orders/:id",
          component: OrdersDetails,
          meta: {
            requiresAuth: true,
            title: "Order Details",
            roles: ["Customer"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "orders/create",
          component: OrdersCreateAlt,
          meta: {
            requiresAuth: true,
            title: "Create a new Order",
            roles: ["Administrator", "Support Specialist", "Sales"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "orders/create/:id",
          component: OrdersCreateAlt,
          meta: {
            requiresAuth: true,
            title: "Create a new Order",
            roles: ["Administrator", "Support Specialist", "Sales"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "orders/:id",
          component: OrdersDetails,
          meta: {
            requiresAuth: true,
            title: "Order Details",
            roles: ["Administrator", "Support Specialist", "View Only", "Customer", "Sales"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "orders/:id/:s",
          component: OrdersDetails,
          meta: {
            requiresAuth: true,
            title: "Order Details",
            roles: ["Administrator", "Support Specialist", "View Only", "Customer", "Sales"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "manage-expenses",
          component: ManageExpenses,
          meta: {
            requiresAuth: true,
            title: "Manage Expenses",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "manage-expenses/:id",
          component: AdjustExpenses,
          meta: {
            requiresAuth: true,
            title: "Adjust Expenses",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "manage-expenses-upload",
          component: ManageExpensesUpload,
          meta: {
            requiresAuth: true,
            title: "Upload Expenses",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "accountposting",
          component: CustomerAccount,
          meta: {
            requiresAuth: true,
            title: "Account Posting",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "managepayments",
          component: PostPayment,
          meta: {
            requiresAuth: true,
            title: "Post Payments",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "managecashchequetransactions",
          component: ManageCashChequeTransactions,
          meta: {
            requiresAuth: true,
            title: "Manage Cash/Cheque Transactions",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "reconcileorderstostock",
          component: ReconcileOrdersToStock,
          meta: {
            requiresAuth: true,
            title: "Reconcile Orders To Stock",
            roles: ["Administrator", "Support Specialist", "View Only"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "distributorList",
          component: DistributorList,
          meta: {
            requiresAuth: true,
            title: "Distributors",
            roles: ["Customer"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "manufacturer-distributors",
          component: ManufacturerDistributor,
          meta: {
            requiresAuth: true,
            title: "Distributors",
            roles: ["Administrator", "Distributor Manager", "View Only"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-distributors/:id/manage-group",
          component: ManageGroup,
          meta: {
            requiresAuth: true,
            title: "Manage Group",
            roles: ["Administrator", "Distributor Manager", "View Only"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-distributors/create",
          component: ManufacturerDistributorCreate,
          meta: {
            requiresAuth: true,
            title: "Create a new Distributor",
            roles: ["Administrator", "Distributor Manager"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-distributors/:id",
          component: ManufacturerDistributorDetails,
          meta: {
            requiresAuth: true,
            title: "Distributors",
            roles: ["Administrator", "Distributor Manager", "View Only"],
            userCategories: ["Manufacturer"],
          },
        },

        {
          path: "sales-user-distributor-products/:id",
          component: SalesUserDistributorProducts,
          meta: {
            requiresAuth: true,
            title: "Distributor Products",
            roles: ["Sales"],
            userCategories: ["Manufacturer"],
          },
        },

        {
          path: "sales-user-distributors",
          component: SalesUserDistributor,
          meta: {
            requiresAuth: true,
            title: "Distributors",
            roles: ["Sales"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "sales-user-distributors/:id",
          component: SalesUserDistributorDetails,
          meta: {
            requiresAuth: true,
            title: "Distributors",
            roles: ["Sales"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "distributors",
          component: Distributor,
          meta: {
            requiresAuth: true,
            title: "Distributors",
            roles: ["Administrator"],
            userCategories: ["System", "Manufacturer"],
          },
        },
        {
          path: "groups",
          component: DistributorGroups,
          meta: {
            requiresAuth: true,
            title: "Groups",
            roles: ["Administrator", "Distributor Manager", "View Only"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "groups/:id/distributor-association",
          component: DistributorAssociation,
          name: "DistributorAssociation",
          meta: {
            requiresAuth: true,
            title: "Distributor Association",
            roles: ["Administrator", "Distributor Manager", "View Only"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "groups/:id/price-option",
          component: PriceOption,
          name: "PriceOption",
          meta: {
            requiresAuth: true,
            title: "Price Option",
            roles: ["Administrator", "Distributor Manager", "View Only"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "distributors/create",
          component: DistributorCreate,
          meta: {
            requiresAuth: true,
            title: "Create a new Distributor",
            roles: ["Administrator"],
            userCategories: ["System"],
          },
        },
        {
          path: "distributors/:id",
          component: DistributorDetails,
          meta: {
            requiresAuth: true,
            title: "Distributors",
            roles: ["Administrator"],
            userCategories: ["System", "Manufacturer"],
          },
        },
        {
          path: "distributors/:id/edit",
          component: DistributorEdit,
          meta: {
            requiresAuth: true,
            title: "Edit Distributor",
            roles: ["Administrator"],
            userCategories: ["System"],
          },
        },
        {
          path: "distributors/:id/parents",
          component: DistributorParents,
          meta: {
            requiresAuth: true,
            title: "Distributors",
            roles: ["Administrator"],
            userCategories: ["System"],
          },
        },
        {
          path: "manufacturers",
          component: Manufacturer,
          meta: {
            requiresAuth: true,
            title: "Manufacturers",
            roles: ["Administrator"],
            userCategories: ["System"],
          },
        },
        {
          path: "manufacturers/create",
          component: ManufacturerCreate,
          meta: {
            requiresAuth: true,
            title: "Create a new Manufacturer",
            roles: ["Administrator"],
            userCategories: ["System"],
          },
        },
        {
          path: "manufacturers/:id",
          component: ManufacturerDetails,
          meta: {
            requiresAuth: true,
            title: "Manufacturers",
            roles: ["Administrator"],
            userCategories: ["System"],
          },
        },
        {
          path: "manufacturers/:id/edit",
          component: ManufacturerEdit,
          meta: {
            requiresAuth: true,
            title: "Edit Manufacturer",
            roles: ["Administrator"],
            userCategories: ["System"],
          },
        },
        {
          path: "customers",
          component: Customers,
          meta: {
            requiresAuth: true,
            title: "Customers",
            roles: ["Administrator", "View Only", "Support Specialist", "Sales"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "customers/:id",
          component: CustomersDetails,
          meta: {
            requiresAuth: true,
            title: "Customers",
            roles: ["Administrator", "View Only", "Support Specialist", "Sales"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "customers/account/:id",
          component: CustomerTransaction,
          meta: {
            requiresAuth: true,
            title: "Customer Transactions",
            roles: ["Administrator", "View Only", "Support Specialist", "Sales"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "customers/edit/:id",
          component: CustomersEdit,
          meta: {
            requiresAuth: true,
            title: "Edit Customer",
            roles: ["Administrator", "Support Specialist", "Sales"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "campaigns",
          component: Campaigns,
          meta: {
            requiresAuth: true,
            title: "Campaigns",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "campaigns/:id/members",
          component: CampaignsMembers,
          meta: {
            title: "Campaigns",
            roles: ["Administrator", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "campaigns/create",
          component: CampaignsCreate,
          meta: {
            requiresAuth: true,
            title: "Create a new Campaign",
            roles: ["Administrator", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "campaigns/:id",
          component: CampaignsDetails,
          meta: {
            requiresAuth: true,
            title: "Campaigns",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "campaigns/:id/edit",
          component: CampaignsEdit,
          meta: {
            requiresAuth: true,
            title: "Edit Campaign",
            roles: ["Administrator", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "products",
          component: Products,
          meta: {
            requiresAuth: true,
            title: "Products",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "products-upload",
          component: ProductsUpload,
          meta: {
            requiresAuth: true,
            title: "Upload Products",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "manufacturer-products",
          component: ProductsManufacturer,
          meta: {
            requiresAuth: true,
            title: "Products",
            roles: ["Administrator", "View Only", "Distributor Manager"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-products/:id/manage-price-option",
          component: ManagePriceOption,
          props: true,
          name: "Manage Price Option",
          meta: {
            requiresAuth: true,
            title: "Manage Price Options",
            roles: ["Administrator", "View Only", "Distributor Manager"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-products/units/view",
          component: ProductUnits,
          meta: {
            requiresAuth: true,
            title: "Product Units",
            roles: ["Administrator"],
            userCategories: ["Manufacturer", "Distributor"],
          },
        },
        {
          path: "manufacturer-products/cat/view",
          component: ProductCategories,
          meta: {
            requiresAuth: true,
            title: "Product Categories",
            roles: ["Administrator"],
            userCategories: ["Manufacturer", "Distributor"],
          },
        },
        {
          path: "salesorders",
          component: PurchaseOrders,
          meta: {
            requiresAuth: true,
            title: "Sales Order",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "salesOrder",
          component: SalesForceSaleOrder,
          meta: {
            requiresAuth: true,
            title: "Sales Order",
            roles: ["Sales"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "salesOrder/:id/view",
          component: SalesForceSaleOrderView,
          meta: {
            requiresAuth: true,
            title: "Sales Order",
            roles: ["Sales"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "salesorders/new-sales-order",
          component: NewPurchaseOrder,
          meta: {
            requiresAuth: true,
            title: "New Sales Order",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "salesorders/:id/edit-sales-order",
          component: EditPurchaseOrder,
          meta: {
            requiresAuth: true,
            title: "Edit Sales Order",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },

        {
          path: "salesorders/:id/view-sales-order",
          component: ViewPurchaseOrder,
          meta: {
            requiresAuth: true,
            title: "View Sales Order",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },

        {
          path: "warehouses",
          component: Warehouses,
          meta: {
            requiresAuth: true,
            title: "Warehouses",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "storage-areas",
          component: StorageAreas,
          meta: {
            requiresAuth: true,
            title: "Storage Areas",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "managestock",
          component: ManageStock,
          meta: {
            requiresAuth: true,
            title: "ManageStock",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "managestock/:id",
          component: ManageStock,
          meta: {
            requiresAuth: true,
            title: "ManageStock",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "stocktransfer",
          component: StockTransfer,
          meta: {
            requiresAuth: true,
            title: "Stock Transfer",
            roles: ["Administrator", "View Only", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "stocktransfer/create/:id",
          component: StockTransferCreate,
          meta: {
            requiresAuth: true,
            title: "New Stock Transfers",
            roles: ["Administrator", "Support Specialist", "Sales"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "stocktransfer/view/:id",
          component: StockTransferView,
          meta: {
            requiresAuth: true,
            title: "View Stock Transfer",
            roles: ["Administrator", "Support Specialist", "Sales"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "reports/distributor",
          component: DistributorReports,
          meta: {
            requiresAuth: true,
            title: "Reports",
            roles: [
              "Administrator",
              "View Only",
              "Distributor Manager",
              "Support Specialist",
              "Sales",
            ],
            userCategories: ["System", "Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo],
          },
        },
        {
          path: "reports/distributorold",
          component: DistributorReportsOld,
          meta: {
            requiresAuth: true,
            title: "Reports",
            roles: [
              "Administrator",
              "View Only",
              "Distributor Manager",
              "Support Specialist",
              "Sales",
            ],
            userCategories: ["System", "Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo],
          },
        },
        {
          path: "reports/manufacturer",
          component: ManufacturerReports,
          meta: {
            requiresAuth: true,
            title: "Reports",
            roles: ["Administrator"],
            userCategories: ["System", "Manufacturer"],
          },
        },
        {
          path: "analytics/distributor",
          component: DistributorAnalytics,
          meta: {
            requiresAuth: true,
            title: "Distributors Analytics",
            roles: [
              "Administrator",
              "View Only",
              "Distributor Manager",
              "Support Specialist",
              "Sales",
            ],
            userCategories: ["System", "Distributor", "Manufacturer"],
          },
        },
        {
          path: "analytics/manufacturer",
          component: ManufacturerAnalytics,
          meta: {
            requiresAuth: true,
            title: "Enterprise Analytics",
            roles: ["Administrator"],
            userCategories: ["System", "Manufacturer"],
          },
        },
        {
          path: "analytics/sales",
          component: SalesAnalytics,
          meta: {
            requiresAuth: true,
            title: "Sales Analytics",
            roles: ["Administrator"],
            userCategories: ["System", "Manufacturer"],
          },
        },
        {
          path: "manufacturer-retailer-report",
          name: "ManufacturerRetailerReport",
          component: ManufacturerRetailerReport,
          meta: {
            requiresAuth: true,
            title: "Retailers Report",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-sales-by-product",
          name: "ManufacturerSalesByProduct",
          component: ManufacturerSalesByProduct,
          meta: {
            requiresAuth: true,
            title: "Sales By Product",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-total-orders-by-distributor",
          name: "ManufacturerTotalOrdersByDistributor",
          component: ManufacturerTotalOrdersByDistributor,
          meta: {
            requiresAuth: true,
            title: "Total Orders By Distributor",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-total-orders-by-state",
          name: "ManufacturerTotalOrdersByState",
          component: ManufacturerTotalOrdersByState,
          meta: {
            requiresAuth: true,
            title: "Total Orders By State",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-total-orders-by-status",
          name: "ManufacturerTotalOrdersByStatus",
          component: ManufacturerTotalOrdersByStatus,
          meta: {
            requiresAuth: true,
            title: "Total Orders By Status",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-retailers-by-aging-credit",
          name: "ManufacturerRetailersByAgingCredit",
          component: ManufacturerRetailersByAgingCredit,
          meta: {
            requiresAuth: true,
            title: "Retailers By Aging Credit",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-product-performance",
          name: "ManufacturerProductPerformance",
          component: ManufacturerProductPerformance,
          meta: {
            requiresAuth: true,
            title: "Product Performance",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-product-performance-distributor",
          name: "ManufacturerProductPerformanceDistributor",
          component: ManufacturerProductPerformanceByDistributor,
          meta: {
            requiresAuth: true,
            title: "Product Performance By Distributor",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-product-performance-state",
          name: "ManufacturerProductPerformanceState",
          component: ManufacturerProductPerformanceByState,
          meta: {
            requiresAuth: true,
            title: "Product Performance By State",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-margin-profile-by-distributor",
          name: "ManufacturerMarginProfileByDistributor ",
          component: ManufacturerMarginProfileByDistributor,
          meta: {
            requiresAuth: true,
            title: "Margin Profile By Distributor",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "manufacturer-expenses-by-distributor",
          name: "ManufacturerExpensesByDistributor",
          component: ManufacturerExpensesByDistributor,
          meta: {
            requiresAuth: true,
            title: "Expenses By Distributor",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "volume-by-customer",
          name: "volumeByCustomerReport",
          component: VolumeByCustomerReport,
          meta: {
            requiresAuth: true,
            title: "Volume By Customer Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "volume-by-product",
          name: "volumeByProductReport",
          component: VolumeByProductReport,
          meta: {
            requiresAuth: true,
            title: "Volume By Product Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "volume-by-product-category",
          name: "VolumeByProductCategoryReport",
          component: VolumeByProductCategoryReport,
          meta: {
            requiresAuth: true,
            title: "Volume By Category Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "daily-sales-by-order",
          name: "DailySalesByOrderReport",
          component: DailySalesByOrderReport,
          meta: {
            requiresAuth: true,
            title: "Orders",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "daily-sales-by-order-status",
          name: "DailySalesByOrderStatusReport",
          component: DailySalesByOrderStatusReport,
          meta: {
            requiresAuth: true,
            title: "Sales By Order Status Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "daily-sales-by-date",
          name: "DailySalesByDateReport",
          component: DailySalesByDateReport,
          meta: {
            requiresAuth: true,
            title: "Daily Sales Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "inventory-by-batch",
          name: "InventoryByBatchReport",
          component: InventoryByBatchReport,
          meta: {
            requiresAuth: true,
            title: "Inventory By Batch Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "inventory-by-product",
          name: "InventoryByProductReport",
          component: InventoryByProductReport,
          meta: {
            requiresAuth: true,
            title: "Inventory By Product Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "inventory-by-warehouse",
          name: "InventoryByWarehouseReport",
          component: InventoryByWarehouseReport,
          meta: {
            requiresAuth: true,
            title: "Inventory By Warehouse Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "inventory-by-product-category",
          name: "InventoryByProductCategoryReport",
          component: InventoryByProductCategoryReport,
          meta: {
            requiresAuth: true,
            title: "Inventory By Product Category Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "reconciliation-by-status",
          name: "ReconciliationByStatusReport",
          component: ReconciliationByStatusReport,
          meta: {
            requiresAuth: true,
            title: "Reconciliation By Status Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "reconciliation-by-order-number",
          name: "ReconciliationByOrderNumberReport",
          component: ReconciliationByOrderNumberReport,
          meta: {
            requiresAuth: true,
            title: "Reconciliation By Order Number Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "total-credit-sales-ytd",
          name: "TotalCreditSalesYTDReport",
          component: TotalCreditSalesYTDReport,
          meta: {
            requiresAuth: true,
            title: "Total Credit Sales YTD Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "total-payment-collection-ytd",
          name: "TotalSalesCollectionsYTDReport",
          component: TotalSalesCollectionsYTDReport,
          meta: {
            requiresAuth: true,
            title: "Total Payment Collection YTD Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "total-cash-credit-ratio",
          name: "TotalCashCreditRatioReport",
          component: TotalCashCreditRatioReport,
          meta: {
            requiresAuth: true,
            title: "Total Cash Credit Ratio Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "my-customers-report",
          name: "NewCustomerAcquisitionYTDReport",
          component: NewCustomerAcquisitionYTDReport,
          meta: {
            requiresAuth: true,
            title: "New Customer Acquisition YTD Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "delisted-customers-ytd-report",
          name: "DelistedCustomersYTDReport",
          component: DelistedCustomersYTDReport,
          meta: {
            requiresAuth: true,
            title: "Delisted Customers YTD Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "active-credit-sales-report",
          name: "ActiveCreditSalesByDateReport",
          component: ActiveCreditSalesByDateReport,
          meta: {
            requiresAuth: true,
            title: "Active Credit Sales By Date Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "active-cash-collections-report",
          name: "ActiveCashCollectionsByDateReport",
          component: ActiveCashCollectionsByDateReport,
          meta: {
            requiresAuth: true,
            title: "Active Cash Collections By Date Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "active-cash-credit-branch",
          name: "ActiveCashCreditRatioGrpByBranch",
          component: ActiveCashCreditRatioGrpByBranch,
          meta: {
            requiresAuth: true,
            title: "Active Cash Credit Ratio By Branch",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "active-cash-credit-customer",
          name: "ActiveCashCreditRatioGrpByCustomer",
          component: ActiveCashCreditRatioGrpByCustomer,
          meta: {
            requiresAuth: true,
            title: "Active Cash Credit Ratio By Customer",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "30_day_credit_outlets",
          name: "Total30DayCreditListofOutletsReport",
          component: Total30DayCreditListofOutletsReport,
          meta: {
            requiresAuth: true,
            title: "Total 30Day Credit ( Customer ) Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "60_day_credit_outlets",
          name: "Total60DayCreditListofOutletsReport",
          component: Total60DayCreditListofOutletsReport,
          meta: {
            requiresAuth: true,
            title: "Total 60Day Credit (Customer ) Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "90_day_credit_outlets",
          name: "Total90DayCreditListofOutletsReport",
          component: Total90DayCreditListofOutletsReport,
          meta: {
            requiresAuth: true,
            title: "Total 90Day Credit ( Customer ) Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "active_cash_collections_Introducer",
          name: "ActiveCashCollectionsByIntroducerReport",
          component: ActiveCashCollectionsByIntroducerReport,
          meta: {
            requiresAuth: true,
            title: "Active Cash Collections By Introducer Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "active_cash_collections_branch",
          name: "ActiveCashCollectionsByBranchReport",
          component: ActiveCashCollectionsByBranchReport,
          meta: {
            requiresAuth: true,
            title: "Active Cash Collections By Branch Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "active_credit_sales_branch",
          name: "ActiveCreditSalesByBranchReport",
          component: ActiveCreditSalesByBranchReport,
          meta: {
            requiresAuth: true,
            title: "Active Credit Sales By Branch Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "active_credit_sales_introducer",
          name: "ActiveCreditSalesByIntroducerReport",
          component: ActiveCreditSalesByIntroducerReport,
          meta: {
            requiresAuth: true,
            title: "Active Credit Sales By Introducer Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "active_orders_customer",
          name: "ActiveOrdersByCustomerReport",
          component: ActiveOrdersByCustomerReport,
          meta: {
            requiresAuth: true,
            title: "Active Orders By Customer Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "active_orders_orderstatus",
          name: "ActiveOrdersByOrderStatusReport",
          component: ActiveOrdersByOrderStatusReport,
          meta: {
            requiresAuth: true,
            title: "Active Orders By OrderStatus Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "monthly_sales_introducer",
          name: "AvgMonthlySalesByIntroducerReport",
          component: AvgMonthlySalesByIntroducerReport,
          meta: {
            requiresAuth: true,
            title: "Monthly Sales By Introducer Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "monthly_collections_introducer",
          name: "AvgMonthlyCollectionsByIntroducerReport",
          component: AvgMonthlyCollectionsByIntroducerReport,
          meta: {
            requiresAuth: true,
            title: "Monthly Collections By Introducer Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "product_performance_by_percentage",
          name: "productPerformanceByPercentageReport",
          component: ProductPerformanceByPercentageReport,
          meta: {
            requiresAuth: true,
            title: "Product Performance Rating By Percentage Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "sales-margins",
          name: "SalesMarginsReport",
          component: SalesMarginsReport,
          meta: {
            requiresAuth: true,
            title: "Sales Margins Report",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "stock_purchase_all",
          name: "StockPurchaseReportAllReport",
          component: StockPurchaseReportAllReport,
          meta: {
            requiresAuth: true,
            title: "Stock Purchase Report (All)",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "stock_purchase_consolidated",
          name: "StockPurchaseReportConsolidated",
          component: StockPurchaseReportConsolidated,
          meta: {
            requiresAuth: true,
            title: "Stock Purchase Report (Consolidated)",
            roles: [
              "Administrator",
              "View Only",
              "Support Specialist",
              "Sales",
              "Distributor Manager",
            ],
            userCategories: ["Distributor", "Manufacturer"],
            breadCrumbsRoutes: [dashboardRouteInfo, distributorReportRouteInfo],
          },
        },
        {
          path: "/settings/discounts",
          component: Discounts,
          meta: {
            requiresAuth: true,
            title: "Discounts",
            roles: ["Administrator", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "/settings/discounts/:id/members",
          component: DiscountMembers,
          meta: {
            requiresAuth: true,
            title: "Discounts",
            roles: ["Administrator", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "/settings/groups",
          component: Groups,
          meta: {
            requiresAuth: true,
            title: "Groups",
            roles: ["Administrator", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "/settings/groups/:id/members",
          component: GroupMembers,
          meta: {
            requiresAuth: true,
            title: "Groups",
            roles: ["Administrator", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "/settings/payment",
          component: Payment,
          meta: {
            requiresAuth: true,
            title: "Teams",
            roles: ["Administrator", "Support Specialist"],
            userCategories: ["System", "Distributor", "Manufacturer"],
          },
        },
        {
          path: "/settings/team",
          component: Team,
          meta: {
            requiresAuth: true,
            title: "Teams",
            roles: ["Administrator", "Support Specialist"],
            userCategories: ["System", "Distributor", "Manufacturer"],
          },
        },
        {
          path: "/settings/business",
          component: Business,
          meta: {
            requiresAuth: true,
            title: "Business Profile",
            roles: ["Administrator", "Support Specialist"],
            userCategories: ["Distributor", "Manufacturer"],
          },
        },
        {
          path: "/settings/branch",
          component: Branches,
          meta: {
            requiresAuth: true,
            title: "Branches",
            roles: ["Administrator", "Support Specialist"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "/settings/systemSettings",
          component: SystemSettings,
          meta: {
            requiresAuth: true,
            title: "System Settings",
            roles: ["Administrator"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "/settings/manufacturersystemSettings",
          component: ManufacturerSystemSettings,
          meta: {
            requiresAuth: true,
            title: "System Settings",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "/settings/expenseCategory",
          component: ExpenseCategory,
          meta: {
            requiresAuth: true,
            title: "Expense Categories",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "/settings/paymentChannel",
          component: PaymentChannel,
          meta: {
            requiresAuth: true,
            title: "Payment Channels",
            roles: ["Administrator"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "/settings/roles",
          component: Roles,
          meta: {
            requiresAuth: true,
            title: "Roles",
            roles: ["Administrator"],
            userCategories: ["Distributor", "Manufacturer"],
          },
        },
        {
          path: "/settings/roles/:id/users",
          component: RoleUsers,
          meta: {
            requiresAuth: true,
            title: "Role Users",
            roles: ["Administrator"],
            userCategories: ["Distributor", "Manufacturer"],
          },
        },
        {
          path: "/settings/geofence",
          component: GeoFencing,
          meta: {
            requiresAuth: true,
            title: "GeoFencing",
            roles: ["Administrator"],
            userCategories: ["Distributor"],
          },
        },
        {
          path: "/sales-order",
          component: PurchaseOrderProcess,
          meta: {
            requiresAuth: true,
            title: "Sales Order Process ",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "/sales-order/:id/edit",
          component: ManufacturerEditPurchaseorder,
          meta: {
            requiresAuth: true,
            title: "Sales Order Edit ",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "/sales-order/:id/view",
          component: ManufacturerViewPurchaseOrder,
          meta: {
            requiresAuth: true,
            title: "Sales Order View ",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "/sales-order/:id/process",
          component: ProcessSalesOrder,
          meta: {
            requiresAuth: true,
            title: "Sales Order View ",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },

        {
          path: "/sales-order/upload",
          component: UploadSalesOrder,
          meta: {
            requiresAuth: true,
            title: "Upload Sales Order",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },

        {
          path: "/sales-order/item/setup",
          component: PurchaseOrderItemSetup,
          meta: {
            requiresAuth: true,
            title: "Sales Order Item Setup ",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },

        {
          path: "/sales-order/stage/setup",
          component: PurchaseOrderStageSetup,
          meta: {
            requiresAuth: true,
            title: "Sales Order Stage Setup ",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "/sales-force/sales-roles",
          name: "SalesRoles",
          component: SalesRoles,
          meta: {
            requiresAuth: true,
            title: "Sales Roles",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "/sales-force/sales-roles/:id/users",
          component: SalesRoleUsers,
          meta: {
            requiresAuth: true,
            title: "Sales Role Users",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },
        {
          path: "/sales-force/sales-team",
          name: "SalesTeam",
          component: SalesTeam,
          meta: {
            requiresAuth: true,
            title: "Sales Team",
            roles: ["Administrator"],
            userCategories: ["Manufacturer"],
          },
        },

        {
          path: "account",
          component: Account,
          meta: {
            requiresAuth: true,
            title: "Account",
            roles: ["Administrator", "Support Specialist", "View Only", "Customer", "Sales"],
            userCategories: ["System", "Distributor", "Manufacturer"],
          },
        },
        {
          path: "statistics",
          component: Statistics,
          meta: {
            requiresAuth: true,
            title: "Statistics",
            roles: ["Administrator"],
            userCategories: ["System"],
          },
        },

        {
          path: "notifications",
          component: Notifications,
          meta: {
            requiresAuth: true,
            title: "Notifications",
            roles: ["Administrator", "Support Specialist", "View Only", "Customer", "Sales"],
            userCategories: ["System", "Distributor", "Manufacturer"],
          },
        },
      ],
    },
    {
      path: "/set-preference",
      name: "SetPreference",
      component: SetPreference,
      meta: {
        title: "Preference",
        requiresAuth: true,
        roles: ["Administrator"],
        windowRedirectAfter: true,
        userCategories: ["Distributor"],
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        requiresGuest: true,
        windowRedirectAfter: true,
      },
    },
    {
      path: "/growtrade-onboarding",
      name: "growtrade-onboarding",
      component: GrowtradeOnboarding,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/growtrade-forgotPassword",
      name: "growtrade-forgotPassword",
      component: GrowTradeForgotPassword,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/growtrade-resetPassword",
      name: "growtrade-resetPassword",
      component: GrowtradeResetPassword,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/growtrade-login",
      name: "growtrade-login",
      component: GrowtradeLogin,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/growtrade-register",
      name: "growtrade-register",
      component: GrowtradeRegister,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/register",
      name: "register",
      component: Register,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/join/:token",
      name: "join",
      component: Join,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/join",
      name: "join",
      component: Join,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/sales-user-join/:token",
      name: "join sales user",
      component: JoinSalesUser,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/sales-user-join",
      name: "join sales user",
      component: JoinSalesUser,
      meta: {
        requiresGuest: true,
      },
    },
    //{
    //    path: '/join',
    //    name: 'joinRedirect',
    //    redirect: '/login'
    //},
    {
      path: "/link-account/:token",
      name: "linkAccount",
      component: LinkAccount,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/link-account",
      name: "linkAccountRedirect",
      redirect: "/login",
    },
    {
      path: "/forgot-password",
      name: "forgotPassword",
      component: ForgotPassword,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/change-password",
      name: "changePassword",
      component: ChangePassword,
      meta: {
        requiresGuest: true,
      },
    },
  ],
});
