<template>
  <select
    :disabled="disable"
    class="custom-select custom-select-md"
    v-model="selectedStage"
    @change="onStageSelect($event.target.value)"
  >
    <option
      v-for="p in orderStages"
      :class="p.disable ? 'disabled' : ''"
      :disabled="p.disable"
      :key="p.id"
      :value="p.id"
    >
      {{ p.name }}
    </option>
  </select>
</template>

<script>
export default {
  props: ["stage", "orderId", "disable", "shouldReset"],

  data() {
    return {
      selectedStage: "",
      orderStages: [],
      isPrepared: false,
    };
  },

  created() {
    this.getOrderStages();
  },

  updated() {
    if (this.shouldReset) {
      this.selectedStage = "";
      this.isPrepared = false;
    }

    if (this.stage && !this.isPrepared) {
      this.prepareOrderStage();
    }
  },

  methods: {
    async getOrderStages() {
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/orderStage/getList?active=true"
        );

        if (response.ok && response.status === 200) {
          this.orderStages = response.body.sort(
            (firstItem, secondItem) => firstItem.stage - secondItem.stage
          );
        }
      } catch (error) {}
    },

    current() {
      return this.orderStages.find((st) =>
        this.stage ? st.id === this.stage : ""
      );
    },

    onStageSelect(value) {
      const stage = this.orderStages.find((stage) => stage.id === value);
      const current = this.current();

      this.$emit("showCaution", {
        current: current ? current.name : "",
        next: stage.name,
        stageId: value,
      });
    },

    prepareOrderStage() {
      const stages = [];
      const current = this.current();
      for (let index = 0; index < this.orderStages.length; index++) {
        const element = this.orderStages[index];
        if (element.active && element.id !== this.stage) {
          element.disable = current ? element.stage < current.stage : false;
          stages.push(element);
        }
      }

      this.isPrepared = true;
      this.orderStages = stages;
    },
  },
};
</script>

<style>
.disabled {
  color: gainsboro;
}
</style>