<template>
    <div class="row">
        <div class="col-lg-12">
            <div :class="['card', 'card-border-color', 'card-border-color-primary', ' card-table', 'be-loading', {'be-loading-active':isBusy}]">
                <div class="card-header">
                    <h4><b>{{ discountName }} members</b></h4>
                    <div class="btn-group">
                        <button v-if="hasPermission('Discount_Create_Members')"
                                class="btn btn-primary btn-rounded btn-space float-right"
                                @click="viewAdd">
                            <span class="icon icon-left mdi mdi-plus text-white"></span> Add member
                        </button>

                        <router-link class="btn btn-space btn-success btn-rounded float-right"
                                     to="/settings/discounts">
                            <span class="icon icon-left mdi mdi-arrow-left text-white"></span> Back
                        </router-link>
                    </div>
                </div>

                <table class="table table-fw-widget dataTable no-footer table-striped table-hover-animation table-bordered">
                    <thead>
                        <tr>
                            <th style="width:55%;">Name</th>
                            <th style="width:20%;">Member Type</th>
                            <th style="width:15%;">Joined</th>
                            <th style="width:10%;"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="m in members" :key="m.id">
                            <td>{{ m.memberName }}</td>
                            <td>{{ m.type }}</td>
                            <td>{{ formatDate(m.joined) }}</td>
                            <td>
                                <div class="text-right" v-if="hasPermission('Discount_Remove_Members')">
                                    <button v-if="hasPermission('Discount_Remove_Members')" class="btn btn-danger btn-sm btn-rounded btn-space"
                                            @click.prevent="remove(m.id)">
                                        <i class="fa fa-times"></i> Remove
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="!members.length">
                            <td colspan="4">
                                <div class="text-center">No members</div>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="be-spinner">
                    <svg width="40px" height="40px" viewBox="0 0 66 66">
                        <circle class="circle"
                                fill="none"
                                stroke-width="4"
                                stroke-linecap="round"
                                cx="33"
                                cy="33"
                                r="30"></circle>
                    </svg>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><b>Add new member</b></h4>
                        <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <p>Some information about the discount policy...</p>

                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Member Type</label>
                            <div class="col-12 col-sm-7 col-lg-7">
                                <select class="form-control" placeholder="Select an option" v-model="newMember.type">
                                    <!-- <select :class="['form-control', { 'is-invalid':v1.type }]" v-model="newMember.type"> -->
                                    <option v-for="type in types" :key="type" :value="type">{{ type }}</option>
                                </select>
                                <ul v-if="v1.type" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-4 col-form-label text-sm-right">Member Name</label>
                            <div class="col-12 col-sm-7 col-lg-7">
                                <select class="form-control" placeholder="Select an option" v-model="newMember.memberId">
                                    <!-- <select :class="['form-control', { 'is-invalid':v1.memberId }]" v-model="newMember.memberId"> -->
                                    <!-- <option v-if="isBusyModal" value="">Loading...</option> -->
                                    <option v-for="m in newMembers" :key="m.id" :value="m.id">{{ m.text }}</option>
                                </select>
                                <ul v-if="v1.memberId" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px;"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 100px;"
                                :disabled="isBusyModal"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="add">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Save</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */

    import moment from "moment";
    import Select2 from "@/components/Select2";
    import SystemPermissions from '@/permissions'

    export default {
        mixins: [SystemPermissions.Mixin],
        name: "settingsDiscountMembers",
        components: {
            Select2
        },
        created() {
            if (!this.hasPermission('Discount_View_Members')) {
                this.$router.push("/restricted");
            }
            this.discountId = this.$route.params.id;
            this.getDiscount();
            this.getMembers();
            this.getTypes();
        },
        data() {
            return {
                discountId: null,
                discountName: "",
                isBusy: false,
                isBusyModal: false,
                newMember: {
                    memberId: null,
                    type: null,
                    discountId: null
                },
                v1: {
                    memberId: false,
                    type: false
                },
                members: [],
                types: [],
                newMembers: []
            };
        },
        computed: {
            user: function () {
                return this.$store.state.currentUser;
            }
        },
        watch: {
            "newMember.type": function (newVal, oldVal) {
                this.onTypeChange(newVal);
            }
        },
        methods: {
            viewAdd() {
                this.newMember = {
                    memberId: null,
                    type: null,
                    discountId: this.discountId
                };
                this.v1 = {
                    memberId: false,
                    type: false
                };

                $("#mod-add").modal("show");
            },
            add() {
                this.isBusyModal = true;
                this.isBusy = true;

                if (this.validate()) {
                    $("#mod-add").modal("hide");

                    this.$http
                        .post("/Discounts/addMember", this.newMember)
                        .then(function (res) {
                            this.isBusyModal = false;
                            this.getMembers();
                        })
                        .catch(function () {
                            this.isBusyModal = false;
                            this.isBusy = false;
                        });
                } else {
                    this.isBusyModal = false;
                    this.isBusy = false;
                }
            },
            validate() {
                this.v1 = {
                    memberId: false,
                    type: false
                };
                var isValid = true;

                if (!this.newMember.memberId) {
                    this.v1.memberId = true;
                    isValid = false;
                }

                if (!this.newMember.type) {
                    this.v1.type = true;
                    isValid = false;
                }

                return isValid;
            },
            remove(id) {
                this.isBusy = true;

                this.$http
                    .delete("/Discounts/deleteMember/" + id)
                    .then(function (res) {
                        this.isBusy = false;
                        this.getMembers();
                    })
                    .catch(function () {
                        this.isBusy = false;
                    });
            },
            getDiscount() {
                this.isBusy = true;

                this.$http
                    .get("/Discounts/getById/" + this.discountId)
                    .then(function (res) {
                        this.discountName = res.body.name;
                        this.isBusy = false;
                    })
                    .catch(function () {
                        this.isBusy = false;
                    });
            },
            getMembers() {
                this.isBusy = true;

                this.$http
                    .get("/Discounts/getMembers/" + this.discountId)
                    .then(function (res) {
                        this.members = res.body;
                        this.isBusy = false;
                    })
                    .catch(function () {
                        this.isBusy = false;
                    });
            },
            getTypes() {
                this.types = ["Group", "Customer"];
            },
            onTypeChange(type) {
                this.newMembers = [];
                this.newMember.memberId = "";

                if (type) {
                    switch (type) {
                        case "Group":
                            this.isBusyModal = true;

                            this.$http
                                .get("/Groups/getItemList/" + this.user.parentId)
                                .then(function (res) {
                                    this.newMembers = res.body;
                                    this.isBusyModal = false;
                                })
                                .catch(function () {
                                    this.isBusyModal = false;
                                });
                            break;
                        case "Merchant":
                            this.isBusyModal = true;

                            this.$http
                                .get(
                                    "/Accounts/getItemList?parentId=" +
                                    this.user.parentId +
                                    "&accountType=" +
                                    this.user.type
                                )
                                .then(function (res) {
                                    this.newMembers = res.body;
                                    this.isBusyModal = false;
                                })
                                .catch(function () {
                                    this.isBusyModal = false;
                                });
                            break;
                        case "Customer":
                            this.isBusyModal = true;

                            this.$http
                                .get("/Users/getItemListOfCustomers/" + this.user.parentId)
                                .then(function (res) {
                                    this.newMembers = res.body;
                                    this.isBusyModal = false;
                                })
                                .catch(function () {
                                    this.isBusyModal = false;
                                });
                            break;
                        default:
                            break;
                    }
                }
            },
            formatDate(date) {
                return moment(date).format("ll");
            }
        }
    };
</script>