<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-new-distributor"
    data-toggle="modal"
    data-backdrop="static"
    data-keyboard="false"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title p-1 distro-modal-title">Hi {{ name }},</h4>
        </div>
        <div class="modal-body">
          <p class="distro-desc">
            You would need to add some distributors.
          </p>
        </div>

        <div class="modal-footer d-flex justify-content-center align-items-center">
          <button class="btn btn-primary" type="button" @click="addDistributor">
            Add Distributor
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddDistributorModal",
  props: ["name"],

  methods: {
    addDistributor() {
      $("#mod-new-distributor").modal("hide");
      this.$router.push("/merchant/distributors/add");
    },
  },
};
</script>

<style scoped>
.modal-header {
  background: #fff !important;
}
.distro-modal-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  width: 100%;
}

.distro-desc {
  text-align: center;
}

.modal-footer {
  border-color: transparent;
}

button {
  width: 100%;
  height: 64px;
  font-weight: 700;
}
</style>
