<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Invite new user</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Email address</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                :class="[
                  'form-control',

                  { 'is-invalid': $v.invitedUser.emailAddress.$error },
                ]"
                type="email"
                placeholder="Email address of user"
                autocomplete="off"
                v-model.trim="$v.invitedUser.emailAddress.$model"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.emailAddress.required"
              >
                Field is required.
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.emailAddress.email"
              >
                Email is invalid.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Role</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <select
                :class="[
                  'form-control',

                  { 'is-invalid': $v.invitedUser.role.$error },
                ]"
                placeholder="Select an option"
                v-model.trim="$v.invitedUser.role.$model"
              >
                <option v-for="r in roles" :key="r.id" :value="r.id">
                  {{ r.text }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.role.required"
              >
                Field is required.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Staff Id</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                class="form-control"
                type="text"
                placeholder="Staff Id of user"
                v-model="invitedUser.staffId"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-secondary"
            type="button"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            style="width: 100px"
            :disabled="isInviting"
            class="btn btn-primary"
            type="button"
            @click.prevent="invite"
          >
            <span
              v-if="isInviting"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else>Invite</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";
export default {
  name: "InviteSalesUser",

  props: ["roles"],

  data() {
    return {
      isInviting: false,
      invitedUser: {
        phoneNumber: "",
        emailAddress: "",
        lastname: "",
        othernames: "",
        role: "",
        staffId: "",
      },
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  validations: {
    invitedUser: {
      emailAddress: { required, email },
      role: { required },
    },
  },

  methods: {
    async invite() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isInviting = true;

        let data = {
          phoneNumber: this.invitedUser.phoneNumber,
          emailAddress: this.invitedUser.emailAddress,
          lastName: this.invitedUser.lastName,
          otherNames: this.invitedUser.otherNames,
          roleId: this.invitedUser.role,
          parentId: this.user.parentId,
          staffId: this.invitedUser.staffId,
        };

        try {
          const response = await this.$http.post("/SalesUsers/invite", data);
          if (response.ok) this.$emit("onInviteComplete");
        } catch (error) {}
        this.isInviting = false;
      }
    },
  },
};
</script>

<style>
</style>