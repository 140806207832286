<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add-posting"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong>New Account Posting</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="cancel"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Customer</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <select
                :class="[
                  'form-control',

                  { 'is-invalid': $v.posting.customerId.$error },
                ]"
                placeholder="Select a customer"
                v-model.trim="$v.posting.customerId.$model"
              >
                <option v-for="c in customers" :key="c.id" :value="c.id">
                  {{ c.text }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                v-if="!$v.posting.customerId.required"
              >
                Field is required.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Posting Type</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <select
                :class="[
                  'form-control',
                  { 'is-invalid': $v.posting.paymentType.$error },
                ]"
                placeholder="Select an option"
                v-model.trim="$v.posting.paymentType.$model"
              >
                <option value=""></option>
                <option
                  v-for="option in paymentItem"
                  :key="option.value"
                  :value="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                v-if="!$v.posting.paymentType.required"
              >
                Field is required.
              </div>
            </div>
          </div>
          <div v-if="posting.paymentType === 0" class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Payment Channel</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <select
                :class="[
                  'form-control',
                  { 'is-invalid': $v.posting.paymentChannelId.$error },
                ]"
                placeholder="Select an option"
                v-model.trim="$v.posting.paymentChannelId.$model"
              >
                <option
                  v-for="br in paymentChannels"
                  :key="br.id"
                  :value="br.id"
                >
                  {{ prepareDetail(br) }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                v-if="!$v.posting.paymentChannelId.required"
              >
                Field is required.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right">
              Enter Amount</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                :class="[
                  'form-control',
                  { 'is-invalid': $v.posting.amount.$error },
                ]"
                type="number"
                placeholder="Enter Amount"
                v-model.trim="$v.posting.amount.$model"
              />
              <div class="invalid-feedback" v-if="!$v.posting.amount.required">
                Field is required.
              </div>
            </div>
          </div>
          <div v-if="posting.paymentType !== 0" class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right">
              Enter Note</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <textarea
                :class="[
                  'form-control',
                  { 'is-invalid': $v.posting.paymentNotes.$error },
                ]"
                type="text"
                placeholder="Enter Note"
                autocomplete="off"
                v-model.trim="$v.posting.paymentNotes.$model"
              ></textarea>
              <div
                class="invalid-feedback"
                v-if="
                  posting.paymentType !== 0 && !$v.posting.paymentNotes.required
                "
              >
                Field is required.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right">
              Posting Date</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                :class="[
                  'form-control',
                  { 'is-invalid': $v.posting.postingDate.$error },
                ]"
                type="date"
                placeholder="Enter payment date"
                v-model.trim="$v.posting.postingDate.$model"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.posting.postingDate.required"
              >
                Field is required.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-secondary md-close"
            type="button"
            data-dismiss="modal"
            @click="cancel"
          >
            Cancel
          </button>
          <button
            style="width: 160px"
            :disabled="isPosting"
            class="btn btn-primary"
            type="button"
            @click.prevent="create"
          >
            <span
              v-if="isPosting"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else>Post</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import datePicker from "vue-bootstrap-datetimepicker";

export default {
  components: { datePicker },

  data() {
    return {
      posting: {
        customerId: "",
        paymentType: "",
        amount: 0,
        paymentNotes: "",
        postingDate: "",
        paymentChannelId: "",
      },

      paymentItem: [
        {
          name: "Payment",
          value: 0,
        },
        {
          name: "Credit Note",
          value: 1,
        },
        {
          name: "Debit Note",
          value: 2,
        },
      ],

      customers: [],
      paymentChannels: [],
      isPosting: false,
    };
  },

  validations() {
    let posting = {
      paymentType: { required },
      amount: { required },
      postingDate: { required },
      customerId: { required },
      postingDate: { required },
    };

    if (this.posting.paymentType === 0)
      posting = { ...posting, paymentChannelId: { required } };
    else posting = { ...posting, paymentNotes: { required } };

    return { posting };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  async created() {
    await this.getCustomers();
    await this.getPaymentChannels();
  },

  methods: {
    async create() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isPosting = true;
        const data = {
          ...this.posting,
        };
        try {
          const response = await this.$http.post("/AccountPosting", data);
          if (response.ok) {
            this.$toast.success("Posted to account succesfully", {
              icon: true,
              rtl: false,
            });
            this.reset();
            this.$emit("onPostAccountComplete");
          }
        } catch (error) {}
        this.isPosting = false;
      }
    },

    async getCustomers() {
      try {
        const response = await this.$http.get(
          "/Users/getItemListOfCustomers/" + this.user.parentId
        );

        if (response.ok) {
          this.customers = response.body;
        }
      } catch (error) {}
    },

    async getPaymentChannels() {
      try {
        const response = await this.$http.get(
          "/PaymentChannels/getListNoPaging?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        );

        if (response.ok) this.paymentChannels = response.body;
      } catch (error) {}
    },

    prepareDetail(value) {
      const name = value.paymentChannelTypeName;
      let result = "";
      if (name) {
        result += name;

        if (name.toLowerCase() === "bank") {
          result += "/ " + value.bankName + "/ " + value.accountNumber;
        } else if (name.toLowerCase() === "pos") {
          result += "/ " + value.bankName + "/ " + value.terminalId;
        } else if (name.toLowerCase() === "cryptocurrency") {
          result += "/ " + value.bankName + "/ " + value.walletAddress;
        }
      }
      return result;
    },

    cancel() {
      this.reset();
      this.$emit("onPostAccountCancel");
    },

    reset() {
      this.posting = {
        paymentType: "",
        amount: 0,
        paymentNotes: "",
        postingDate: "",
        paymentChannelId: "",
        customerId: "",
      };

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },
  },
};
</script>