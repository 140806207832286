<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <h5 class="card-title">PO Number - {{ orderNumber }}</h5>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <form @submit.prevent="onProceed('submitted')" class="col">
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label"
                      >Order for:</label
                    >
                    <div class="col-sm-5">
                      <input
                        type="text"
                        readonly
                        class="form-control-plaintext"
                        :value="user.parent.name"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label"
                      >Delivery Address:</label
                    >
                    <div class="col-sm-9">
                      <textarea
                        class="form-control"
                        :class="{
                          'is-invalid': $v.order.deliveryAddress.$error,
                        }"
                        v-model.trim="$v.order.deliveryAddress.$model"
                      ></textarea>
                      <span
                        class="invalid-feedback"
                        v-if="!$v.order.deliveryAddress.minLength"
                      >
                        Delivery Address must have at least
                        {{ $v.order.deliveryAddress.$params.minLength.min }}
                        letters.
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-6">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="contact-details"
                          v-model="showContact"
                        />
                        <label
                          class="custom-control-label"
                          for="contact-details"
                          >Add Contact Details</label
                        >
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="special-instruction"
                          v-model="showSpecialInstructions"
                        />
                        <label
                          class="custom-control-label"
                          for="special-instruction"
                          >Add Special Instructions</label
                        >
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="showContact"
                    class="mt-4 p-2 rounded-md"
                    style="background: #fafafa"
                  >
                    <div class="form-group row">
                      <label class="col-12 col-sm-3 col-form-label"
                        >Contact Name</label
                      >
                      <div class="col-12 col-sm-8 col-lg-4">
                        <input
                          class="form-control"
                          type="text"
                          :class="{
                            'is-invalid': $v.order.contact.name.$error,
                          }"
                          v-model.trim="$v.order.contact.name.$model"
                        />
                        <span
                          class="invalid-feedback"
                          v-if="!$v.order.contact.name.minLength"
                        >
                          Name must have at least
                          {{ $v.order.contact.name.$params.minLength.min }}
                          letters.
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-12 col-sm-3 col-form-label"
                        >Contact Phone</label
                      >
                      <div class="col-12 col-sm-8 col-lg-4">
                        <input
                          class="form-control"
                          type="tel"
                          :class="{
                            'is-invalid': $v.order.contact.phoneNumber.$error,
                          }"
                          v-model.trim="$v.order.contact.phoneNumber.$model"
                        />
                        <span
                          class="invalid-feedback"
                          v-if="!$v.order.contact.phoneNumber.minLength"
                        >
                          Phone number is not valid
                        </span>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-12 col-sm-3 col-form-label"
                        >Contact Email</label
                      >
                      <div class="col-12 col-sm-8 col-lg-4">
                        <input
                          class="form-control"
                          type="email"
                          :class="{
                            'is-invalid': $v.order.contact.email.$error,
                          }"
                          v-model.trim="$v.order.contact.email.$model"
                        />
                        <span
                          class="invalid-feedback"
                          v-if="!$v.order.contact.email.minLength"
                        >
                          Email is not valid
                        </span>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="showSpecialInstructions"
                    class="mt-4 p-2 rounded-md"
                    style="background: #fafafa"
                  >
                    <div class="form-group row">
                      <label class="col-12 col-sm-3 col-form-label"
                        >Special Instructions</label
                      >
                      <div class="col-12 col-sm-9 col-lg-9">
                        <textarea
                          class="form-control"
                          :class="{
                            'is-invalid': $v.order.specialInstructions.$error,
                          }"
                          v-model.trim="$v.order.specialInstructions.$model"
                        ></textarea>
                        <span
                          class="invalid-feedback"
                          v-if="!$v.order.specialInstructions.minLength"
                        >
                          Special instructions must be at least
                          {{
                            $v.order.specialInstructions.$params.minLength.min
                          }}
                          characters.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <PurchaseOrderDetail
                :items="addedItems"
                @onDeleteItem="onDeleteItemHandler"
              />
              <div class="col mt-5">
                <div class="row justify-content-end">
                  <div class="col col-md-4 align-self-end">
                    <div class="row justify-content-betweeen">
                      <div class="col-6">
                        <b class="text-uppercase">Sub-total</b>
                      </div>
                      <div class="col-6">
                        <p>&#8358; {{ subTotal }}</p>
                      </div>
                    </div>
                    <div class="row justify-content-betweeen">
                      <div class="col-6">
                        <b class="text-uppercase">Total Payable</b>
                      </div>
                      <div class="col-6">
                        <p>&#8358; {{ subTotal }}</p>
                      </div>
                    </div>
                    <div class="d-flex justify-content-between mt-3">
                      <button
                        type="button"
                        @click="goBack"
                        class="btn btn-danger"
                      >
                        Cancel
                      </button>
                      <button
                        :disabled="isBusyModal"
                        type="button"
                        @click="onProceed('draft')"
                        class="btn btn-secondary"
                      >
                        <span v-if="isBusyModal">Busy...</span>
                        <span v-else> Save to draft</span>
                      </button>
                      <button
                        :disabled="isBusyModal"
                        type="submit"
                        class="btn btn-primary"
                      >
                        <span v-if="isBusyModal">Busy...</span>
                        <span v-else>Generate Purchase Order</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <AddItem
      :products="manufacturerProducts"
      :filteredProducts="filteredProducts"
      @onItemAdded="onItemAddHandler"
      :groupId="groupId"
      :edit="item"
    />
  </div>
</template>

<script>
import { required, minLength, helpers, email } from "vuelidate/lib/validators";
import { helper } from "@/helper";

import PurchaseOrderDetail from "../../components/PurchaseOrder/PurchaseOrderDetail";
import AddItem from "../../components/PurchaseOrder/AddItem";

// eslint-disable-next-line no-useless-escape
const phone = helpers.regex("alpha", /^[0]\d{10}$|[\+]?[234]\d{12}$/);

export default {
  components: { PurchaseOrderDetail, AddItem },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      orderNumber: "",
      showContact: false,
      showSpecialInstructions: false,
      manufacturerProducts: [],
      filteredProducts: [],
      addedItems: [],
      groupId: "",
      item: null,

      order: {
        deliveryAddress: "",
        contact: {
          name: "",
          phoneNumber: "",
          email: "",
        },

        specialInstructions: "",
      },
    };
  },

  validations: {
    order: {
      deliveryAddress: {
        required,
        minLength: minLength(3),
      },

      contact: {
        name: {
          minLength: minLength(3),
        },

        phoneNumber: {
          phone,
        },

        email: {
          email,
        },
      },

      specialInstructions: {
        minLength: minLength(5),
      },
    },
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },

    subTotal() {
      let result = 0;
      this.addedItems.forEach((item) => {
        result += item.quantity * item.price;
      });
      return this.formatMoney(result);
    },
  },

  mounted() {
    this.initializeOrder();
  },

  methods: {
    async getManufacturerProducts() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/Products/getListNoPagingForManufacturer?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.groupId ? "&groupId=" + this.groupId : "")
        );
        if (response.ok)
          this.manufacturerProducts = this.filteredProducts = response.body;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    onItemAddHandler(newItem) {
      const product = this.manufacturerProducts.find(
        (product) => product.id === newItem.productId
      );

      this.filteredProducts = this.filteredProducts.filter(
        (product) => product.id !== newItem.productId
      );

      this.prepareItem(product, newItem);
    },

    prepareItem(product, newItem) {
      const preparedItem = {
        quantity: newItem.quantity,
        description: `${product.name} (${product.code}) - ${newItem.price}/${product.unit}`,
        price: newItem.price,
        id: product.id,
      };

      this.addedItems.push(preparedItem);

      // eslint-disable-next-line no-undef
      $("#mod-item-add").modal("hide");
    },

    onDeleteItemHandler(id) {
      const product = this.manufacturerProducts.find(
        (product) => product.id === id
      );

      this.addedItems = this.addedItems.filter((item) => item.id !== id);
      this.filteredProducts.push(product);
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    async initializeOrder() {
      this.isBusy = true;
      try {
        const response = await this.$http.get("/PurchaseOrders/initialize");
        if (response.ok) {
          this.orderNumber = response.body.orderNumber;
          this.groupId = response.body.groupId;
          this.getManufacturerProducts();
        }

        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    async onProceed(status) {
      if (this.addedItems.length > 0) {
        this.isBusy = true;
        this.isBusyModal = true;
        try {
          const data = {
            orderNumber: this.orderNumber,
            subTotal: this.subTotal,
            total: this.subTotal,
            deliveryAddress: this.order.deliveryAddress,
            details: this.addedItems,
            date: Date.now(),
            orderContact: this.order.contact,
            specialInstructions: this.order.specialInstructions,
            status,
          };

          const response = await this.$http.post(
            "/PurchaseOrders/create",
            data
          );

          if (response.status === 201 && response.ok) this.goBack();

          this.isBusy = false;
          this.isBusyModal = false;
        } catch (error) {
          this.isBusy = false;
          this.isBusyModal = false;
        }
      } else {
        this.$toast("Sales Order is empty", {
          icon: false,
          rtl: false,
        });
      }
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
};
</script>

<style>
</style>