<template>
  <div class="modal fade" id="mod-delete-msg" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-4">
          <p class="text-center">{{ message }}</p>
          <div class="mt-3 d-flex justify-content-end align-items-center">
            <button style="width: 100px" class="btn btn-primary" type="button" data-dismiss="modal">
              Okay
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GeneralDeleteMessage",
  props: ["message"],
  methods: {},
};
</script>
