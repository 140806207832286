var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade colored-header colored-header-primary",attrs:{"id":"mod-add","role":"dialog"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header modal-header-colored"},[_vm._m(0),_c('button',{staticClass:"close md-close",attrs:{"type":"button","data-dismiss":"modal","aria-hidden":"true"},on:{"click":_vm.reset}},[_c('span',{staticClass:"mdi mdi-close"})])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Send invite via")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.invitedUser.type.$model),expression:"$v.invitedUser.type.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:[
                'form-control',
                { 'is-invalid': _vm.$v.invitedUser.type.$error } ],attrs:{"placeholder":"Select an option"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.invitedUser.type, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"email"}},[_vm._v("Email")]),(_vm.user.type != 'System')?_c('option',{attrs:{"value":"sms"}},[_vm._v("SMS")]):_vm._e()]),(!_vm.$v.invitedUser.type.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e()])]),(_vm.invitedUser.type == 'sms' || _vm.invitedUser.type == 'email')?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Email address")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.invitedUser.emailAddress.$model),expression:"$v.invitedUser.emailAddress.$model",modifiers:{"trim":true}}],class:[
                'form-control',

                { 'is-invalid': _vm.$v.invitedUser.emailAddress.$error } ],attrs:{"type":"email","placeholder":"Email address of customer","autocomplete":"off"},domProps:{"value":(_vm.$v.invitedUser.emailAddress.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.invitedUser.emailAddress, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                _vm.invitedUser.type == 'email' &&
                !_vm.$v.invitedUser.emailAddress.required
              )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e(),(!_vm.$v.invitedUser.emailAddress.email)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Email is invalid. ")]):_vm._e()])]):_vm._e(),(_vm.invitedUser.type == 'sms' || _vm.invitedUser.type == 'email')?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Phone Number")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.invitedUser.phoneNumber.$model),expression:"$v.invitedUser.phoneNumber.$model",modifiers:{"trim":true}}],class:[
                'form-control',
                { 'is-invalid': _vm.$v.invitedUser.phoneNumber.$error } ],attrs:{"type":"tel","placeholder":"Phone number of customer","autocomplete":"off"},domProps:{"value":(_vm.$v.invitedUser.phoneNumber.$model)},on:{"blur":[_vm.findUser,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.invitedUser.phoneNumber, "$model", $event.target.value.trim())}}}),(
                _vm.invitedUser.type == 'sms' &&
                !_vm.$v.invitedUser.phoneNumber.required
              )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e(),(!_vm.$v.invitedUser.phoneNumber.validatePhone)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Number not valid! ")]):_vm._e()])]):_vm._e(),(_vm.invitedUser.type == 'sms' || _vm.invitedUser.type == 'email')?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Name")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.invitedUser.name.$model),expression:"$v.invitedUser.name.$model",modifiers:{"trim":true}}],class:[
                'form-control',
                { 'is-invalid': _vm.$v.invitedUser.name.$error } ],attrs:{"disabled":_vm.customerExists,"type":"text","placeholder":"Name of customer"},domProps:{"value":(_vm.$v.invitedUser.name.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.invitedUser.name, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.invitedUser.name.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e()])]):_vm._e(),(_vm.invitedUser.type == 'sms' || _vm.invitedUser.type == 'email')?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Delivery Address")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('textarea',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.invitedUser.deliveryAddress.$model),expression:"$v.invitedUser.deliveryAddress.$model",modifiers:{"trim":true}}],class:[
                'form-control',
                { 'is-invalid': _vm.$v.invitedUser.deliveryAddress.$error } ],attrs:{"disabled":_vm.customerExists,"placeholder":"Delivery address of customer"},domProps:{"value":(_vm.$v.invitedUser.deliveryAddress.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.invitedUser.deliveryAddress, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.invitedUser.deliveryAddress.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e()])]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Account Manager")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.invitedUser.introducerId),expression:"invitedUser.introducerId",modifiers:{"trim":true}}],staticClass:"form-control",attrs:{"placeholder":"Select Account Manager (optional)"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.invitedUser, "introducerId", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.introducers),function(c){return _c('option',{key:c.id,domProps:{"value":c.id}},[_vm._v(" "+_vm._s(c.text)+" ")])}),0)])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Customer Access")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.invitedUser.allowCustomerAccess.$model),expression:"$v.invitedUser.allowCustomerAccess.$model",modifiers:{"trim":true}}],class:[
                'form-control',
                { 'is-invalid': _vm.$v.invitedUser.allowCustomerAccess.$error } ],attrs:{"placeholder":"Select an option"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.invitedUser.allowCustomerAccess, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":"Yes"}},[_vm._v("Yes")]),_c('option',{attrs:{"value":"No"}},[_vm._v("No")])]),(!_vm.$v.invitedUser.allowCustomerAccess.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e()])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",staticStyle:{"width":"100px"},attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.reset}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-primary",staticStyle:{"width":"100px"},attrs:{"disabled":_vm.isInviting,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.invite.apply(null, arguments)}}},[(_vm.isInviting)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}},[_c('i',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_c('span',[_vm._v("Invite")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"modal-title p-1"},[_c('strong',[_vm._v("Invite new customer")])])}]

export { render, staticRenderFns }