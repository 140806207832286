<template>
    <div class="be-splash-screen">
        <!--<div class="be-wrapper be-login">
      <div class="be-content">
        <div class="main-content container-fluid">
          <div class="splash-container forgot-password">
            <div class="card card-border-color card-border-color-primary">
              <div class="card-header">
                <img
                  class="logo-img"
                  src="/assets/img/logo-xx.png"
                  alt="logo"
                  width="200"
                  height="60"
                >
                <h4>Reset Password</h4>
                <span class="splash-description">Forgot your password? Please enter your registered email address.</span>
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group pt-4">
                    <input
                      :class="['form-control', { 'is-invalid':$v.user.emailAddress.$error }]"
                      type="email"
                      placeholder="Your Email"
                      autocomplete="off"
                      v-model.trim="$v.user.emailAddress.$model"
                    >
                    <ul
                      v-if="!$v.user.emailAddress.required && $v.user.emailAddress.$dirty"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                    <ul v-if="!$v.user.emailAddress.email" class="parsley-errors-list filled">
                      <li class="parsley-required">Enter a valid email address.</li>
                    </ul>
                  </div>

                  <div class="form-group pt-1">
                    <button
                      :disabled="isBusy"
                      class="btn btn-block btn-primary btn-xl"
                      @click.prevent="reset"
                    >
                      <span v-if="isBusy">Busy...</span>
                      <span v-else>Reset password</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>-->
        <div class="forny-container">
            <div class="forny-inner">
                <div class="forny-two-pane">
                    <div>
                        <div class="forny-form">
                            <ul class="nav nav-tabs" role="tablist">
                                <li class="nav-item">

                                </li>
                            </ul>

                            <div class="tab-content">
                                <div class="tab-pane fade show active" role="tabpanel" id="login">
                                    <a class="nav-link active bg-transparent" href="#login" data-toggle="tab" role="tab">
                                        <span>
                                            <img class="logo-img"
                                                 src="/assets/img/logo-xx.png"
                                                 alt="logo"
                                                 width="250"
                                                 height="80">
                                        </span>
                                    </a>
                                    <h1 class="font-weight-bold mt-3">
                                        Reset Password
                                    </h1>
                                    <p class="mt-4 mb-6 font-medium-2">
                                        Forgot your password? Please enter your registered email address.
                                    </p>
                                    <form>
                                        <div class="form-group">
                                            <input :class="['form-control', 'font-medium-2', { 'is-invalid':$v.user.emailAddress.$error }]"
                                                   type="email"
                                                   placeholder="Your Email"
                                                   autocomplete="off"
                                                   v-model.trim="$v.user.emailAddress.$model">
                                            <ul v-if="!$v.user.emailAddress.required && $v.user.emailAddress.$dirty"
                                                class="parsley-errors-list filled">
                                                <li class="parsley-required text-danger">Required.</li>
                                            </ul>
                                            <ul v-if="!$v.user.emailAddress.email" class="parsley-errors-list filled">
                                                <li class="parsley-required text-danger">Enter a valid email address.</li>
                                            </ul>
                                        </div>

                                        <div class="form-group">
                                            <button :disabled="isBusy"
                                                    class="btn btn-block btn-primary btn-lg p-1"
                                                    @click.prevent="reset">
                                                <i class="feather icon-lock"></i> &nbsp;
                                                <span v-if="isBusy">Busy...</span>
                                                <span v-else>Reset password</span>
                                            </button>

                                        </div>
                                        <p class="text-center">
                                            <router-link to="/login">Back to Login</router-link>
                                        </p>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="right-pane">
                        <div class="text-center" style="width: 300px; margin-top:-140px">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "forgotPassword",
  data() {
    return {
      isBusy: false,
      user: {
        emailAddress: ""
      }
    };
  },
  validations: {
    user: {
      emailAddress: { required, email }
    }
  },
  methods: {
    async reset() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isBusy = true;

        await this.$http
          .get("/Users/resetPassword/" + this.user.emailAddress)
          .then(function(res) {
            $.gritter.add({
              title: "Password reset",
              text: "Your new password has been sent to your email address.",
              class_name: "color success"
            });

            this.$router.push("/login");
          })
          .catch(function() {});

        this.isBusy = false;
      }
    }
  }
};
</script>