<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <span class="feather icon-filter text-white"></span> Filter
          </button>

          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
            v-if="hasPermission('Inventory_Create')"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> New Inventory
          </button>
        </div>
        <div class="card-body">
          <div
            class="
              dataTables_wrapper
              container-fluid
              dt-bootstrap4
              no-footer
              table-striped table-hover-animation
            "
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer text-nowrap">
                  <thead>
                    <tr>
                      <th style="width: 10%">Product</th>
                      <th style="width: 10%">Product Code</th>
                      <th style="width: 10%">Unit</th>
                      <th style="width: 10%">Warehouse</th>
                      <th style="width: 10%">Storage Area</th>
                      <th style="width: 5%">Cost Per Item</th>
                      <th style="width: 5%">Quantity (#)</th>
                      <th style="width: 10%">Receipt Date</th>
                      <th style="width: 5%">Expiry Date</th>
                      <th style="width: 10%">Product Batch ID</th>
                      <th style="width: 5%">Created</th>
                      <th style="width: 10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l in Inventories" :key="l.id">
                      <td>
                        {{ l.product.name }}
                      </td>
                      <td>
                        {{ l.product.code }}
                      </td>
                      <td>
                        {{ l.product.manufacturerProduct.unit }}
                      </td>
                      <td>
                        {{ l.subLocation.location.name }}
                      </td>
                      <td>
                        {{ l.subLocation.name }}
                      </td>
                      <td>
                        {{ formatMoney(l.costPerItem) }}
                      </td>
                      <!-- <td>
                                              {{ l.sellPrice }}
                                            </td> -->
                      <td>
                        {{ formatNumber(l.quantity) }}
                      </td>
                      <!-- <td>{{ l.subLocation.location.parent? l.subLocation.location.parent.name : 'System' }}</td> -->
                      <td>
                        {{ l.receiptDate ? formatDate(l.receiptDate) : formatDate(l.created) }}
                      </td>
                      <td>
                        {{ l.expiryDate ? formatDate(l.expiryDate) : "" }}
                      </td>
                      <td>
                        {{ l.productBatchId }}
                      </td>
                      <td>{{ formatDateWithTime(l.created) }}</td>
                      <td>
                        <button
                          class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div class="dropdown-menu px-1" aria-labelledby="dropdownMenu2">
                          <div v-if="hasPermission('Inventory_Update')">
                            <a
                              class="dropdown-item"
                              v-if="l.subLocation.location.parentId == user.parentId"
                              @click.prevent="viewEdit(l)"
                            >
                              <i class="feather icon-edit"></i> &nbsp;Edit
                            </a>
                          </div>
                          <a
                            class="dropdown-item"
                            href="#"
                            v-if="hasPermission('Inventory_Delete')"
                            style="color: red"
                            @click.prevent="confirmDelete(l.id)"
                          >
                            Delete Stock
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!Inventories.length">
                      <td colspan="12">
                        <div class="text-center" style="padding-top: 50px">
                          <span style="font-size: 4.615rem" class="mdi mdi-crosshairs-gps"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any inventories.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }}
                  {{ count != 1 ? "Inventory Items" : "Inventory Item" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Create new Inventory</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Product</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="createInventories.productId"
                >
                  <option v-for="loc in Products" :key="loc.id" :value="loc.id">
                    {{ loc.name }} - {{ loc.manufacturerProduct.unit }}
                  </option>
                </select>
                <ul v-if="v1.productId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Warehouse</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="createInventories.locationId"
                >
                  <option v-for="loc in Locations" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>

                <ul v-if="v1.locationId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Storage Area</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="createInventories.subLocationId"
                >
                  <option v-for="loc in computed_sublocations_create" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>
                <ul v-if="v1.subLocationId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Cost Per Item</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.costPerItem }]"
                  type="number"
                  v-model="createInventories.costPerItem"
                />
                <ul v-if="v1.costPerItem" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Quantity</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.quantity }]"
                  type="number"
                  v-model="createInventories.quantity"
                />
                <ul v-if="v1.quantity" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Inventory Receipt Date</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.receiptDate }]"
                  type="date"
                  placeholder="Select a date"
                  v-model="createInventories.receiptDate"
                />
                <ul v-if="v1.receiptDate" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Expiry Date</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.expiryDate }]"
                  type="date"
                  placeholder="Select a date"
                  v-model="createInventories.expiryDate"
                />
                <ul v-if="v1.expiryDate" class="parsley-errors-list filled">
                  <li class="parsley-required">
                    Expiry date must be greater than inventory received date.
                  </li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Expiry Notification (Days)</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.expiryNotification }]"
                  type="number"
                  v-model="createInventories.expiryNotification"
                />
                <ul v-if="v1.expiryNotification" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Product Batch ID</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.productBatchId }]"
                  v-model="createInventories.productBatchId"
                />
                <ul v-if="v1.productBatchId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 180px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add inventory</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Stock</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Product</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="updateInventories.productId"
                >
                  <option v-for="loc in Products" :key="loc.id" :value="loc.id">
                    {{ loc.name }} - {{ loc.manufacturerProduct.unit }}
                  </option>
                </select>
                <ul v-if="v2.productId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Warehouse</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  id="location_select"
                  placeholder="Select an option"
                  v-model="updateInventories.subLocation.locationId"
                >
                  <option v-for="loc in Locations" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>
                <ul v-if="v2.locationId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Storage Area</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  id="sublocation_select"
                  placeholder="Select an option"
                  v-model="updateInventories.subLocationId"
                >
                  <option v-for="loc in SubLocationsForUpdate" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>
                <ul v-if="v2.subLocationId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Cost Per Item</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.costPerItem }]"
                  type="number"
                  v-model="updateInventories.costPerItem"
                />
                <ul v-if="v2.costPerItem" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Quantity</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.quantity }]"
                  type="number"
                  v-model="updateInventories.quantity"
                />
                <ul v-if="v2.quantity" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Inventory Receipt Date</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.receiptDate }]"
                  type="date"
                  placeholder="Select a date"
                  v-model="updateInventories.receiptDate"
                />
                <ul v-if="v2.receiptDate" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Expiry Date</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.expiryDate }]"
                  type="date"
                  placeholder="Select a date"
                  v-model="updateInventories.expiryDate"
                />
                <ul v-if="v2.expiryDate" class="parsley-errors-list filled">
                  <li class="parsley-required">
                    Expiry date must be greater than inventory received date.
                  </li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Expiry Notification (Days)</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.expiryNotification }]"
                  type="number"
                  v-model="updateInventories.expiryNotification"
                />
                <ul v-if="v2.expiryNotification" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Product Batch ID</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.productBatchId }]"
                  v-model="updateInventories.productBatchId"
                />
                <ul v-if="v2.productBatchId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Warehouse</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.locationId"
                >
                  <option value="0">All Warehouses</option>
                  <option v-for="loc in Locations" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Other filters</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by product code or product name..."
                  v-model="filter.qry"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input class="custom-control-input" type="checkbox" v-model="filter.showForMe" />
                  <span class="custom-control-label">My Inventories Only</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getInventories"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <GeneralDeleteCaution
      :id="DeleteId"
      @proceed="deleteStock"
      :isBusy="isBusyModal"
      text="stock"
    />
    <GeneralDeleteMessage
      message="You cannot delete this inventory record because there is a reconcile order transaction history associated with it."
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
import Pagination from "../components/Pagination.vue";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsManageStock",
  components: {
    Select2,
    GeneralDeleteCaution,
    GeneralDeleteMessage,
    Pagination,
  },

  created() {
    if (!this.hasPermission("Inventory_View")) {
      this.$router.push("/restricted");
    }
    this.getInventories();
    this.getLocations();
    this.getSubLocations();
    this.getProducts();
    this.getSystemSettings();
  },

  updated() {
    if (this.$route.params.id && this.Inventories) {
      const inventory = this.Inventories.find((i) => i.id === parseInt(this.$route.params.id));

      if (inventory) this.viewEdit(inventory);
    }
  },

  watch: {
    updateInventories: {
      deep: true,
      immediate: true,

      handler() {
        let filterLocation = this.updateInventories.subLocation.locationId;
        if (filterLocation && filterLocation.length > 0) {
          this.SubLocationsForUpdate = this.SubLocations;
          var newList = this.SubLocationsForUpdate.filter(function(item) {
            let filtered = true;
            if (filterLocation && filterLocation.length > 0) {
              filtered = item.locationId == filterLocation;
            }
            return filtered;
          });
          this.SubLocationsForUpdate = newList;
        }
      },
    },
  },

  data() {
    return {
      filter: {
        status: "0",
        locationId: "0",
        subLocationId: "0",
        qry: "",
        showForMe: false,
      },
      isBusy: false,
      isBusyModal: false,
      createInventories: {
        costPerItem: null,
        sellPrice: 0,
        quantity: null,
        locationId: null,
        subLocationId: null,
        productId: null,
        receiptDate: null,
        expiryDate: null,
        productBatchId: null,
        expiryNotification: null,
      },
      updateInventories: {
        costPerItem: null,
        sellPrice: 0,
        quantity: null,
        locationId: null,
        subLocationId: null,
        productId: null,
        receiptDate: null,
        expiryDate: null,
        productBatchId: null,
        expiryNotification: null,
        id: null,
        subLocation: {
          locationId: null,
        },
      },
      editInventories: {
        costPerItem: null,
        sellPrice: 0,
        quantity: null,
        locationId: null,
        subLocationId: null,
        productId: null,
        receiptDate: null,
        expiryDate: null,
        productBatchId: null,
        expiryNotification: null,
        id: null,
        subLocation: {
          locationId: null,
        },
      },
      v1: {
        costPerItem: null,
        sellPrice: null,
        quantity: null,
        locationId: null,
        subLocationId: null,
        productId: null,
        receiptDate: null,
        expiryDate: null,
        productBatchId: null,
        expiryNotification: null,
      },
      v2: {
        costPerItem: null,
        sellPrice: null,
        quantity: null,
        locationId: null,
        subLocationId: null,
        productId: null,
        receiptDate: null,
        expiryDate: null,
        productBatchId: null,
        expiryNotification: null,
      },
      Inventories: [],
      Locations: [],
      DeleteId: "",
      SubLocations: [],
      SubLocationsForUpdate: [],
      Products: [],
      systemSettings: {},
      pageCount: 0,
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },

    computed_sublocations_create: function() {
      let filterLocation = this.createInventories.locationId;
      let subLocations = this.SubLocations;
      if (filterLocation && filterLocation.length > 0) {
        return this.SubLocations.filter(function(item) {
          let filtered = true;
          if (filterLocation && filterLocation.length > 0) {
            filtered = item.locationId == filterLocation;
          }
          return filtered;
        });
      }
    },

    computed_sublocations_update: function() {
      let filterLocation = this.updateInventories.subLocation.locationId;
      let subLocations = this.SubLocationsForUpdate;
      if (filterLocation && filterLocation.length > 0) {
        return this.SubLocationsForUpdate.filter(function(item) {
          let filtered = true;
          if (filterLocation && filterLocation.length > 0) {
            filtered = item.locationId == filterLocation;
          }
          return filtered;
        });
      }
    },
  },
  methods: {
    reset() {
      this.filter = {
        subLocationId: "0",
        locationId: "0",
        qry: "",
        showForMe: false,
      };
    },

    confirmDelete(id) {
      this.DeleteId = id;
      $("#mod-confirm-delete").modal("show");
    },

    showDeleteMsg() {
      $("#mod-delete-msg").modal("show");
    },

    cancelConfirm() {
      this.DeleteId = "";
      $("#mod-confirm-delete").modal("hide");
    },
    async deleteStock() {
      this.isBusyModal = true;
      try {
        const res = await this.$http.delete(`/Inventories/${this.DeleteId}`);
        if (res.ok) {
          this.$toast.success("Stock deleted successfully", {
            icon: true,
            rtl: false,
          });
          this.getInventories();
          this.cancelConfirm();
        }
      } catch (error) {
        this.cancelConfirm();
        this.showDeleteMsg();
      }
      this.isBusyModal = false;
    },

    cancel() {
      const currentStock = this.Inventories.find((p) => p.id === this.editInventories.id);
      (currentStock.costPerItem = this.editInventories.costPerItem),
        (currentStock.sellPrice = this.editInventories.sellPrice),
        (currentStock.quantity = this.editInventories.quantity),
        (currentStock.receiptDate = this.editInventories.receiptDate),
        (currentStock.id = this.editInventories.id),
        (currentStock.productId = this.editInventories.productId),
        (currentStock.subLocation.locationId = this.editInventories.locationId),
        (currentStock.subLocationId = this.editInventories.subLocationId),
        (currentStock.expiryDate = this.editInventories.expiryDate),
        (currentStock.productBatchId = this.editInventories.productBatchId),
        (currentStock.expiryNotification = this.editInventories.expiryNotification);
      $("#mod-edit").modal("hide");
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    viewAdd() {
      this.createInventories = {
        costPerItem: null,
        sellPrice: 0,
        quantity: null,
        locationId: null,
        subLocationId: null,
        productId: null,
        receiptDate: moment().format("YYYY-MM-DD"),
        expiryDate: null,
        productBatchId: null,
        expiryNotification: null,
      };
      this.v1 = {
        costPerItem: null,
        sellPrice: 0,
        quantity: null,
        locationId: null,
        subLocationId: null,
        productId: null,
        receiptDate: null,
        expiryDate: null,
        productBatchId: null,
        expiryNotification: null,
      };
      this.createInventories.expiryNotification = this.systemSettings.defaultProductExpiryNotification;
      $("#mod-add").modal("show");
    },

    viewEdit(c) {
      var editValues = {
        costPerItem: c.costPerItem,
        sellPrice: c.sellPrice,
        quantity: c.quantity,
        receiptDate: c.receiptDate,
        productId: c.product.id,
        locationId: c.subLocation.location.id,
        subLocationId: c.subLocation.id,
        id: c.id,
        expiryDate: c.expiryDate,
        productBatchId: c.productBatchId,
        expiryNotification: c.expiryNotification,
      };

      this.editInventories = editValues;
      this.updateInventories = c;
      this.updateInventories.receiptDate = moment(String(c.receiptDate)).format("YYYY-MM-DD");

      this.updateInventories.expiryDate = c.expiryDate
        ? moment(String(c.expiryDate)).format("YYYY-MM-DD")
        : "";

      if (!this.updateInventories.expiryNotification) {
        this.updateInventories.expiryNotification = this.systemSettings.defaultProductExpiryNotification;
      }
      $("#mod-edit").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        costPerItem: this.createInventories.costPerItem,
        sellPrice: 0, //this.createInventories.sellPrice,
        quantity: this.createInventories.quantity,
        parentId: this.user.parentId,
        subLocationId: this.createInventories.subLocationId,
        productId: this.createInventories.productId,
        receiptDate: this.createInventories.receiptDate,
        expiryDate: this.createInventories.expiryDate,
        productBatchId: this.createInventories.productBatchId,
        expiryNotification: this.createInventories.expiryNotification,
      };

      if (this.validateCreate()) {
        $("#mod-add").modal("hide");

        await this.$http
          .post("/Inventories/create", data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getInventories();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateCreate() {
      this.v1 = {
        costPerItem: null,
        sellPrice: 0,
        quantity: null,
        locationId: null,
        subLocationId: null,
        productId: null,
        receiptDate: null,
        expiryDate: null,
        productBatchId: null,
        expiryNotification: null,
      };
      var isValid = true;

      if (!this.createInventories.costPerItem) {
        this.v1.costPerItem = true;
        isValid = false;
      }

      if (!this.createInventories.quantity) {
        this.v1.quantity = true;
        isValid = false;
      }

      if (!this.createInventories.locationId) {
        this.v1.locationId = true;
        isValid = false;
      }

      if (!this.createInventories.subLocationId) {
        this.v1.subLocationId = true;
        isValid = false;
      }

      if (!this.createInventories.productId) {
        this.v1.productId = true;
        isValid = false;
      }

      if (!this.createInventories.receiptDate) {
        this.v1.receiptDate = true;
        isValid = false;
      } else {
        if (this.createInventories.expiryDate) {
          var receivedDate = moment(String(this.createInventories.receiptDate)).format(
            "YYYY-MM-DD"
          );
          var expiryDate = moment(String(this.createInventories.expiryDate)).format("YYYY-MM-DD");
          if (expiryDate <= receivedDate) {
            this.v1.expiryDate = true;
            isValid = false;
          }
        }
      }

      return isValid;
    },

    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        costPerItem: this.updateInventories.costPerItem,
        sellPrice: 0, //this.updateInventories.sellPrice,
        quantity: this.updateInventories.quantity,
        id: this.updateInventories.id,
        locationId: this.updateInventories.subLocation.locationId,
        subLocationId: this.updateInventories.subLocationId,
        productId: this.updateInventories.productId,
        receiptDate: this.updateInventories.receiptDate,
        expiryDate: this.updateInventories.expiryDate,
        productBatchId: this.updateInventories.productBatchId,
        expiryNotification: this.updateInventories.expiryNotification,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        await this.$http
          .put("/Inventories/update/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            if (this.$route.params.id) this.$router.push("/managestock");
          })
          .catch(function() {});

        this.getInventories();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateUpdate() {
      this.v2 = {
        costPerItem: null,
        sellPrice: 0,
        quantity: null,
        locationId: null,
        subLocationId: null,
        productId: null,
        receiptDate: null,
        expiryDate: null,
        productBatchId: null,
        expiryNotification: null,
      };
      var isValid = true;

      if (!this.updateInventories.costPerItem) {
        this.v2.costPerItem = true;
        isValid = false;
      }

      if (!this.updateInventories.quantity) {
        this.v2.quantity = true;
        isValid = false;
      }

      if (!this.updateInventories.subLocation.locationId) {
        this.v2.locationId = true;
        isValid = false;
      }

      if (!this.updateInventories.subLocationId) {
        this.v2.subLocationId = true;
        isValid = false;
      }

      if (!this.updateInventories.productId) {
        this.v2.productId = true;
        isValid = false;
      }

      if (!this.updateInventories.receiptDate) {
        this.v2.receiptDate = true;
        isValid = false;
      } else {
        if (this.updateInventories.expiryDate) {
          var receivedDate = moment(String(this.updateInventories.receiptDate)).format(
            "YYYY-MM-DD"
          );
          var expiryDate = moment(String(this.updateInventories.expiryDate)).format("YYYY-MM-DD");
          if (expiryDate <= receivedDate) {
            this.v2.expiryDate = true;
            isValid = false;
          }
        }
      }

      return isValid;
    },

    async getInventories() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Inventories/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.locationId != "0" ? "&locationId=" + this.filter.locationId : "") +
            (this.filter.subLocationId != "0"
              ? "&subLocationId=" + this.filter.subLocationId
              : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.filter.showForMe != "" ? "&showForMe=" + this.filter.showForMe : "")
        )
        .then(function(res) {
          this.Inventories = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
    getLocations() {
      var t = "Location";
      var a = true;

      this.$http
        .get("/Locations/getListNoPaging?active=" + a + "&parentId=" + this.user.parentId)
        .then(function(res) {
          this.Locations = res.body;
        })
        .catch(function() {});
    },
    getSubLocations() {
      var t = "Location";
      var a = true;

      this.$http
        .get(
          "/SubLocations/getListNoPaging?active=" +
            a +
            "&parentId=" +
            this.user.parentId +
            "&locationId="
        )
        .then(function(res) {
          this.SubLocations = res.body;
          this.SubLocationsForUpdate = res.body;
        })
        .catch(function() {});
    },
    getProducts() {
      var a = true;

      this.$http
        .get(
          "/Products/getListNoPagingForDistributor?active=" + a + "&parentId=" + this.user.parentId
        )
        .then(function(res) {
          this.Products = res.body;
        })
        .catch(function() {});
    },
    async getSystemSettings() {
      this.isBusy = true;

      await this.$http
        .get(
          "/SystemSettings/getList?page=0" +
            "&pageSize=10" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function(res) {
          this.systemSettings = res.body.data[0];
          this.isBusy = false;
        })
        .catch(function() {});

      this.isBusy = false;
    },
    formatDate(date) {
      return moment(date).format("ll");
    },
    formatDateWithTime(date) {
      return moment(date).format("lll");
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    formatNumber(val) {
      return helper.formatNumber(val);
    },
    prev() {
      this.page -= 1;
      this.getInventories();
    },
    next() {
      this.page += 1;
      this.getInventories();
    },
    gotoPage(page) {
      this.page = page - 1;
      this.getInventories();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getInventories();
    },
  },
};
</script>
