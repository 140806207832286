<template>
  <div class="be-splash-screen">
    <div class="be-wrapper be-login">
      <div class="be-content">
        <div class="main-content container-fluid">
          <div class="splash-container">
            <div class="card card-border-color card-border-color-primary">
              <div class="card-header">
                <img
                  class="logo-img"
                  src="/assets/img/logo-xx.png"
                  alt="logo"
                  width="200"
                  height="60"
                />
                <h4>Register Your Account</h4>
                <span class="splash-description"
                  >Please enter your user information to create your
                  account.</span
                >
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.username.$error },
                      ]"
                      id="username"
                      type="text"
                      placeholder="Username"
                      autocomplete="off"
                      v-model.trim="$v.user.username.$model"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="!$v.user.username.required"
                    >
                      Field is required.
                    </div>
                    <div
                      class="invalid-feedback"
                      v-if="!$v.user.username.alphaNumAndDotValidator"
                    >
                      Username must not include space or special characters!
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.displayName.$error },
                      ]"
                      type="text"
                      placeholder="Name"
                      v-model.trim="$v.user.displayName.$model"
                    />
                    <ul
                      v-if="
                        !$v.user.displayName.required &&
                        $v.user.displayName.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      v-model.trim="$v.user.emailAddress.$model"
                      placeholder="Email Address"
                    />
                    <ul
                      v-if="
                        !$v.user.emailAddress.required &&
                        $v.user.emailAddress.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.phoneNumber.$error },
                      ]"
                      type="text"
                      v-model.trim="$v.user.phoneNumber.$model"
                      placeholder="Phone Number"
                    />
                    <ul
                      v-if="
                        !$v.user.phoneNumber.required &&
                        $v.user.phoneNumber.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                    <ul
                      v-if="!$v.user.phoneNumber.numeric"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">
                        Enter a valid phone number.
                      </li>
                    </ul>
                  </div>
                  <div class="form-group" v-if="user.deliveryAddress != 'N/A'">
                    <textarea
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.deliveryAddress.$error },
                      ]"
                      placeholder="Delivery Address"
                      v-model.trim="$v.user.deliveryAddress.$model"
                    ></textarea>
                    <ul
                      v-if="
                        !$v.user.deliveryAddress.required &&
                        $v.user.deliveryAddress.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.password.$error },
                      ]"
                      type="password"
                      v-model.trim="$v.user.password.$model"
                      placeholder="Password"
                    />
                    <ul
                      v-if="
                        !$v.user.password.required && $v.user.password.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                    <ul
                      v-if="!$v.user.password.minLength"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">
                        Enter a password greater than or equal to 6 characters.
                      </li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.repeatPassword.$error },
                      ]"
                      type="password"
                      v-model.trim="$v.user.repeatPassword.$model"
                      placeholder="Confirm Password"
                    />
                    <ul
                      v-if="
                        !$v.user.repeatPassword.required &&
                        $v.user.repeatPassword.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                    <ul
                      v-if="!$v.user.repeatPassword.sameAsPassword"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">
                        Passwords must be identical.
                      </li>
                    </ul>
                  </div>
                  <div class="form-group login-submit">
                    <button
                      :disabled="isBusy"
                      class="btn btn-primary"
                      @click.prevent="register"
                    >
                      <span v-if="isBusy">Busy...</span>
                      <span v-else>Create account</span>
                    </button>
                  </div>
                  <div class="row">
                    <div class="col-12" style="text-align: center">
                      <router-link to="/login">Login</router-link>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import {
  required,
  numeric,
  minLength,
  sameAs,
  helpers,
} from "vuelidate/lib/validators";
const alphaNumAndDotValidator = helpers.regex("alphaNumAndDot", /^[a-z\d.]*$/i);

export default {
  name: "register",

  created() {},

  data() {
    return {
      isBusy: false,
      user: {
        id: "",
        username: "",
        displayName: "",
        emailAddress: "",
        phoneNumber: "",
        password: "",
        repeatPassword: "",
        deliveryAddress: "",
      },
    };
  },

  validations: {
    user: {
      username: { required, alphaNumAndDotValidator },
      displayName: { required },
      emailAddress: { required },
      phoneNumber: { required, numeric },
      password: { required, minLength: minLength(6) },
      repeatPassword: { required, sameAsPassword: sameAs("password") },
      deliveryAddress: { required },
    },
  },

  methods: {
    async register() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isBusy = true;

        await this.$http
          .post("/Users/customerRegistration", this.user)
          .then(function (res) {
            this.$toast.success(
              "Your user account has been created. You can now login with your username and password.",
              {
                icon: false,
                rtl: false,
              }
            );

            this.$router.push("/login");
          })
          .catch(function (error) {
            const str = error.body.errors[0];
            if (str === "A user with this username already exists.") {
              this.$router.push("/login");
            }
          });

        this.isBusy = false;
      }
    },
  },
};
</script>