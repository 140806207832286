<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button
            class="btn btn-success btn-rounded btn-space"
            @click="viewFilter"
          >
            <span class="feather icon-filter text-white"></span> Filter
          </button>

          <!-- <button class="btn btn-secondary btn-rounded btn-space float-right" @click="viewExport">
                      <span class="icon icon-left mdi mdi-arrow-top-right text-primary"></span> Export
                    </button>-->
          <div class="btn-group">
            <button
              class="btn btn-primary btn-rounded btn-space float-right"
              @click="viewAdd"
              v-if="hasPermission('Expense_Post')"
            >
              <span class="icon icon-left mdi mdi-plus text-white"></span> Post
              Expense
            </button>

            <button
              class="btn btn-primary btn-rounded btn-space float-right"
              @click="viewUploadExpenses"
              v-if="hasPermission('Expense_Post')"
            >
              <span class="icon icon-left mdi mdi-plus text-white"></span>
              Upload Expenses
            </button>
          </div>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 20%">Category</th>
                      <th style="width: 10%">Amount</th>
                      <th style="width: 10%">Date</th>
                      <th style="width: 15%">Status</th>
                      <th style="width: 20%">Notes</th>
                      <th style="width: 15%">Created</th>
                      <th style="width: 10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l in Expenses" :key="l.id">
                      <td>
                        {{ l.expenseCategory.name }}
                      </td>
                      <td>{{ formatMoney(l.amount) }}</td>
                      <td>{{ formatDate(l.expenseDate) }}</td>
                      <td>{{ l.status }}</td>
                      <td>{{ l.notes }}</td>
                      <td>{{ formatDateWithTime(l.created) }}</td>
                      <td>
                        <div
                          class="text-right"
                          v-if="hasPermission('Expense_Update')"
                        >
                          <button
                            v-if="
                              l.parentId == user.parentId && l.status == 'SAVED'
                            "
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewEdit(l)"
                          >
                            <i class="feather icon-edit"></i> Edit
                          </button>
                        </div>

                        <div
                          class="text-right"
                          v-if="hasPermission('Expense_Adjust')"
                        >
                          <button
                            v-if="
                              l.parentId == user.parentId &&
                              l.status == 'SUBMITTED'
                            "
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewAdjust(l)"
                          >
                            <i class="feather icon-edit"></i> View / Adjust
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!Expenses.length">
                      <td colspan="7">
                        <div class="text-center" style="padding-top: 50px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-crosshairs-gps"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any expenses.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} expense{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Post Expense</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row" v-if="!v1.is_valid">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="col-12 col-sm-12 text-sm-center alert alert-danger"
                  >{{ v1.text }}</label
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Expense Category</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="postExpense.expenseCategoryId"
                >
                  <option
                    v-for="br in expenseCategories"
                    :key="br.id"
                    :value="br.id"
                  >
                    {{ br.name }}
                  </option>
                </select>
                <ul
                  v-if="v1.expenseCategoryId"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Amount</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.amount }]"
                  type="number"
                  placeholder="Enter amount"
                  v-model="postExpense.amount"
                />
                <ul v-if="v1.amount" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Notes</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <textarea
                  :class="['form-control', { 'is-invalid': v1.notes }]"
                  v-model="postExpense.notes"
                ></textarea>
                <ul v-if="v1.notes" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Expense Date</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <date-picker
                  :class="['form-control', { 'is-invalid': v1.expenseDate }]"
                  v-model="postExpense.expenseDate"
                  :config="options"
                />
                <ul v-if="v1.expenseDate" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="postExpense.status"
                >
                  <option value="SAVED">SAVED</option>
                  <option value="SUBMITTED">SUBMITTED</option>
                </select>
                <ul v-if="v1.status" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Post</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Expense</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row" v-if="!v2.is_valid">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="col-12 col-sm-12 text-sm-center alert alert-danger"
                  >{{ v2.text }}</label
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Expense Category</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="updateExpense.expenseCategoryId"
                >
                  <option
                    v-for="br in expenseCategories"
                    :key="br.id"
                    :value="br.id"
                  >
                    {{ br.name }}
                  </option>
                </select>
                <ul
                  v-if="v2.expenseCategoryId"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Amount</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.amount }]"
                  type="number"
                  placeholder="Enter amount"
                  v-model="updateExpense.amount"
                />
                <ul v-if="v2.amount" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Notes</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <textarea
                  :class="['form-control', { 'is-invalid': v1.notes }]"
                  v-model="updateExpense.notes"
                ></textarea>
                <ul v-if="v2.notes" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Expense Date</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <date-picker
                  :class="['form-control', { 'is-invalid': v1.expenseDate }]"
                  v-model="updateExpense.expenseDate"
                  :config="options"
                />

                <ul v-if="v2.expenseDate" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="updateExpense.status"
                >
                  <option value="SAVED">SAVED</option>
                  <option value="SUBMITTED">SUBMITTED</option>
                </select>
                <ul v-if="v2.status" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option value="0">All Expenses</option>
                  <option value="SAVED">SAVED</option>
                  <option value="SUBMITTED">SUBMITTED</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Expense Date range</label
              >
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.expenseDateStart"
                />
              </div>
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.expenseDateEnd"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Created Date range</label
              >
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.dateStart"
                />
              </div>
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.dateEnd"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getExpenses"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import datePicker from "vue-bootstrap-datetimepicker";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsExpenses",
  components: {
    Select2,
    datePicker,
  },

  created() {
    if (!this.hasPermission("Expense_View")) {
      this.$router.push("/restricted");
    }

    var startDate = this.$route.query.sd
      ? this.$route.query.sd
      : moment().subtract(6, "days").format("YYYY-MM-DD");
    var endDate = this.$route.query.ed
      ? this.$route.query.ed
      : moment().format("YYYY-MM-DD");
    var status = this.$route.query.st ? this.$route.query.st : "0";
    var expenseStartDate = this.$route.query.eds
      ? this.$route.query.eds
      : moment().subtract(6, "days").format("YYYY-MM-DD");
    var expenseEndDate = this.$route.query.ede
      ? this.$route.query.ede
      : moment().format("YYYY-MM-DD");

    this.filter = {
      status: status,
      expenseDateStart: moment(String(expenseStartDate)).format("YYYY-MM-DD"),
      expenseDateEnd: moment(String(expenseEndDate)).format("YYYY-MM-DD"),
      dateStart: moment(String(startDate)).format("YYYY-MM-DD"),
      dateEnd: moment(String(endDate)).format("YYYY-MM-DD"),
    };

    this.getExpenses();
    this.getExpenseCategories();
  },

  data() {
    return {
      options: {
        format: "YYYY-MM-DD",
        useCurrent: false,
      },

      filter: {
        status: "0",
        dateStart: moment().subtract(6, "days").format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
        expenseDateStart: moment().subtract(6, "days").format("YYYY-MM-DD"),
        expenseDateEnd: moment().format("YYYY-MM-DD"),
      },

      isBusy: false,
      isBusyModal: false,
      postExpense: {},
      updateExpense: {},
      v1: {
        status: false,
        amount: false,
        notes: false,
        expenseCategoryId: false,
        expenseDate: false,
        is_valid: true,
        text: "",
      },
      v2: {
        status: false,
        amount: false,
        notes: false,
        expenseCategoryId: false,
        expenseDate: false,
        is_valid: true,
        text: "",
      },
      expenseCategories: [],
      Expenses: [],
      expenseUpload: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        dateStart: moment().subtract(6, "days").format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
        expenseDateStart: moment().subtract(6, "days").format("YYYY-MM-DD"),
        expenseDateEnd: moment().format("YYYY-MM-DD"),
      };
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    viewAdd() {
      this.v1 = {
        status: false,
        amount: false,
        notes: false,
        expenseCategoryId: false,
        expenseDate: false,
        is_valid: true,
        text: "",
      };
      this.postExpense = {
        expenseCategoryId: null,
        status: null,
        amount: null,
        notes: null,
        expenseDate: moment().format("YYYY-MM-DD"),
        parentId: null,
      };

      $("#mod-add").modal("show");
    },
    viewUploadExpenses() {
      this.$router.push("/manage-expenses-upload");
    },
    viewEdit(c) {
      this.updateExpense = c;
      this.updateExpense.expenseDate = moment(String(c.expenseDate)).format(
        "YYYY-MM-DD"
      );
      $("#mod-edit").modal("show");
    },
    viewAdjust(c) {
      this.$router.push(
        "/manage-expenses/" +
          c.id +
          "?sd=" +
          this.filter.dateStart +
          "&ed=" +
          this.filter.dateEnd +
          "&st=" +
          this.filter.status +
          "&eds=" +
          this.filter.expenseDateStart +
          "&ede=" +
          this.filter.expenseDateEnd
      );
    },
    viewFilter() {
      $("#mod-filter").modal("show");
    },
    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateCreate()) {
        $("#mod-add").modal("hide");
        this.postExpense.parentId = this.user.parentId;
        await this.$http
          .post("/Expenses/create", this.postExpense)
          .then(function (res) {
            this.isBusyModal = false;
            this.getExpenses();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateCreate() {
      this.v1 = {
        status: false,
        amount: false,
        notes: false,
        expenseCategoryId: false,
        expenseDate: false,
        is_valid: true,
        text: "",
      };
      var isValid = true;

      if (!this.postExpense.amount) {
        this.v1.amount = true;
        isValid = false;
      }

      if (!this.postExpense.notes) {
        this.v1.notes = true;
        isValid = false;
      }

      if (!this.postExpense.expenseDate) {
        this.v1.expenseDate = true;
        isValid = false;
      }

      if (!this.postExpense.expenseCategoryId) {
        this.v1.expenseCategoryId = true;
        isValid = false;
      }

      if (!this.postExpense.status) {
        this.v1.status = true;
        isValid = false;
      }

      if (this.postExpense.amount <= 0) {
        this.v1.is_valid = false;
        this.v1.text = "Amount must be greater than 0";
        isValid = false;
      }

      return isValid;
    },
    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        expenseCategoryId: this.updateExpense.expenseCategoryId,
        status: this.updateExpense.status,
        amount: this.updateExpense.amount,
        notes: this.updateExpense.notes,
        expenseDate: this.updateExpense.expenseDate,
        id: this.updateExpense.id,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        await this.$http
          .put("/Expenses/update/" + data.id, data)
          .then(function (res) {
            this.isBusyModal = false;
          })
          .catch(function () {});

        this.getExpenses();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateUpdate() {
      this.v2 = {
        status: false,
        amount: false,
        notes: false,
        expenseCategoryId: false,
        expenseDate: false,
        is_valid: true,
        text: "",
      };
      var isValid = true;

      if (!this.updateExpense.amount) {
        this.v2.amount = true;
        isValid = false;
      }

      if (!this.updateExpense.notes) {
        this.v2.notes = true;
        isValid = false;
      }

      if (!this.updateExpense.expenseDate) {
        this.v2.expenseDate = true;
        isValid = false;
      }

      if (!this.updateExpense.expenseCategoryId) {
        this.v2.expenseCategoryId = true;
        isValid = false;
      }

      if (!this.updateExpense.status) {
        this.v2.status = true;
        isValid = false;
      }

      if (this.updateExpense.amount <= 0) {
        this.v2.is_valid = false;
        this.v2.text = "Amount must be greater than 0";
        isValid = false;
      }

      return isValid;
    },
    async getExpenses() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Expenses/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&status=" + this.filter.status : "") +
            (this.filter.dateStart != ""
              ? "&dateStart=" +
                moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
              : "") +
            (this.filter.dateEnd != ""
              ? "&dateEnd=" +
                moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
              : "") +
            (this.filter.expenseDateStart != ""
              ? "&expenseDateStart=" +
                moment(this.filter.expenseDateStart).format(
                  "YYYY-MM-DD 00:00:00"
                )
              : "") +
            (this.filter.expenseDateEnd != ""
              ? "&expenseDateEnd=" +
                moment(this.filter.expenseDateEnd).format("YYYY-MM-DD 23:59:59")
              : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function (res) {
          this.Expenses = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function () {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
    async getExpenseCategories() {
      this.isBusy = true;

      await this.$http
        .get(
          "/ExpenseCategories/getListNoPaging?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function (res) {
          this.expenseCategories = res.body;
          this.isBusy = false;
        })
        .catch(function () {});

      this.isBusy = false;
    },
    formatDate(date) {
      return moment(date).format("ll");
    },
    formatDateWithTime(date) {
      return moment(date).format("lll");
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    prev() {
      this.page -= 1;
      this.getExpenses();
    },
    next() {
      this.page += 1;
      this.getExpenses();
    },
  },
};
</script>