<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-action"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body p-4">
          <h4 class="text-center">
            You are about to
            <span class="text-uppercase">{{ action }} </span> a purchase order
            with Purchase Order Number {{ purchaseNumber }}
          </h4>
        </div>
        <div
          class="modal-footer d-flex justify-content-center align-items-center"
        >
          <button
            style="width: 100px"
            class="btn btn-danger"
            type="button"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            class="btn btn-success"
            type="button"
            @click.prevent="onSubmitClick"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActionStatusModal",
  props: ["action", "purchaseNumber"],
  methods: {
    onSubmitClick() {
      switch (this.action) {
        case "submit":
          this.$emit("onSubmit");
          break;
        case "withdraw":
          this.$emit("onWithdraw");
          break;
        case "delete":
          this.$emit("onDelete");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
</style>