<template>
  <div class="modal fade" id="mod-confirm-payment" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-4">
          <p class="text-center">
            Are you sure you want to place this order? This action can not be reversed.
          </p>
          <div class="mt-3 d-flex justify-content-between align-items-center">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click.prevent="cancelConfirm"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusy"
              class="btn btn-primary"
              type="button"
              @click.prevent="handleOrder"
            >
              <span v-if="isBusy">Busy...</span>
              <span v-else>Confirm</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ConfirmPaymentModal",
  props: ["isBusy"],
  methods: {
    handleOrder() {
      this.$emit("proceed", "placeOrderAndPay");
    },
    cancelConfirm() {
      this.$emit("cancel");
    },
  },
};
</script>
