<template>
  <div class="be-splash-screen">
    <div class="forny-container">
      <div class="forny-inner">
        <div class="forny-two-pane">
          <div>
            <div class="forny-form">
              <ul class="nav nav-tabs" role="tablist">
                <li class="nav-item"></li>
              </ul>

              <div class="tab-content">
                <div class="tab-pane fade show active" role="tabpanel" id="login">
                  <a
                    class="nav-link active bg-transparent"
                    href="#login"
                    data-toggle="tab"
                    role="tab"
                  >
                    <span>
                      <img class="logo-img" src="/assets/img/logo-xx.png" alt="logo" width="250" />
                    </span>
                  </a>
                  <h1 class="font-weight-bold mt-3">Login</h1>
                  <p class="mt-4 mb-6 font-medium-3">
                    Use your credentials to login into account.
                  </p>
                  <form>
                    <div class="form-group pb-2 mt-3">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text mt-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="25"
                              viewBox="0 0 24 16"
                            >
                              <g transform="translate(0)">
                                <path
                                  d="M23.983,101.792a1.3,1.3,0,0,0-1.229-1.347h0l-21.525.032a1.169,1.169,0,0,0-.869.4,1.41,1.41,0,0,0-.359.954L.017,115.1a1.408,1.408,0,0,0,.361.953,1.169,1.169,0,0,0,.868.394h0l21.525-.032A1.3,1.3,0,0,0,24,115.062Zm-2.58,0L12,108.967,2.58,101.824Zm-5.427,8.525,5.577,4.745-19.124.029,5.611-4.774a.719.719,0,0,0,.109-.946.579.579,0,0,0-.862-.12L1.245,114.4,1.23,102.44l10.422,7.9a.57.57,0,0,0,.7,0l10.4-7.934.016,11.986-6.04-5.139a.579.579,0,0,0-.862.12A.719.719,0,0,0,15.977,110.321Z"
                                  transform="translate(0 -100.445)"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>

                        <input
                          :class="[
                            'form-control',
                            'font-medium-3',
                            { 'is-invalid': $v.user.username.$error },
                          ]"
                          type="text"
                          placeholder="Username"
                          autocomplete="off"
                          v-model.trim="$v.user.username.$model"
                        />
                        <p
                          v-if="!$v.user.username.required && $v.user.username.$dirty"
                          class="parsley-errors-list filled"
                        >
                          <span class="parsley-required text-danger font-small-2">* Required.</span>
                        </p>
                      </div>
                    </div>

                    <div class="form-group password-field pb-2 mb-3">
                      <div class="input-group">
                        <div class="input-group-prepend">
                          <span class="input-group-text mt-1">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="25"
                              height="30"
                              viewBox="0 0 16 24"
                            >
                              <g transform="translate(0)">
                                <g transform="translate(5.457 12.224)">
                                  <path
                                    d="M207.734,276.673a2.543,2.543,0,0,0-1.749,4.389v2.3a1.749,1.749,0,1,0,3.5,0v-2.3a2.543,2.543,0,0,0-1.749-4.389Zm.9,3.5a1.212,1.212,0,0,0-.382.877v2.31a.518.518,0,1,1-1.035,0v-2.31a1.212,1.212,0,0,0-.382-.877,1.3,1.3,0,0,1-.412-.955,1.311,1.311,0,1,1,2.622,0A1.3,1.3,0,0,1,208.633,280.17Z"
                                    transform="translate(-205.191 -276.673)"
                                  />
                                </g>
                                <path
                                  d="M84.521,9.838H82.933V5.253a4.841,4.841,0,1,0-9.646,0V9.838H71.7a1.666,1.666,0,0,0-1.589,1.73v10.7A1.666,1.666,0,0,0,71.7,24H84.521a1.666,1.666,0,0,0,1.589-1.73v-10.7A1.666,1.666,0,0,0,84.521,9.838ZM74.346,5.253a3.778,3.778,0,1,1,7.528,0V9.838H74.346ZM85.051,22.27h0a.555.555,0,0,1-.53.577H71.7a.555.555,0,0,1-.53-.577v-10.7a.555.555,0,0,1,.53-.577H84.521a.555.555,0,0,1,.53.577Z"
                                  transform="translate(-70.11)"
                                />
                              </g>
                            </svg>
                          </span>
                        </div>

                        <input
                          :class="[
                            'form-control',
                            'font-medium-3',
                            { 'is-invalid': $v.user.password.$error },
                          ]"
                          type="password"
                          placeholder="Password"
                          autocomplete="off"
                          v-model.trim="$v.user.password.$model"
                        />
                        <p
                          v-if="!$v.user.password.required && $v.user.password.$dirty"
                          class="parsley-errors-list filled"
                        >
                          <span class="parsley-required text-danger font-small-2">* Required.</span>
                        </p>
                      </div>
                    </div>

                    <div class="row pb-4 mt-2">
                      <div class="col-6">
                        <div class="form-group login-submit">
                          <button
                            :disabled="isBusy"
                            class="btn btn-primary btn-lg btn-block p-1"
                            @click.prevent="login"
                          >
                            <i class="feather icon icon-log-in"></i> &nbsp;
                            <span v-if="isBusy">Busy...</span>
                            <span v-else>Sign me in</span>
                          </button>
                        </div>
                      </div>
                      <div
                        class="
                          col-6
                          d-flex
                          align-items-center
                          justify-content-end
                        "
                      >
                        <router-link to="/forgot-password">Forgot Password?</router-link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="right-pane">
            <div class="text-center" style="width: 300px; margin-top: -140px"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { required } from "vuelidate/lib/validators";

export default {
  name: "login",
  data() {
    return {
      isBusy: false,
      user: {
        username: "",
        password: "",
      },
    };
  },
  validations: {
    user: {
      username: { required },
      password: { required },
    },
  },

  methods: {
    async login() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isBusy = true;
        this.user = { ...this.user, channelType: 1 };

        await this.$http
          .post("/Auth/generateToken", this.user)
          .then(function(res) {
            if (res.body.forceChangeOfPassword) {
              this.$store.commit("setUserId", res.body.id);
              this.$router.push("/change-password");
            } else {
              this.$auth.setToken(
                res.body.accessToken,
                Date.now() + res.body.expiresIn * 60000,
                res.body.refreshToken,
                res.body.role,
                res.body.userType,
                res.body.permissions,
                res.body.salesUserRole
              );

              this.$router.push("/");
            }
          })
          .catch(function() {});

        this.isBusy = false;
      }
    },
  },
};
</script>

<style scoped>
:root {
  --forny-p1: 0.25rem; /* 4px */
  --forny-p2: 0.5rem; /* 8px */
  --forny-p3: 0.75rem; /* 12px */
  --forny-p4: 1rem; /* 16px */
  --forny-p5: 1.25rem; /* 20px */
  --forny-p6: 1.5rem; /* 24px */
  --forny-p8: 2rem; /* 32px */
  --forny-p10: 2.5rem; /* 40px */
  --forny-text-xs: 0.75rem; /* 12px */
  --forny-text-sm: 0.875rem; /* 12px */
  --forny-text-md: 1rem; /* 16px */
  --forny-form-width: 35rem;
  --forny-color-facebook: hsla(221, 40%, 47%, 1);
  --forny-color-google: hsla(4, 69%, 53%, 1);
  --forny-color-twitter: hsla(195, 100%, 60%, 1);
  /*--forny-form-control-border: 1px solid red;*/
  --forny-form-control-padding: 7px;
  --forny-shadow-color: black;
  --forny-shadow: 0 3px 6px var(--forny-shadow-color);

  --forny-red-100: hsla(0, 100%, 94%, 1);
  --forny-red-500: hsla(4, 69%, 53%, 1);

  --forny-danger-100: hsla(0, 100%, 94%, 1);
  --forny-danger-500: hsla(4, 69%, 53%, 1);

  --forny-color-success-100: hsla(152, 68%, 96%, 1);
  --forny-color-text: black;
  --forny-color-header: var(--forny-color-text);

  --forny-color-accent-500: red;
}

.forny-container .text-xs {
  font-size: 0.75rem;
  font-size: var(--forny-text-xs);
}

.forny-container .text-sm {
  font-size: 0.875rem;
  font-size: var(--forny-text-sm);
}

.forny-container .text-md {
  font-size: 1rem;
  font-size: var(--forny-text-md);
}

.forny-container .cursor-pointer {
  cursor: pointer;
}

.forny-container .pt-1 {
  padding-top: 0.25rem !important;
  padding-top: var(--forny-p1) !important;
}
.forny-container .pt-2 {
  padding-top: 0.5rem !important;
  padding-top: var(--forny-p2) !important;
}
.forny-container .pt-4 {
  padding-top: 1rem !important;
  padding-top: var(--forny-p4) !important;
}
.forny-container .pt-10 {
  padding-top: 2.5rem !important;
  padding-top: var(--forny-p10) !important;
}

.forny-container .pb-1 {
  padding-bottom: 0.25rem !important;
  padding-bottom: var(--forny-p1) !important;
}
.forny-container .pb-2 {
  padding-bottom: 0.5rem !important;
  padding-bottom: var(--forny-p2) !important;
}
.forny-container .pb-4 {
  padding-bottom: 1rem !important;
  padding-bottom: var(--forny-p4) !important;
}
.forny-container .pb-10 {
  padding-bottom: 2.5rem !important;
  padding-bottom: var(--forny-p10) !important;
}

.forny-container .mr-1 {
  margin-right: 0.25rem !important;
  margin-right: var(--forny-p1) !important;
}
.forny-container .mr-2 {
  margin-right: 0.5rem !important;
  margin-right: var(--forny-p2) !important;
}
.forny-container .mr-4 {
  margin-right: 1rem !important;
  margin-right: var(--forny-p4) !important;
}
.forny-container .mr-6 {
  margin-right: 1.5rem !important;
  margin-right: var(--forny-p6) !important;
}
.forny-container .mr-10 {
  margin-right: 2.5rem !important;
  margin-right: var(--forny-p10) !important;
}

.forny-container .mb-1 {
  margin-bottom: 0.25rem !important;
  margin-bottom: var(--forny-p1) !important;
}
.forny-container .mb-2 {
  margin-bottom: 0.5rem !important;
  margin-bottom: var(--forny-p2) !important;
}
.forny-container .mb-4 {
  margin-bottom: 1rem !important;
  margin-bottom: var(--forny-p4) !important;
}
.forny-container .mb-5 {
  margin-bottom: 1.25rem !important;
  margin-bottom: var(--forny-p5) !important;
}
.forny-container .mb-6 {
  margin-bottom: 1.5rem !important;
  margin-bottom: var(--forny-p6) !important;
}
.forny-container .mb-8 {
  margin-bottom: 2rem !important;
  margin-bottom: var(--forny-p8) !important;
}
.forny-container .mb-10 {
  margin-bottom: 2.5rem !important;
  margin-bottom: var(--forny-p10) !important;
}

.forny-container .mt-1 {
  margin-top: 0.25rem !important;
  margin-top: var(--forny-p1) !important;
}
.forny-container .mt-2 {
  margin-top: 0.5rem !important;
  margin-top: var(--forny-p2) !important;
}
.forny-container .mt-4 {
  margin-top: 1rem !important;
  margin-top: var(--forny-p4) !important;
}
.forny-container .mt-5 {
  margin-top: 1.25rem !important;
  margin-top: var(--forny-p5) !important;
}
.forny-container .mt-6 {
  margin-top: 1.5rem !important;
  margin-top: var(--forny-p6) !important;
}
.forny-container .mt-10 {
  margin-top: 2.5rem !important;
  margin-top: var(--forny-p10) !important;
}

.forny-container .border-r-0 {
  border-right-width: 0 !important;
}

.forny-container .btn-social {
  color: white;
  border: 0;
}

.forny-container .btn-flat {
  padding: 0 !important;
}

.forny-container .btn-facebook {
  background: hsla(221, 40%, 47%, 1);
  background: var(--forny-color-facebook);
}
.forny-container .btn-facebook:hover {
  background: hsla(221, 40%, 40%, 1);
}

.forny-container .btn-google {
  background: hsla(4, 69%, 53%, 1);
  background: var(--forny-color-google);
}
.forny-container .btn-google:hover {
  background: hsla(4, 59%, 47%, 1);
}

.forny-container .btn-twitter {
  background: hsla(195, 100%, 60%, 1);
  background: var(--forny-color-twitter);
}
.forny-container .btn-twitter:hover {
  background: hsla(195, 78%, 54%, 1);
}

.forny-form .btn.btn-primary {
  border: 0;
  background-color: red;
  background-color: var(--forny-color-accent-500);
}

.forny-form .btn.btn-primary:hover {
  background-color: var(--forny-color-accent-700);
}

.forny-container .text-facebook {
  color: hsla(221, 40%, 47%, 1);
  color: var(--forny-color-facebook);
}

.forny-container .text-google {
  color: hsla(4, 69%, 53%, 1);
  color: var(--forny-color-google);
}

.forny-container .text-twitter {
  color: hsla(195, 100%, 60%, 1);
  color: var(--forny-color-twitter);
}

.forny-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  font-family: var(--forny-font-family);
  color: black;
  color: var(--forny-color-text);
}

.forny-container a {
  color: var(--forny-link-color);
}

.forny-form {
  margin: 0 auto;
}

.forny-form h4 {
  color: black;
  color: var(--forny-color-header);
  font-weight: 700;
  margin-bottom: 1.5rem;
  margin-bottom: var(--forny-p5);
}

.forny-form .forny-logo {
  text-align: center;
  margin-bottom: 2.5rem;
  margin-bottom: var(--forny-p10);
}

.forny-form .form-control {
  height: auto;
  border: 1px solid red;
  border: var(--forny-form-control-border);
  padding: var(--forny-form-control-padding);
  padding: 10px;
  border-radius: var(--forny-form-control-radius);
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.forny-form .input-group-prepend .input-group-text,
.forny-form .input-group-append .input-group-text {
  border: 1px solid red;
  border: var(--forny-form-control-border);
  padding: 4px;
  padding: var(--forny-form-control-padding);
  border-radius: var(--forny-form-control-radius);
  text-align: center;
  display: inline-block;
  min-width: 48px;
}

.forny-form .input-group-prepend .input-group-text svg,
.forny-form .input-group-append .input-group-text svg {
  fill: currentColor;
}

.forny-form .input-group .input-group-prepend + .form-control {
  border-left: 0 !important;
  padding-left: 0.25rem;
  padding-left: var(--forny-p1);
}

.forny-form .input-group .input-group-append + .form-control {
  border-right: 0;
  padding-right: 0.25rem;
  padding-right: var(--forny-p1);
}

.forny-container .alert-danger {
  background-color: hsla(0, 100%, 94%, 1);
  background-color: var(--forny-danger-100);
  color: hsla(4, 69%, 53%, 1);
  color: var(--forny-danger-500);
}

.forny-container .has-error .form-control,
.forny-container .has-error .input-group-append .input-group-text,
.forny-container .has-error .input-group-prepend .input-group-text {
  border-color: hsla(4, 69%, 53%, 1);
  border-color: var(--forny-danger-500);
  outline-color: hsla(4, 69%, 53%, 1);
  outline-color: var(--forny-danger-500);
  box-shadow: none;
}

.forny-container .has-error .form-control,
.forny-container .has-error .input-group-prepend .input-group-text {
  color: hsla(4, 69%, 53%, 1);
  color: var(--forny-danger-500);
}

.forny-inner {
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
}

.forny-form {
  margin: auto;
}

@media (min-width: 768px) {
  .forny-inner {
    height: auto;
    width: 26rem;
    width: var(--forny-form-width);
  }

  .forny-form {
    width: 26rem;
    width: var(--forny-form-width);
  }
}

.forny-container .has-error {
  background: hsla(0, 100%, 94%, 1);
  background: var(--forny-danger-100);
}

.forny-container .has-error .form-control::-webkit-input-placeholder {
  color: hsla(4, 69%, 53%, 1);
  color: var(--forny-danger-500);
}

.forny-container .has-error .form-control::-moz-placeholder {
  color: hsla(4, 69%, 53%, 1);
  color: var(--forny-danger-500);
}

.forny-container .has-error .form-control:-ms-input-placeholder {
  color: hsla(4, 69%, 53%, 1);
  color: var(--forny-danger-500);
}

.forny-container .has-error .form-control::-ms-input-placeholder {
  color: hsla(4, 69%, 53%, 1);
  color: var(--forny-danger-500);
}

.forny-container .has-error .form-control::placeholder {
  color: hsla(4, 69%, 53%, 1);
  color: var(--forny-danger-500);
}

.forny-container .has-error .form-control,
.forny-container .has-error .input-group-text,
.forny-container .has-error input:-webkit-autofill,
.forny-container .has-error input:-webkit-autofill:hover,
.forny-container .has-error input:-webkit-autofill:focus,
.forny-container .has-error input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px hsla(0, 100%, 94%, 1) inset !important;
  box-shadow: 0 0 0 30px var(--forny-danger-100) inset !important;
  border-color: hsla(4, 69%, 53%, 1);
  border-color: var(--forny-danger-500);
  color: hsla(4, 69%, 53%, 1);
  color: var(--forny-danger-500);
}

.forny-container .is-valid,
.forny-container .is-valid input:-webkit-autofill,
.forny-container .is-valid input:-webkit-autofill:hover,
.forny-container .is-valid input:-webkit-autofill:focus,
.forny-container .is-valid input:-webkit-autofill:active {
  background-color: hsla(152, 68%, 96%, 1);
  background-color: var(--forny-color-success-100);
  box-shadow: 0 0 0 30px hsla(152, 68%, 96%, 1) inset !important;
  box-shadow: 0 0 0 30px var(--forny-color-success-100) inset !important;
}

.forny-form .form-group.focused:not(.has-error) {
  background: white;
  border-color: var(--forny-color-text-100);
}

.forny-form .form-control,
.forny-form .input-group-text {
  color: black;
  color: var(--forny-color-text);
  background: transparent;
}

.forny-form .form-control::-webkit-input-placeholder {
  color: black;
  color: var(--forny-color-text);
  font-size: 1.1rem;
}

.forny-form .form-control::-moz-placeholder {
  color: black;
  color: var(--forny-color-text);
}

.forny-form .form-control:-ms-input-placeholder {
  color: black;
  color: var(--forny-color-text);
}

.forny-form .form-control::-ms-input-placeholder {
  color: black;
  color: var(--forny-color-text);
}

.forny-form .form-control::placeholder {
  color: black;
  color: var(--forny-color-text);
  font-size: 1.1rem;
}

.forny-container .custom-control-input:checked ~ .custom-control-label::before {
  border-color: red;
  border-color: var(--forny-color-accent-500);
  background-color: red;
  background-color: var(--forny-color-accent-500);
}

/* INPUT ADDONS */

.forny-form .form-group .input-group-append .input-group-text {
  padding-left: 0.5rem;
  padding-left: var(--forny-p2);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 0 !important;
}

.forny-form .form-group .input-group-append .input-group-text:hover {
  color: red;
  color: var(--forny-color-accent-500);
}

.forny-form .input-group-prepend .input-group-text {
  padding-right: 0.5rem;
  padding-right: var(--forny-p2);
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0 !important;
}

/* ALERTS */

.forny-container .alert {
  margin-bottom: 1rem;
  margin-bottom: var(--forny-p4);
}

.forny-container .password-field .form-control {
  border-right: 0 !important;
}

.forny-container .btn-icon {
  margin-right: 8px;
}

:root {
  --forny-form-paddings: 2.5rem;

  --forny-color-accent-100: hsla(125, 64%, 93%, 1);
  --forny-color-accent-500: hsla(211, 91%, 42%, 1);
  --forny-color-accent-700: hsla(213, 94%, 36%, 1);

  --forny-color-gray-100: hsla(216, 33%, 97%, 1);
  --forny-color-gray-500: hsla(210, 27%, 70%, 1);
  --forny-color-gray-900: hsla(244, 17%, 28%, 1);

  --forny-font-family: "Nunito", sans-serif;
  --forny-color-text-100: var(--forny-color-gray-100);
  --forny-color-text: var(--forny-color-gray-500);
  --forny-color-header: var(--forny-color-gray-900);
  --forny-link-color: var(--forny-color-accent-500);

  --forny-form-control-border: none;
  --forny-form-control-padding: var(--forny-p2) var(--forny-p4);
  --forny-form-control-radius: 12px;
  --forny-form-control-background: white;
  --forny-shadow-color: var(--forny-color-gray-300);
}

.forny-container {
  display: block;
  align-items: center;
  background-color: hsla(216, 33%, 97%, 1);
  background-color: var(--forny-color-gray-100);
}

.forny-inner {
  display: flex;
  height: 100vh;
  width: 100%;
}

.forny-two-pane {
  height: 100%;
  display: flex;
  flex-direction: row;
}

.forny-two-pane > div {
  flex: 1;
}

.forny-two-pane > div:first-child {
  display: flex;
  align-items: center;
}

.forny-two-pane > div:last-child {
  display: none;
  background-image: url(../../public/assets/img/business.jpg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: hsla(244, 17%, 28%, 1);
  color: var(--forny-color-gray-900);
  text-align: center;
  align-items: center;
  justify-content: center;
}

.forny-form {
  padding: 2.5rem;
  padding: var(--forny-form-paddings);
}

.forny-container h4 {
  font-weight: 700;
}

.forny-container .btn-social {
  font-weight: 500;
}

.forny-form .forny-logo {
  text-align: left;
  color: hsla(244, 17%, 28%, 1);
  color: var(--forny-color-gray-900);
}

@media (min-width: 768px) {
  .forny-container {
    background-color: hsla(216, 33%, 97%, 1);
    background-color: var(--forny-color-gray-100);
  }

  .forny-two-pane > div:last-child {
    display: flex;
  }
}

.forny-container .btn-social {
  color: hsla(244, 17%, 28%, 1);
  color: var(--forny-color-gray-900);
  background-color: transparent !important;
  width: auto;
  display: inline-block;
  font-size: 0.875rem;
  font-size: var(--forny-text-sm);
  padding: 0 !important;
  margin: 0 !important;
  margin-right: 1rem !important;
  margin-right: var(--forny-p4) !important;
}

.forny-container .btn-social:hover {
  text-decoration: underline;
}

/* FORMS */

.forny-form .form-group {
  margin-bottom: 1rem;
  margin-bottom: var(--forny-p4);
  background: white;
  background: var(--forny-form-control-background);
  border-radius: 12px;
  border-radius: var(--forny-form-control-radius);
  transition: box-shadow 0.3s;
  border: 1px solid var(--forny-form-control-background);
  border: 2px solid #dfdfdf;
}

/* BUTTONS */

.forny-form .btn {
  border-radius: 12px;
  border-radius: var(--forny-form-control-radius);
  font-weight: bold;
  padding: 0.5rem 2rem;
  padding: var(--forny-p2) var(--forny-p8);
}

.forny-form .input-group .input-group-prepend + .form-control {
  padding-left: 0.5rem;
  padding-left: var(--forny-p2);
}

.forny-container .nav-tabs {
  margin-bottom: 1.5rem;
  margin-bottom: var(--forny-p6);
  border-bottom: 0;
}

.forny-container .nav-tabs a {
  padding: 0.75rem 2rem;
  padding: var(--forny-p3) var(--forny-p8);
  border-top: 2px solid transparent;
  color: hsla(210, 27%, 70%, 1);
  color: var(--forny-color-gray-500);
  border-bottom: 1px solid hsla(210, 27%, 70%, 1);
  border-bottom: 1px solid var(--forny-color-gray-500);
}

.forny-container .nav-tabs a.active {
  border-color: hsla(210, 27%, 70%, 1);
  border-color: var(--forny-color-gray-500);
  border-bottom-color: transparent;
  border-top-color: hsla(211, 91%, 42%, 1);
  border-top-color: var(--forny-color-accent-500);
  color: hsla(211, 91%, 42%, 1);
  color: var(--forny-color-accent-500);
  font-weight: bold;
}
.tab-content {
  background: transparent;
  padding: 20px;
  margin-bottom: 40px;
  border-radius: 0 0 3px 3px;
}
</style>
