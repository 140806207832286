var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{class:[
        'card',
        'card-border-color',
        'card-border-color-primary',
        'card-table',
        'be-loading',
        { 'be-loading-active': _vm.isBusy } ]},[_c('div',{staticClass:"card-header"},[_c('button',{staticClass:"btn btn-success btn-rounded btn-space",on:{"click":_vm.viewFilter}},[_c('span',{staticClass:"feather icon-filter text-white"}),_vm._v(" Filter ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"dataTables_wrapper container-fluid dt-bootstrap4 no-footer"},[_c('div',{staticClass:"row be-datatable-body"},[_c('div',{staticClass:"col-sm-12 table-responsive"},[_c('table',{staticClass:"table table-fw-widget dataTable no-footer"},[_vm._m(0),_c('tbody',[_vm._l((_vm.users),function(u){return _c('tr',{key:u.id},[_c('td',[_c('b',[(u.displayName)?_c('span',[_vm._v(" "+_vm._s(u.displayName)+" "),_c('br')]):_vm._e()])]),_c('td',[_c('span',{class:[
                          'badge',
                          ' badge-pill',
                          { 'badge-primary': u.status == 'Pending' },
                          { 'badge-success': u.status == 'Active' },
                          { 'badge-danger': u.status == 'Disabled' } ]},[_vm._v(_vm._s(u.status == "Pending" ? "Invitation sent" : u.status))])]),_c('td',[(_vm.user.name !== 'Dispatched')?_c('SwitchStatus',{attrs:{"stage":_vm.user},on:{"onComplete":_vm.onComplete}}):_vm._e()],1)])}),(!_vm.users.length)?_c('tr',[_vm._m(1)]):_vm._e()],2)]),_c('Pagination',{attrs:{"limit":_vm.pageSize,"count":_vm.pageCount,"page":_vm.page,"itemsCount":_vm.count},on:{"changePage":_vm.gotoPage,"changeLimit":_vm.changeLimit}})],1)]),_c('div',{staticClass:"row be-datatable-footer"},[_c('div',{staticClass:"col-sm-5"},[_c('div',{staticClass:"dataTables_info"},[_vm._v(_vm._s(_vm.count)+" user"+_vm._s(_vm.count != 1 ? "s" : ""))])])])])])])]),_c('RenameStageModal',{attrs:{"stage":_vm.orderStage},on:{"onRenameComplete":_vm.onRenameCompleteHandler}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{staticStyle:{"width":"15%"}},[_vm._v("Name")]),_c('th',{staticStyle:{"width":"10%"}},[_vm._v("Status")]),_c('th',{staticStyle:{"width":"10%"}},[_vm._v("Deactivate")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('td',{attrs:{"colspan":"8"}},[_c('div',{staticClass:"text-center"},[_vm._v("No matching records")])])}]

export { render, staticRenderFns }