<template>
  <td>&#8358;{{ formatMoney(amount) }}</td>
</template>

<script>
import { helper } from "@/helper";
export default {
  props: ["details", "orderItems"],

  computed: {
    amount: function () {
      let amount = 0;
      if (this.details) {
        this.details.forEach((element) => {
          amount += element.price * element.quantity;
        });
      }

      return amount;
    },

    total: function () {
      let result = this.amount;
      if (this.orderItems && this.orderItems.length > 0) {
        this.orderItems.forEach((item) => {
          if (item.orderItem.type === 0) {
            result -=
              item.orderItem.subType === 0
                ? item.orderItem.flatAmount
                : this.calculatePercentageAmount(
                    item.orderItem.percentage,
                    this.amount
                  );
          }

          if (item.orderItem.type === 1) {
            result += this.calculatePercentageAmount(
              item.orderItem.percentage,
              this.amount
            );
          }

          if (item.orderItem.type === 2) {
            if (item.orderItem.method === 0) {
              result +=
                item.orderItem.subType === 0
                  ? item.orderItem.flatAmount
                  : this.calculatePercentageAmount(
                      item.orderItem.percentage,
                      this.amount
                    );
            }

            if (item.orderItem.method === 1) {
              result -=
                item.orderItem.subType === 0
                  ? item.orderItem.flatAmount
                  : this.calculatePercentageAmount(
                      item.orderItem.percentage,
                      this.amount
                    );
            }
          }
        });
      }
      return this.formatMoney(result);
    },
  },

  methods: {
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    calculatePercentageAmount(percent, amount) {
      return (percent / 100) * amount;
    },
  },
};
</script>

<style>
</style>