<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-account-posting-filter"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong>Filter posting</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="clearFilter"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Customer</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <multiselect
                v-model="accountPostingFilter.customerId"
                label="text"
                track-by="id"
                :options="customers"
                @select="setCustomer"
              ></multiselect>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right">
              Account</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                @input="setAccount"
                :value="accountPostingFilter.accountId"
                class="form-control"
                type="text"
                placeholder="Enter Account"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Authorized By</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <select
                @input="setAuthorizeBy"
                class="form-control"
                placeholder="Select a customer"
                :value="accountPostingFilter.authorizedBy"
              >
                <option value=""></option>
                <option v-for="c in distributors" :key="c.id" :value="c.id">
                  {{ c.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Payment Channel</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <select
                @input="setPaymentChannel"
                class="form-control"
                placeholder="Select a payment channel"
                :value="accountPostingFilter.paymentChannelId"
              >
                <option value=""></option>
                <option
                  v-for="br in paymentChannels"
                  :key="br.id"
                  :value="br.id"
                >
                  {{ prepareDetail(br) }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >Start Date</label
            >

            <div class="col-12 col-sm-8 col-lg-7">
              <date-picker
                @input="setStartDate"
                class="form-control"
                :value="accountPostingFilter.startDate"
                :config="options"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >End Date</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <date-picker
                @input="setEndDate"
                class="form-control"
                :value="accountPostingFilter.endDate"
                :config="endOptions"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="clearFilter" type="button">
            Clear
          </button>
          <button class="btn btn-danger" type="button" data-dismiss="modal">
            Cancel
          </button>
          <button
            :disabled="accountPosting.loading"
            class="btn btn-primary"
            data-dismiss="modal"
            @click.prevent="filter"
            type="button"
          >
            <span
              v-if="accountPosting.loading"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else> Submit </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import datePicker from "vue-bootstrap-datetimepicker";
import Multiselect from "vue-multiselect";
export default {
  components: { datePicker, Multiselect },
  data() {
    return {
      paymentChannels: [],
      customers: [],
      distributors: [],

      options: {
        format: "YYYY-MM-DD",
      },

      endOptions: {
        format: "YYYY-MM-DD",
      },

      paymentItem: [
        {
          name: "Payment",
          value: 0,
        },
        {
          name: "Credit Note",
          value: 1,
        },
        {
          name: "Debit Note",
          value: 2,
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["accountPostingFilter", "accountPosting"]),
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  created() {
    this.getCustomers();
    this.getPaymentChannels();
    this.getDistributors();
  },

  methods: {
    ...mapActions(["getAccountPostings"]),

    setCustomer(value) {
      this.accountPostingFilter.customerId = value;
      this.updateFilter(this.accountPostingFilter);
    },

    setAccount(value) {
      this.accountPostingFilter.accountId = value.target.value;
      this.updateFilter(this.accountPostingFilter);
    },

    setPaymentChannel(value) {
      this.accountPostingFilter.paymentChannelId = value.target.value;
      this.updateFilter(this.accountPostingFilter);
    },

    setStartDate(date) {
      this.accountPostingFilter.startDate = date;
      this.updateFilter(this.accountPostingFilter);
    },

    setEndDate(date) {
      this.accountPostingFilter.endDate = date;
      this.updateFilter(this.accountPostingFilter);
    },

    setCreatedBy(value) {
      this.accountPostingFilter.createdBy = value.target.value;
      this.updateFilter(this.accountPostingFilter);
    },

    setAuthorizeBy(value) {
      this.accountPostingFilter.authorizedBy = value.target.value;
      this.updateFilter(this.accountPostingFilter);
    },

    async getCustomers() {
      try {
        const response = await this.$http.get(
          "/Users/getItemListOfCustomers/" + this.user.parentId
        );

        if (response.ok) {
          this.customers = response.body;
        }
      } catch (error) {}
    },

    async getPaymentChannels() {
      try {
        const response = await this.$http.get(
          "/PaymentChannels/getListNoPaging?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        );

        if (response.ok) this.paymentChannels = response.body;
      } catch (error) {}
    },

    clearFilter() {
      this.$store.commit("clearAccountPostingFilter");
      this.updateFilter(this.accountPostingFilter);
      this.$emit("onFilterClear");
    },

    setDateTime() {
      const format = "YYYY-MM-DD";
      const date = new Date();
      this.accountPostingFilter.startDate = moment(
        new Date(date.getFullYear(), date.getMonth(), 1)
      ).format(format);

      this.accountPostingFilter.endDate = moment(Date.now()).format(format);
      this.updateFilter(this.accountPostingFilter);
    },

    updateFilter(filter) {
      this.$store.commit("updatePostingFilter", filter);
    },

    prepareDetail(value) {
      const name = value.paymentChannelTypeName;
      let result = "";
      if (name) {
        result += name;

        if (name.toLowerCase() === "bank") {
          result += "/ " + value.bankName + "/ " + value.accountNumber;
        } else if (name.toLowerCase() === "pos") {
          result += "/ " + value.bankName + "/ " + value.terminalId;
        } else if (name.toLowerCase() === "cryptocurrency") {
          result += "/ " + value.bankName + "/ " + value.walletAddress;
        }
      }
      return result;
    },

    filter() {
      this.$store.dispatch("getAccountPostings");
    },

    async getDistributors() {
      this.isBusy = true;

      try {
        const response = await this.$http.get("/Users/getParentDistributors");

        if (response.ok) this.distributors = response.body;
      } catch (error) {}
      this.isBusy = false;
    },
  },
};
</script>
