<template>

    <div class="row">
        <div class="col-lg-12">
            <div :class="['card', 'card-border-color', 'card-border-color-primary', ' card-table', 'be-loading', {'be-loading-active':isBusy}]">
                <div class="card-header">
                    <!--<button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
                        <span class="feather icon-filter text-white"></span> Filter
                    </button>-->
                    &nbsp;
                    <button class="btn btn-primary btn-rounded btn-space float-right"
                            @click="viewAdd"
                            v-if="hasPermission('Sales_Role_Create')">
                        <span class="icon icon-left mdi mdi-plus text-white"></span> Create New Sales Role
                    </button>
                </div>
                <div class="card-body">
                    <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
                        <div class="row be-datatable-body">
                            <div class="col-sm-12 table-responsive">
                                <table class="table table-fw-widget dataTable no-footer table-responsiv table-striped">
                                    <thead>
                                        <tr>
                                            <th style="width:20%;">Role Name</th>
                                            <th style="width:20%;">Status</th>
                                            <th style="width:10%;">Assigned Staff</th>
                                            <th style="width:20%;">Created</th>
                                            <th style="width:30%;"></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="l in roles" :key="l.id">
                                            <td>
                                                {{ l.roleName }}
                                            </td>
                                            <td>
                                                <span :class="['badge', ' badge-pill', { 'badge-success':l.numberOfAssignedUsers > 0 }, { 'badge-danger':l.numberOfAssignedUsers <= 0 }]">
                                                    {{
 l.numberOfAssignedUsers > 0 ? 'Assigned' :
                          'Not Assigned'
                                                    }}
                                                </span>
                                            </td>
                                            <td>{{ l.numberOfAssignedUsers }}</td>
                                            <td>{{ formatDate(l.created) }}</td>
                                            <td>
                                                <div class="text-right">
                                                    <button v-if="l.parentId==user.parentId && hasPermission('Sales_Role_Update')"
                                                            class="btn btn-success btn-sm btn-rounded btn-space"
                                                            @click.prevent="viewEdit(l)">
                                                        <i class="feather icon-edit"></i> &nbsp;Edit Role
                                                    </button>&nbsp;
                                                    <!--<button v-if="l.parentId==user.parentId"
                                                            class="btn btn-secondary btn-sm btn-rounded btn-space dropdown-toggle " data-toggle="dropdown"
                                                            style="color:black">
                                                        More Actions
                                                    </button>-->
                                                    <button class="btn btn-outline-primary btn-sm dropdown-toggle"
                                                            type="button"
                                                            id="dropdownMenu2"
                                                            data-toggle="dropdown"
                                                            aria-haspopup="true"
                                                            aria-expanded="false">
                                                       More Actions
                                                    </button>
                                                    <div class="dropdown-menu px-1"
                                                         aria-labelledby="dropdownMenu2">
                                                        <a v-if="l.numberOfAssignedUsers > 0 && hasPermission('Sales_Role_User_View')" class="dropdown-item" href="#" @click.prevent="viewRoleUsers(l)">
                                                            View Assigned Users
                                                        </a>
                                                        <div v-if="l.numberOfAssignedUsers > 0" class="dropdown-divider"></div>
                                                        <a v-if="hasPermission('Sales_Role_Delete')" class="dropdown-item" href="#" @click.prevent="deleteRole(l)" style="color:red">
                                                            Delete Role
                                                        </a>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr v-if="!roles.length">
                                            <td colspan="5">
                                                <div class="text-center" style="padding-top:50px;">
                                                    <span style="font-size: 4.615rem;" class="mdi mdi-crosshairs-gps"></span>
                                                </div>
                                                <h3 class="text-center" style="padding-bottom:100px;">
                                                    You don't have
                                                    any roles.
                                                </h3>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div class="row be-datatable-footer">
                            <div class="col-sm-5">
                                <div class="dataTables_info">{{ count }} role{{ count !=1? 's' : '' }}</div>
                            </div>
                            <div class="col-sm-7">
                                <div class="dataTables_paginate paging_simple_numbers">
                                    <ul class="pagination">
                                        <li :class="['paginate_button', 'page-item', 'previous', {'disabled':!hasPrev}]">
                                            <a href="#" class="page-link" @click.prevent="prev">Previous</a>
                                        </li>
                                        <li :class="['paginate_button', 'page-item', 'previous', {'disabled':!hasNext}]">
                                            <a href="#" class="page-link" @click.prevent="next">Next</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="be-spinner">
                    <svg width="40px" height="40px" viewBox="0 0 66 66">
                        <circle class="circle"
                                fill="none"
                                stroke-width="4"
                                stroke-linecap="round"
                                cx="33"
                                cy="33"
                                r="30"></circle>
                    </svg>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><b>Create new sales role</b></h4>
                        <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Role Name</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input :class="['form-control', { 'is-invalid':v1.name }]"
                                       type="text"
                                       v-model="createRole.name">
                                <ul v-if="v1.name" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Access Rights</label>
                            <div class="dd col-12 col-sm-8 col-lg-8" style="height:320px;border:1px solid #EEE">
                                <ol class="dd-list">
                                    <li>
                                        <div class="dd-handle">
                                            <input type="checkbox"
                                                   name="manager"
                                                   v-model="createRole.isManager">
                                            <label for="manager"><b>Manager</b></label>
                                        </div>

                                        <ol class="dd-list-inner">
                                            <li class="dd-item">
                                                <div class="dd-handle">
                                                    <input type="checkbox"
                                                           name="manageDistributorManagers"
                                                           v-model="createRole.canManageDistributorManagers">
                                                    <label for="manageDistributorManagers">Manage Distributor Managers</label>
                                                </div>
                                            </li>
                                            <li class="dd-item">
                                                <div class="dd-handle">
                                                    <input type="checkbox"
                                                           name="manageStateManagers"
                                                           v-model="createRole.canManageStateManagers">
                                                    <label for="manageStateManagers">Manage State Managers</label>
                                                </div>
                                            </li>
                                            <li class="dd-item">
                                                <div class="dd-handle">
                                                    <input type="checkbox"
                                                           name="manageGroupManagers"
                                                           v-model="createRole.canManageGroupManagers">
                                                    <label for="manageGroupManagers">Manage Group Managers</label>
                                                </div>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <div class="dd-handle">
                                            <input type="checkbox"
                                                   name="distributorManager"
                                                   v-model="createRole.isDistributorManager">
                                            <label for="distributorManager"><b>Distributor Manager</b></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="dd-handle">
                                            <input type="checkbox"
                                                   name="stateManager"
                                                   v-model="createRole.isStateManager">
                                            <label for="stateManager"><b>State Manager</b></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="dd-handle">
                                            <input type="checkbox"
                                                   name="groupManager"
                                                   v-model="createRole.isGroupManager">
                                            <label for="groupManager"><b>Group Manager</b></label>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            <ul v-if="v1.accessRights" class="parsley-errors-list filled">
                                <li class="parsley-required">Please select at least one access right.</li>
                            </ul>
                            <ul v-if="v1.managerAccessRights" class="parsley-errors-list filled">
                                <li class="parsley-required">Please select at least one access right for manager.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px;"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 160px;"
                                :disabled="isBusyModal"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="create">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Add Role</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><b>Manage Role</b></h4>
                        <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Role Name</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input :class="['form-control', { 'is-invalid':v2.name }]"
                                       type="text"
                                       v-model="updateRole.name">
                                <ul v-if="v2.name" class="parsley-errors-list filled">
                                    <li class="parsley-required">Required.</li>
                                </ul>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Access Rights</label>
                            <div class="dd col-12 col-sm-8 col-lg-8" style="height:320px;border:1px solid #EEE">
                                <ol class="dd-list">
                                    <li>
                                        <div class="dd-handle">
                                            <input type="checkbox"
                                                   name="manager"
                                                   v-model="updateRole.isManager">
                                            <label for="manager"><b>Manager</b></label>
                                        </div>

                                        <ol class="dd-list-inner">
                                            <li class="dd-item">
                                                <div class="dd-handle">
                                                    <input type="checkbox"
                                                           name="manageDistributorManagers"
                                                           v-model="updateRole.canManageDistributorManagers">
                                                    <label for="manageDistributorManagers">Manage Distributor Managers</label>
                                                </div>
                                            </li>
                                            <li class="dd-item">
                                                <div class="dd-handle">
                                                    <input type="checkbox"
                                                           name="manageStateManagers"
                                                           v-model="updateRole.canManageStateManagers">
                                                    <label for="manageStateManagers">Manage State Managers</label>
                                                </div>
                                            </li>
                                            <li class="dd-item">
                                                <div class="dd-handle">
                                                    <input type="checkbox"
                                                           name="manageGroupManagers"
                                                           v-model="updateRole.canManageGroupManagers">
                                                    <label for="manageGroupManagers">Manage Group Managers</label>
                                                </div>
                                            </li>
                                        </ol>
                                    </li>
                                    <li>
                                        <div class="dd-handle">
                                            <input type="checkbox"
                                                   name="distributorManager"
                                                   v-model="updateRole.isDistributorManager">
                                            <label for="distributorManager"><b>Distributor Manager</b></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="dd-handle">
                                            <input type="checkbox"
                                                   name="stateManager"
                                                   v-model="updateRole.isStateManager">
                                            <label for="stateManager"><b>State Manager</b></label>
                                        </div>
                                    </li>
                                    <li>
                                        <div class="dd-handle">
                                            <input type="checkbox"
                                                   name="groupManager"
                                                   v-model="updateRole.isGroupManager">
                                            <label for="groupManager"><b>Group Manager</b></label>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                            <ul v-if="v2.accessRights" class="parsley-errors-list filled">
                                <li class="parsley-required">Please select at least one access right.</li>
                            </ul>
                            <ul v-if="v2.managerAccessRights" class="parsley-errors-list filled">
                                <li class="parsley-required">Please select at least one access right for manager.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px;"
                                class="btn btn-secondary"
                                type="button"
                                data-dismiss="modal"
                                @click="cancel">
                            Cancel
                        </button>
                        <button style="width: 100px;"
                                :disabled="isBusyModal"
                                class="btn btn-primary"
                                type="button"
                                @click.prevent="update">
                            <span v-if="isBusyModal">Busy...</span>
                            <span v-else>Update</span>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header modal-header-colored">
                        <h4 class="modal-title p-1"><b>Filter</b></h4>
                        <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
                            <span class="mdi mdi-close"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <select class="form-control" placeholder="Select an option" v-model="filter.status">
                                    <option value="0">All Roles</option>
                                    <option value="True">Active</option>
                                    <option value="False">Disabled</option>
                                </select>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Other filters</label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <input class="form-control"
                                       type="text"
                                       placeholder="Filter by code or name..."
                                       v-model="filter.qry">
                            </div>
                        </div>

                    </div>
                    <div class="modal-footer">
                        <button style="width: 100px;"
                                class="btn btn-secondary md-close"
                                type="button"
                                data-dismiss="modal">
                            Cancel
                        </button>
                        <button style="width: 100px;"
                                class="btn btn-secondary md-close"
                                type="button"
                                @click="reset">
                            Reset
                        </button>
                        <button style="width: 100px;"
                                class="btn btn-primary md-close"
                                type="button"
                                @click="getRoles">
                            Search
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>



<style>
    .dd {
        margin: 0;
        padding: 0;
        margin-left: 12px;
        list-style: none;
        line-height: 20px;
        max-width: 305px;
    }

    .dd-list {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        list-style: none;
    }


    .dd-list-inner {
        padding-left: 30px;
    }

    .dd-item {
        display: block;
        position: relative;
        margin: 0;
        padding: 0;
        min-height: 20px;
        line-height: 20px;
    }

    .dd-handle {
        display: block;
        margin: 5px 0;
        padding: 0px 10px;
        text-decoration: none;
        background: #fff;
        -webkit-border-radius: 3px;
        border-radius: 3px;
    }
</style>
<script>
    /* eslint-disable */

    import moment from "moment";
    import { helper } from "@/helper";
    import Select2 from "@/components/Select2";
    import SystemPermissions from '@/permissions'

    export default {
        mixins: [SystemPermissions.Mixin],
        name: "settingsRoles",
        components: {
            Select2
        },
        created() {
            if (!this.hasPermission('Sales_Role_View')) {
                this.$router.push("/restricted");
            }
            this.getRoles();
        },
        watch: {
            "createRole.isManager": function (newType, oldType) {
                if (newType == false) {
                    this.createRole.canManageDistributorManagers = newType;
                    this.createRole.canManageStateManagers = newType;
                    this.createRole.canManageGroupManagers = newType;
                }
            },
            "updateRole.isManager": function (newType, oldType) {
                if (newType == false) {
                    this.updateRole.canManageDistributorManagers = newType;
                    this.updateRole.canManageStateManagers = newType;
                    this.updateRole.canManageGroupManagers = newType;
                }
            },
            "createRole.canManageDistributorManagers": function (newType, oldType) {
                if (newType == true)
                    this.createRole.isManager = newType;
                else {
                    if (!this.createRole.canManageGroupManagers && !this.createRole.canManageStateManagers)
                        this.createRole.isManager = false;
                }
            },
            "createRole.canManageStateManagers": function (newType, oldType) {
                if (newType == true)
                    this.createRole.isManager = newType;
                else {
                    if (!this.createRole.canManageGroupManagers && !this.createRole.canManageDistributorManagers)
                        this.createRole.isManager = false;
                }
            },
            "createRole.canManageGroupManagers": function (newType, oldType) {
                if (newType == true)
                    this.createRole.isManager = newType;
                else {
                    if (!this.createRole.canManageStateManagers && !this.createRole.canManageDistributorManagers)
                        this.createRole.isManager = false;
                }
            },
            "updateRole.canManageDistributorManagers": function (newType, oldType) {
                if (newType == true)
                    this.updateRole.isManager = newType;
                else {
                    if (!this.updateRole.canManageGroupManagers && !this.updateRole.canManageStateManagers)
                        this.updateRole.isManager = false;
                }
            },
            "updateRole.canManageStateManagers": function (newType, oldType) {
                if (newType == true)
                    this.updateRole.isManager = newType;
                else {
                    if (!this.updateRole.canManageGroupManagers && !this.updateRole.canManageDistributorManagers)
                        this.updateRole.isManager = false;
                }
            },
            "updateRole.canManageGroupManagers": function (newType, oldType) {
                if (newType == true)
                    this.updateRole.isManager = newType;
                else {
                    if (!this.updateRole.canManageStateManagers && !this.updateRole.canManageDistributorManagers)
                        this.updateRole.isManager = false;
                }
            },
        },
        data() {
            return {
                filter: {
                    status: "0",
                    qry: ""
                },
                isBusy: false,
                isBusyModal: false,
                createRole: {
                    name: null,
                    isManager: false,
                    canManageDistributorManagers: false,
                    canManageGroupManagers: false,
                    canManageStateManagers: false,
                    isDistributorManager: false,
                    isStateManager: false,
                    isGroupManager: false
                },
                updateRole: {
                    name: null,
                    id: null,
                    isManager: false,
                    canManageDistributorManagers: false,
                    canManageGroupManagers: false,
                    canManageStateManagers: false,
                    isDistributorManager: false,
                    isStateManager: false,
                    isGroupManager: false
                },
                editRole: {
                    name: null,
                    id: null,
                    isManager: false,
                    canManageDistributorManagers: false,
                    canManageGroupManagers: false,
                    canManageStateManagers: false,
                    isDistributorManager: false,
                    isStateManager: false,
                    isGroupManager: false
                },
                v1: {
                    name: false,
                    accessRights: false,
                    managerAccessRights: false
                },
                v2: {
                    name: false,
                    accessRights: false,
                    managerAccessRights: false
                },
                roles: [],
                count: 0,
                page: 0,
                pageSize: 10,
                hasNext: false,
                hasPrev: false
            };
        },
        computed: {
            user: function () {
                return this.$store.state.currentUser;
            },
        },
        methods: {
            reset() {
                this.filter = {
                    status: "0",
                    qry: ""
                };
            },
            cancel() {
                const currentRole = this.Roles.find(l => l.id === this.editRole.id);
                currentRole.name = this.editRole.name;
                currentRole.id = this.editRole.id;
                $("#mod-edit").modal("hide");
            },
            viewRoleUsers(r) {

                this.$router.push("/sales-force/sales-roles/" + r.id + "/users?n=" + r.roleName);
            },
            viewExport() {
                //   $("#mod-export").modal("show");
            },
            viewAdd() {
                this.createRole = {
                    name: null,
                    isManager: false,
                    canManageDistributorManagers: false,
                    canManageGroupManagers: false,
                    canManageStateManagers: false,
                    isDistributorManager: false,
                    isStateManager: false,
                    isGroupManager: false
                };
                this.v1 = {
                    name: false,
                    accessRights: false
                };
                $("#mod-add").modal("show");
            },
            viewEdit(c) {
                var editValues = {
                    name: c.roleName,
                    id: c.id,
                    isManager: c.canManageDistributorManagers || c.canManageGroupManagers || c.canManageStateManagers,
                    canManageDistributorManagers: c.canManageDistributorManagers,
                    canManageGroupManagers: c.canManageGroupManagers,
                    canManageStateManagers: c.canManageStateManagers,
                    isDistributorManager: c.isDistributorManager,
                    isStateManager: c.isStateManager,
                    isGroupManager: c.isGroupManager
                }
                this.editRole = editValues;
                //this.updateRole = c;
                this.updateRole = {
                    id: c.id,
                    name: c.roleName,
                    isManager: c.canManageDistributorManagers || c.canManageGroupManagers || c.canManageStateManagers,
                    canManageDistributorManagers: c.canManageDistributorManagers,
                    canManageGroupManagers: c.canManageGroupManagers,
                    canManageStateManagers: c.canManageStateManagers,
                    isDistributorManager: c.isDistributorManager,
                    isStateManager: c.isStateManager,
                    isGroupManager: c.isGroupManager
                };
               
                $("#mod-edit").modal("show");
            },
            viewFilter() {
                $("#mod-filter").modal("show");
            },
            async deleteRole(c) {
                if (c.numberOfAssignedUsers > 0) {
                    alert("This role is currently in use by " + c.numberOfAssignedUsers + " user(s) and cannot be deleted. Please remove all users and then delete");
                    return;
                }
                if (confirm("Are you sure you want to delete this role?")) {
                    this.isBusyModal = true;
                    this.isBusy = true;

                    await this.$http
                        .post("/SalesRoles/delete/"+ c.id, null)
                        .then(function (res) {
                            this.isBusyModal = false;
                        })
                        .catch(function () { });

                    this.getRoles();

                    this.isBusy = false;
                }
            },
            async create() {
                this.isBusyModal = true;
                this.isBusy = true;

                var data = {
                    name: this.createRole.name,
                    parentId: this.user.parentId,
                    canManageDistributorManagers: this.createRole.canManageDistributorManagers,
                    canManageGroupManagers: this.createRole.canManageGroupManagers,
                    canManageStateManagers: this.createRole.canManageStateManagers,
                    isDistributorManager: this.createRole.isDistributorManager,
                    isStateManager: this.createRole.isStateManager,
                    isGroupManager: this.createRole.isGroupManager
                };

                if (this.validateCreate()) {
                    $("#mod-add").modal("hide");

                    await this.$http
                        .post("/SalesRoles/create", data)
                        .then(function (res) {
                            this.isBusyModal = false;
                        })
                        .catch(function () { });

                    this.getRoles();
                }

                this.isBusy = false;
                this.isBusyModal = false;
            },
            validateCreate() {
                this.v1 = {
                    name: false,
                    accessRights: false,
                    managerAccessRights: false
                };
                var isValid = true;

                if (!this.createRole.name) {
                    this.v1.name = true;
                    isValid = false;
                }

                if (this.createRole.isManager && !this.createRole.canManageDistributorManagers && !this.createRole.canManageGroupManagers && !this.createRole.canManageStateManagers) {
                    this.v1.managerAccessRights = true;
                    isValid = false;
                }

                if (!this.createRole.canManageDistributorManagers && !this.createRole.canManageGroupManagers && !this.createRole.canManageStateManagers && !this.createRole.isDistributorManager && !this.createRole.isStateManager && !this.createRole.isGroupManager) {
                    this.v1.accessRights = true;
                    isValid = false;
                }

                return isValid;
            },
            async update() {
                this.isBusyModal = true;
                this.isBusy = true;

                var data = {
                    name: this.updateRole.name,
                    canManageDistributorManagers: this.updateRole.canManageDistributorManagers,
                    canManageGroupManagers: this.updateRole.canManageGroupManagers,
                    canManageStateManagers: this.updateRole.canManageStateManagers,
                    isDistributorManager: this.updateRole.isDistributorManager,
                    isStateManager: this.updateRole.isStateManager,
                    isGroupManager: this.updateRole.isGroupManager,
                    roleId: this.updateRole.id,
                    parentId: this.user.parentId
                };

                if (this.validateUpdate()) {
                    $("#mod-edit").modal("hide");

                    await this.$http
                        .put("/SalesRoles/update/" + data.id, data)
                        .then(function (res) {
                            this.isBusyModal = false;
                        })
                        .catch(function () { });

                    this.getRoles();
                }

                this.isBusy = false;
                this.isBusyModal = false;
            },
            validateUpdate() {
                this.v2 = {
                    name: false,
                    accessRights: false,
                    managerAccessRights: false
                };
                var isValid = true;

                if (!this.updateRole.name) {
                    this.v2.name = true;
                    isValid = false;
                }

                if (this.updateRole.isManager && !this.updateRole.canManageDistributorManagers && !this.updateRole.canManageGroupManagers && !this.updateRole.canManageStateManagers) {
                    this.v2.managerAccessRights = true;
                    isValid = false;
                }

                if (!this.updateRole.canManageDistributorManagers && !this.updateRole.canManageGroupManagers && !this.updateRole.canManageStateManagers && !this.updateRole.isDistributorManager && !this.updateRole.isStateManager && !this.updateRole.isGroupManager) {
                    this.v2.accessRights = true;
                    isValid = false;
                }

                return isValid;
            },
            async getRoles() {
                this.isBusy = true;

                await this.$http
                    .get(
                        "/SalesRoles/getRoleList?page=" +
                        this.page +
                        "&pageSize=" +
                        this.pageSize +
                        (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
                        (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
                        (this.filter.qry != "" ? "&query=" + this.filter.qry : "")
                    )
                    .then(function (res) {
                        this.roles = res.body.data;
                        this.page = res.body.page;
                        this.pageSize = res.body.pageSize;
                        this.count = res.body.count;
                        this.hasPrev = res.body.hasPrev;
                        this.hasNext = res.body.hasNext;
                        this.isBusy = false;
                    })
                    .catch(function () { });

                $("#mod-filter").modal("hide");
                this.isBusy = false;
            },
            
            formatDate(date) {
                return moment(date).format("lll");
            },
            formatMoney(val) {
                return helper.formatMoney(val);
            },
            prev() {
                this.page -= 1;
                this.getRoles();
            },
            next() {
                this.page += 1;
                this.getRoles();
            }
        }
    };
</script>