var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade colored-header colored-header-primary",attrs:{"id":"mod-add","role":"dialog"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header modal-header-colored"},[_vm._m(0),_c('button',{staticClass:"close md-close",attrs:{"type":"button","data-dismiss":"modal","aria-hidden":"true"},on:{"click":_vm.reset}},[_c('span',{staticClass:"mdi mdi-close"})])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Email address")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.invitedUser.emailAddress.$model),expression:"$v.invitedUser.emailAddress.$model",modifiers:{"trim":true}}],class:[
                'form-control',

                { 'is-invalid': _vm.$v.invitedUser.emailAddress.$error } ],attrs:{"type":"email","placeholder":"Email address of user","autocomplete":"off"},domProps:{"value":(_vm.$v.invitedUser.emailAddress.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.invitedUser.emailAddress, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(!_vm.$v.invitedUser.emailAddress.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e(),(!_vm.$v.invitedUser.emailAddress.email)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Email is invalid. ")]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Role")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.invitedUser.role),expression:"invitedUser.role"}],class:[
                'form-control',

                { 'is-invalid': _vm.$v.invitedUser.role.$error } ],attrs:{"placeholder":"Select an option"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.invitedUser, "role", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.roles),function(r){return _c('option',{key:r.id,domProps:{"value":r.text}},[_vm._v(" "+_vm._s(r.text)+" ")])}),0),(!_vm.$v.invitedUser.role.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Staff Id")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.invitedUser.staffId),expression:"invitedUser.staffId"}],staticClass:"form-control",attrs:{"type":"text","placeholder":"Staff Id of user"},domProps:{"value":(_vm.invitedUser.staffId)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.invitedUser, "staffId", $event.target.value)}}})])]),(_vm.invitedUser.role == 'Sales')?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Sales Access Id")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.invitedUser.salesAccessId.$model),expression:"$v.invitedUser.salesAccessId.$model",modifiers:{"trim":true}}],class:[
                'form-control',

                { 'is-invalid': _vm.$v.invitedUser.salesAccessId.$error } ],attrs:{"type":"text","placeholder":"Sales Access Id of user"},domProps:{"value":(_vm.$v.invitedUser.salesAccessId.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.invitedUser.salesAccessId, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                _vm.invitedUser.role === 'Sales' &&
                  !_vm.$v.invitedUser.salesAccessId.required
              )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e()])]):_vm._e(),(_vm.user.type != 'Manufacturer')?_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-3 col-form-label text-sm-right"},[_vm._v("Branch")]),_c('div',{staticClass:"col-12 col-sm-8 col-lg-8"},[_c('select',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.invitedUser.branch.$model),expression:"$v.invitedUser.branch.$model",modifiers:{"trim":true}}],class:[
                'form-control',

                { 'is-invalid': _vm.$v.invitedUser.branch.$error } ],attrs:{"placeholder":"Select an option"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.$v.invitedUser.branch, "$model", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},_vm._l((_vm.branches),function(br){return _c('option',{key:br.id,domProps:{"value":br.id}},[_vm._v(" "+_vm._s(br.name)+" ")])}),0),(!_vm.$v.invitedUser.branch.required)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e()])]):_vm._e()]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",staticStyle:{"width":"100px"},attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.reset}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-primary",staticStyle:{"width":"100px"},attrs:{"disabled":_vm.isInviting,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.invite.apply(null, arguments)}}},[(_vm.isInviting)?_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status"}},[_c('i',{staticClass:"sr-only"},[_vm._v("Loading...")])]):_c('span',[_vm._v("Invite")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"modal-title p-1"},[_c('b',[_vm._v("Invite new users")])])}]

export { render, staticRenderFns }