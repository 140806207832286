<template>
  <div class="row match-height">
    <div class="col-lg-3 col-sm-6 col-12">
      <div :class="['card', 'be-loading', 'gradBlu', 'text-whit', { 'be-loading-active': isBusy }]">
        <div class="card-content p-2">
          <i
            class="
              feather
              icon-shopping-cart
              font-large-3
              pull-left
              text-primary
              mt-1
            "
          ></i>
          <div class="ml-3 pull-left">
            <h1 class="text-bold-700 text-whit font-large-2">
              {{ dashData.total }}
            </h1>
            <p class="mt-0 mb-0">Order this week</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-12">
      <div
        :class="['card', 'be-loading', 'gradOrang', 'text-whit', { 'be-loading-active': isBusy }]"
      >
        <div class="card-content p-2">
          <i
            class="
              feather
              icon-briefcase
              font-large-3
              pull-left
              text-warning
              mt-1
            "
          ></i>
          <div class="ml-3 pull-left">
            <h1 class="text-bold-700 text-whit font-large-2">
              {{ dashData.pending }}
            </h1>
            <p class="mt-0 mb-0">Total Pending</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-12">
      <div
        :class="['card', 'be-loading', 'gradGree', 'text-whit', { 'be-loading-active': isBusy }]"
      >
        <div class="card-content p-2">
          <i
            class="
              feather
              icon-check-circle
              font-large-3
              pull-left
              text-success
              mt-1
            "
          ></i>
          <div class="ml-3 pull-left">
            <h1 class="text-bold-700 text-whit font-large-2">
              {{ dashData.fulfilled }}
            </h1>
            <p class="mt-0 mb-0">Total Fulfilled</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3 col-sm-6 col-12">
      <div :class="['card', 'be-loading', 'gradRe', 'text-whit', { 'be-loading-active': isBusy }]">
        <div class="card-content p-2">
          <i
            class="
              feather
              icon-minus-circle
              font-large-3
              pull-left
              text-danger
              mt-1
            "
          ></i>
          <div class="ml-3 pull-left">
            <h1 class="text-bold-700 text-whit font-large-2">
              {{ dashData.canceled }}
            </h1>
            <p class="mt-0 mb-0">Total Cancelled</p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
        <div class="card-header"><h4 class="text-primary">Channels</h4></div>
        <div class="card card-table" v-if="dashData.hasOrders">
          <div class="row">
            <div class="col-md-6 flex-center">
              <apexchart
                type="radialBar"
                height="350"
                :options="chartOptionsChannels"
                :series="seriesChannels"
              ></apexchart>
            </div>
            <div class="col-md-6 flex-center pr-3">
              <table class="table table-striped table-borderless">
                <tbody>
                  <tr v-for="o in dashData.channels" :key="o.label">
                    <td>
                      <span class="float-right">{{ o.value }}</span>
                      <i class="icon mdi mdi-album"></i> &nbsp;&nbsp;{{ o.label }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="card-body" v-else>
          <div class="text-center" style="padding-top: 50px">
            <i style="font-size: 4.615rem" class="feather icon-shopping-bag"></i>
          </div>
          <h4 class="text-center mt-2" style="padding-bottom: 50px">
            No orders yet today
          </h4>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>
    <div class="col-lg-6">
      <div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
        <div class="card-header">
          <h4 class="text-primary">Top Products</h4>
        </div>
        <div class="card card-table p-3" v-if="dashData.hasOrders">
          <apexchart
            type="bar"
            height="300"
            :options="topProductChartOptions"
            :series="seriesTopProduct"
          ></apexchart>
        </div>
        <div class="card-body" v-else>
          <div class="text-center" style="padding-top: 50px">
            <i style="font-size: 4.615rem" class="feather icon-shopping-bag"></i>
          </div>
          <h4 class="text-center mt-2" style="padding-bottom: 50px">
            No orders yet today
          </h4>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="col-lg-6">
      <div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
        <div class="card-header">
          <h4 class="text-primary">Top Categories</h4>
        </div>
        <div class="card card-table" v-if="dashData.hasOrders">
          <table class="table table-striped table-borderless">
            <tbody>
              <tr v-for="o in dashData.categories" :key="o.label">
                <td>
                  <span class="float-right">{{ o.value }}</span>
                  <i class="icon mdi mdi-tag text-primary mr-3"></i>
                  &nbsp;&nbsp;{{ o.label }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" v-else>
          <div class="text-center" style="padding-top: 50px">
            <i style="font-size: 4.615rem" class="feather icon-shopping-bag"></i>
          </div>
          <h4 class="text-center mt-2" style="padding-bottom: 50px">
            No orders yet today
          </h4>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="col-lg-6" v-if="user.role == 'Customer'">
      <div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
        <div class="card-header">
          <h4 class="text-primary">Distributor Network</h4>
        </div>
        <div class="card card-table" v-if="distributorNetwork.length">
          <table class="table table-striped table-borderless">
            <tbody>
              <tr v-for="p in distributorNetworkFiltered" :key="p.id">
                <td>
                  <span class="float-right"
                    ><i class="icon mdi mdi-calendar-account text-success"></i> &nbsp;&nbsp;
                    {{ formatDate(p.date) }}</span
                  >
                  {{ p.parentName }}
                </td>
              </tr>
            </tbody>
            <a
              v-if="distributorNetwork.length > 5"
              href="#"
              class="footer"
              @click.prevent="showAll(1)"
              >View all</a
            >
          </table>
        </div>
        <div class="card-body" v-else>
          <div class="text-center" style="padding-top: 50px">
            <i style="font-size: 4.615rem" class="feather icon-file-text"></i>
          </div>
          <h4 class="text-center mt-2" style="padding-bottom: 50px">
            You are currently not attached to a distributor
          </h4>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="col-lg-6" v-if="user.type == 'Manufacturer'">
      <div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
        <div class="card-header">
          <h4 class="text-primary">Distributor Network</h4>
        </div>
        <div class="card card-table" v-if="distributors.length">
          <table class="table table-striped table-borderless">
            <tbody>
              <tr v-for="p in distributorsFiltered" :key="p.id">
                <td>
                  <span class="float-right"
                    ><i class="icon mdi mdi-calendar-account text-success"></i> &nbsp;&nbsp;
                    {{ formatDate(p.date) }}</span
                  >
                  {{ p.parentName }}
                </td>
              </tr>
            </tbody>
          </table>
          <a v-if="distributors.length > 5" href="#" class="footer" @click.prevent="showAll(2)"
            >View all</a
          >
        </div>
        <div class="card-body" v-else>
          <div class="text-center" style="padding-top: 50px">
            <i style="font-size: 4.615rem" class="feather icon-file-text"></i>
          </div>
          <h4 class="text-center mt-2" style="padding-bottom: 50px">
            You are currently not attached to a distributor
          </h4>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="col-lg-6" v-if="user.type == 'Distributor' && user.role != 'Customer'">
      <div :class="['card', 'be-loading', { 'be-loading-active': isBusy }]">
        <div class="card-heade p-2">
          <a
            v-if="manufacturers.length > 5"
            href="#"
            class="btn btn-sm btn-primary float-right"
            @click.prevent="showAll(3)"
            ><i class="feather icon-eye"></i> &nbsp;VIEW ALL</a
          >
          <h4 class="text-primary">Manufacturer Network</h4>
        </div>
        <div class="card card-table" v-if="manufacturers.length">
          <table class="table table-striped table-borderless">
            <tbody>
              <tr v-for="p in manufacturersFiltered" :key="p.id">
                <td>
                  <span class="float-right"
                    ><i class="icon mdi mdi-calendar text-success"></i> &nbsp;&nbsp;
                    {{ formatDate(p.date) }}</span
                  >
                  <span
                    ><i class="feather icon-circle text-success"></i> &nbsp;
                    {{ p.parentName }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="card-body" v-else>
          <div class="text-center" style="padding-top: 50px">
            <i style="font-size: 4.615rem" class="feather icon-file-text"></i>
          </div>
          <h4 class="text-center mt-2" style="padding-bottom: 50px">
            You are currently not attached to a manufacturer
          </h4>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-viewList" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <strong>{{ viewListType }}</strong>
            </h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <table id="ManufacturersExtTable" class="table table-striped table-borderless dataTable">
            <tbody>
              <tr v-for="p in viewList" :key="p.id">
                <td>
                  <span class="float-right"
                    ><i class="feather icon-calendar text-success"></i> &nbsp;{{
                      formatDate(p.date)
                    }}</span
                  >
                  <i class="feather icon-circle text-success"></i> &nbsp;
                  {{ p.parentName }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "dashboard",
  created() {
    this.load();
  },

  mounted() {
    // if (!this.user.reservedAccountNumber) {
    //   $("#mod-add-onepipe-account").modal({ backdrop: "static", keyboard: false });
    // }
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      isBusy: true,
      dashData: {},
      distributorNetwork: [],
      manufacturers: [],
      distributors: [],
      viewList: [],
      viewListType: "",
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
        },
        colors: ["#FF9F43", "#7367F0", "#EA5455"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "45%",
            },
            dataLabels: {
              name: {
                fontSize: "18px",
                offsetY: -10,
                show: true,
              },
              value: {
                color: "#111",
                fontSize: "30px",
                show: true,
              },
              total: {
                show: false,
                label: "Total",
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: ["Pending", "Fulfilled", "Canceled"],
      },
      seriesChannels: [],
      chartOptionsChannels: {
        chart: {
          height: 350,
          type: "radialBar",
        },
        colors: ["#FF9F43", "#7367F0", "#EA5455"],
        plotOptions: {
          radialBar: {
            hollow: {
              margin: 15,
              size: "45%",
            },
            dataLabels: {
              name: {
                fontSize: "18px",
                offsetY: -10,
                show: true,
              },
              value: {
                color: "#111",
                fontSize: "30px",
                show: true,
              },
              total: {
                show: false,
                label: "Total",
              },
            },
          },
        },
        stroke: {
          lineCap: "round",
        },
        labels: [],
      },
      topProductChartOptions: {
        chart: {
          id: "Top Products Chart",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesTopProduct: [
        {
          name: "Sale Count",
          data: [],
        },
      ],
      seriesTopProductLabels: [],
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },

    distributorNetworkFiltered: function() {
      if (this.distributorNetwork.length > 5) {
        let clone = this.distributorNetwork.slice();
        return clone.splice(0, 5);
      } else {
        return this.distributorNetwork;
      }
    },

    manufacturersFiltered: function() {
      if (this.manufacturers.length > 5) {
        let clone = this.manufacturers.slice();
        return clone.splice(0, 5);
      } else {
        return this.manufacturers;
      }
    },

    distributorsFiltered: function() {
      if (this.distributors.length > 5) {
        let clone = this.distributors.slice();
        return clone.splice(0, 5);
      } else {
        return this.distributors;
      }
    },
  },

  methods: {
    async load() {
      await this.$http
        .get("/Reports/getDashboardReport")
        .then(function(res) {
          this.dashData = res.body;
          this.series.push(res.body.pending);
          this.series.push(res.body.fulfilled);
          this.series.push(res.body.canceled);
          for (var i = 0; i < res.body.products.length; i++) {
            var obj = res.body.products[i];
            this.seriesTopProduct[0].data.push(obj.value);
            this.topProductChartOptions.xaxis.categories.push(obj.label);
          }
          for (var j = 0; j < res.body.channels.length; j++) {
            var obj = res.body.channels[j];
            this.seriesChannels.push(obj.value);
            this.chartOptionsChannels.labels.push(obj.label);
          }
        })
        .catch(function() {});

      if (this.user.role == "Customer") {
        this.getDistributorNetwork();
      } else if (this.user.type == "Distributor") {
        this.getManufacturers();
      } else if (this.user.type == "Manufacturer") {
        this.getDistributors();
      }

      this.isBusy = false;
    },

    async getDistributorNetwork() {
      this.isBusy = true;

      await this.$http
        .get("/Users/getParents/" + this.user.id)
        .then(function(res) {
          this.distributorNetwork = res.body;
        })
        .catch(function(err) {});

      this.isBusy = false;
    },

    async getManufacturers() {
      this.isBusy = true;

      await this.$http
        .get("/Accounts/getParents/" + this.user.parentId)
        .then(function(res) {
          this.manufacturers = res.body;
        })
        .catch(function(err) {});

      this.isBusy = false;
    },

    async getDistributors() {
      this.isBusy = true;

      await this.$http
        .get("/Accounts/getChildren/" + this.user.parentId)
        .then(function(res) {
          this.distributors = res.body;
        })
        .catch(function(err) {});

      this.isBusy = false;
    },

    showAll(listType) {
      switch (listType) {
        case 1:
          this.viewList = this.distributorNetwork;
          this.viewListType = "Distributor Network";
          break;
        case 2:
          this.viewList = this.distributors;
          this.viewListType = "Distributor Network";
          break;
        default:
          this.viewList = this.manufacturers;
          this.viewListType = "Manufacturer Network";
          break;
      }

      $("#mod-viewList").modal("show");
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    testAlert() {
      alert("This is a test");
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #fafafa;
  font-size: 1rem;
  color: #8a8a8a;
  text-align: center;
  padding: 15px 0;
  border-top: 1px solid #ebebeb;
  display: block;
  outline: 0;
}

.text-bold-600 {
  font-weight: 600;
}

.bg-light-grey {
  background-color: #f5f5f5;
}

.card {
  margin-bottom: 2.2rem;
  border: none;
  border-radius: 0.5rem;
  box-shadow: 0 4px 25px 0 rgba(0, 0, 0, 0.01);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.gradGreen {
  /*background-image: linear-gradient(to right, #67B26F, #4ca2cd);*/
  background: #1abc9c;
}

.gradBlue {
  /*background-image: linear-gradient(to right, #00d2ff, #928dab);*/
  background: #3498db;
}

.gradOrange {
  /*background-image: linear-gradient(to right, #ffb75e, #ed8f03);*/
  background: #f39c12;
}

.gradRed {
  /*background-image: linear-gradient(to right, #f85032, #e73827);*/
  background: #e74c3c;
}

.icon-2x {
  font-size: 4rem;
}

.flex-center {
  align-items: center;
  justify-content: center;
  flex-direction: column;
  align-self: center;
}
</style>
