<template>
  <div class="be-wrapper">
    <NavBar></NavBar>
    <SideBar></SideBar>
    <!--<div class="be-content">
        <div class="main-content container-fluid">
            <router-view v-if="loaded"/>
        </div>
    </div>-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>
      <div class="content-wrapper">
        <div class="content-body">
          <section>
            <router-view v-if="loaded" />
          </section>
        </div>
      </div>
      <div>
        <AddDistributorModal :name="user.displayName" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import NavBar from "@/components/NavBar";
import SideBar from "@/components/SideBar";
import AddDistributorModal from "../components/GrowtradeOnboarding/AddDistributorModal.vue";

export default {
  name: "home",
  components: {
    NavBar,
    SideBar,
    AddDistributorModal,
  },
  created() {
    if (this.$auth.loggedIn()) {
      this.getUser();
    } else {
      this.$store.commit("clearCurrentUser");
    }
  },

  mounted() {},
  updated() {
    const user = this.$store.state.currentUser;
    this.user = user;
    if (this.user.role === "Customer" && !this.user.hasDistributor) {
      $("#mod-new-distributor").modal("show");
    }
    if (
      user.parent &&
      !user.parent.hasPreference &&
      user.parent.accountType === "Distributor" &&
      user.role === "Administrator"
    ) {
      this.$router.push("/set-preference");
    }
  },
  data() {
    return {
      loaded: false,
      user: {},
      showFrame: false,
    };
  },
  methods: {
    getUser() {
      this.$http
        .get("/Users/getCurrent")
        .then(function(res) {
          this.$store.commit("setCurrentUser", res.body);
          this.loaded = true;
        })
        .catch(function() {
          this.loaded = false;
        });
    },
  },
};
</script>
