<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="goBack">
            <i class="mdi icon mdi-backburger text-white"></i>
            Back
          </button>
          <div class="btn-group" role="group" aria-label="Order Item Action">
            <button
              class="btn btn-primary btn-rounded btn-space"
              @click="addItem"
            >
              <i class="icon icon-left mdi mdi-plus text-white"></i>
              Create New Item
            </button>
            <button class="btn btn-info btn-rounded btn-space" @click="filter">
              <i class="feather icon-filter text-white"></i>
              Filter
            </button>
          </div>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="o in orderItems" :key="o.id">
                      <td>{{ o.name }}</td>
                      <td>
                        {{
                          o.type === 0
                            ? "Discount"
                            : o.type === 1
                            ? "Tax"
                            : "Free"
                        }}
                      </td>

                      <td>
                        <SwitchOrderItemStatus
                          :orderItem="o"
                          @onComplete="onComplete"
                        />
                      </td>
                      <td>
                        <div class="d-flex">
                          <button
                            data-toggle="tooltip"
                            title="Edit"
                            class="btn btn-success btn-sm btn-rounded btn-space mr-1"
                            @click.prevent="editOrderItem(o)"
                          >
                            <i
                              class="icon mdi mdi-circle-edit-outline text-white"
                            ></i>
                          </button>
                          <DeleteOrderItem
                            :id="o.id"
                            @onComplete="onComplete"
                            :ref="o.id"
                            @showDeleteCaution="showCaution"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} Order Item{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddOrderItem
      @onAddComplete="onAddCompleteHandler"
      :orderItem="orderItem"
      :isEdit="isEdit"
      @hide="hideAddModal"
    />
    <DeleteOrderItemCaution @proceed="perfomDelete" />
    <FilterPurchaseOrderItem @onFilter="performFilter" />
  </div>
</template>

<script>
/* eslint-disable */
import AddOrderItem from "../../components/SettingsPurchaseOrder/AddOrderItem";
import SwitchOrderItemStatus from "../../components/SettingsPurchaseOrder/SwitchOrderItemStatus";
import DeleteOrderItem from "../../components/SettingsPurchaseOrder/DeleteOrderItem";
import DeleteOrderItemCaution from "../../components/SettingsPurchaseOrder/DeleteOrderItemCaution";
import FilterPurchaseOrderItem from "../../components/SettingsPurchaseOrder/FilterPurchaseOrderItem";

export default {
  components: {
    AddOrderItem,
    SwitchOrderItemStatus,
    DeleteOrderItem,
    DeleteOrderItemCaution,
    FilterPurchaseOrderItem,
  },

  data() {
    return {
      isBusy: false,
      orderItem: {},
      orderItems: [],
      count: 0,
      pageNumber: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
      name: "",
      active: "",
      id: "",
      isEdit: false,
      type: -1,
    };
  },

  mounted() {
    this.getOrderItems();
  },

  methods: {
    addItem() {
      // eslint-disable-next-line no-undef
      $("#mod-add-order-item").modal("show");
    },

    editOrderItem(orderItem) {
      this.orderItem = orderItem;
      this.isEdit = true;
      // eslint-disable-next-line no-undef
      $("#mod-add-order-item").modal("show");
    },

    hideAddModal() {
      this.isEdit = false;
      // eslint-disable-next-line no-undef
      $("#mod-add-order-item").modal("hide");
    },

    onAddCompleteHandler() {
      this.hideAddModal();
      this.getOrderItems();
    },

    onComplete() {
      this.getOrderItems();
    },

    showCaution(id) {
      this.id = id;
      // eslint-disable-next-line no-undef
      $("#mod-del-order-item").modal("show");
    },

    async perfomDelete() {
      // eslint-disable-next-line no-undef
      $("#mod-del-order-item").modal("hide");
      const component = this.$refs[this.id][0];
      component.deleteOrderItem();
    },

    filter() {
      $("#mod-order-item-filter").modal("show");
    },

    performFilter(data) {
      this.name = data.name;
      this.active = data.active == "empty" ? "" : data.active == "true";
      this.type = data.type;

      this.getOrderItems();
      $("#mod-order-item-filter").modal("hide");
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    async getOrderItems() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/orderItem/getList?pageNumber=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            "&name=" +
            this.name +
            "&active=" +
            this.active +
            "&Type=" +
            this.type
        );

        if (response.ok && response.status === 200) {
          this.orderItems = response.body.data;
          this.pageNumber = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },
  },
};
</script>

<style>
</style>