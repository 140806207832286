<template>
  <form @submit.prevent="login">
    <div>
      <label class="merchant-login-label"> FIRST NAME* </label>
      <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.firstName }]">
        <input type="text" placeholder="Enter first name" v-model="firstName" />
      </div>
      <p v-if="credentialValidation.firstName">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">First name cannot be empty</span>
      </p>
    </div>

    <div>
      <label class="merchant-login-label mt-2"> LAST NAME* </label>
      <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.lastName }]">
        <input type="text" placeholder="Enter last name" v-model="lastName" />
      </div>
      <p v-if="credentialValidation.lastName">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Last name cannot be empty</span>
      </p>
    </div>

    <div>
      <label class="merchant-login-label mt-2"> EMAIL ADDRESS* </label>
      <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.email }]">
        <input type="text" placeholder="Enter email address" v-model="email" />
      </div>
      <p v-if="credentialValidation.email">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Email cannot be empty</span>
      </p>
      <p v-if="credentialValidation.emailValid">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Input a valid email</span>
      </p>
    </div>

    <div>
      <label class="merchant-login-label mt-2"> USERNAME* </label>
      <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.username }]">
        <input type="text" placeholder="Enter Username" v-model="username" />
      </div>
      <p class="onboarding-helper">You will need this to <span>Log in</span></p>
      <p v-if="credentialValidation.username">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Username cannot be empty</span>
      </p>
    </div>

    <div class="mt-2">
      <label class="merchant-login-label"> CREATE PASSWORD* </label>
      <div
        :class="[
          'merchant-input-div',
          {
            'is-invalid': credentialValidation.password || credentialValidation.passwordLength,
          },
        ]"
      >
        <input :type="passwordType" placeholder="Enter Password" v-model="password" />
        <button type="button" class="togglePassword" @click.prevent="togglePassword">
          Show
        </button>
      </div>
      <p v-if="credentialValidation.password">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Password cannot be empty</span>
      </p>
      <p v-if="credentialValidation.passwordLength">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Password must be at least 6 characters</span>
      </p>
    </div>

    <button type="submit" class="login-submit-btn">Continue</button>
    <p class="signup-text">
      Already have an account?
      <button type="button" class="signup-btn" @click="goToLogin">Sign In</button>
    </p>
  </form>
</template>

<script>
/* eslint-disable */
import { helper } from "@/helper";

export default {
  name: "distributor-registration-form",
  components: {},
  props: ["saveDistributorData"],

  data() {
    return {
      checked: false,
      client: null,
      passwordType: "password",
      username: null,
      password: null,
      firstName: null,
      lastName: null,
      email: null,
      credentialValidation: {
        username: false,
        password: false,
        passwordLength: false,
        firstName: false,
        lastName: false,
        email: false,
        emailValid: false,
      },
      isBusy: false,
    };
  },

  mounted() {},

  beforeCreate() {},

  created() {},

  computed: {
    // user: function() {
    //   return this.$store.state.currentUser;
    // },
  },

  methods: {
    togglePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },

    async login() {
      this.isBusy = true;

      if (this.validateUserInfo()) {
        let data = {
          username: this.username,
          password: this.password,
          emailAddress: this.email,
          firstName: this.firstName,
          lastName: this.lastName,
        };

        console.log(data);

        this.$emit("saveDistributorData", data);
      } else {
        this.isBusy = false;
      }
    },

    validateUserInfo() {
      this.credentialValidation = {
        username: false,
        password: false,
        passwordLength: false,
        firstName: false,
        lastName: false,
        email: false,
        emailValid: false,
      };

      let isValid = true;

      if (!this.username) {
        this.credentialValidation.username = true;
        isValid = false;
      }

      if (!this.firstName) {
        this.credentialValidation.firstName = true;
        isValid = false;
      }

      if (!this.lastName) {
        this.credentialValidation.lastName = true;
        isValid = false;
      }

      if (!this.email) {
        this.credentialValidation.email = true;
        isValid = false;
      }

      if (this.email && !helper.validateEmail(this.email)) {
        this.credentialValidation.emailValid = true;
        isValid = false;
      }

      if (!this.password) {
        this.credentialValidation.password = true;
        isValid = false;
      }

      if (this.password && !helper.validateLength(this.password, 6)) {
        this.credentialValidation.passwordLength = true;
        isValid = false;
      }

      return isValid;
    },

    goToLogin() {
      this.$router.push(`/growtrade-login`);
    },
  },
};
</script>

<style scoped>
.merchant-login-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #18191f;
  padding: 0;
  margin-bottom: 16px;
}

.merchant-input-div {
  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
}

.merchant-input-div:focus-within {
  border: 1px solid #18191f;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}

.togglePassword {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  line-height: 17px;
  color: #38369a;
}
input {
  border: none;
  outline: none;
  width: 100%;
}

.login-submit-btn {
  background: #675ce8;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  outline: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin: 32px 0 8px;
  border: none;
}

.signup-btn {
  border: none;
  font-size: 18px;
  font-weight: 700;
  padding: 0;
  line-height: 28px;
  outline: none;
  background: transparent;
  color: #001688;
}

.signup-text {
  font-size: 18px;
  color: rgba(24, 25, 31, 0.5);
  margin-bottom: 60px;
}

.onboarding-helper {
  font-size: 14px;
  line-height: 142.52%;
  color: rgba(24, 25, 31, 0.7);
  margin-top: 8px;
}

.onboarding-helper span {
  font-weight: 700;
}

.is-invalid {
  border: 1px solid #ff6767;
}

.login-error {
  font-size: 12px;
  line-height: 15px;
  color: #ff6767;
  margin-left: 5px;
}
@media screen and (max-width: 767px) {
  .merchant-input-div,
  .login-submit-btn {
    width: 100%;
  }
}
</style>
