<template>
  <div class="onboarding-body">
    <div class="onboarding-card">
      <img src="/assets/img/logo-xx.png" alt="logo" width="175" />
      <p class="onboarding-header">Welcome,</p>
      <p class="onboarding-desc">Please select what you want to register as.</p>
      <div>
        <router-link class="onboarding-div" to="/growtrade-register?client=distributor">
          <div class="onboarding-text-div">
            <p class="onboarding-title">Distributor</p>
            <p class="onboarding-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus habitant vitae arcu,
              etiam.
            </p>
          </div>
        </router-link>
        <router-link class="onboarding-div" to="/growtrade-register?client=merchant">
          <div class="onboarding-text-div">
            <p class="onboarding-title">Merchant</p>
            <p class="onboarding-text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lectus habitant vitae arcu,
              etiam.
            </p>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: "growtrade-onboarding",
  components: {},

  data() {
    return {};
  },
};
</script>
<style scoped>
.onboarding-body {
  background-color: #f6f6f6;
  min-width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-card {
  width: 524px;
  background-color: #fff;
  padding: 48px;
}

@media screen and (max-width: 767px) {
  .onboarding-card {
    width: 90%;
    padding: 24px;
  }
}

.onboarding-header {
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #373737;
  margin: 32px 0 8px;
}

.onboarding-desc {
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: rgba(24, 25, 31, 0.5);
  margin-bottom: 16px;
}

.onboarding-div {
  padding: 24px 7px 24px 0;
  cursor: pointer;
  display: flex;
  border-top: 1px solid #f5f5f5;
}

.onboarding-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #18191f;
  margin-bottom: 8px;
}

.onboarding-text {
  font-size: 16px;
  line-height: 19px;
  color: rgba(24, 25, 31, 0.6);
}
</style>
