var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col"},[_c('div',{class:[
        'card',
        'card-border-color',
        'card-border-color-primary',
        'card-table',
        'be-loading',
        { 'be-loading-active': _vm.isBusy } ]},[_c('div',{staticClass:"card-header"},[_c('button',{staticClass:"btn btn-success btn-rounded btn-space",on:{"click":_vm.goBack}},[_c('i',{staticClass:"mdi icon mdi-backburger text-white"}),_vm._v(" Back ")])]),_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"dataTables_wrapper container-fluid dt-bootstrap4 no-footer"},[_c('div',{staticClass:"row be-datatable-body"},[_c('div',{staticClass:"col-sm-12 table-responsive"},[_c('table',{staticClass:"table table-fw-widget dataTable no-footer"},[_vm._m(0),_c('tbody',_vm._l((_vm.orderStages),function(o){return _c('tr',{key:o.id},[_c('td',[_vm._v(_vm._s(o.name))]),_c('td',[(
                          o.name !== 'Dispatched' &&
                          _vm.hasPermission('Sales_Order_Stages_Update')
                        )?_c('SwitchStageStatus',{attrs:{"stage":o},on:{"onComplete":_vm.onComplete}}):_c('span',{class:[
                          'badge',
                          ' badge-pill',
                          { 'badge-success': o.active },
                          { 'badge-danger': !o.active } ]},[_vm._v(" "+_vm._s(o.active ? "Active" : "Inactive")+" ")])],1),_c('td',[_c('div',{staticClass:"d-flex"},[(
                            o.name !== 'Dispatched' &&
                            _vm.hasPermission('Sales_Order_Stages_Update')
                          )?_c('button',{staticClass:"\n                            btn btn-success btn-sm btn-rounded btn-space\n                            mr-1\n                          ",on:{"click":function($event){$event.preventDefault();return _vm.renameOrderItem(o)}}},[_c('i',{staticClass:"\n                              icon\n                              mdi mdi-circle-edit-outline\n                              text-white\n                            "}),_vm._v(" Rename ")]):_vm._e(),(
                            o.name !== 'Dispatched' &&
                            _vm.hasPermission('Sales_Order_Stages_Update')
                          )?_c('HideStage',{ref:o.id,refInFor:true,attrs:{"stage":o},on:{"onComplete":_vm.onComplete,"showHideCaution":_vm.showCaution}}):_vm._e()],1)])])}),0)])])])])])])]),_c('RenameStageModal',{attrs:{"stage":_vm.orderStage},on:{"onRenameComplete":_vm.onRenameCompleteHandler}}),_c('HideStageCaution',{attrs:{"text":_vm.text},on:{"proceed":_vm.perfomHide}})],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',[_vm._v("Name")]),_c('th',[_vm._v("Status")]),_c('th',[_vm._v("Actions")])])])}]

export { render, staticRenderFns }