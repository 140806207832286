var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"be-splash-screen"},[_c('div',{staticClass:"be-wrapper be-login"},[_c('div',{staticClass:"be-content"},[_c('div',{staticClass:"main-content container-fluid"},[_c('div',{staticClass:"splash-container"},[_c('div',{staticClass:"card card-border-color card-border-color-primary"},[_vm._m(0),_c('div',{staticClass:"card-body"},[_c('form',[_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.username.$model),expression:"$v.user.username.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.username.$error } ],attrs:{"id":"username","type":"text","placeholder":"Username","autocomplete":"off"},domProps:{"value":(_vm.$v.user.username.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.username, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      !_vm.$v.user.username.required && _vm.$v.user.username.$dirty
                    )?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Field is required. ")]):_vm._e(),(!_vm.$v.user.username.alphaNumAndDotValidator)?_c('div',{staticClass:"invalid-feedback"},[_vm._v(" Username must not include space or special characters! ")]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.lastName.$model),expression:"$v.user.lastName.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.lastName.$error } ],attrs:{"type":"text","placeholder":"Last Name"},domProps:{"value":(_vm.$v.user.lastName.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.lastName, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      !_vm.$v.user.lastName.required && _vm.$v.user.lastName.$dirty
                    )?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v("Required.")])]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.otherNames.$model),expression:"$v.user.otherNames.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.otherNames.$error } ],attrs:{"type":"text","placeholder":"Other Names"},domProps:{"value":(_vm.$v.user.otherNames.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.otherNames, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      !_vm.$v.user.otherNames.required &&
                      _vm.$v.user.otherNames.$dirty
                    )?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v("Required.")])]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.user.emailAddress),expression:"user.emailAddress"}],staticClass:"form-control",attrs:{"type":"text","readonly":"","placeholder":"Email Address"},domProps:{"value":(_vm.user.emailAddress)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.user, "emailAddress", $event.target.value)}}})]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.phoneNumber.$model),expression:"$v.user.phoneNumber.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.phoneNumber.$error } ],attrs:{"type":"text","placeholder":"Phone Number"},domProps:{"value":(_vm.$v.user.phoneNumber.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.phoneNumber, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      !_vm.$v.user.phoneNumber.required &&
                      _vm.$v.user.phoneNumber.$dirty
                    )?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v("Required.")])]):_vm._e(),(!_vm.$v.user.phoneNumber.numeric)?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v(" Enter a valid phone number. ")])]):_vm._e()]),_c('div',{staticClass:"form-group"},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.user.password.$model),expression:"$v.user.password.$model",modifiers:{"trim":true}}],class:[
                      'form-control',
                      { 'is-invalid': _vm.$v.user.password.$error } ],attrs:{"type":"password","placeholder":"Password"},domProps:{"value":(_vm.$v.user.password.$model)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.$v.user.password, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(
                      !_vm.$v.user.password.required && _vm.$v.user.password.$dirty
                    )?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v("Required.")])]):_vm._e(),(!_vm.$v.user.password.minLength)?_c('ul',{staticClass:"parsley-errors-list filled"},[_c('li',{staticClass:"parsley-required"},[_vm._v(" Enter a password greater than or equal to 6 characters. ")])]):_vm._e()]),_c('div',{staticClass:"form-group login-submit"},[_c('button',{staticClass:"btn btn-primary",attrs:{"disabled":_vm.isBusy},on:{"click":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[(_vm.isBusy)?_c('span',[_vm._v("Busy...")]):_c('span',[_vm._v("Create account")])])])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card-header"},[_c('img',{staticClass:"logo-img",attrs:{"src":"/assets/img/logo-xx.png","alt":"logo","width":"200","height":"60"}}),_c('h4',[_vm._v("Register Your Account")]),_c('span',{staticClass:"splash-description"},[_vm._v("Please enter your user information to create your account.")])])}]

export { render, staticRenderFns }