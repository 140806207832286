<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button
            class="btn btn-success btn-rounded btn-space"
            @click="viewFilter"
          >
            <i class="feather icon-filter text-white"></i> Filter
          </button>
          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
            v-if="hasPermission('Product_Create')"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> New
            product
          </button>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-striped table-hover-animation
                  "
                >
                  <thead>
                    <tr>
                      <th style="width: 25%">Name</th>
                      <th style="width: 10%">Status</th>
                      <th style="width: 15%">Category</th>
                      <th style="width: 15%">Created</th>
                      <th style="width: 30%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="p in Products" :key="p.id">
                      <td>
                        {{ p.name }} ({{ p.code }})
                        <br />
                        <b
                          >{{ `&#8358;${formatMoney(p.distributorPrice)}` }} /
                          {{ p.unit }}</b
                        >
                      </td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': p.active },
                            { 'badge-danger': !p.active },
                          ]"
                        >
                          {{ p.active ? "Active" : "Inactive" }}
                        </span>
                      </td>
                      <td>{{ p.category.name }}</td>
                      <td>{{ formatDate(p.created) }}</td>
                      <td>
                        <div
                          class="text-right d-flex"
                          v-if="hasPermission('Product_Update') || hasPermission('Price_Option_View')"
                        >
                          <router-link
                            class="
                              btn
                              btn-outline-primary
                              btn-sm
                              btn-rounded
                              btn-space
                              mr-1
                            "
                            v-if="p.parentId == user.parentId && hasPermission('Price_Option_View')"
                            :to="{
                              path: `manufacturer-products/${p.id}/manage-price-option`,
                            }"
                          >
                            <span>Manage Price Options</span>
                          </router-link>
                          <button
                            v-if="p.parentId == user.parentId && hasPermission('Product_Update')"
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewEdit(p)"
                          >
                            <i class="feather icon-edit"></i> &nbsp;Edit
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!Products.length">
                      <td colspan="6">
                        <div class="text-center" style="padding-top: 50px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-tag-multiple"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any products.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} product{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><strong>Create new product</strong></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Category</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="createProduct.categoryId"
                >
                  <option
                    v-for="cat in filteredCategory"
                    :key="cat.id"
                    :value="cat.id"
                  >
                    {{ cat.name }}
                  </option>
                </select>
                <ul v-if="v1.categoryId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Code</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.code }]"
                  type="text"
                  v-model="createProduct.code"
                />
                <ul v-if="v1.code" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.name }]"
                  type="text"
                  v-model="createProduct.name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Description</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <textarea
                  class="form-control"
                  v-model="createProduct.description"
                ></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Unit</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="createProduct.unit"
                >
                  <option
                    v-for="unit in units"
                    :key="unit.name"
                    :value="unit.name"
                  >
                    {{ unit.name }}
                  </option>
                </select>
                <ul v-if="v1.unit" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Default Retail Price (RRP)</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.retailPrice }]"
                  type="number"
                  v-model="createProduct.retailPrice"
                />
                <ul v-if="v1.retailPrice" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid number.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Default Distributor Price (DP)</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v1.distributorPrice },
                  ]"
                  type="number"
                  v-model="createProduct.distributorPrice"
                />
                <ul
                  v-if="v1.distributorPrice"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a valid number.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Active</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="createProduct.active"
                  />
                  <span class="custom-control-label"
                    >This product is active</span
                  >
                </label>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Allow Price Override</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="createProduct.allowPriceOverride"
                  />
                  <span class="custom-control-label"
                    >Price can be overriden by distributor</span
                  >
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 160px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add product</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><strong>Edit product</strong></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="cancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Category</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="updateProduct.categoryId"
                >
                  <option
                    v-for="cat in filteredCategory"
                    :key="cat.id"
                    :value="cat.id"
                  >
                    {{ cat.name }}
                  </option>
                </select>
                <ul v-if="v2.categoryId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Code</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.code }]"
                  type="text"
                  v-model="updateProduct.code"
                />
                <ul v-if="v2.code" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Name</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.name }]"
                  type="text"
                  v-model="updateProduct.name"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Description</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <textarea
                  class="form-control"
                  v-model="updateProduct.description"
                ></textarea>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Unit</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="updateProduct.unit"
                >
                  <option
                    v-for="unit in units"
                    :key="unit.name"
                    :value="unit.name"
                  >
                    {{ unit.name }}
                  </option>
                </select>
                <ul v-if="v2.unit" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Default Retail Price (RRP)</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.retailPrice }]"
                  type="number"
                  v-model="updateProduct.retailPrice"
                />
                <ul v-if="v2.retailPrice" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid number.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Default Distributor Price (DP)</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v2.distributorPrice },
                  ]"
                  type="number"
                  v-model="updateProduct.distributorPrice"
                />
                <ul
                  v-if="v2.distributorPrice"
                  class="parsley-errors-list filled"
                >
                  <li class="parsley-required">Enter a valid number.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Active</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="updateProduct.active"
                  />
                  <span class="custom-control-label"
                    >This product is active</span
                  >
                </label>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Allow Price Override</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <label
                  class="custom-control custom-checkbox custom-control-inline"
                >
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="updateProduct.allowPriceOverride"
                  />
                  <span class="custom-control-label"
                    >Price can be overriden by distributor</span
                  >
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><strong>Filter</strong></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="select2 form-control"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option value="0">All Products</option>
                  <option value="True">Active</option>
                  <option value="False">Disabled</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Category</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.categoryId"
                >
                  <option value="0">All Categories</option>
                  <option
                    v-for="cat in categories"
                    :key="cat.id"
                    :value="cat.id"
                  >
                    {{ cat.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Other filters</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by code or name..."
                  v-model="filter.qry"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getProducts"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
    import Select2 from "@/components/Select2";
    import SystemPermissions from "@/permissions";

    export default {
        mixins: [SystemPermissions.Mixin],
  name: "settingsProducts",
  components: {
    Select2,
  },

        created() {
            if (!this.hasPermission("Product_View")) {
                this.$router.push("/restricted");
            }
    this.getProducts();
    this.getTypes();
    this.getCategories();
    this.getUnits();
  },

  updated() {
    if (this.$route.params.id && this.Products) {
      const product = this.Products.find(
        (prod) => prod.id === this.$route.params.id
      );
      if (product) {
        this.viewEdit(product);
      }
    }
  },

  watch: {
    "createProduct.type": function (newType, oldType) {
      if (newType == "Infinite") {
        this.createProduct.quantity = 0;
      }
    },
    "updateProduct.type": function (newType, oldType) {
      if (newType == "Infinite") {
        this.updateProduct.quantity = 0;
      }
    },
  },

  data() {
    return {
      filter: {
        status: "0",
        categoryId: "0",
        qry: "",
      },
      isBusy: false,
      isBusyModal: false,
      createProduct: {
        code: null,
        name: null,
        description: null,
        unit: null,
        volume: null,
        group: null,
        groupSize: null,
        quantity: 99999999,
        type: "Finite",
        retailPrice: null,
        distributorPrice: null,
        active: null,
        categoryId: null,
        restockLevel: 0,
        allowPriceOverride: null,
      },
      updateProduct: {
        code: null,
        name: null,
        description: null,
        unit: null,
        volume: null,
        group: null,
        groupSize: null,
        quantity: 99999999,
        type: "Finite",
        retailPrice: null,
        distributorPrice: null,
        active: true,
        categoryId: null,
        id: null,
        restockLevel: 0,
        allowPriceOverride: null,
      },
      editProduct: {
        code: null,
        name: null,
        description: null,
        unit: null,
        volume: null,
        group: null,
        groupSize: null,
        quantity: 99999999,
        type: "Finite",
        retailPrice: null,
        distributorPrice: null,
        active: true,
        categoryId: null,
        id: null,
        restockLevel: 0,
        allowPriceOverride: null,
      },
      v1: {
        code: false,
        name: false,
        unit: false,
        group: false,
        groupSize: false,
        quantity: false,
        type: false,
        retailPrice: false,
        distributorPrice: false,
        categoryId: false,
        restockLevel: false,
      },
      v2: {
        code: false,
        name: false,
        unit: false,
        group: false,
        groupSize: false,
        quantity: false,
        type: false,
        retailPrice: false,
        distributorPrice: false,
        categoryId: false,
        restockLevel: false,
      },
      Products: [],
      types: [],
      categories: [],
      units: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },

    filteredCategory: function () {
      return this.categories.filter((x) => x.active);
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        categoryId: "0",
        qry: "",
      };

      this.getProducts();
    },

    cancel() {
      const currentProduct = this.Products.find(
        (p) => p.id === this.editProduct.id
      );
      currentProduct.name = this.editProduct.name;
      currentProduct.code = this.editProduct.code;
      currentProduct.retailPrice = this.editProduct.retailPrice;
      currentProduct.distributorPrice = this.editProduct.distributorPrice;
      currentProduct.unit = this.editProduct.unit;
      currentProduct.active = this.editProduct.active;
      currentProduct.categoryId = this.editProduct.categoryId;
      currentProduct.allowPriceOverride = this.editProduct.allowPriceOverride;
      currentProduct.quantity = this.editProduct.quantity;
      $("#mod-edit").modal("hide");
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    viewAdd() {
      this.createProduct = {
        code: null,
        name: null,
        description: null,
        unit: null,
        volume: null,
        group: null,
        groupSize: null,
        quantity: 99999999,
        type: "Finite",
        retailPrice: null,
        distributorPrice: null,
        active: false,
        categoryId: null,
        restockLevel: 0,
        allowPriceOverride: false,
      };
      this.v1 = {
        code: false,
        name: false,
        unit: false,
        group: false,
        groupSize: false,
        quantity: false,
        type: false,
        categoryId: false,
        restockLevel: 0,
      };
      $("#mod-add").modal("show");
    },

    viewEdit(c) {
      var editValue = {
        code: c.code,
        name: c.name,
        description: c.description,
        quantity: c.quantity,
        type: c.type,
        retailPrice: c.retailPrice,
        distributorPrice: c.distributorPrice,
        unit: c.unit,
        categoryId: c.category.id,
        active: c.active,
        restockLevel: c.restockLevel,
        allowPriceOverride: c.allowPriceOverride,
        id: c.id,
      };
      this.editProduct = editValue;
      this.updateProduct = c;
      $("#mod-edit").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        code: this.createProduct.code,
        name: this.createProduct.name,
        description: this.createProduct.description,
        unit: this.createProduct.unit,
        volume: "N/A", // this.createProduct.volume,
        group: "N/A", // this.createProduct.group,
        groupSize: 0, // this.createProduct.groupSize,
        quantity: 99999999,
        type: "Finite",
        categoryId: this.createProduct.categoryId,
        retailPrice: this.createProduct.retailPrice,
        distributorPrice: this.createProduct.distributorPrice,
        active: this.createProduct.active,
        parentId: this.user.parentId,
        restockLevel: this.createProduct.restockLevel,
        allowPriceOverride: this.createProduct.allowPriceOverride,
      };

      if (this.validateCreate()) {
        $("#mod-add").modal("hide");

        await this.$http
          .post("/Products/createForManufacturer", data)
          .then(function (res) {
            this.isBusyModal = false;
          })
          .catch(function () {});

        this.getProducts();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateCreate() {
      this.v1 = {
        code: false,
        name: false,
        unit: false,
        quantity: false,
        type: false,
        categoryId: false,
        restockLevel: false,
      };
      var isValid = true;

      if (!this.createProduct.code) {
        this.v1.code = true;
        isValid = false;
      }

      if (!this.createProduct.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.createProduct.unit) {
        this.v1.unit = true;
        isValid = false;
      }

      if (!this.createProduct.categoryId) {
        this.v1.categoryId = true;
        isValid = false;
      }

      if (Number(this.createProduct.retailPrice) <= 0) {
        this.v1.retailPrice = true;
        isValid = false;
      }

      if (Number(this.createProduct.distributorPrice) <= 0) {
        this.v1.distributorPrice = true;
        isValid = false;
      }

      if (Number(this.createProduct.restockLevel) < 0) {
        this.v1.restockLevel = true;
        isValid = false;
      }

      return isValid;
    },

    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        code: this.updateProduct.code,
        name: this.updateProduct.name,
        description: this.updateProduct.description,
        unit: this.updateProduct.unit,
        volume: this.updateProduct.volume,
        group: this.updateProduct.group,
        groupSize: this.updateProduct.groupSize,
        quantity: 99999999,
        type: "Finite",
        retailPrice: this.updateProduct.retailPrice,
        distributorPrice: this.updateProduct.distributorPrice,
        categoryId: this.updateProduct.categoryId,
        active: this.updateProduct.active,
        id: this.updateProduct.id,
        restockLevel: 0,
        allowPriceOverride: this.updateProduct.allowPriceOverride,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        await this.$http
          .put("/Products/updateForManufacturer/" + data.id, data)
          .then(function (res) {
            this.isBusyModal = false;
            if (this.$route.params.id) this.$router.push("/products");
          })
          .catch(function () {});

        this.getProducts();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateUpdate() {
      this.v2 = {
        code: false,
        name: false,
        unit: false,
        // group: false,
        // groupSize: false,
        quantity: false,
        type: false,
        categoryId: false,
        restockLevel: false,
      };
      var isValid = true;

      if (!this.updateProduct.code) {
        this.v2.code = true;
        isValid = false;
      }

      if (!this.updateProduct.name) {
        this.v2.name = true;
        isValid = false;
      }

      if (!this.updateProduct.unit) {
        this.v2.unit = true;
        isValid = false;
      }

      if (!this.updateProduct.categoryId) {
        this.v2.categoryId = true;
        isValid = false;
      }

      if (Number(this.updateProduct.retailPrice) <= 0) {
        this.v2.retailPrice = true;
        isValid = false;
      }

      if (Number(this.updateProduct.distributorPrice) <= 0) {
        this.v2.distributorPrice = true;
        isValid = false;
      }

      if (Number(this.updateProduct.restockLevel) < 0) {
        this.v2.restockLevel = true;
        isValid = false;
      }

      return isValid;
    },

    async getProducts() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Products/getListForManufacturer?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.filter.categoryId != "0"
              ? "&categoryId=" + this.filter.categoryId
              : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function (res) {
          this.Products = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function () {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    getTypes() {
      this.types = ["Finite", "Infinite"];
    },

    getCategories() {
      var t = "Product Category";

      this.$http
        .get(
          "/Categories/getListNoPagging?type=" +
            t +
            (this.user.parentId
              ? "&parentId=" + this.user.parentId
              : "Product Category")
        )
        .then(function (res) {
          this.categories = res.body;
        })
        .catch(function () {});
    },

    getUnits() {
      var t = "Product Unit";
      var a = true;

      this.$http
        .get(
          "/Categories/getListNoPagging?type=" +
            t +
            "&active=" +
            a +
            (this.user.parentId
              ? "&parentId=" + this.user.parentId
              : "Product Category")
        )
        .then(function (res) {
          this.units = res.body;
        })
        .catch(function () {});
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    formatNumber(val) {
      return helper.formatNumber(val);
    },

    prev() {
      this.page -= 1;
      this.getProducts();
    },

    next() {
      this.page += 1;
      this.getProducts();
    },
  },
};
</script>