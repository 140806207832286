<template>
  <div class="row mt-5">
    <div class="col mb-2">
      <div class="d-flex justify-content-between">
        <h4 class="font-weight-bold">Purchase Order Details</h4>
        <button
          v-if="confirmed"
          class="btn btn-success"
          type="button"
          @click="addPaymentDetails"
        >
          <i class="icon icon-left mdi mdi-plus text-white"></i>
          Add Payment Details
        </button>

        <button
          v-if="draft || !disableEdit"
          class="btn btn-success"
          type="button"
          @click="addItem"
        >
          <i class="icon icon-left mdi mdi-plus text-white"></i>
          Add Product
        </button>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th scope="col">Item #</th>
            <th scope="col">Description</th>
            <th scope="col">Quantity</th>
            <th scope="col">Total</th>
            <th v-if="!disableEdit" scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="item.id">
            <td>{{ index + 1 }}</td>
            <td>{{ item.description }}</td>
            <td>{{ item.quantity }}</td>
            <td>&#8358;{{ formatMoney(item.quantity * item.price) }}</td>
            <td v-if="!disableEdit">
              <button
                class="btn btn-success btn-sm btn-rounded btn-space mr-1"
                @click.prevent="onEdit(item)"
              >
                <i class="icon mdi mdi-edit text-white"></i>
                Edit
              </button>
              <button
                class="btn btn-danger btn-sm btn-rounded btn-space mr-1"
                @click.prevent="removeItem(item.id)"
              >
                <i class="icon mdi mdi-delete text-white"></i>
                Remove
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { helper } from "@/helper";

export default {
  name: "ViewPurchaseOrderDetail",
  props: ["items", "disableEdit", "draft", "confirmed"],

  methods: {
    addItem() {
      // eslint-disable-next-line no-undef
      $("#mod-item-add").modal("show");
    },

    addPaymentDetails() {
      // eslint-disable-next-line no-undef
      $("#mod-upload-details").modal("show");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    onEdit(item) {
      this.$emit("performEdit", { id: item.id, quantity: item.quantity });
    },

    removeItem(id) {
      this.$emit("onDeleteItem", id);
    },
  },
};
</script>

<style>
</style>