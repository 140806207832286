<template>
  <div class="modal fade colored-header colored-header-primary" id="mod-part-payment" role="dialog">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong>Pay Other Amount</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="cancel"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right">Order Amount</label>
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                :class="['form-control', { 'is-invalid': v1.amount || !v1.is_valid }]"
                type="number"
                placeholder="Enter amount"
                v-model.trim="amount"
              />
              <div class="invalid-feedback" v-if="v1.amount">
                Field is required.
              </div>
              <div class="invalid-feedback" v-if="!v1.is_valid">
                {{ v1.text }}
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary md-close"
            type="button"
            data-dismiss="modal"
            @click="cancel"
          >
            Cancel
          </button>
          <button class="btn btn-primary" @click.prevent="recordPayment" type="button">
            <span v-if="isPosting" class="spinner-border spinner-border-sm" role="status">
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else> Pay </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "add-part-payment",
  props: ["isPosting"],

  data() {
    return {
      amount: "",
      v1: {
        amount: false,
        text: "",
        is_valid: true,
      },
    };
  },

  validations: {
    payment: {
      amount: { required },
    },
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  async mounted() {
    this.v1 = {
      amount: false,
      text: "",
      is_valid: true,
    };
  },

  methods: {
    cancel() {
      this.v1 = {
        amount: false,
        text: "",
        is_valid: true,
      };
      this.amount = 0;
      this.$emit("closeModal");
    },

    validatePayment() {
      this.v1 = {
        amount: false,
        text: "",
        is_valid: true,
      };
      var isValid = true;

      if (!this.amount) {
        this.v1.amount = true;
        this.v1.is_valid = true;
        return (isValid = false);
      }

      if (this.amount <= 0) {
        this.v1.amount = false;
        this.v1.is_valid = false;
        this.v1.text = "Payment amount must be greater than 0";
        isValid = false;
      }

      return isValid;
    },

    async recordPayment() {
      if (this.validatePayment()) {
        this.$emit("makePayment", this.amount, true);
      }
    },
  },
};
</script>
