<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button
            class="btn btn-secondary btn-rounded btn-space"
            @click="viewFilter"
          >
            <span class="feather icon-filter text-white"></span> Filters
          </button>

          <!-- <button class="btn btn-secondary btn-rounded btn-space float-right" @click="viewExport">
                      <span class="icon icon-left mdi mdi-arrow-top-right text-primary"></span> Export
                    </button>-->
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 30%">Name</th>
                      <th style="width: 10%">Code</th>
                      <th style="width: 10%">Status</th>
                      <th style="width: 20%">Created</th>
                      <th style="width: 30%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="m in distributors" :key="m.id">
                      <td>
                        <a href="#" @click.prevent="details(m.id)">
                          <strong>{{ m.name }}</strong>
                        </a>
                        <br />
                        <span>{{ m.description }}</span>
                      </td>
                      <td>{{ m.code }}</td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': m.active },
                            { 'badge-danger': !m.active },
                          ]"
                        >
                          {{ m.active ? "Active" : "Inactive" }}
                        </span>
                      </td>
                      <td>{{ formatDate(m.created) }}</td>
                      <td class="text-right">
                        <button
                          class="btn btn-danger btn-sm btn-rounded btn-space"
                          @click.prevent="viewRemove(m)"
                        >
                          {{ m.active ? "Disable" : "Enable" }}
                        </button>
                        <button
                          v-if="m.active"
                          class="btn btn-primary btn-sm btn-rounded btn-space"
                          @click.prevent="manageManufacturers(m.id)"
                        >
                          Manufacturers
                        </button>
                        <button
                          v-if="m.active"
                          :disabled="isBusy == true"
                          class="btn btn-primary btn-sm btn-rounded btn-space"
                          @click.prevent="impersonate(m)"
                        >
                          <span v-if="isBusy">Please wait...</span>
                          <span v-else>Login</span>
                        </button>
                        <!-- <button v-if="m.active" class="btn btn-secondary btn-rounded btn-space" @click.prevent="viewCreditLimit(m)">Manage credit</button> -->
                      </td>
                    </tr>
                    <tr v-if="!distributors.length">
                      <td colspan="5">
                        <div class="text-center" style="padding-top: 25px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-truck-fast"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 50px">
                          You don't have any distributors.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} distributor{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-export"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Export</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body"></div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button style="width: 100px" class="btn btn-primary" type="button">
              Export
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-delete"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <!-- <div class="text-warning"><span class="modal-main-icon mdi mdi-alert"></span></div> -->
              <h4 class="p-1"><b>Confirmation</b></h4>
              <p>
                Are you sure you want to
                {{ deletingMerchant.active ? "disable" : "enable" }} this
                distributor: <strong>{{ deletingMerchant.name }}</strong
                >?
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 150px"
              :disabled="isBusyModal"
              class="btn btn-warning"
              type="button"
              @click.prevent="updateStatus"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option value="0">All Distributors</option>
                  <option value="True">Active</option>
                  <option value="False">Disabled</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Manufacturer</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.parentId"
                >
                  <option value="0">All Manufacturers</option>
                  <option v-for="m in manufacturers" :key="m.id" :value="m.id">
                    {{ m.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Other filters</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by name..."
                  v-model="filter.qry"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getMerchants"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import Select2 from "@/components/Select2";

export default {
  name: "distributors",
  components: {
    Select2,
  },
  created() {
    this.getMerchants();
    this.getManufacturers();
  },
  watch: {
    "updatingMerchant.hasCredit": function (newVal, oldVal) {
      if (!newVal) {
        this.updatingMerchant.creditLimit = 0;
      }
    },
  },
  data() {
    return {
      filter: {
        status: "0",
        parentId: "0",
        qry: "",
      },
      manufacturers: [],
      deletingMerchant: {},
      updatingMerchant: {},
      v1: {
        creditLimit: false,
      },
      isBusy: false,
      isBusyModal: false,
      distributors: [],
      count: 0,
      pageNumber: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    reset() {
      this.filter = {
        status: "0",
        parentId: "0",
        qry: "",
      };
    },

    viewRemove(m) {
      this.deletingMerchant = m;
      $("#mod-delete").modal("show");
    },

    viewCreditLimit(m) {
      this.updatingMerchant = m;
      $("#mod-update").modal("show");
    },

    manageManufacturers(id) {
      this.$router.push("/distributors/" + id + "/parents");
    },

    async impersonate(m) {
      if (
        confirm(
          "You are about to login as " +
            m.name +
            "\nAre you sure you want to proceed?"
        )
      ) {
        this.isBusy = true;

        await this.$http
          .post("/Auth/generateDistributorImpersonationToken/" + m.id, null)
          .then(function (res) {
            if (res.body.forceChangeOfPassword) {
              this.$store.commit("setuserId", res.body.id);
              this.$router.push("/change-password");
            } else {
              this.$auth.setToken(
                res.body.accessToken,
                Date.now() + res.body.expiresIn * 60000,
                res.body.refreshToken,
                res.body.role,
                res.body.userType
              );

              window.location.href = "dashboard";
            }
            this.isBusy = false;
          })
          .catch(function () {
            this.isBusy = false;
          });
      }
    },
    viewFilter() {
      $("#mod-filter").modal("show");
    },
    details(id) {
      this.$router.push("/distributors/" + id);
    },
    updateStatus() {
      this.isBusyModal = true;
      var data = {
        id: this.deletingMerchant.id,
        active: !this.deletingMerchant.active,
      };

      $("#mod-delete").modal("hide");

      this.$http
        .put("/Accounts/updateStatus/" + data.id, data)
        .then(function (res) {
          this.isBusyModal = false;
          this.getMerchants();
        })
        .catch(function () {
          this.isBusyModal = false;
        });
    },
    updateCreditLimits() {
      this.isBusyModal = true;

      if (this.validate()) {
        var data = {
          id: this.updatingMerchant.id,
          creditLimit: this.updatingMerchant.creditLimit,
          hasCredit: this.updatingMerchant.hasCredit,
        };

        $("#mod-update").modal("hide");

        this.$http
          .put("/Accounts/updateCreditLimits/" + data.id, data)
          .then(function (res) {
            this.isBusyModal = false;
            this.getMerchants();
          })
          .catch(function () {
            this.isBusyModal = false;
          });
      } else {
        this.isBusyModal = false;
      }
    },
    validate() {
      this.v1 = {
        creditLimit: false,
      };
      var isValid = true;

      if (Number(this.updatingMerchant.creditLimit) < 0) {
        this.v1.creditLimit = true;
        isValid = false;
      }

      return isValid;
    },
    async getMerchants() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Accounts/getList?pageNumber=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.filter.parentId != "0"
              ? "&parentId=" + this.filter.parentId
              : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            "&accountType=Distributor" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function (res) {
          this.distributors = res.body.data;
          this.pageNumber = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function () {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    prev() {
      this.pageNumber -= 1;
      this.getMerchants();
    },

    next() {
      this.pageNumber += 1;
      this.getMerchants();
    },

    async getManufacturers() {
      await this.$http
        .get("/Accounts/getItemList?accountType=Manufacturer")
        .then(function (res) {
          this.manufacturers = res.body;
        })
        .catch(function () {});
    },
  },
};
</script>