<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong>Invite new customer</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="reset"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Send invite via</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <select
                class="form-control"
                :class="[
                  'form-control',
                  { 'is-invalid': $v.invitedUser.type.$error },
                ]"
                placeholder="Select an option"
                v-model.trim="$v.invitedUser.type.$model"
              >
                <option value="email">Email</option>
                <option value="sms" v-if="user.type != 'System'">SMS</option>
              </select>
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.type.required"
              >
                Field is required.
              </div>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="invitedUser.type == 'sms' || invitedUser.type == 'email'"
          >
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Email address</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                :class="[
                  'form-control',

                  { 'is-invalid': $v.invitedUser.emailAddress.$error },
                ]"
                type="email"
                placeholder="Email address of customer"
                autocomplete="off"
                v-model.trim="$v.invitedUser.emailAddress.$model"
              />
              <div
                class="invalid-feedback"
                v-if="
                  invitedUser.type == 'email' &&
                  !$v.invitedUser.emailAddress.required
                "
              >
                Field is required.
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.emailAddress.email"
              >
                Email is invalid.
              </div>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="invitedUser.type == 'sms' || invitedUser.type == 'email'"
          >
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Phone Number</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                :class="[
                  'form-control',
                  { 'is-invalid': $v.invitedUser.phoneNumber.$error },
                ]"
                type="tel"
                placeholder="Phone number of customer"
                autocomplete="off"
                v-model.trim="$v.invitedUser.phoneNumber.$model"
                @blur="findUser"
              />
              <div
                class="invalid-feedback"
                v-if="
                  invitedUser.type == 'sms' &&
                  !$v.invitedUser.phoneNumber.required
                "
              >
                Field is required.
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.phoneNumber.validatePhone"
              >
                Number not valid!
              </div>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="invitedUser.type == 'sms' || invitedUser.type == 'email'"
          >
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Name</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                :disabled="customerExists"
                :class="[
                  'form-control',
                  { 'is-invalid': $v.invitedUser.name.$error },
                ]"
                type="text"
                placeholder="Name of customer"
                v-model.trim="$v.invitedUser.name.$model"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.name.required"
              >
                Field is required.
              </div>
            </div>
          </div>
          <div
            class="form-group row"
            v-if="invitedUser.type == 'sms' || invitedUser.type == 'email'"
          >
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Delivery Address</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <textarea
                :disabled="customerExists"
                :class="[
                  'form-control',
                  { 'is-invalid': $v.invitedUser.deliveryAddress.$error },
                ]"
                placeholder="Delivery address of customer"
                v-model.trim="$v.invitedUser.deliveryAddress.$model"
              ></textarea>
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.deliveryAddress.required"
              >
                Field is required.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Account Manager</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <select
                class="form-control"
                placeholder="Select Account Manager (optional)"
                v-model.trim="invitedUser.introducerId"
              >
                <option v-for="c in introducers" :key="c.id" :value="c.id">
                  {{ c.text }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Customer Access</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <select
                :class="[
                  'form-control',
                  { 'is-invalid': $v.invitedUser.allowCustomerAccess.$error },
                ]"
                placeholder="Select an option"
                v-model.trim="$v.invitedUser.allowCustomerAccess.$model"
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.allowCustomerAccess.required"
              >
                Field is required.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-secondary"
            type="button"
            data-dismiss="modal"
            @click="reset"
          >
            Cancel
          </button>
          <button
            style="width: 100px"
            :disabled="isInviting"
            class="btn btn-primary"
            type="button"
            @click.prevent="invite"
          >
            <span
              v-if="isInviting"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else>Invite</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { helper } from "@/helper";
import { required, email } from "vuelidate/lib/validators";

const validatePhone = (value) => (value ? helper.validatePhone(value) : true);

export default {
  name: "InviteCustomer",

  data() {
    return {
      introducers: [],
      isInviting: false,
      customerExists: false,
      invitedUser: {
        type: "",
        phoneNumber: "",
        emailAddress: "",
        name: "",
        role: "Customer",
        deliveryAddress: "",
        introduerId: "",
        allowCustomerAccess: "",
      },
    };
  },

  async created() {
    await this.getIntroducers();
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  validations() {
    const invitedUser = {
      type: { required },
      allowCustomerAccess: { required },
      name: { required },
      deliveryAddress: { required },
    };

    if (this.invitedUser.type === "sms") {
      return {
        invitedUser: {
          ...invitedUser,
          emailAddress: { email },
          phoneNumber: { required, validatePhone },
        },
      };
    } else if (this.invitedUser.type === "email") {
      return {
        invitedUser: {
          ...invitedUser,
          emailAddress: { required, email },
          phoneNumber: { validatePhone },
        },
      };
    } else {
      return {
        invitedUser: invitedUser,
      };
    }
  },

  methods: {
    async invite() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isInviting = true;

        try {
          const data = {
            phoneNumber: this.invitedUser.phoneNumber,
            emailAddress: this.invitedUser.emailAddress,
            identifierType: this.invitedUser.type,
            userType: this.user.type,
            name: this.invitedUser.name,
            role: this.invitedUser.role,
            parentId: this.user.parentId,
            deliveryAddress: this.invitedUser.deliveryAddress,
            introducerId: this.invitedUser.introducerId,
            allowCustomerAccess: this.invitedUser.allowCustomerAccess,
            createdBy: this.user.displayName,
          };

          const response = await await this.$http.post("/Users/invite", data);
          if (response.ok) {
            this.$toast.success("User invited successfully", {
              icon: true,
              rtl: false,
            });
            this.reset();
            this.$emit("onInviteComplete");
          }
        } catch (error) {}
        this.isInviting = false;
      }
    },

    async getIntroducers() {
      try {
        const response = await this.$http.get(
          "/Users/getItemListOfIntroducers/" + this.user.parentId
        );
        if (response.ok) this.introducers = response.body;
      } catch (error) {}
    },

    async findUser() {
      if (this.invitedUser.phoneNumber.length == 11) {
        try {
          const response = await this.$http.get(
            "/Users/getByPhoneNumber/" + this.invitedUser.phoneNumber
          );
          if (response.ok) {
            this.invitedUser.name = res.body.displayName;
            this.invitedUser.deliveryAddress = res.body.deliveryAddress;
            this.customerExists = true;

            this.$toast.error(
              `This number already exists within our database as ${this.invitedUser.name}. By clicking invite, this customer will be added to your customer list as ${this.invitedUser.name}.`,
              {
                icon: true,
                rtl: false,
              }
            );
          } else {
            this.invitedUser.name = "";
            this.invitedUser.deliveryAddress = "";
            this.customerExists = false;
          }
        } catch (error) {
          this.invitedUser.name = "";
          this.invitedUser.deliveryAddress = "";
          this.customerExists = false;
        }
      }
    },

    reset() {
      this.invitedUser = {
        type: "",
        phoneNumber: "",
        emailAddress: "",
        name: "",
        role: "Customer",
        deliveryAddress: "",
        introduerId: "",
        allowCustomerAccess: "",
      };

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },
  },
};
</script>
