<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-payment-view"
    role="dialog"
  >
    <div class="modal-dialog">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header modal-header-colored">
          <div class="w-100 d-flex align-items-center">
            <h4 class="modal-title p-1">
              <strong>View Transaction</strong>
            </h4>
            <span
              :class="[
                'badge',
                ' badge-pill',
                {
                  'badge-warning':
                    data.paymentStatus == 'PENDING AUTHORIZATION',
                },
                { 'badge-danger': data.paymentStatus == 'REVERSED' },
                { 'badge-success': data.paymentStatus == 'AUTHORIZED' },
              ]"
              >{{
                data.paymentStatus === "AUTHORIZED"
                  ? "COMPLETED"
                  : data.paymentStatus
              }}</span
            >
          </div>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Amount</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                readonly
                class="form-control"
                type="text"
                :value="formatMoney(data.paidAmount)"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Customer</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                readonly
                class="form-control"
                type="text"
                :value="data.owner.displayName"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Account ID</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                readonly
                class="form-control"
                type="text"
                :value="data.owner.code"
              />
            </div>
          </div>

          <div>
            <hr />
          </div>

          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Reference ID</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                readonly
                class="form-control"
                type="text"
                :value="data.referenceId"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Channel</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                readonly
                class="form-control"
                type="text"
                :value="prepareDetail(data.paymentChannel)"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Fees</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                readonly
                class="form-control"
                type="text"
                :value="data.fees"
              />
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Posting Date</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                readonly
                class="form-control"
                type="text"
                :value="formatDate(data.paidOn)"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Completed Date</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                readonly
                class="form-control"
                type="text"
                :value="formatDate(data.created)"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Created By</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                readonly
                class="form-control"
                type="text"
                :value="data.maker.displayName"
              />
            </div>
          </div>
          <div v-if="data.authorizedBy" class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Authorization</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                readonly
                class="form-control"
                type="text"
                :value="data.authorizedBy.displayName"
              />
            </div>
          </div>
          <div>
            <hr />
          </div>
          <div v-if="data.desc" class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Payment Notes</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <input
                readonly
                class="form-control-plaintext"
                type="text"
                :value="data.desc"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-success" type="button" data-dismiss="modal">
            Ok
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { helper } from "@/helper";
export default {
  props: ["data"],

  methods: {
    prepareDetail(value) {
      const name = value ? value.paymentChannelTypeName : null;
      let result = "";
      if (name) {
        result += name;

        if (name.toLowerCase() === "bank") {
          result += "/ " + value.bankName + "/ " + value.accountNumber;
        } else if (name.toLowerCase() === "pos") {
          result += "/ " + value.bankName + "/ " + value.terminalId;
        } else if (name.toLowerCase() === "cryptocurrency") {
          result += "/ " + value.bankName + "/ " + value.walletAddress;
        }
      }
      return result;
    },

    formatDate(date) {
      if (date) return moment(date).format("ll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },
  },
};
</script>
