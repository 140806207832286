<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-upload-sales-edit"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Edit Sales Order</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="reset"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row" v-if="error">
            <div class="col-12 col-sm-12 col-lg-12">
              <label
                class="col-12 col-sm-12 text-sm-center alert alert-danger"
                >{{ error }}</label
              >
            </div>
          </div>

          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Sales Order Number</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.salesOrder.salesOrder.$error,
                }"
                type="number"
                placeholder="Enter sales order number"
                v-model="salesOrder.salesOrder"
                @input="setSalesOrder($event.target.value)"
              />
              <span
                class="invalid-feedback"
                v-if="!$v.salesOrder.salesOrder.required"
              >
                Sales Order Number is required!
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Customer Code</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.salesOrder.customerCode.$error,
                }"
                type="text"
                placeholder="Enter Customer Code"
                v-model="salesOrder.customerCode"
                @input="setCustomerCode($event.target.value)"
              />
              <span
                class="invalid-feedback"
                v-if="!$v.salesOrder.customerCode.required"
              >
                Customer Code is required!
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Customer Full Name</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.salesOrder.customerFullName.$error,
                }"
                type="text"
                placeholder="Enter Customer Full Name"
                v-model="salesOrder.customerFullName"
                @input="setCustomerFullName($event.target.value)"
              />
              <span
                class="invalid-feedback"
                v-if="!$v.salesOrder.customerFullName.required"
              >
                Customer Full Name is required!
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Product Code</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.salesOrder.productCode.$error,
                }"
                type="text"
                placeholder="Enter Product Code"
                v-model="salesOrder.productCode"
                @input="setProductCode($event.target.value)"
              />
              <span
                class="invalid-feedback"
                v-if="!$v.salesOrder.productCode.required"
              >
                Product Code is required!
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Product Price(NGN)</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.salesOrder.productPrice.$error,
                }"
                type="text"
                placeholder="Enter Product Price"
                v-model="salesOrder.productPrice"
                @input="setProductPrice($event.target.value)"
              />
              <span
                class="invalid-feedback"
                v-if="!$v.salesOrder.productPrice.required"
              >
                Product Price is required!
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Quantity Ordered</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.salesOrder.quantityOrdered.$error,
                }"
                type="text"
                placeholder="Enter Quantity"
                v-model="salesOrder.quantityOrdered"
                @input="setQuantity($event.target.value)"
              />
              <span
                class="invalid-feedback"
                v-if="!$v.salesOrder.quantityOrdered.required"
              >
                Quantity is required!
              </span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-secondary"
            type="button"
            data-dismiss="modal"
            @click="reset"
          >
            Cancel
          </button>
          <button
            :disabled="isValidating"
            style="width: 100px"
            class="btn btn-primary"
            type="button"
            @click.prevent="update"
          >
            <span v-if="isValidating">Validating..</span>
            <span v-else>Update</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
export default {
  props: ["sales", "error", "isValidating"],

  data() {
    return {
      salesOrder: {
        salesOrder: "",
        customerCode: "",
        customerFullName: "",
        productCode: "",
        productPrice: "",
        quantityOrdered: "",
      },
    };
  },

  watch: {
    sales: function (value) {
      this.setSalesOrder(value.salesOrder);
      this.setCustomerCode(value.customerCode);
      this.setCustomerFullName(value.customerFullName);
      this.setProductCode(value.productCode);
      this.setProductPrice(value.productPrice);
      this.setQuantity(value.quantityOrdered);
    },
  },

  validations: {
    salesOrder: {
      salesOrder: {
        required,
      },
      customerCode: {
        required,
      },
      customerFullName: {
        required,
      },
      productCode: {
        required,
      },
      productPrice: {
        required,
      },
      quantityOrdered: {
        required,
      },
    },
  },

  methods: {
    setSalesOrder(value) {
      this.salesOrder.salesOrder = value;
      this.$v.salesOrder.salesOrder.$touch();
    },

    setCustomerCode(value) {
      this.salesOrder.customerCode = value;
      this.$v.salesOrder.customerCode.$touch();
    },

    setCustomerFullName(value) {
      this.salesOrder.customerFullName = value;
      this.$v.salesOrder.customerFullName.$touch();
    },

    setProductCode(value) {
      this.salesOrder.productCode = value;
      this.$v.salesOrder.productCode.$touch();
    },

    setProductPrice(value) {
      this.salesOrder.productPrice = value;
      this.$v.salesOrder.productPrice.$touch();
    },

    setQuantity(value) {
      this.salesOrder.quantityOrdered = value;
      this.$v.salesOrder.quantityOrdered.$touch();
    },

    update() {
      this.$v.$touch();
      if (!this.$v.$invalid) this.$emit("onUpdateHandler", this.salesOrder);
    },

    reset() {
      this.setQuantity("");
      this.setProductPrice("");
      this.setProductCode("");
      this.setCustomerFullName("");
      this.setCustomerCode("");
      this.setSalesOrder("");

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },
  },
};
</script>

<style>
</style>