<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <i class="feather icon-filter text-white"></i> Filters
          </button>
          <button
            v-if="hasPermission('Global_View_Payments')"
            class="btn btn-info btn-rounded float-right"
            @click.prevent="viewGlobal()"
          >
            <span>
              <i class="mdi mdi-earth text-white"></i>
              Switch to {{ user.isGlobal ? "Default" : "Global" }}
            </span>
          </button>
          <button class="btn btn-primary btn-rounded btn-space" @click="addPayment">
            <i class="feather icon-plus text-white"></i> Post Payment
          </button>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    text-nowrap
                    table-striped table-hover-animation
                  "
                >
                  <thead>
                    <tr>
                      <th scope="col">Payment Id</th>
                      <th scope="col">Customer Name</th>
                      <th scope="col">Account ID</th>
                      <th scope="col">Amount(#)</th>
                      <th scope="col">Payment Channel</th>
                      <th scope="col">Reference</th>
                      <th scope="col">Entered By</th>
                      <th scope="col">Entry Date</th>
                      <th scope="col">Payment Date</th>
                      <th scope="col">Status</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="o in payments.data" :key="o.id">
                      <td>{{ o.paymentId }}</td>
                      <td>{{ o.owner ? o.owner.displayName : "NA" }}</td>
                      <td>
                        {{ o.owner && o.owner.code ? o.owner.code : "NA" }}
                      </td>
                      <td>
                        {{ o.paidAmount }}
                      </td>
                      <td>
                        {{ o.paymentChannel ? prepareDetail(o.paymentChannel) : o.paymentType }}
                      </td>
                      <td>
                        {{ o.referenceId }}
                      </td>
                      <td>
                        {{ o.maker.displayName }}
                      </td>
                      <td>
                        {{ formatDate(o.created) }}
                      </td>
                      <td>
                        {{ formatDate(o.paidOn) }}
                      </td>
                      <td>
                        {{ o.paymentStatus === "AUTHORIZED" ? "COMPLETED" : o.paymentStatus }}
                      </td>
                      <td>
                        <delete-post-payment
                          v-if="o.paymentStatus == 'PENDING AUTHORIZATION'"
                          :payment="o"
                          @showCaution="showCautionHandler"
                          @onDeleteComplete="onDeleteCompleteHandler"
                          :ref="o.id"
                        />
                        <button
                          class="btn btn-outline-primary btn-sm dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div class="dropdown-menu px-1" aria-labelledby="dropdownMenu2">
                          <a class="dropdown-item" href="#" @click="viewPayment(o)">
                            View
                          </a>
                          <a
                            v-if="o.paymentStatus === 'PENDING AUTHORIZATION'"
                            class="dropdown-item"
                            href="#"
                            @click.prevent="viewUpdate(o)"
                          >
                            Update
                          </a>
                          <router-link
                            v-if="hasPermission('View_Customer_Account')"
                            class="dropdown-item"
                            :to="{
                              path: `customers/account/${o.owner.id}`,
                            }"
                          >
                            View Customer Account</router-link
                          >
                          <a
                            v-if="o.paymentStatus != 'REVERSED'"
                            class="dropdown-item"
                            href="#"
                            @click.prevent="initiatePrintReceipt(o.id)"
                          >
                            Print Receipt
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="payments.length < 0">
                      <td colspan="12">
                        <div class="text-center" style="padding-top: 50px">
                          <span style="font-size: 4.615rem" class="mdi mdi-basket"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You have no payments.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="payments.pageSize"
                  :count="payments.pageCount"
                  :page="payments.pageNumber"
                  :itemsCount="payments.count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ payments.count }} payment{{ payments.count != 1 ? "s" : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
      </div>
      <add-payment
        :value="payment"
        :update="isUpdate"
        @onPostPaymentComplete="onPostPaymentCompleteHandler"
        @onAddPaymentCancel="onAddPaymentCancelHandler"
        @onUpdatePaymentComplete="onUpdatePaymentCompleteHandler"
      />
      <filter-payment />
      <view-payment :data="payment" />
      <delete-payment-caution
        :referenceId="selectedItem.referenceId"
        @onProceedToDelete="onProceedToDeleteHandler"
      />
      <AccountPostingReceipt :receiptData="singlePosting" :isPostPaymentPage="true" />
      <global-caution :isGlobal="user.isGlobal" @toggleGlobal="toggleGlobalHandler" />
    </div>
  </div>
</template>

<script>
import moment from "moment";
import AddPayment from "../../components/Payment/AddPayment.vue";
import FilterPayment from "../../components/Payment/FilterPayment.vue";
import ViewPayment from "../../components/Payment/ViewPayment.vue";
import DeletePostPayment from "../../components/Payment/DeletePostPayment.vue";
import GlobalCaution from "../../components/Posting/GlobalCaution.vue";
import SystemPermissions from "@/permissions";
import Pagination from "../../components/Pagination.vue";
import { mapActions, mapGetters } from "vuex";
import DeletePaymentCaution from "../../components/Payment/DeletePaymentCaution.vue";
import AccountPostingReceipt from "../../components/Posting/AccountPostingReceipt.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  components: {
    AddPayment,
    FilterPayment,
    ViewPayment,
    DeletePostPayment,
    DeletePaymentCaution,
    GlobalCaution,
    Pagination,
    AccountPostingReceipt,
  },

  data() {
    return {
      isBusy: false,
      isUpdate: false,
      selectedItem: {
        id: "",
        referenceId: "",
      },
      payment: {
        owner: {
          displayName: "",
        },

        maker: {
          displayName: "",
        },

        paymentChannel: {
          paymentChannelTypeName: "",
        },
      },
      singlePosting: {},
    };
  },

  computed: {
    ...mapGetters(["payments", "paymentFilter"]),
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  created() {
    this.getPayments();
  },

  watch: {
    "payments.loading": function(value) {
      if (value === false) $("#mod-post-payment-filter").modal("hide");
    },
  },

  methods: {
    ...mapActions(["getPayments"]),

    printReceipt() {
      const prtHtml = document.getElementById("paymentReceipt").innerHTML;
      let stylesHtml = "";
      for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
        stylesHtml += node.outerHTML;
      }

      const WinPrint = window.open("", "_blank", "");

      WinPrint.document.write(`<!DOCTYPE html>
                <html>
                  <head>
                    ${stylesHtml}
                    <style>
                        @font-face{
                        font-family:feather;
                        src:url(../assets/fonts/feather/fonts/feather.eot?t=1525787366991);
                        src:url(../assets/fonts/feather/fonts/feather.eot?t=1525787366991#iefix) format('embedded-opentype'),
                        url(../assets/fonts/feather/fonts/feather.woff?t=1525787366991) format('woff'),
                        url(../assets/fonts/feather/fonts/feather.ttf?t=1525787366991) format('truetype'),
                        url(../assets/fonts/feather/fonts/feather.svg?t=1525787366991#feather) format('svg')
                        }
                        html *
                        {
                           font-family: Sans-Serif, Arial !important;
                        }
                    </style>
                  </head>
                  <body>
                    ${prtHtml}
                  </body>
                </html>`);

      WinPrint.print();
    },

    async initiatePrintReceipt(id) {
      const singlePosting = this.payments.data.find((el) => el.id === id);
      this.singlePosting = singlePosting;
      setTimeout(this.printReceipt, 500);
    },

    viewFilter() {
      $("#mod-post-payment-filter").modal("show");
    },

    addPayment() {
      $("#mod-post-payment-add").modal("show");
    },

    onPostPaymentCompleteHandler() {
      this.getPayments();
      $("#mod-post-payment-add").modal("hide");
    },

    viewGlobal() {
      $("#mod-global").modal("show");
    },

    toggleGlobalHandler() {
      $("#mod-global").modal("hide");
      this.$store.commit("setIsGlobal");
      this.switchGlobal();
      this.getPayments();
    },

    prepareDetail(value) {
      const name = value ? value.paymentChannelTypeName : "";
      let result = "";
      if (name) {
        result += name;

        if (name.toLowerCase() === "bank") {
          result += "/ " + value.bankName + "/ " + value.accountNumber;
        } else if (name.toLowerCase() === "pos") {
          result += "/ " + value.bankName + "/ " + value.terminalId;
        } else if (name.toLowerCase() === "cryptocurrency") {
          result += "/ " + value.bankName + "/ " + value.walletAddress;
        }
      }
      return result;
    },

    onProceedToDeleteHandler() {
      $("#mod-post-payment-caution").modal("hide");
      const component = this.$refs[this.selectedItem.id][0];
      component.deletePayment();
    },

    showCautionHandler(data) {
      $("#mod-post-payment-caution").modal("show");
      this.selectedItem = data;
    },

    onDeleteCompleteHandler() {
      this.getPayments();
    },

    async switchGlobal() {
      this.isBusy = true;
      try {
        const response = await this.$http.put("/Users/switchGlobal");
        if (response.ok) {
        }
      } catch (error) {}
      this.isBusy = false;
    },

    onAddPaymentCancelHandler() {
      this.payment = {
        owner: {
          displayName: "",
        },

        maker: {
          displayName: "",
        },

        paymentChannel: {
          paymentChannelTypeName: "",
        },
      };
      this.isUpdate = false;
    },

    onUpdatePaymentCompleteHandler() {
      this.getPayments();
      this.onAddPaymentCancelHandler();
      $("#mod-post-payment-add").modal("hide");
      this.isUpdate = false;
    },

    viewUpdate(data) {
      this.payment = data;
      this.isUpdate = true;
      $("#mod-post-payment-add").modal("show");
    },

    viewPayment(data) {
      this.payment = data;
      $("#mod-payment-view").modal("show");
    },

    formatDate(date) {
      if (date) return moment(date).format("ll");
      else return "";
    },

    prev() {
      this.payments.pageNumber -= 1;
      this.getPayments();
    },

    next() {
      this.payments.pageNumber += 1;
      this.getPayments();
    },

    gotoPage(page) {
      this.payments.pageNumber = page;
      this.getPayments();
    },

    changeLimit(limit) {
      this.payments.pageSize = limit;
      this.getPayments();
    },
  },
};
</script>
