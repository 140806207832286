<template>
  <button
    data-toggle="tooltip"
    title="Edit"
    :class="[
      { 'btn-success': distributorPrice.active },
      { 'btn-danger': !distributorPrice.active },
    ]"
    class="btn btn-sm btn-rounded btn-space mr-1"
    :disabled="activeLoading"
    @click.prevent="switchStatus"
  >
    <span v-if="activeLoading">Busy...</span>
    <span v-else>
      {{ distributorPrice.active ? "Active" : "Inactive" }}
    </span>
  </button>
</template>

<script>
    import SystemPermissions from "@/permissions";
    export default {
        mixins: [SystemPermissions.Mixin],
  name: "SwitchDistributorPriceStatus",
  props: ["distributorPrice"],

  data() {
    return {
      activeLoading: false,
    };
  },

  methods: {
    async switchStatus() {
      this.activeLoading = true;
      this.isBusy = true;
      try {
        const response = await this.$http.put(
          "/Groups/updatePriceOptionStatus/" +
            this.distributorPrice.id +
            "/" +
            !this.distributorPrice.active
        );

        if (response.ok && response.status === 200) this.$emit("onComplete");
        this.activeLoading = false;
        this.isBusy = true;
      } catch (error) {
        this.activeLoading = false;
        this.isBusy = true;
      }
    },
  },
};
</script>
