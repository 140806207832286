<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="['card', 'card-border-color', 'card-border-color-primary', ' card-table', 'be-loading', {'be-loading-active':isBusy}]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <span class="feather icon-filter text-white"></span> Filter
          </button>

          <!-- <button class="btn btn-secondary btn-rounded btn-space float-right" @click="viewExport">
            <span class="icon icon-left mdi mdi-arrow-top-right text-primary"></span> Export
          </button>-->

          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
            v-if="hasPermission('Expense_Category_Create')"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> New Expense Category
          </button>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width:50%;">Name</th>
                      <th style="width:20%;">Status</th>
                      <th style="width:20%;">Created</th>
                      <th style="width:10%;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l in ExpenseCategories" :key="l.id">
                      <td>
                        {{ l.name }}
                      </td>
                      <td>
                        <span :class="['badge', ' badge-pill', { 'badge-success':l.active }, { 'badge-danger':!l.active }]">
                          {{ l.active? 'Active' :
                          'Inactive' }}
                        </span>
                      </td>
                      <td>{{ formatDate(l.created) }}</td>
                      <td>
                        <div class="text-right" v-if="hasPermission('Expense_Category_Update')">
                          <button
                            v-if="l.parentId==user.parentId"
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewEdit(l)"
                          ><i class="feather icon-edit"></i> Edit</button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!ExpenseCategories.length">
                      <td colspan="6">
                        <div class="text-center" style="padding-top:50px;">
                          <span style="font-size: 4.615rem;" class="mdi mdi-crosshairs-gps"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom:100px;">
                          You don't have
                          any expense categories.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">{{ count }} expense categor{{ count !=1? 'ies' : 'y' }}</div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="['paginate_button', 'page-item', 'previous', {'disabled':!hasPrev}]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev">Previous</a>
                    </li>
                    <li
                      :class="['paginate_button', 'page-item', 'previous', {'disabled':!hasNext}]"
                    >
                      <a href="#" class="page-link" @click.prevent="next">Next</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Create new expense category</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid':v1.name }]"
                  type="text"
                  v-model="createExpenseCategory.name"
                >
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="createExpenseCategory.active"
                  >
                  <span class="custom-control-label">This category is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px;"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >Cancel</button>
            <button
              style="width: 130px;"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Expense Category</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid':v2.name }]"
                  type="text"
                  v-model="updateExpenseCategory.name"
                >
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="updateExpenseCategory.active"
                  >
                  <span class="custom-control-label">This expense category is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px;"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >Cancel</button>
            <button
              style="width: 100px;"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select class="form-control" placeholder="Select an option" v-model="filter.status">
                  <option value="0">All Expense Categories</option>
                  <option value="True">Active</option>
                  <option value="False">Disabled</option>
                </select>
              </div>
            </div>
            
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Other filters</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by name..."
                  v-model="filter.qry"
                >
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px;"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >Cancel</button>
            <button
              style="width: 100px;"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >Reset</button>
            <button
              style="width: 100px;"
              class="btn btn-primary md-close"
              type="button"
              @click="getExpenseCategories"
            >Search</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
    import Select2 from "@/components/Select2";
    import SystemPermissions from '@/permissions'

    export default {
        mixins: [SystemPermissions.Mixin],
  name: "settingsExpenseCategories",
  components: {
    Select2
  },
        created() {
            if (!this.hasPermission('Expense_Category_View')) {
                this.$router.push("/restricted");
            }
    this.getExpenseCategories();
  },
  watch: {
    
  },
  data() {
    return {
      filter: {
        status: "0",
        qry: ""
      },
      isBusy: false,
      isBusyModal: false,
      createExpenseCategory: {
        name: null,
        active: null
      },
      updateExpenseCategory: {
        name: null,
        active: true,
        id: null
      },
      v1: {
        name: false
      },
      v2: {
        name: false
      },
      ExpenseCategories: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    }
  },
  methods: {
    reset() {
      this.filter = {
        status: "0",
        qry: ""
      };
    },
    viewExport() {
      //   $("#mod-export").modal("show");
    },
    viewAdd() {
      this.createExpenseCategory = {
        name: null,
        active: false
      };
      this.v1 = {
        name: false
      };
      $("#mod-add").modal("show");
    },
    viewEdit(c) {
      this.updateExpenseCategory = c;
      $("#mod-edit").modal("show");
    },
    viewFilter() {
      $("#mod-filter").modal("show");
    },
    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.createExpenseCategory.name,
        active: this.createExpenseCategory.active,
        parentId: this.user.parentId
      };

      if (this.validateCreate()) {
        $("#mod-add").modal("hide");

        await this.$http
          .post("/ExpenseCategories/create", data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getExpenseCategories();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateCreate() {
      this.v1 = {
        name: false
      };
      var isValid = true;

      if (!this.createExpenseCategory.name) {
        this.v1.name = true;
        isValid = false;
      }

      return isValid;
    },
    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        name: this.updateExpenseCategory.name,
        active: this.updateExpenseCategory.active,
        id: this.updateExpenseCategory.id
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        await this.$http
          .put("/ExpenseCategories/update/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getExpenseCategories();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateUpdate() {
      this.v2 = {
        name: false
      };
      var isValid = true;

      if (!this.updateExpenseCategory.name) {
        this.v2.name = true;
        isValid = false;
      }

      return isValid;
    },
    async getExpenseCategories() {
      this.isBusy = true;

      await this.$http
        .get(
          "/ExpenseCategories/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function(res) {
          this.ExpenseCategories = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
    formatDate(date) {
      return moment(date).format("lll");
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    prev() {
      this.page -= 1;
      this.getExpenseCategories();
    },
    next() {
      this.page += 1;
      this.getExpenseCategories();
    }
  }
};
</script>