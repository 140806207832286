<template>
  <div class="row mt-5">
    <div class="table-responsive">
      <table class="table table-hover table-sm">
        <thead class="thead-light">
          <tr>
            <th scope="col">Item #</th>
            <th scope="col">Name</th>
            <th scope="col">Type</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td>{{ index + 1 }}</td>
            <td>{{ item.name }}</td>
            <td>
              {{
                item.type === 0 ? "Discount" : item.type === 1 ? "Tax" : "Free"
              }}
            </td>
            <td>
              <RemoveItem
                :itemId="item.id"
                @removeItem="onRemove"
                v-if="!isView"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { helper } from "@/helper";
import RemoveItem from "../../components/SettingsPurchaseOrder/RemoveItem.vue";
export default {
  props: ["items", "isView"],

  components: {
    RemoveItem,
  },

  methods: {
    formatMoney(val) {
      return helper.formatMoney(val);
    },

    onRemove(itemId) {
      this.$emit("onRemove", itemId);
    },
  },
};
</script>

<style>
</style>