<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header d-flex justify-content-end">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button
              class="btn btn-primary btn-rounded btn-space"
              v-if="hasPermission('Group_Create')"
              @click="addGroup"
            >
              <span class="icon icon-left mdi mdi-plus text-white"></span>
              New Group
            </button>
            <button
              class="btn btn-info btn-rounded btn-space"
              @click="filterActive"
            >
              Show/Hide Deactivated
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4">
            <div class="row be-datatable-body max-height">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Description</th>
                      <th>Created</th>
                      <th>Last Modified</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="g in groups" :key="g.id">
                      <td>{{ g.name }}</td>
                      <td>{{ g.description.substring(0, 100) }}</td>
                      <td>{{ formatDate(g.created) }}</td>
                      <td>{{ formatDate(g.updated) }}</td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            {
                              'badge-success': g.active,
                            },
                            {
                              'badge-danger': !g.active,
                            },
                          ]"
                          >{{ g.active ? "Active" : "Inactive" }}</span
                        >
                      </td>
                      <td>
                        <div class="d-flex">
                          <button
                            v-if="hasPermission('Group_Update')"
                            data-toggle="tooltip"
                            title="Edit"
                            class="
                              btn btn-success btn-sm btn-rounded btn-space
                              mr-1
                            "
                            @click.prevent="editGroup(g)"
                          >
                            <i
                              class="
                                icon
                                mdi mdi-circle-edit-outline
                                text-white
                              "
                            ></i>
                          </button>
                          <DeleteGroup
                                       v-if="hasPermission('Group_Delete')"
                            :id="g.id"
                            @onComplete="onActionComplete"
                          />
                          <div>
                            <router-link
                                         v-if="hasPermission('Group_View_Members')"
                              class="
                                btn
                                btn-outline-info
                                btn-sm
                                btn-rounded
                                btn-space
                                mr-1
                              "
                              :to="{
                                path: `groups/${g.id}/distributor-association`,
                              }"
                            >
                              Distributor Association
                            </router-link>

                            <router-link  v-if="hasPermission('Price_Option_View')"
                              class="
                                btn
                                btn-outline-info
                                btn-sm
                                btn-rounded
                                btn-space
                              "
                              :to="{
                                path: `groups/${g.id}/price-option`,
                              }"
                            >
                              Price Option
                            </router-link>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} group{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Group
      :group="group"
      :showUpdate="update"
      @hide="hideModal"
      @onComplete="onActionComplete"
    />
  </div>
</template>

<script>
/* eslint-disable */
import Group from "./DistributorGroup.vue";
import DeleteGroup from "../../components/Group/DeleteGroup";
    import moment from "moment";
    import SystemPermissions from "@/permissions";

    export default {
        mixins: [SystemPermissions.Mixin],
  components: { Group, DeleteGroup },

        created() {
            if (!this.hasPermission("Group_View")) {
                this.$router.push("/restricted");
            }
    this.getGroups();
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      count: 0,
      pageNumber: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
      groups: [],
      group: null,
      showDeactivated: "",
      update: false,
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    addGroup() {
      this.group = null;
      $("#mod-group-add").modal("show");
    },

    editGroup(group) {
      this.group = group;
      this.update = true;
      $("#mod-group-add").modal("show");
    },

    hideModal() {
      $("#mod-group-add").modal("hide");
      this.update = false;
    },

    onActionComplete() {
      this.hideModal();
      this.getGroups();
    },

    onComplete() {
      this.hideModal();
      this.getGroups();
    },

    filterActive() {
      this.showDeactivated = !this.showDeactivated;
      this.getGroups();
    },

    async getGroups() {
      try {
        this.isBusy = true;

        const response = await this.$http.get(
          "/Groups/getList?page=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            "&active=" +
            this.showDeactivated
        );
        if (response.ok && response.status === 200) {
          this.groups = response.body.data;
          this.pageNumber = response.body.page;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
        }
      } catch (error) {
        this.isBusy = false;
      }
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    prev() {
      this.pageNumber -= 1;
      this.getGroups();
    },

    next() {
      this.pageNumber += 1;
      this.getGroups();
    },
  },
};
</script>

<style>
.dropdown-menu {
  width: 14.6rem;
}
</style>