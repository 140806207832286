<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-group-add"
    role="dialog"
  >
    <div class="modal-dialog">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong v-if="showUpdate">Update group</strong>
            <strong v-else>Create new group</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            @click.prevent="cancel"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Name</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                class="form-control"
                :class="{ 'is-invalid': $v.name.$error }"
                type="text"
                v-model.trim="$v.name.$model"
              />
              <div class="invalid-feedback" v-if="!$v.name.required">
                Field is required
              </div>
              <div class="invalid-feedback" v-if="!$v.name.minLength">
                Name must have at least
                {{ $v.name.$params.minLength.min }} letters.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Description</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <textarea
                class="form-control"
                :class="{ 'is-invalid': $v.description.$error }"
                v-model.trim="$v.description.$model"
              ></textarea>
              <div class="invalid-feedback" v-if="!$v.description.required">
                Field is required
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Active</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <label
                class="custom-control custom-checkbox custom-control-inline"
              >
                <input
                  class="custom-control-input"
                  v-model="active"
                  type="checkbox"
                />
                <span class="custom-control-label">This group is active</span>
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-secondary"
            type="button"
            @click.prevent="cancel"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            style="width: 160px"
            :disabled="isBusyModal"
            class="btn btn-primary"
            type="submit"
          >
            <span v-if="isBusyModal">Busy...</span>
            <span v-else-if="showUpdate"> Update Group</span>
            <span v-else>Add Group</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  name: "CreateDistributorGroup",
  props: ["group", "showUpdate"],

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      id: "",
      name: "",
      description: "",
      active: false,
    };
  },

  watch: {
    group: function (value) {
      if (this.showUpdate && value) {
        this.id = value.id;
        this.name = value.name;
        this.description = value.description;
        this.active = value.active;
      }
    },
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  validations: {
    name: {
      required,
      minLength: minLength(3),
    },

    description: {
      required,
    },
  },

  methods: {
    async submit() {
      if (this.showUpdate) {
        await this.update();
      } else {
        await this.create();
      }
    },

    async create() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isBusyModal = false;
        this.isBusy = false;
      } else {
        try {
          this.isBusyModal = true;
          this.isBusy = true;

          const data = {
            name: this.name,
            active: this.active,
            description: this.description,
            parentId: this.user.parentId,
            type: "Distributor",
          };
          const response = await this.$http.post("/Groups/create/", data);
          if (response.status === 201 && response.statusText === "Created") {
            this.reset();
            this.$emit("onComplete");
          }
          this.isBusyModal = false;
          this.isBusy = false;
        } catch (error) {
          this.isBusyModal = false;
          this.isBusy = false;
        }
      }
    },

    async update() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isBusyModal = false;
        this.isBusy = false;
      } else {
        try {
          this.isBusyModal = true;
          this.isBusy = true;

          var data = {
            name: this.name,
            active: this.active,
            id: this.id,
            description: this.description,
            type: "Distributor",
          };

          const response = await this.$http.put(
            "/Groups/update/" + data.id,
            data
          );

          if (response.status === 200) {
            this.$emit("onComplete");
          }

          this.isBusyModal = false;
          this.isBusy = false;
        } catch (error) {
          this.isBusy = false;
          this.isBusyModal = false;
        }
      }
    },

    async reset() {
      this.id = "";
      this.name = "";
      this.description = "";
      this.active = false;

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },

    cancel() {
      this.reset();
      this.$emit("hide");
    },
  },
};
</script>

<style>
</style>