<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <span class="feather icon-filter text-white"></span> Filter
          </button>

          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
            v-if="hasPermission('Inventory_Location_Create')"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> New Warehouse
          </button>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    text-nowrapa
                    no-footer
                    table-responsiv table-striped table-hover-animation
                  "
                >
                  <thead>
                    <tr>
                      <th style="width: 20%">Name</th>
                      <th style="width: 20%">Warehouse Manager</th>
                      <th style="width: 23%">Location</th>
                      <th style="width: 10%">Status</th>
                      <th style="width: 17%">Created</th>
                      <th style="width: 10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l in Locations" :key="l.id">
                      <td>
                        <span v-if="l.name">{{ l.name }}</span>
                        <span v-if="l.code">({{ l.code }})</span>
                      </td>
                      <td>
                        <span>{{ l.manager ? l.manager.displayName : "N/A" }}</span>
                      </td>
                      <td>
                        <span>{{ l.address && l.address }} {{ l.state ? l.state : "N/A" }} </span>
                      </td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': l.active },
                            { 'badge-danger': !l.active },
                          ]"
                        >
                          {{ l.active ? "Active" : "Inactive" }}
                        </span>
                      </td>
                      <td>{{ formatDate(l.created) }}</td>
                      <td>
                        <button
                          class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div class="dropdown-menu px-1" aria-labelledby="dropdownMenu2">
                          <div v-if="hasPermission('Inventory_Location_Update')">
                            <a
                              class="dropdown-item"
                              v-if="l.parentId == user.parentId"
                              @click.prevent="viewEdit(l)"
                            >
                              <i class="feather icon-edit"></i> &nbsp;Edit
                            </a>
                          </div>
                          <a
                            class="dropdown-item"
                            href="#"
                            v-if="hasPermission('Inventory_Location_Delete')"
                            style="color: red"
                            @click.prevent="confirmDelete(l.id)"
                          >
                            Delete Warehouse
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!Locations.length">
                      <td colspan="6">
                        <div class="text-center" style="padding-top: 50px">
                          <span style="font-size: 4.615rem" class="mdi mdi-crosshairs-gps"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any warehouses.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">{{ count }} warehouse{{ count != 1 ? "s" : "" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Create new warehouse</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.name }]"
                  type="text"
                  v-model="createLocation.name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Warehouse Manager</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  :disabled="isBusy"
                  v-model="createLocation.managerId"
                >
                  <option v-for="p in warehouseManagers" :key="p.id" :value="p.id">
                    {{ p.displayName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input :class="['form-control']" type="text" v-model="createLocation.code" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">State</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  :disabled="isBusy"
                  v-model="createLocation.state"
                >
                  <option v-for="(s, i) in states" :key="i" :value="s">
                    {{ s }}
                  </option>
                </select>
                <ul v-if="v1.state" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Address</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input :class="['form-control']" type="text" v-model="createLocation.address" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="createLocation.active"
                  />
                  <span class="custom-control-label">This warehouse is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 160px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add warehouse</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit warehouse</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="cancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.name }]"
                  type="text"
                  v-model="updateLocation.name"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Warehouse Manager</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  :disabled="isBusy"
                  v-model="updateLocation.managerId"
                >
                  <option v-for="p in warehouseManagers" :key="p.id" :value="p.id">
                    {{ p.displayName }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input :class="['form-control']" type="text" v-model="updateLocation.code" />
                <!-- <ul v-if="v2.code" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul> -->
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">State</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  :disabled="isBusy"
                  v-model="updateLocation.state"
                >
                  <option v-for="(s, i) in states" :key="i" :value="s">
                    {{ s }}
                  </option>
                </select>
                <ul v-if="v2.state" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Address</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input :class="['form-control']" type="text" v-model="updateLocation.address" />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="updateLocation.active"
                  />
                  <span class="custom-control-label">This warehouse is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Filter</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select class="form-control" placeholder="Select an option" v-model="filter.status">
                  <option value="0">All warehouses</option>
                  <option value="True">Active</option>
                  <option value="False">Disabled</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Other filters</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by code or name..."
                  v-model="filter.qry"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input class="custom-control-input" type="checkbox" v-model="filter.showForMe" />
                  <span class="custom-control-label">My warehouses Only</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getLocations"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <GeneralDeleteCaution
      :id="DeleteId"
      @proceed="deleteWarehouse"
      :isBusy="isBusyModal"
      text="warehouse"
      message="Deleting this warehouse will delete associated storage areas. Click DELETE to proceed."
    />
    <GeneralDeleteMessage
      message="You cannot delete this warehouse because there is an inventory or reconcile order transaction history associated with it."
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
import Select2 from "@/components/Select2";
import Pagination from "../components/Pagination.vue";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsLocations",
  components: {
    Pagination,
    Select2,
    GeneralDeleteCaution,
    GeneralDeleteMessage,
  },
  created() {
    if (!this.hasPermission("Inventory_Location_View")) {
      this.$router.push("/restricted");
    }
    this.getLocations();
    this.getWarehouseManagers();
  },
  watch: {},
  data() {
    return {
      filter: {
        status: "0",
        qry: "",
        showForMe: false,
      },
      isBusy: false,
      isBusyModal: false,
      warehouseManagers: [],
      states: helper.getStates(),
      createLocation: {
        code: "",
        managerId: "",
        state: "",
        address: "",
        name: "",
        active: "",
      },
      updateLocation: {
        code: "",
        name: "",
        managerId: "",
        state: "",
        address: "",
        active: true,
        id: "",
      },
      editLocation: {
        code: null,
        managerId: null,
        state: null,
        address: null,
        name: null,
        active: true,
        id: null,
      },
      v1: {
        code: false,
        state: false,
        name: false,
      },
      v2: {
        code: false,
        state: false,
        name: false,
      },
      Locations: [],
      DeleteId: "",
      count: 0,
      page: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    reset() {
      this.filter = {
        status: "0",
        qry: "",
        showForMe: false,
      };
    },

    cancel() {
      const currentLocation = this.Locations.find((l) => l.id === this.editLocation.id);

      currentLocation.code = this.editLocation.code;
      currentLocation.name = this.editLocation.name;
      currentLocation.state = this.editLocation.state;
      currentLocation.address = this.editLocation.address;
      currentLocation.managerId = this.editLocation.managerId;
      currentLocation.id = this.editLocation.id;
      currentLocation.active = this.editLocation.active;

      $("#mod-edit").modal("hide");
    },

    async getWarehouseManagers() {
      this.isBusy = true;

      await this.$http
        .get("/Users/getWarehouseManager/")
        .then(function(res) {
          this.warehouseManagers = res.body;
          this.isBusy = false;
        })
        .catch(function() {});
      this.isBusy = false;
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    viewAdd() {
      this.createLocation = {
        code: "",
        name: "",
        managerId: "",
        state: "",
        address: "",
        active: false,
      };
      this.v1 = {
        code: false,
        name: false,
        state: false,
      };
      $("#mod-add").modal("show");
    },

    viewEdit(c) {
      var editValues = {
        code: c.code,
        name: c.name,
        managerId: c.managerId,
        state: c.state,
        address: c.address,
        active: c.active,
        id: c.id,
      };
      this.editLocation = editValues;
      this.updateLocation = c;
      $("#mod-edit").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    confirmDelete(id) {
      this.DeleteId = id;
      $("#mod-confirm-delete").modal("show");
    },

    showDeleteMsg() {
      $("#mod-delete-msg").modal("show");
    },

    cancelConfirm() {
      this.DeleteId = "";
      $("#mod-confirm-delete").modal("hide");
    },
    async deleteWarehouse() {
      this.isBusyModal = true;
      try {
        const res = await this.$http.delete(`/Locations/delete/${this.DeleteId}`);
        if (res.ok) {
          this.$toast.success("Warehouse deleted successfully", {
            icon: true,
            rtl: false,
          });
          this.getLocations();
          this.cancelConfirm();
        }
      } catch (error) {
        this.cancelConfirm();
        this.showDeleteMsg();
      }
      this.isBusyModal = false;
    },

    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        code: this.createLocation.code,
        name: this.createLocation.name,
        managerId: this.createLocation.managerId,
        state: this.createLocation.state,
        address: this.createLocation.address,
        active: this.createLocation.active,
        parentId: this.user.parentId,
      };

      if (this.validateCreate()) {
        await this.$http
          .post("/Locations/create", data)
          .then(function(res) {
            this.isBusyModal = false;
            $("#mod-add").modal("hide");
          })
          .catch(function() {});

        this.getLocations();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateCreate() {
      this.v1 = {
        code: false,
        name: false,
        state: false,
      };
      var isValid = true;

      if (!this.createLocation.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.createLocation.state) {
        this.v1.state = true;
        isValid = false;
      }

      return isValid;
    },

    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        code: this.updateLocation.code,
        name: this.updateLocation.name,
        managerId: this.updateLocation.managerId,
        state: this.updateLocation.state,
        address: this.updateLocation.address,
        active: this.updateLocation.active,
        id: this.updateLocation.id,
        parentId: this.user.parentId,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        await this.$http
          .put("/Locations/update/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getLocations();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateUpdate() {
      this.v2 = {
        name: false,
        state: false,
      };
      var isValid = true;

      if (!this.updateLocation.name) {
        this.v2.name = true;
        isValid = false;
      }

      if (!this.updateLocation.state) {
        this.v1.state = true;
        isValid = false;
      }

      return isValid;
    },

    async getLocations() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Locations/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.filter.showForMe != "" ? "&showForMe=" + this.filter.showForMe : "")
        )
        .then(function(res) {
          this.Locations = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});
      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    appendNameCode(name, code) {
      return code ? `${name} (${code})` : `${name}`;
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    prev() {
      this.page -= 1;
      this.getLocations();
    },

    next() {
      this.page += 1;
      this.getLocations();
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getLocations();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getLocations();
    },
  },
};
</script>
