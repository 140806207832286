<template>
  <div
    id="sidebar"
    class="main-menu menu-fixed menu-light menu-accordion menu-shadow"
    data-scroll-to-active="true"
  >
    <div class="navbar-header">
      <ul class="nav navbar-nav flex-row">
        <li class="nav-item mr-auto">
          <router-link class="navbar-brand" to="/dashboard">
            <img src="/assets/img/logo-xx.png" alt="logo" width="175" />
          </router-link>
        </li>
        <li class="nav-item nav-toggle">
          <a class="nav-link modern-nav-toggle pr-0" data-toggle="collapse">
            <i
              class="
                feather
                icon-x
                d-block d-xl-none
                font-medium-4
                primary
                toggle-icon
              "
            ></i
            ><i
              class="
                toggle-icon
                feather
                icon-disc
                font-medium-4
                d-none d-xl-block
                collapse-toggle-icon
                primary
              "
              data-ticon="icon-disc"
            ></i>
          </a>
        </li>
      </ul>
    </div>
    <div class="shadow-bottom"></div>
    <div class="main-menu-content">
      <ul class="navigation navigation-main" id="main-menu-navigation" data-menu="menu-navigation">
        <li :class="[{ parent: user.role == 'Customer' }, { active: isDashboardActive }]">
          <router-link to="/dashboard">
            <i class="feather icon-grid"></i>
            <span>Dashboard</span>
          </router-link>
        </li>
        <li
          v-if="user.role == 'Customer'"
          :class="[
            { parent: user.role == 'Customer' },
            { active: isOrdersParentActive },
            {
              open: isOrdersSentActive || isOrdersCreateActive || isOrdersDraftActive,
            },
          ]"
        >
          <router-link to="#" @click.native.prevent="handleParentMenuClick('Orders')">
            <i class="feather icon-shopping-cart"></i>
            <span>Orders</span>
          </router-link>
          <ul class="menu-content">
            <li :class="{ active: isOrdersSentActive }">
              <router-link to="/merchant/orders">My Orders</router-link>
            </li>
            <li :class="{ active: isOrdersCreateActive }">
              <router-link to="/merchant/orders/create">New Order</router-link>
            </li>
            <li :class="{ active: isOrdersDraftActive }">
              <router-link to="/merchant/drafts">Drafts</router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="user.role == 'Customer'"
          :class="[
            { parent: user.role == 'Customer' },
            { active: isDistributorsActive },
            { open: isMerchantAddDistributorActive || isMerchantViewDistributorActive },
          ]"
        >
          <router-link to="#" @click.native.prevent="handleParentMenuClick('MerchantDistributors')">
            <i class="icon mdi mdi-truck-fast"></i>
            <span>Distributors</span>
          </router-link>
          <ul c class="menu-content">
            <li :class="{ active: isMerchantViewDistributorActive }">
              <router-link to="/merchant/distributors/view">My Distributors</router-link>
            </li>
            <li :class="{ active: isMerchantAddDistributorActive }">
              <router-link to="/merchant/distributors/add">Add New Distributors</router-link>
            </li>
          </ul>
        </li>
        <li :class="{ active: isGrowTradeActive }" v-if="user.role == 'Customer'">
          <router-link to="/merchant/growtrade">GrowTrade Account</router-link>
        </li>
        <li
          v-if="
            !(user.type != 'Distributor' || user.role == 'Customer') &&
              (hasPermission('Orders_View') || hasPermission('Orders_Create'))
          "
          :class="[
            {
              parent:
                user.role == 'Administrator' ||
                user.role == 'Support Specialist' ||
                user.role == 'Sales',
            },
            { active: isOrdersParentActive },
            { open: isOrdersActive || isOrdersCreateAltActive },
          ]"
        >
          <router-link to="#" @click.native.prevent="handleParentMenuClick('Orders')">
            <i class="feather icon-shopping-cart"></i>
            <span>Orders</span>
          </router-link>
          <ul
            class="menu-content"
            v-if="hasPermission('Orders_View') || hasPermission('Orders_Create')"
          >
            <li v-if="hasPermission('Orders_View')" :class="{ active: isOrdersActive }">
              <router-link to="/orders">
                <i class="feather icon-circle"></i> &nbsp;View Orders
              </router-link>
            </li>
            <li v-if="hasPermission('Orders_Create')" :class="{ active: isOrdersCreateAltActive }">
              <router-link to="/orders/create">
                <i class="feather icon-circle"></i> &nbsp;New Order
              </router-link>
            </li>
          </ul>
        </li>

        <li
          v-if="
            (user.type == 'Distributor' &&
              user.role != 'Customer' &&
              user.role != 'Sales' &&
              hasPermission('Account_Posting')) ||
              hasPermission('Payment_Posting')
          "
          :class="[
            'parent',
            { active: isManageCustomerAccountParentActive },
            {
              open: isManagePaymentsActive || isAccountPosting,
            },
          ]"
        >
          <a
            href="javascript:void"
            v-if="!user.isSalesUser"
            @click.prevent="handleParentMenuClick('CustomerAccount')"
          >
            <i class="feather icon-book-open"></i>
            <span>Manage Payment</span>
          </a>
          <ul class="sub-menu">
            <li v-if="hasPermission('Account_Posting')" :class="{ active: isAccountPosting }">
              <router-link to="/accountposting">
                <i class="feather icon-user"></i>
                <span>Account Posting</span>
              </router-link>
            </li>
            <li v-if="hasPermission('Payment_Posting')" :class="{ active: isManagePaymentsActive }">
              <router-link to="/managepayments">
                <i class="feather icon-credit-card"></i>
                <span>Post Payments</span>
              </router-link>
            </li>
          </ul>
        </li>

        <li
          v-if="
            user.type == 'Distributor' &&
              user.role != 'Customer' &&
              user.role != 'Sales' &&
              hasPermission('Expense_View')
          "
          :class="[
            {
              parent: user.role == 'Administrator' || user.role == 'Support Specialist',
            },
            { active: isManageExpensesActive },
            { open: isManageExpensesActive },
          ]"
        >
          <router-link to="/manage-expenses">
            <i class="feather icon-credit-card"></i>
            <span>Manage Expenses</span>
          </router-link>
        </li>

        <li
          v-if="
            !(user.type != 'Distributor' || user.role == 'Customer') &&
              hasPermission('Customer_View')
          "
          :class="{ active: isCustomerActive }"
        >
          <router-link to="/customers">
            <i class="feather icon-users"></i>
            <span>Customers</span>
          </router-link>
        </li>
        <li
          v-if="user.type == 'System'"
          :class="['parent', { active: isManufacturersActive }, { open: isManufacturersActive }]"
        >
          <router-link to="/manufacturers">
            <i class="feather icon-user"></i>
            <span>Manufacturers</span>
          </router-link>
          <ul class="sub-menu">
            <li :class="{ active: isManufacturersCreateActive }">
              <router-link to="/manufacturers/create">
                <i class="feather icon-circle"></i> &nbsp; New Manufacturer
              </router-link>
            </li>
          </ul>
        </li>
        <li v-if="user.type == 'System'" :class="['parent', { active: isStatisticsActive }]">
          <router-link to="/statistics">
            <i class="feather icon-bar-chart"></i>
            <span>Statistics</span>
          </router-link>
        </li>

        <li
          v-if="user.type == 'System'"
          :class="['parent', { active: isDistributorsActive }, { open: isDistributorsActive }]"
        >
          <router-link to="/distributors">
            <i class="icon mdi mdi-truck-fast"></i>
            <span>Distributors</span>
          </router-link>
          <ul class="sub-menu">
            <li :class="{ active: isDistributorsCreateActive }">
              <router-link to="/distributors/create">New Distributor</router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="
            !user.isSalesUser &&
              user.type == 'Manufacturer' &&
              (hasPermission('Distributor_View') || hasPermission('Distributor_Create'))
          "
          :class="[
            'parent',
            { active: isManufacturerDistributorsParentActive },
            {
              open: isManufacturerDistributorsCreateActive || isManufacturerDistributorsActive,
            },
          ]"
        >
          <a
            href="javascript:void"
            @click.prevent="handleParentMenuClick('ManufacturerDistributor')"
          >
            <i class="feather icon-book-open"></i>
            <span>Distributors</span>
          </a>
          <ul class="sub-menu">
            <li
              v-if="hasPermission('Distributor_View')"
              :class="{ active: isManufacturerDistributorsActive }"
            >
              <router-link to="/manufacturer-distributors">
                <i class="feather icon-circle"></i> &nbsp; Distributors
              </router-link>
            </li>
            <li
              v-if="hasPermission('Distributor_Create')"
              :class="{ active: isManufacturerDistributorsCreateActive }"
            >
              <router-link to="/manufacturer-distributors/create"
                ><i class="feather icon-circle"></i> &nbsp; New Distributor</router-link
              >
            </li>
          </ul>
        </li>
        <li
          v-if="!user.isSalesUser && user.type == 'Manufacturer' && hasPermission('Group_View')"
          :class="[
            'parent',
            { active: isManufacturerGroupActive },
            { open: isManufacturerGroupActive },
          ]"
        >
          <router-link to="/groups">
            <i class="icon mdi mdi-account-group"></i>
            <span>Groups</span>
          </router-link>
        </li>
        <li
          v-if="
            !user.isSalesUser &&
              user.type == 'Manufacturer' &&
              (hasPermission('Product_View') ||
                hasPermission('Product_Category_View') ||
                hasPermission('Product_Unit_View'))
          "
          :class="[
            'parent',
            { active: isManufacturerProductParentActive },
            {
              open: isManufacturerProductsActive || isProductCatActive || isProductUnitActive,
            },
          ]"
        >
          <a href="javascript:void" @click.prevent="handleParentMenuClick('ManufacturerProduct')">
            <i class="feather icon-tag"></i>
            <span>Products</span>
          </a>
          <ul class="sub-menu">
            <li
              v-if="hasPermission('Product_View')"
              :class="{ active: isManufacturerProductsActive }"
            >
              <router-link to="/manufacturer-products">
                <i class="feather icon-circle"></i> &nbsp; View Products
              </router-link>
            </li>
            <li
              v-if="hasPermission('Product_Category_View')"
              :class="{ active: isProductCatActive }"
            >
              <router-link to="/manufacturer-products/cat/view">
                <i class="feather icon-circle"></i> &nbsp; Product Categories
              </router-link>
            </li>
            <li v-if="hasPermission('Product_Unit_View')" :class="{ active: isProductUnitActive }">
              <router-link to="/manufacturer-products/units/view">
                <i class="feather icon-circle"></i> &nbsp; Product Units
              </router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="
            user.type == 'Distributor' &&
              user.role != 'Customer' &&
              user.role != 'Sales' &&
              hasPermission('Product_View')
          "
          :class="[
            {
              parent: user.role == 'Administrator' || user.role == 'Support Specialist',
            },
            {
              active: isProductsActive || isProductCatActive || isProductUnitActive,
            },
            {
              open: isProductsActive || isProductCatActive || isProductUnitActive,
            },
          ]"
        >
          <router-link to="/products">
            <i class="feather icon-tag"></i>
            <span>Products</span>
          </router-link>
          <ul
            class="sub-menu"
            v-if="
              user.distributorProductPermission &&
                (user.distributorProductPermission.noRestriction ||
                  user.distributorProductPermission.onlySellTheirProducts) &&
                (hasPermission('Product_Category_View') || hasPermission('Product_Unit_View'))
            "
          >
            <li
              v-if="hasPermission('Product_Category_View')"
              :class="{ active: isProductCatActive }"
            >
              <router-link to="/manufacturer-products/cat/view?t=d">
                <i class="feather icon-circle"></i> &nbsp; Product Categories
              </router-link>
            </li>
            <li v-if="hasPermission('Product_Unit_View')" :class="{ active: isProductUnitActive }">
              <router-link to="/manufacturer-products/units/view?t=d">
                <i class="feather icon-circle"></i> &nbsp; Product Units
              </router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="
            user.type == 'Distributor' &&
              user.role != 'Customer' &&
              (hasPermission('Inventory_View') ||
                hasPermission('Inventory_Location_View') ||
                hasPermission('Inventory_SubLocation_View') ||
                hasPermission('Inventory_Reconcile_Stock_View') ||
                hasPermission('Stock_Transfer_View'))
          "
          :class="[
            'parent',
            { active: isManageStockParentActive },
            {
              open:
                isManageStockActive ||
                isStockTransferActive ||
                isLocationsActive ||
                isSubLocationsActive ||
                isReconcileOrdersToStockActive ||
                isPurchaseOrderActive,
            },
          ]"
        >
          <a href="javascript:void" @click.prevent="handleParentMenuClick('Inventory')">
            <i class="feather icon-book-open"></i>
            <span>Inventory Management</span>
          </a>
          <ul class="sub-menu">
            <li v-if="hasPermission('Inventory_View')" :class="{ active: isManageStockActive }">
              <router-link to="/managestock">
                <i class="feather icon-circle"></i> &nbsp; Manage Stock
              </router-link>
            </li>
            <li
              v-if="hasPermission('Stock_Transfer_View')"
              :class="{ active: isStockTransferActive }"
            >
              <router-link to="/stocktransfer">
                <i class="feather icon-circle"></i> &nbsp; Stock Transfers
              </router-link>
            </li>
            <li
              v-if="hasPermission('Inventory_Location_View')"
              :class="{ active: isLocationsActive }"
            >
              <router-link to="/warehouses">
                <i class="feather icon-circle"></i> &nbsp; Warehouses
              </router-link>
            </li>
            <li
              v-if="hasPermission('Inventory_SubLocation_View')"
              :class="{ active: isSubLocationsActive }"
            >
              <router-link to="/storage-areas">
                <i class="feather icon-circle"></i>
                &nbsp;Storage Areas
              </router-link>
            </li>
            <li
              v-if="hasPermission('Inventory_Reconcile_Stock_View')"
              :class="{ active: isReconcileOrdersToStockActive }"
            >
              <router-link to="/reconcileorderstostock">
                <i class="feather icon-circle"></i> &nbsp;Reconcile Orders
              </router-link>
            </li>
            <li
              v-if="hasPermission('Inventory_Purchase_Order')"
              :class="{ active: isPurchaseOrderActive }"
            >
              <router-link to="/salesorders">
                <i class="feather icon-circle"></i> &nbsp; Sales Order
              </router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="
            user.type == 'Distributor' &&
              user.role != 'Customer' &&
              (hasPermission('Campaign_View') || hasPermission('Campaign_Create'))
          "
          :class="[
            'parent',
            { active: isCampaignsParentActive },
            { open: isCampaignsActive || isCampaignsCreateActive },
          ]"
        >
          <router-link to="#" @click.native.prevent="handleParentMenuClick('Campaigns')">
            <i class="feather icon-speaker"></i>
            <span>Campaigns</span>
          </router-link>
          <ul class="sub-menu">
            <li v-if="hasPermission('Campaign_View')" :class="{ active: isCampaignsActive }">
              <router-link to="/campaigns">
                <i class="feather icon-circle"></i> &nbsp;View Campaigns
              </router-link>
            </li>
            <li
              v-if="hasPermission('Campaign_Create')"
              :class="{ active: isCampaignsCreateActive }"
            >
              <router-link to="/campaigns/create">
                <i class="feather icon-circle"></i> &nbsp;New Campaign
              </router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="
            !user.isSalesUser &&
              (hasPermission('User_View') ||
                hasPermission('BusinessProfile_View') ||
                (user.type == 'Distributor' && hasPermission('Group_View')) ||
                (user.type == 'Distributor' && hasPermission('Branch_View')) ||
                (user.type == 'Distributor' && hasPermission('Discount_View')) ||
                (user.type == 'Distributor' && hasPermission('System_Settings_View')) ||
                (user.type == 'Manufacturer' && hasPermission('Expense_Category_View')) ||
                (user.type == 'Distributor' && hasPermission('Payment_Channels_View')) ||
                ((user.type == 'Distributor' || user.type == 'Manufacturer') &&
                  hasPermission('Role_View')))
          "
          :class="[
            'parent',
            { active: isSettingsActive },
            {
              open:
                isTeamActive ||
                isBizProfileActive ||
                isGroupsActive ||
                isDiscountsActive ||
                isBranchesActive ||
                isSystemSettingsActive ||
                isExpenseCategoryActive ||
                isPaymentChannelActive ||
                isRolesActive,
            },
          ]"
        >
          <router-link to="#" @click.native.prevent="handleParentMenuClick('Settings')">
            <i class="feather icon-settings"></i>
            <span>Settings</span>
          </router-link>
          <ul class="sub-menu">
            <li v-if="hasPermission('User_View')" :class="{ active: isTeamActive }">
              <router-link to="/settings/team"
                ><i class="feather icon-circle"></i>&nbsp;Users</router-link
              >
            </li>
            <li v-if="hasPermission('User_View')" :class="{ active: isPaymentActive }">
              <!-- <router-link to="/settings/payment"
            ><i class="feather icon-circle"></i> &nbsp;Payment
            Channel</router-link
          > -->
            </li>
            <li
              v-if="user.type != 'System' && hasPermission('BusinessProfile_View')"
              :class="{ active: isBizProfileActive }"
            >
              <router-link to="/settings/business">
                <i class="feather icon-circle"></i> &nbsp;Business Profile
              </router-link>
            </li>
            <li
              v-if="user.type == 'Distributor' && hasPermission('Group_View')"
              :class="{ active: isGroupsActive }"
            >
              <router-link to="/settings/groups"
                ><i class="feather icon-circle"></i>&nbsp;Groups</router-link
              >
            </li>
            <li
              v-if="user.type == 'Distributor' && hasPermission('Discount_View')"
              :class="{ active: isDiscountsActive }"
            >
              <router-link to="/settings/discounts">
                <i class="feather icon-circle"></i>
                &nbsp;Discounts
              </router-link>
            </li>
            <li
              v-if="user.type == 'Distributor' && hasPermission('Branch_View')"
              :class="{ active: isBranchesActive }"
            >
              <router-link to="/settings/branch"
                ><i class="feather icon-circle"></i>&nbsp;Branches</router-link
              >
            </li>
            <li
              v-if="user.type == 'Distributor' && hasPermission('System_Settings_View')"
              :class="{ active: isSystemSettingsActive }"
            >
              <router-link to="/settings/systemsettings">
                <i class="feather icon-circle"></i> &nbsp;System Settings
              </router-link>
            </li>
            <li
              v-if="user.type == 'Manufacturer' && hasPermission('System_Settings_View')"
              :class="{ active: isSystemSettingsActive }"
            >
              <router-link to="/settings/manufacturersystemsettings">
                <i class="feather icon-circle"></i> &nbsp;System Settings
              </router-link>
            </li>
            <li
              v-if="user.type == 'Manufacturer' && hasPermission('Expense_Category_View')"
              :class="{ active: isExpenseCategoryActive }"
            >
              <router-link to="/settings/expenseCategory">
                <i class="feather icon-circle"></i> &nbsp;Expense Categories
              </router-link>
            </li>
            <li
              v-if="user.type == 'Distributor' && hasPermission('Payment_Channels_View')"
              :class="{ active: isPaymentChannelActive }"
            >
              <router-link to="/settings/paymentChannel">
                <i class="feather icon-circle"></i> &nbsp;Payment Channels
              </router-link>
            </li>
            <li
              v-if="
                (user.type == 'Distributor' || user.type == 'Manufacturer') &&
                  hasPermission('Role_View')
              "
              :class="{ active: isRolesActive }"
            >
              <router-link to="/settings/roles">
                <i class="feather icon-circle"></i> &nbsp;Role Management
              </router-link>
            </li>
            <li
              v-if="user.type == 'Distributor' && hasPermission('GeoFencing_View')"
              :class="{ active: isManageGeoFenceActive }"
            >
              <router-link to="/settings/geofence">
                <i class="feather icon-circle"></i> &nbsp;Manage Geo-Fence
              </router-link>
            </li>
          </ul>
        </li>

        <li
          v-if="
            !user.isSalesUser && user.type == 'Manufacturer' && hasPermission('Sales_Order_View')
          "
          :class="[
            'parent',

            { active: isPurchaseOrderParentActive },
            {
              open:
                isPurchaseOrderItemSetupActive ||
                isPurchaseOrderProcessActive ||
                isPurchaseOrderStageSetupActive,
            },
          ]"
        >
          <a href="javascript:void" @click.prevent="handleParentMenuClick('PurchaseOrder')">
            <i class="feather icon-list"></i>
            <span>Sales Order</span>
          </a>
          <ul class="sub-menu">
            <li
              v-if="hasPermission('Sales_Order_View')"
              :class="{ active: isPurchaseOrderProcessActive }"
            >
              <router-link to="/sales-order">
                <i class="feather icon-circle"></i>
                &nbsp;View Sales Orders
              </router-link>
            </li>
            <li
              v-if="hasPermission('Sales_Order_Stages_View')"
              :class="{ active: isPurchaseOrderStageSetupActive }"
            >
              <router-link to="/sales-order/stage/setup">
                <i class="feather icon-circle"></i>
                &nbsp;Sales Order Stages
              </router-link>
            </li>
          </ul>
        </li>

        <li
          v-if="!user.isSalesUser && user.role != 'Customer' && hasPermission('Report_View')"
          :class="[
            'parent',
            { active: isReportsActive },
            { open: isDistributorReportsActive || isManufacturerReportsActive },
          ]"
        >
          <router-link to="#" @click.native.prevent="handleParentMenuClick('Reports')">
            <i class="feather icon-bar-chart-2"></i>
            <span>Reports</span>
          </router-link>
          <ul class="sub-menu">
            <li
              v-if="user.type == 'Distributor' && hasPermission('Report_View')"
              :class="[{ active: isDistributorReportsActive }]"
            >
              <router-link to="/reports/distributor">
                <i class="feather icon-circle"></i> &nbsp; Distributor
              </router-link>
            </li>
            <li
              v-if="user.type == 'Manufacturer' && user.role == 'Administrator'"
              :class="[{ active: isManufacturerReportsActive }]"
            >
              <router-link to="/reports/manufacturer">
                <i class="feather icon-circle"></i> &nbsp; Manufacturer
              </router-link>
            </li>
          </ul>
        </li>

        <li
          v-if="user.role != 'Customer' && hasPermission('Analytics_View')"
          :class="[
            'parent',
            { active: isAnalyticsActive },
            {
              open:
                isDistributorAnalyticsActive ||
                isManufacturerAnalyticsActive ||
                isSalesAnalyticsActive,
            },
          ]"
        >
          <router-link to="#" @click.native.prevent="handleParentMenuClick('Analytics')">
            <i class="feather icon-pie-chart"></i>
            <span>Analytics</span>
          </router-link>
          <ul class="sub-menu">
            <li
              v-if="user.type == 'Distributor' && hasPermission('Analytics_View')"
              :class="[{ active: isDistributorAnalyticsActive }]"
            >
              <router-link to="/analytics/distributor">
                <i class="feather icon-circle"></i> &nbsp; Distributor
              </router-link>
            </li>
            <li
              v-if="
                user.type == 'Manufacturer' && hasPermission('Analytics_View') && !user.isSalesUser
              "
              :class="[{ active: isManufacturerAnalyticsActive }]"
            >
              <router-link to="/analytics/manufacturer">
                <i class="feather icon-circle"></i> &nbsp; Manufacturer
              </router-link>
            </li>
            <li
              v-if="
                user.isSalesUser &&
                  (user.role.isDistributorManager ||
                    user.role.isStateManager ||
                    user.role.isGroupManager)
              "
              :class="[{ active: isSalesAnalyticsActive }]"
            >
              <router-link to="/analytics/sales">
                <i class="feather icon-circle"></i> &nbsp; Sales
              </router-link>
            </li>
          </ul>
        </li>

        <li
          v-if="
            user.isSalesUser &&
              (user.role.isDistributorManager ||
                user.role.isStateManager ||
                user.role.isGroupManager)
          "
          :class="['parent', { active: isSaleOrderParentActive }, { open: isSaleOrderActive }]"
        >
          <router-link to="#" @click.native.prevent="handleParentMenuClick('SaleOrder')">
            <i class="feather icon-list"></i>
            <span>Sales Order</span>
          </router-link>
          <ul class="sub-menu">
            <li :class="{ active: isSaleOrderActive }">
              <router-link to="/salesOrder">
                <i class="feather icon-circle"></i>
                &nbsp;View Sales Orders
              </router-link>
            </li>
          </ul>
        </li>
        <li
          v-if="
            user.isSalesUser &&
              (user.role.isDistributorManager ||
                user.role.isStateManager ||
                user.role.isGroupManager)
          "
          :class="[
            'parent',
            { active: isDistributorDirectoryParentActive },
            { open: isDistributorDirectoryActive },
          ]"
        >
          <router-link to="#" @click.native.prevent="handleParentMenuClick('DistributorDirectory')">
            <i class="feather icon-speaker"></i>
            <span>Distributor Directory</span>
          </router-link>
          <ul class="sub-menu">
            <li :class="{ active: isDistributorDirectoryActive }">
              <router-link to="/sales-user-distributors">
                <i class="feather icon-circle"></i> &nbsp; View Distributors
              </router-link>
            </li>
          </ul>
        </li>

        <li
          v-if="
            !user.isSalesUser &&
              user.type == 'Manufacturer' &&
              (hasPermission('Sales_Role_View') || hasPermission('Sales_Team_View'))
          "
          :class="[
            'parent',
            { active: isSalesForceParentActive },
            { open: isSalesRolesActive || isSalesTeamActive },
          ]"
        >
          <a href="javascript:void" @click.prevent="handleParentMenuClick('SalesForce')">
            <i class="feather icon-speaker"></i>
            <span>Sales Force</span>
          </a>
          <ul class="sub-menu">
            <li
              v-if="user.type == 'Manufacturer' && hasPermission('Sales_Role_View')"
              :class="{ active: isSalesRolesActive }"
            >
              <router-link to="/sales-force/sales-roles">
                <i class="feather icon-circle"></i> &nbsp;Manage Sales Role
              </router-link>
            </li>
            <li
              v-if="user.type == 'Manufacturer' && hasPermission('Sales_Team_View')"
              :class="{ active: isSalesTeamActive }"
            >
              <router-link to="/sales-force/sales-team">
                <i class="feather icon-circle"></i> &nbsp;Manage Sales Team
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
  <!-- END: Main Menu-->
</template>

<script>
/* eslint-disable */

import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "sideBar",
  data() {
    return {
      toggle: false,
      distributorProductPermission: false,
      title: this.$route.meta.title,
      isDashboardActive: false,
      isOrdersActive: false,
      isOrdersSentActive: false,
      isMerchantViewDistributorActive: false,
      isMerchantAddDistributorActive: false,
      isOrdersDraftActive: false,
      isGrowTradeActive: false,
      isOrdersCreateActive: false,
      isOrdersCreateAltActive: false,
      isDistributorListActive: false,
      isisManufacturerDistributorsActive: false,
      isDistributorsActive: false,
      isDistributorsCreateActive: false,
      isManufacturerDistributorsCreateActive: false,
      isManufacturerGroupActive: false,
      isManufacturersActive: false,
      isManufacturersCreateActive: false,
      isManufacturerProductsActive: false,
      isCustomerActive: false,
      isCampaignsActive: false,
      isCampaignsCreateActive: false,
      isReportsActive: false,
      isDistributorReportsActive: false,
      isManufacturerReportsActive: false,
      isAnalyticsActive: false,
      isDistributorAnalyticsActive: false,
      isManufacturerAnalyticsActive: false,
      isSalesAnalyticsActive: false,
      isSettingsActive: false,
      isBizProfileActive: false,
      isProductsActive: false,
      isProductCatActive: false,
      isProductUnitActive: false,
      isLocationsActive: false,
      isSubLocationsActive: false,
      isManageStockActive: false,
      isStockTransfer: false,
      isPurchaseOrderActive: false,
      isReconcileOrdersToStockActive: false,
      isTeamActive: false,
      isPaymentActive: false,
      isGroupsActive: false,
      isDiscountsActive: false,
      isVolumeByCustomerReport: false,
      isVolumeByProductReport: false,
      isVolumeByProductCategoryReport: false,
      isDailySalesByOrderReport: false,
      isDailySalesByDateReport: false,
      isDailySalesByOrderStatusReport: false,
      isInventoryByBatchReport: false,
      isInventoryByProductReport: false,
      isInventoryByProductCategoryReport: false,
      isReconciliationByStatusReport: false,
      isReconciliationByOrderNumberReport: false,
      isBranchesActive: false,
      isAccountPosting: false,
      isManagePaymentsActive: false,
      isManageCashChequeTransactionsActive: false,
      isSystemSettingsActive: false,
      isExpenseCategoryActive: false,
      isPaymentChannelActive: false,
      isManageExpensesActive: false,
      isAdjustExpensesActive: false,
      isStatisticsActive: false,
      isRolesActive: false,
      isPurchaseOrderProcessActive: false,
      isPurchaseOrderItemSetupActive: false,
      isPurchaseOrderStageSetupActive: false,
      isManageCustomerAccountParentActive: false,
      isManageStockParentActive: false,
      isCampaignsParentActive: false,
      isOrdersParentActive: false,
      isSalesForceParentActive: false,
      isSalesRolesActive: false,
      isSalesTeamActive: false,
      isSaleOrderActive: false,
      isDistributorDirectoryActive: false,
      isManufacturerDistributorsParentActive: false,
      isManufacturerProductParentActive: false,
      isPurchaseOrderParentActive: false,
      isManageGeoFenceActive: false,
    };
  },
  created() {
    this.checkRoute();
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },
  watch: {
    $route: "checkRoute",
  },
  methods: {
    handleParentMenuClick(parent) {
      this.title = this.$route.meta.title;
      this.isDashboardActive = false;
      this.isOrdersActive = false;
      this.isOrdersSentActive = false;
      this.isMerchantViewDistributorActive = false;
      this.isMerchantAddDistributorActive = false;
      this.isOrdersDraftActive = false;
      this.isGrowTradeActive = false;
      this.isOrdersCreateActive = false;
      this.isOrdersCreateAltActive = false;
      this.isDistributorListActive = false;
      this.isDistributorsActive = false;
      this.isManufacturerDistributorsActive = false;
      this.isManufacturerDistributorsCreateActive = false;
      this.isManufacturerGroupActive = false;
      this.isDistributorsCreateActive = false;
      this.isManufacturersActive = false;
      this.isManufacturersCreateActive = false;
      this.isCustomerActive = false;
      this.isCampaignsActive = false;
      this.isCampaignsCreateActive = false;
      this.isReportsActive = false;
      this.isDistributorReportsActive = false;
      this.isManufacturerReportsActive = false;
      this.isAnalyticsActive = false;
      this.isDistributorAnalyticsActive = false;
      this.isManufacturerAnalyticsActive = false;
      this.isSalesAnalyticsActive = false;
      this.isSettingsActive = false;
      this.isBizProfileActive = false;
      this.isProductsActive = false;
      this.isManufacturerProductsActive = false;
      this.isProductCatActive = false;
      this.isProductUnitActive = false;
      this.isLocationsActive = false;
      this.isSubLocationsActive = false;
      this.isManageStockActive = false;
      this.isStockTransferActive = false;
      this.isReconcileOrdersToStockActive = false;
      this.isPaymentActive = false;
      this.isTeamActive = false;
      this.isGroupsActive = false;
      this.isDiscountsActive = false;
      this.isVolumeByCustomerReport = false;
      this.isVolumeByProductCategoryReport = false;
      this.isVolumeByProductReport = false;
      this.isDailySalesByDateReport = false;
      this.isDailySalesByOrderReport = false;
      this.isDailySalesByOrderStatusReport = false;
      this.isInventoryByBatchReport = false;
      this.isInventoryByProductReport = false;
      this.isInventoryByProductCategoryReport = false;
      this.isReconciliationByStatusReport = false;
      this.isReconciliationByOrderNumberReport = false;
      this.isBranchesActive = false;
      this.isAccountPosting = false;
      this.isManagePaymentsActive = false;
      this.isManageCashChequeTransactionsActive = false;
      this.isSystemSettingsActive = false;
      this.isExpenseCategoryActive = false;
      this.isPaymentChannelActive = false;
      this.isManageExpensesActive = false;
      this.isAdjustExpensesActive = false;
      this.isStatisticsActive = false;
      this.isRolesActive = false;
      this.isPurchaseOrderProcessActive = false;
      this.isPurchaseOrderItemSetupActive = false;
      this.isPurchaseOrderStageSetupActive = false;
      this.isManageCustomerAccountParentActive = false;
      this.isManageStockParentActive = false;
      this.isCampaignsParentActive = false;
      this.isOrdersParentActive = false;
      this.isSalesRolesActive = false;
      this.isSalesTeamActive = false;
      this.isSaleOrderParentActive = false;
      this.isSaleOrderActive = false;
      this.isDistributorDirectoryParentActive = false;
      this.isDistributorDirectoryActive = false;
      this.isSalesForceParentActive = false;
      this.isManufacturerDistributorsParentActive = false;
      this.isManufacturerProductParentActive = false;
      this.isPurchaseOrderParentActive = false;
      this.isManageGeoFenceActive = false;

      if (parent === "Settings") this.isSettingsActive = true;
      else if (parent === "Reports") this.isReportsActive = true;
      else if (parent === "Analytics") this.isAnalyticsActive = true;
      else if (parent === "Campaigns") this.isCampaignsParentActive = true;
      else if (parent === "CustomerAccount") this.isManageCustomerAccountParentActive = true;
      else if (parent === "Inventory") this.isManageStockParentActive = true;
      else if (parent === "Orders") this.isOrdersParentActive = true;
      else if (parent === "SaleOrder") this.isSaleOrderParentActive = true;
      else if (parent === "DistributorDirectory") this.isDistributorDirectoryParentActive = true;
      else if (parent === "salesorders") this.isPurchaseOrderProcessActive = true;
      else if (parent === "SalesForce") this.isSalesForceParentActive = true;
      else if (parent === "ManufacturerDistributor")
        this.isManufacturerDistributorsParentActive = true;
      else if (parent === "ManufacturerProduct") this.isManufacturerProductParentActive = true;
      else if (parent === "PurchaseOrder") this.isPurchaseOrderParentActive = true;
      else if (parent === "MerchantDistributors") this.isDistributorsActive = true;
      //
    },
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
    checkRoute() {
      this.title = this.$route.meta.title;
      this.isDashboardActive = false;
      this.isOrdersActive = false;
      this.isOrdersSentActive = false;
      this.isMerchantViewDistributorActive = false;
      this.isMerchantAddDistributorActive = false;
      this.isOrdersDraftActive = false;
      this.isGrowTradeActive = false;
      this.isOrdersCreateActive = false;
      this.isOrdersCreateAltActive = false;
      this.isDistributorListActive = false;
      this.isDistributorsActive = false;
      this.isManufacturerDistributorsActive = false;
      this.isManufacturerDistributorsCreateActive = false;
      this.isManufacturerGroupActive = false;
      this.isDistributorsCreateActive = false;
      this.isManufacturersActive = false;
      this.isManufacturersCreateActive = false;
      this.isCustomerActive = false;
      this.isCampaignsActive = false;
      this.isCampaignsCreateActive = false;
      this.isReportsActive = false;
      this.isDistributorReportsActive = false;
      this.isManufacturerReportsActive = false;
      this.isAnalyticsActive = false;
      this.isDistributorAnalyticsActive = false;
      this.isManufacturerAnalyticsActive = false;
      this.isSalesAnalyticsActive = false;
      this.isSettingsActive = false;
      this.isBizProfileActive = false;
      this.isProductsActive = false;
      this.isManufacturerProductsActive = false;
      this.isProductCatActive = false;
      this.isProductUnitActive = false;
      this.isLocationsActive = false;
      this.isSubLocationsActive = false;
      this.isManageStockActive = false;
      this.isStockTransferActive = false;
      (this.isPurchaseOrderActive = false), (this.isReconcileOrdersToStockActive = false);
      this.isPaymentActive = false;
      this.isTeamActive = false;
      this.isGroupsActive = false;
      this.isDiscountsActive = false;
      this.isVolumeByCustomerReport = false;
      this.isVolumeByProductCategoryReport = false;
      this.isVolumeByProductReport = false;
      this.isDailySalesByDateReport = false;
      this.isDailySalesByOrderReport = false;
      this.isDailySalesByOrderStatusReport = false;
      this.isInventoryByBatchReport = false;
      this.isInventoryByProductReport = false;
      this.isInventoryByProductCategoryReport = false;
      this.isReconciliationByStatusReport = false;
      this.isReconciliationByOrderNumberReport = false;
      this.isBranchesActive = false;
      this.isAccountPosting = false;
      this.isManagePaymentsActive = false;
      this.isManageCashChequeTransactionsActive = false;
      this.isSystemSettingsActive = false;
      this.isExpenseCategoryActive = false;
      this.isPaymentChannelActive = false;
      this.isManageExpensesActive = false;
      this.isAdjustExpensesActive = false;
      this.isStatisticsActive = false;
      this.isRolesActive = false;
      this.isPurchaseOrderProcessActive = false;
      this.isPurchaseOrderItemSetupActive = false;
      this.isPurchaseOrderStageSetupActive = false;
      this.isManageCustomerAccountParentActive = false;
      this.isManageStockParentActive = false;
      this.isCampaignsParentActive = false;
      this.isOrdersParentActive = false;
      this.isSalesRolesActive = false;
      this.isSalesTeamActive = false;
      this.isSaleOrderParentActive = false;
      this.isDistributorDirectoryParentActive = false;
      this.isDistributorDirectoryActive = false;
      this.isSalesForceParentActive = false;
      this.isManufacturerDistributorsParentActive = false;
      this.isManufacturerProductParentActive = false;
      this.isPurchaseOrderParentActive = false;
      this.isManageGeoFenceActive = false;

      switch (true) {
        case this.$route.path.startsWith("/dashboard"):
          this.isDashboardActive = true;

          break;

        case this.$route.path.startsWith("/merchant/orders"):
          this.isOrdersParentActive = true;

          if (
            this.$route.path.startsWith("/merchant/orders") &&
            this.$route.path != "/merchant/orders/create" &&
            !this.$route.path.startsWith("/merchant/orders/edit") &&
            !this.$route.query.isDraft
          ) {
            this.isOrdersSentActive = true;
          } else if (this.$route.path.startsWith("/merchant/orders/edit")) {
            this.isOrdersDraftActive = true;
          } else if (this.$route.path == "/merchant/orders/create") {
            this.isOrdersCreateActive = true;
          }

          break;

        case this.$route.path.startsWith("/merchant/drafts"):
          this.isOrdersParentActive = true;

          if (this.$route.path.startsWith("/merchant/drafts") || this.$route.query.isDraft) {
            this.isOrdersDraftActive = true;
          }

          break;
        case this.$route.path.startsWith("/merchant/distributors"):
          this.isDistributorsActive = true;

          if (this.$route.path.startsWith("/merchant/distributors/add")) {
            this.isMerchantAddDistributorActive = true;
          } else if (this.$route.path.startsWith("/merchant/distributors/view")) {
            this.isMerchantViewDistributorActive = true;
          }

          break;

        case this.$route.path.startsWith("/merchant/growtrade"):
          this.isGrowTradeActive = true;
          break;

        case this.$route.path.startsWith("/orders"):
          this.isOrdersParentActive = true;

          if (this.$route.path == "/orders/create") {
            this.isOrdersCreateAltActive = true;
          } else {
            this.isOrdersActive = true;
          }
          break;
        case this.$route.path.startsWith("/manage-expenses"):
          this.isManageExpensesActive = true;
          break;
        case this.$route.path.startsWith("/managecashchequetransactions"):
          this.isManagePaymentsActive = true;
          this.isManageCashChequeTransactionsActive = true;
          break;
        case this.$route.path.startsWith("/adjust-expenses"):
          this.isManageExpensesActive = true;
          this.isAdjustExpensesActive = true;
          break;
        case this.$route.path.startsWith("/distributorlist"):
          this.isDistributorListActive = true;

          break;
        case this.$route.path.startsWith("/manufacturer-distributors"):
          this.isManufacturerDistributorsParentActive = true;

          if (this.$route.path == "/manufacturer-distributors/create") {
            this.isManufacturerDistributorsCreateActive = true;
          } else {
            this.isManufacturerDistributorsActive = true;
          }

          break;
        case this.$route.path.startsWith("/sales-user-distributor"):
          this.isDistributorDirectoryActive = true;

          break;

        case this.$route.path.startsWith("/groups"):
          this.isManufacturerGroupActive = true;
          break;
        case this.$route.path.startsWith("/distributors"):
          this.isDistributorsActive = true;

          if (this.$route.path == "/distributors/create") {
            this.isDistributorsCreateActive = true;
          }

          break;
        case this.$route.path.startsWith("/manufacturers"):
          this.isManufacturersActive = true;

          if (this.$route.path == "/manufacturers/create") {
            this.isManufacturersCreateActive = true;
          }

          break;
        case this.$route.path.startsWith("/customers"):
          this.isCustomerActive = true;
          break;
        case this.$route.path.startsWith("/manufacturer-products"):
          this.isManufacturerProductParentActive = true;

          if (this.$route.path == "/manufacturer-products") {
            this.isManufacturerProductsActive = true;
          }

          if (this.$route.path == "/manufacturer-products/cat/view") {
            this.isProductCatActive = true;
          }

          if (this.$route.path == "/manufacturer-products/units/view") {
            this.isProductUnitActive = true;
          }
          break;
        case this.$route.path.startsWith("/products"):
          this.isProductsActive = true;
          break;

        case this.$route.path.startsWith("/managepayments"):
          this.isManagePaymentsActive = true;
          this.isManageCustomerAccountParentActive = true;
          break;

        case this.$route.path.startsWith("/accountposting"):
          this.isManageCustomerAccountParentActive = true;
          this.isAccountPosting = true;
          break;

        case this.$route.path.startsWith("/warehouses"):
          this.isManageStockParentActive = true;
          this.isLocationsActive = true;

          break;
        case this.$route.path.startsWith("/storage-areas"):
          this.isManageStockParentActive = true;
          this.isSubLocationsActive = true;

          break;
        case this.$route.path.startsWith("/managestock"):
          this.isManageStockActive = true;
          this.isManageStockParentActive = true;

          break;
        case this.$route.path.startsWith("/stocktransfer"):
          this.isStockTransferActive = true;
          this.isManageStockParentActive = true;

          break;
        case this.$route.path.startsWith("/reconcileorderstostock"):
          this.isManageStockParentActive = true;
          this.isReconcileOrdersToStockActive = true;

          break;

        case this.$route.path.startsWith("/salesorders"):
          this.isPurchaseOrderActive = true;
          this.isManageStockParentActive = true;

          break;
        case this.$route.path.startsWith("/campaigns"):
          this.isCampaignsParentActive = true;

          if (this.$route.path == "/campaigns/create") {
            this.isCampaignsCreateActive = true;
          } else {
            this.isCampaignsActive = true;
          }

          break;
        case this.$route.path.startsWith("/analytics"):
          this.isAnalyticsActive = true;

          if (this.$route.path == "/analytics/distributor") {
            this.isDistributorAnalyticsActive = true;
          }
          if (this.$route.path == "/analytics/manufacturer") {
            this.isManufacturerAnalyticsActive = true;
          }
          if (this.$route.path == "/analytics/sales") {
            this.isSalesAnalyticsActive = true;
          }

          break;
        case this.$route.path.startsWith("/reports"):
          this.isReportsActive = true;

          if (this.$route.path == "/reports/distributor") {
            this.isDistributorReportsActive = true;
          }
          if (this.$route.path == "/reports/manufacturer") {
            this.isManufacturerReportsActive = true;
          }

          break;
        case this.$route.path.startsWith("/volume-by-customer"):
          this.isReportsActive = true;
          this.isVolumeByCustomerReport = true;
          break;
        case this.$route.path.startsWith("/volume-by-product-category"):
          this.isReportsActive = true;
          this.isVolumeByProductCategoryReport = true;
          break;
        case this.$route.path.startsWith("/volume-by-product"):
          this.isReportsActive = true;
          this.isVolumeByProductReport = true;
          break;
        case this.$route.path.startsWith("/daily-sales-by-order-status"):
          this.isReportsActive = true;
          this.isDailySalesByOrderStatusReport = true;
          break;
        case this.$route.path.startsWith("/daily-sales-by-date"):
          this.isReportsActive = true;
          this.isDailySalesByDateReport = true;
          break;
        case this.$route.path.startsWith("/daily-sales-by-order"):
          this.isReportsActive = true;
          this.isDailySalesByOrderReport = true;
          break;

        case this.$route.path.startsWith("/inventory-by-batch"):
          this.isReportsActive = true;
          this.isInventoryByBatchReport = true;
          break;
        case this.$route.path.startsWith("/inventory-by-product-category"):
          this.isReportsActive = true;
          this.isInventoryByProductCategoryReport = true;
          break;
        case this.$route.path.startsWith("/inventory-by-product"):
          this.isReportsActive = true;
          this.isInventoryByProductReport = true;
          break;

        case this.$route.path.startsWith("/reconciliation-by-status"):
          this.isReportsActive = true;
          this.isReconciliationByStatusReport = true;
          break;
        case this.$route.path.startsWith("/reconciliation-by-order-number"):
          this.isReportsActive = true;
          this.isReconciliationByOrderNumberReport = true;
          break;

        case this.$route.path.startsWith("/settings"):
          this.isSettingsActive = true;

          if (this.$route.path == "/settings/payment") {
            this.isPaymentActive = true;
          }
          if (this.$route.path == "/settings/team") {
            this.isTeamActive = true;
          }

          if (this.$route.path == "/settings/business") {
            this.isBizProfileActive = true;
          }

          if (this.$route.path.startsWith("/settings/groups")) {
            this.isGroupsActive = true;
          }

          if (this.$route.path.startsWith("/settings/discounts")) {
            this.isDiscountsActive = true;
          }

          if (this.$route.path.startsWith("/settings/branch")) {
            this.isBranchesActive = true;
          }

          if (
            this.$route.path.startsWith("/settings/systemsettings") ||
            this.$route.path.startsWith("/settings/manufacturersystemsettings")
          ) {
            this.isSystemSettingsActive = true;
          }

          if (this.$route.path.startsWith("/settings/expenseCategory")) {
            this.isExpenseCategoryActive = true;
          }

          if (this.$route.path.startsWith("/settings/paymentChannel")) {
            this.isPaymentChannelActive = true;
          }

          if (this.$route.path.startsWith("/settings/roles")) {
            this.isRolesActive = true;
          }
          if (this.$route.path.startsWith("/settings/geofence")) {
            this.isManageGeoFenceActive = true;
          }

          break;

        case this.$route.path.startsWith("/sales-force"):
          this.isSalesForceParentActive = true;

          if (this.$route.path.startsWith("/sales-force/sales-roles")) {
            this.isSalesRolesActive = true;
          }
          if (this.$route.path.startsWith("/sales-force/sales-team")) {
            this.isSalesTeamActive = true;
          }

          break;

        case this.$route.path.startsWith("/sales-order"):
          this.isPurchaseOrderParentActive = true;

          if (this.$route.path === "/sales-order") {
            this.isPurchaseOrderProcessActive = true;
          }

          if (this.$route.path === "/sales-order/item/setup") {
            this.isPurchaseOrderItemSetupActive = true;
          }

          if (this.$route.path === "/sales-order/stage/setup") {
            this.isPurchaseOrderStageSetupActive = true;
          }

          break;

        case this.$route.path.startsWith("/account"):
          if (this.user.role === "Customer") this.isDashboardActive = true;

          break;
        case this.$route.path.startsWith("/statistics"):
          this.isStatisticsActive = true;
          break;
      }

      var isMenuExpanded = $.app.menu.expanded;
      var isMobile = this.isMobile();
      if (isMenuExpanded === true && isMobile === true) $.app.menu.toggle();
    },
  },
};
</script>
