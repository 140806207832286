<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-payment-status"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong>Place Order</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="cancel"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div
              :class="[
                'parent-div',
                { 'error-div': status === 'fail', 'success-div': status === 'success' },
              ]"
            >
              <div class="first-child">
                <div class="second-child">
                  <svg
                    width="4"
                    height="20"
                    viewBox="0 0 4 20"
                    fill="none"
                    v-if="status === 'fail'"
                  >
                    <path
                      d="M2.00158 19.8888C2.68418 19.8888 3.23754 19.2618 3.23754 18.4884C3.23754 17.7149 2.68418 17.0879 2.00158 17.0879C1.31898 17.0879 0.765625 17.7149 0.765625 18.4884C0.765625 19.2618 1.31898 19.8888 2.00158 19.8888Z"
                      fill="white"
                    />
                    <path
                      d="M2.00156 14.8551C1.75509 14.8551 1.51872 14.7442 1.34445 14.5467C1.17017 14.3493 1.07227 14.0814 1.07227 13.8022V1.16627C1.07227 0.887001 1.17017 0.619169 1.34445 0.421695C1.51872 0.224221 1.75509 0.113281 2.00156 0.113281C2.24802 0.113281 2.48439 0.224221 2.65866 0.421695C2.83294 0.619169 2.93085 0.887001 2.93085 1.16627V13.8022C2.93085 14.0814 2.83294 14.3493 2.65866 14.5467C2.48439 14.7442 2.24802 14.8551 2.00156 14.8551Z"
                      fill="white"
                    />
                  </svg>
                  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" v-else>
                    <path
                      d="M19.599 0.410713C19.2129 0.0245541 18.5869 0.0245541 18.2007 0.410713L6.35464 12.2568L1.80123 7.70343C1.41511 7.31727 0.789098 7.31731 0.4029 7.70343C0.0167416 8.08955 0.0167416 8.71556 0.4029 9.10172L5.65548 14.3542C6.04148 14.7403 6.66796 14.7401 7.05381 14.3542L19.599 1.80904C19.9851 1.42292 19.9851 0.796872 19.599 0.410713Z"
                      fill="white"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <p class="text-center text-bold">
            {{ status === "fail" ? "Payment failed" : "Payment successful" }}
          </p>
        </div>
        <div class="modal-footer" v-if="status === 'fail'">
          <button
            class="btn btn-secondary md-close"
            type="button"
            data-dismiss="modal"
            @click="cancel"
          >
            Cancel
          </button>
          <button class="btn btn-primary" @click.prevent="retryPayment" type="button">
            <span v-if="isPosting" class="spinner-border spinner-border-sm" role="status">
              <i class="sr-only">Busy...</i>
            </span>
            <span v-else> Retry </span>
          </button>
        </div>
        <div class="modal-footer" v-else>
          <button class="btn btn-primary" @click.prevent="orderDone" type="button">
            <span> Done </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "payment-status-modal",
  props: ["isPosting", "status"],

  data() {
    return {};
  },

  methods: {
    cancel() {
      this.v1 = {
        amount: false,
        text: "",
        is_valid: true,
      };
      this.amount = 0;
      this.$emit("closeModal");
    },

    async retryPayment() {
      this.$emit("retryPayment");
    },

    orderDone() {
      $("#mod-payment-status").modal("hide");
      this.cancel();
      this.$router.push("/merchant/orders");
    },
  },
};
</script>

<style scoped>
.parent-div {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto 34px;
  width: 110px;
  height: 110px;
}

.first-child {
  width: 86.52px;
  height: 86.52px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.second-child {
  width: 61.8px;
  height: 61.8px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.error-div {
  border: 1px solid rgba(255, 103, 103, 0.3);
}

.error-div .first-child {
  border: 1px solid rgba(255, 103, 103, 0.7);
}

.error-div .second-child {
  background: #ff6767;
}

.success-div {
  border: 1px solid rgba(182, 201, 121, 0.2);
}

.success-div .first-child {
  border: 1px solid rgba(182, 201, 121, 0.5);
}

.success-div .second-child {
  background: rgba(182, 201, 121, 1);
}

.text-bold {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
</style>
