<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-reverse-note"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">Add notes (optional)</h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Notes</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <textarea class="form-control" v-model.trim="notes"></textarea>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary md-close"
            type="button"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            class="btn btn-primary"
            @click.prevent="proceed"
            type="button"
          >
            Proceed
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notes: "",
    };
  },

  methods: {
    proceed() {
      this.$emit("onReverseProceed", this.notes);
    },
  },
};
</script>

