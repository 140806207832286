<template>
  <li class="nav-item dropdown dropdown-notification">
    <a
      role="button"
      href="#"
      target="_self"
      data-toggle="dropdown"
      class="nav-link dropdown-toggle"
      aria-expanded="false"
      id="__BVID__105__BV_toggle_"
    >
      <span class="feather-icon">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21px"
          height="21px"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="text-body feather feather-bell"
        >
          <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9"></path>
          <path d="M13.73 21a2 2 0 0 1-3.46 0"></path>
        </svg>
        <span class="badge badge-up badge-pill bg-danger">{{
          unreadCount
        }}</span>
      </span>
    </a>
    <ul
      tabindex="-1"
      class="dropdown-menu dropdown-menu-media dropdown-menu-right"
      aria-labelledby="__BVID__105__BV_toggle_"
    >
      <li>
        <div class="dropdown-header d-flex">
          <h5 class="notification-title text-dark mb-0 mr-auto d-inline">
            Notifications
          </h5>
          <span class="badge badge-light-danger badge-pill">
            {{ unreadCount }} New
          </span>
        </div>
      </li>
      <li class="ps-container scrollable-container media-list scroll-area ps">
        <router-link
          v-for="notification in notifications"
          :key="notification.id"
          to="/notifications"
          @click.native="readNotification(notification.id)"
          target="_self"
        >
          <div class="media">
            <div class="media-body">
              <p
                class="media-heading"
                :class="{ 'text-secondary': notification.read }"
              >
                <span class="font-weight-bolder">
                  {{ notification.title }}
                </span>
              </p>
              <small class="notification-text">
                {{ notification.description }}</small
              >
            </div>
          </div>
        </router-link>
        <div v-if="notifications.length <= 0" class="media">
          <div class="media-body d-flex justify-content-center">
            <span>
              <i class="mdi mdi-email-open" />
              You're up to date.</span
            >
          </div>
        </div>
        <div class="media">
          <div class="media-body d-flex justify-content-center">
            <router-link to="/notifications" class="btn btn-success"
              >Notifications</router-link
            >
          </div>
        </div>
      </li>
    </ul>
  </li>
</template>

<script>
import moment from "moment";
import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";

const url = process.env.VUE_APP_BASE_API_URL;

export default {
  name: "notification",
  props: ["parentId"],
  data() {
    return {
      notifications: [],
    };
  },

  watch: {
    parentId: "getNotifications",
  },

  computed: {
    unreadCount: function () {
      let count = 0;
      if (this.notifications.length > 0) {
        this.notifications.forEach((element) => {
          if (!element.read) count++;
        });
      }

      return count;
    },
  },

  updated() {
    if (this.$auth.getToken()) {
      let connection = new HubConnectionBuilder()
        .withUrl(`${url}/hubs/notifications`, {
          accessTokenFactory: () => this.$auth.getToken(),
        })
        .configureLogging(LogLevel.None)
        .build();

      connection.on("displayNotification", (notification) => {
        this.$toast.info(notification.title, {
          icon: true,
          rtl: false,
        });
        this.notifications.unshift(notification);
      });
      Object.defineProperty(WebSocket, "OPEN", { value: 1 });
      connection.start();
    }
  },

  methods: {
    async getNotifications() {
      const response = await this.$http.get(
        "/notification/" + this.parentId + "?read=false"
      );

      if (response && response.body) {
        this.notifications = response.body.sort(
          (a, b) => this.formatDate(b.created) - this.formatDate(a.created)
        );
      }
    },

    async submitRead(id) {
      const response = await this.$http.post("/notification/read/" + id);
      if (response.body) {
        this.getNotifications();
      }
    },

    readNotification(id) {
      this.submitRead(id);
      const notificationIndex = this.notifications.findIndex(
        (x) => x.id === id
      );
      this.notifications[notificationIndex].read = true;
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },
  },
};
</script>

<style>
</style>