<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <span class="feather icon-filter text-white"></span> Filter
          </button>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-striped table-hover-animation table-bordered
                  "
                >
                  <thead>
                    <tr>
                      <th style="width: 10%">Product</th>
                      <th style="width: 15%">Order Number</th>
                      <th style="width: 15%">Fulfiled Date</th>
                      <th style="width: 15%">Customer Name</th>
                      <th style="width: 15%">Quantity</th>
                      <th style="width: 10%">Order Value (₦)</th>
                      <th style="width: 10%">Reconcile Type</th>
                      <th style="width: 10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l in ReconcileOrdersToStocks" :key="l.id">
                      <td>{{ l.productName }} ({{ l.productCode }})</td>
                      <td>
                        {{ l.orderNumber }}
                      </td>
                      <td>{{ formatDate(l.fulfilledDate) }}</td>
                      <td>{{ l.customerName }}</td>
                      <td>{{ formatNumber(l.quantity) }}</td>
                      <td>{{ formatMoney(l.sellPrice) }}</td>
                      <td>{{ l.reconciliationMode || "MANUAL" }}</td>
                      <td>
                        <div
                          class="text-right"
                          v-if="hasPermission('Inventory_Reconcile_Stock_Update')"
                        >
                          <button
                            v-if="l.status == 'Fulfilled'"
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="viewEdit(l)"
                          >
                            Reconcile Stock
                          </button>

                          <button
                            v-else
                            class="btn btn-primary btn-sm btn-rounded btn-space"
                            disabled
                          >
                            {{ l.status }}
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!ReconcileOrdersToStocks.length">
                      <td colspan="8">
                        <div class="text-center" style="padding-top: 50px">
                          <span style="font-size: 4.615rem" class="mdi mdi-crosshairs-gps"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any orders to reconcile.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} Reconciliation Item{{ count != 1 ? "s" : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Reconcile Orders To Stock</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="cancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row" v-if="!v1.is_valid">
              <div class="col-12 col-sm-12 col-lg-12">
                <label class="col-12 col-sm-12 text-sm-center alert alert-danger">{{
                  v1.text
                }}</label>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-sm-12 col-form-label text-sm-left"
                ><b>Customer:</b> {{ updateReconcileOrdersToStock.customerName }}</label
              >
              <label class="col-4 col-sm-6 col-form-label text-sm-left"
                ><b>Product:</b> {{ updateReconcileOrdersToStock.productName }} ({{
                  updateReconcileOrdersToStock.productUnit
                }})
              </label>
              <label class="col-4 col-sm-6 col-form-label text-sm-left"
                ><b>Qty:</b> {{ updateReconcileOrdersToStock.quantity }}</label
              >
            </div>
            <div class="form-group row">
              <div class="col-12 col-sm-12 col-lg-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer text-nowrap">
                  <thead>
                    <tr>
                      <th style="width: 15%">Inventory Id</th>
                      <th style="width: 20%">Location</th>
                      <th style="width: 15%">Product Batch Id</th>
                      <th style="width: 10%">Expiry Date</th>
                      <th style="width: 15%">Cost Price</th>
                      <th style="width: 10%">Qty</th>
                      <th style="width: 15%">Sales</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(l, key) in updateReconcileOrdersToStock.productInventories"
                      :key="l.id"
                    >
                      <td>
                        {{ l.inventoryId }}
                      </td>
                      <td>{{ l.warehouseName }}, {{ l.storageAreaName }}</td>
                      <td>
                        {{ l.productBatchId }}
                      </td>
                      <td>
                        {{ l.expiryDate ? formatDate(l.expiryDate) : "" }}
                      </td>
                      <td>{{ l.costPrice }}</td>
                      <td>{{ l.quantityInStock }}</td>
                      <td>
                        <input
                          :class="[
                            'form-control',
                            {
                              'is-invalid': !v1.quantities[key].quantity.is_valid,
                            },
                          ]"
                          type="number"
                          v-model="l.quantitySold"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              style="width: 150px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="reconcile"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Reconcile Stock</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Filter</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Date range</label>
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.dateStart"
                />
              </div>
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.dateEnd"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Other filters</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by order number..."
                  v-model="filter.qry"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Product Code</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Product code"
                  v-model="filter.productCode"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.isReconciled"
                >
                  <option value="0">All Status</option>
                  <option value="False">Pending Reconciliation</option>
                  <option value="True">Reconciled</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getReconcileOrdersToStocks"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import Pagination from "../components/Pagination.vue";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsReconcileOrdersToStocks",
  components: {
    Select2,
    Pagination,
  },

  created() {
    if (!this.hasPermission("Inventory_Reconcile_Stock_View")) {
      this.$router.push("/restricted");
    }
    this.getReconcileOrdersToStocks();
  },

  data() {
    return {
      filter: {
        status: "0",
        qry: "",
        productCode: "",
        isReconciled: "0",
        dateStart: moment()
          .subtract(4, "years")
          .format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
      },

      isBusy: false,
      isBusyModal: false,
      updateReconcileOrdersToStock: {
        productCode: null,
        orderId: null,
        orderName: null,
        orderDetailId: null,
        quantity: null,
        fulfilledDate: null,
        customerName: null,
        sellPrice: null,
        productInventories: [],
        id: null,
      },

      v1: {
        quantities: [],
        is_valid: true,
        text: "",
      },

      ReconcileOrdersToStocks: [],
      count: 0,
      page: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      options: {
        format: "YYYY-MM-DD",
        icons: {
          time: "mdi mdi-time",
          date: "mdi mdi-calendar",
          up: "mdi mdi-chevron-up",
          down: "mdi mdi-chevron-down",
          previous: "mdi mdi-chevron-left",
          next: "mdi mdi-chevron-right",
          today: "mdi mdi-screenshot",
          clear: "mdi mdi-trash",
          close: "mdi mdi-remove",
        },
      },

      local: {
        dow: 0, // Sunday is the first day of the week
        hourTip: "Select Hour", // tip of select hour
        minuteTip: "Select Minute", // tip of select minute
        secondTip: "Select Second", // tip of select second
        yearSuffix: "", // suffix of head year
        monthsHead: "January_February_March_April_May_June_July_August_September_October_November_December".split(
          "_"
        ), // months of head
        months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
        weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
        cancelTip: "cancel",
        submitTip: "confirm",
      },
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        isReconciled: "0",
        qry: "",
        productCode: "",
      };
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    viewEdit(c) {
      this.updateReconcileOrdersToStock = c;
      for (let i = 0; i < this.updateReconcileOrdersToStock.productInventories.length; i++) {
        this.v1.quantities.push({
          quantity: {
            is_valid: true,
            text: "",
          },
        });
      }

      $("#mod-edit").modal("show");
    },

    cancel() {
      for (let i = 0; i < this.v1.quantities.length; i++) {
        this.v1.quantities[i].quantity.is_valid = true;
        this.v1.quantities[i].quantity.text = "";
      }

      this.v1.text = "";
      this.v1.is_valid = true;
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    async reconcile() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        orderId: this.updateReconcileOrdersToStock.orderId,
        inventoryItems: this.updateReconcileOrdersToStock.productInventories,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        await this.$http
          .post("/Orders/reconciliation", data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getReconcileOrdersToStocks();
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateUpdate() {
      for (let i = 0; i < this.v1.quantities.length; i++) {
        this.v1.quantities[i].quantity.is_valid = true;
        this.v1.quantities[i].quantity.text = "";
      }

      this.v1.text = "";
      this.v1.is_valid = true;
      var isValid = true;

      let total = 0;
      this.updateReconcileOrdersToStock.productInventories.forEach((item) => {
        total += parseInt(item.quantitySold);
      });
      if (total === 0) {
        this.v1.is_valid = false;
        this.v1.text = "Total quantities sold cannot be zero";
        isValid = false;
      }

      if (isValid) {
        for (let k = 0; k < this.updateReconcileOrdersToStock.productInventories.length; k++) {
          let qtySold = parseInt(
            this.updateReconcileOrdersToStock.productInventories[k].quantitySold
          );
          if (!Number.isInteger(qtySold)) {
            /*
                            this.v1.quantities[k].quantity.text = 'Please enter a valid number'; */
            this.v1.quantities[k].quantity.is_valid = false;
            this.v1.is_valid = false;
            this.v1.text = "Please enter a valid number";
            isValid = false;
          } else {
            if (
              this.updateReconcileOrdersToStock.productInventories[k].quantitySold >
              this.updateReconcileOrdersToStock.productInventories[k].quantityInStock
            ) {
              /*
                                this.v1.quantities[k].quantity.text = 'Quantity sold cannot be more than quantity in stock'; */
              this.v1.quantities[k].quantity.is_valid = false;
              this.v1.is_valid = false;
              this.v1.text = "Quantity sold cannot be more than quantity in stock";
              isValid = false;
            }
            if (
              this.updateReconcileOrdersToStock.productInventories[k].quantitySold >
              this.updateReconcileOrdersToStock.quantity
            ) {
              /*
                                this.v1.quantities[k].quantity.text = 'Quantity sold cannot be more than quantity in this order'; */
              this.v1.quantities[k].quantity.is_valid = false;
              this.v1.is_valid = false;
              this.v1.text = "Quantity sold cannot be more than quantity in this order";
              isValid = false;
            }
          }
        }
      }

      if (isValid) {
        let total = 0;
        this.updateReconcileOrdersToStock.productInventories.forEach((item) => {
          total += parseInt(item.quantitySold);
        });
        if (total != parseInt(this.updateReconcileOrdersToStock.quantity)) {
          this.v1.is_valid = false;
          this.v1.text = "Total quantities sold must match the order quantity";
          isValid = false;
        }
      }

      return isValid;
    },

    async getReconcileOrdersToStocks() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Orders/getListForReconciliation?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.filter.dateStart != ""
              ? "&dateStart=" + moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
              : "") +
            (this.filter.dateEnd != ""
              ? "&dateEnd=" + moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
              : "") +
            "&sellerId=" +
            this.user.parentId +
            (this.filter.isReconciled != "0" ? "&isReconciled=" + this.filter.isReconciled : "") +
            (this.filter.productCode != "" ? "&productCode=" + this.filter.productCode : "")
        )
        .then(function(res) {
          this.ReconcileOrdersToStocks = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    formatNumber(val) {
      return helper.formatNumber(val);
    },

    prev() {
      this.page -= 1;
      this.getReconcileOrdersToStocks();
    },

    next() {
      this.page += 1;
      this.getReconcileOrdersToStocks();
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getReconcileOrdersToStocks();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getReconcileOrdersToStocks();
    },
  },
};
</script>
