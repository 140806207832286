<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <span class="feather icon-filter text-white"></span> Filter
          </button>

          <div class="btn-group">
            <button
              class="btn btn-warning btn-rounded btn-space float-right"
              @click="viewAdd"
              v-if="hasPermission('Sales_Team_Create')"
            >
              <span class="icon icon-left mdi mdi-plus text-white"></span>
              Invite user
            </button>

            <button
              class="btn btn-primary btn-rounded btn-space float-right"
              @click="viewAddManual"
              v-if="hasPermission('Sales_Team_Create')"
            >
              <span class="icon icon-left mdi mdi-plus text-white"></span>
              Create user manually
            </button>
          </div>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive" style="min-height: 450px">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 30%">User</th>
                      <th style="width: 10%">Status</th>
                      <th style="width: 10%">Role</th>
                      <th style="width: 10%">Staff Id</th>
                      <th style="width: 10%">Assigned Rights</th>
                      <th style="width: 10%">Created</th>
                      <th style="width: 20%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="u in users" :key="u.id">
                      <td>
                        <b>
                          <span>
                            {{ u.lastName ? u.lastName + " " + u.otherNames : "" }}
                            <br />
                          </span>
                        </b>
                        {{ u.emailAddress ? u.emailAddress : u.phoneNumber }}
                        <br />
                        <span
                          style="
                            color: gray;
                            font-size: 11px;
                            font-weight: bold;
                          "
                          >{{ u.username }}</span
                        >
                      </td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-primary': u.status == 'Pending' },
                            { 'badge-success': u.status == 'Active' },
                            { 'badge-danger': u.status == 'Disabled' },
                          ]"
                        >
                          {{ u.status == "Pending" ? "Invitation sent" : u.status }}
                        </span>
                      </td>
                      <td>{{ u.role ? u.role.roleName : "Not Assigned" }}</td>
                      <td>{{ u.staffId ? u.staffId : "N/A" }}</td>
                      <td>
                        <div v-if="u.role">
                          <button
                            class="btn btn-outline-info btn-sm dropdown-toggle"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            See Assigned Rights
                          </button>
                          <div class="dropdown-menu px-1" aria-labelledby="dropdownMenu2">
                            <label v-if="u.role.isDistributorManager" class="dropdown-item">
                              Manage Distributors
                            </label>
                            <label v-if="u.role.isGroupManager" class="dropdown-item">
                              Manage Groups
                            </label>
                            <label v-if="u.role.isStateManager" class="dropdown-item">
                              Manage States
                            </label>
                            <label v-if="u.role.canManageDistributorManagers" class="dropdown-item">
                              Manage Distributor Managers
                            </label>
                            <label v-if="u.role.canManageGroupManagers" class="dropdown-item">
                              Manage Group Managers
                            </label>
                            <label v-if="u.role.canManageStateManagers" class="dropdown-item">
                              Manage State Managers
                            </label>
                          </div>
                        </div>
                        <div v-else>No Assigned Rights</div>
                      </td>
                      <td>{{ formatDate(u.created) }}</td>
                      <td>
                        <div class="dropdown">
                          <button
                            class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Actions
                          </button>
                          <div class="dropdown-menu px-1" aria-labelledby="dropdownMenu2">
                            <a
                              v-if="
                                u.role &&
                                  u.status != 'Inactive' &&
                                  hasPermission('Sales_Team_Mapping_View')
                              "
                              class="dropdown-item"
                              @click.prevent="viewMapping(u)"
                            >
                              View Mapping
                            </a>
                            <div
                              v-if="
                                u.role &&
                                  u.status != 'Inactive' &&
                                  u.role.isDistributorManager &&
                                  hasPermission('Sales_Team_Update')
                              "
                              class="dropdown-divider"
                            ></div>

                            <a
                              v-if="
                                u.role &&
                                  u.id != user.id &&
                                  u.status != 'Inactive' &&
                                  u.role.isDistributorManager &&
                                  hasPermission('Sales_Team_Update')
                              "
                              class="dropdown-item"
                              @click.prevent="viewAssignDistributors(u)"
                            >
                              Assign Distributors
                            </a>
                            <div
                              v-if="
                                u.role &&
                                  u.status != 'Inactive' &&
                                  u.role.isGroupManager &&
                                  hasPermission('Sales_Team_Update')
                              "
                              class="dropdown-divider"
                            ></div>
                            <a
                              v-if="
                                u.role &&
                                  u.status != 'Inactive' &&
                                  u.role.isGroupManager &&
                                  hasPermission('Sales_Team_Update')
                              "
                              class="dropdown-item"
                              @click.prevent="viewAssignGroups(u)"
                            >
                              Assign Groups
                            </a>
                            <div
                              v-if="
                                u.role &&
                                  u.status != 'Inactive' &&
                                  u.role.isStateManager &&
                                  hasPermission('Sales_Team_Update')
                              "
                              class="dropdown-divider"
                            ></div>
                            <a
                              v-if="
                                u.role &&
                                  u.status != 'Inactive' &&
                                  u.role.isStateManager &&
                                  hasPermission('Sales_Team_Update')
                              "
                              class="dropdown-item"
                              @click.prevent="viewAssignStates(u)"
                            >
                              Assign State
                            </a>
                            <div
                              v-if="
                                u.role &&
                                  hasPermission('Sales_Team_Update') &&
                                  u.status != 'Inactive' &&
                                  (u.role.canManageDistributorManagers ||
                                    u.role.canManageGroupManagers ||
                                    u.role.canManageStateManagers)
                              "
                              class="dropdown-divider"
                            ></div>
                            <a
                              v-if="
                                u.role &&
                                  hasPermission('Sales_Team_Update') &&
                                  u.status != 'Inactive' &&
                                  (u.role.canManageDistributorManagers ||
                                    u.role.canManageGroupManagers ||
                                    u.role.canManageStateManagers)
                              "
                              class="dropdown-item"
                              @click.prevent="viewAssignTeam(u)"
                            >
                              Assign Teams
                            </a>
                            <div class="dropdown-divider"></div>
                            <a
                              v-if="u.id != user.id && hasPermission('Sales_Team_Update')"
                              class="dropdown-item"
                              @click.prevent="viewEdit(u)"
                            >
                              Edit User
                            </a>
                            <div class="dropdown-divider"></div>
                            <a
                              v-if="u.id != user.id && hasPermission('Sales_Team_Enable_Disable')"
                              class="dropdown-item"
                              @click.prevent="viewRemove(u)"
                            >
                              {{ u.status == "Inactive" ? "Enable" : "Disable" }}
                            </a>
                            <div class="dropdown-divider"></div>
                            <a
                              class="dropdown-item"
                              @click.prevent="viewResend(u)"
                              v-if="
                                u.id != user.id &&
                                  u.status == 'Pending' &&
                                  hasPermission('Sales_Team_Send_Invite')
                              "
                            >
                              Resend Invite
                            </a>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!users.length">
                      <td colspan="8">
                        <div class="text-center">No matching records</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">{{ count }} user{{ count != 1 ? "s" : "" }}</div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="['paginate_button', 'page-item', 'previous', { disabled: !hasPrev }]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev">Previous</a>
                    </li>
                    <li
                      :class="['paginate_button', 'page-item', 'previous', { disabled: !hasNext }]"
                    >
                      <a href="#" class="page-link" @click.prevent="next">Next</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <invite-sales-user @onInviteComplete="onInviteCompleteHandler" :roles="roles" />
    <create-sales-user @onCreateUser="handleCreateUser" />

    <div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Edit Sales User</b>
            </h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Email address</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.emailAddressValid }]"
                  type="email"
                  placeholder="Email address of user"
                  autocomplete="off"
                  v-model="editingUser.emailAddress"
                />
                <ul v-if="v2.emailAddressValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid email.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Phone Number</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="[
                    'form-control',
                    { 'is-invalid': v2.phoneNumber },
                    { 'is-invalid': v2.phoneNumberValid },
                  ]"
                  type="text"
                  placeholder="Phone number of user"
                  autocomplete="off"
                  v-model="editingUser.phoneNumber"
                />
                <ul v-if="v2.phoneNumber" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <ul v-if="v2.phoneNumberValid" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid phone number.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Last Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.lastname }]"
                  type="text"
                  placeholder="Last Name of user"
                  v-model="editingUser.lastName"
                />
                <ul v-if="v2.lastname" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Other Names</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.othernames }]"
                  type="text"
                  placeholder="Other Names of user"
                  v-model="editingUser.otherNames"
                />
                <ul v-if="v2.othernames" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Role</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="editingUser.roleId"
                >
                  <option v-for="r in roles" :key="r.id" :value="r.id">
                    {{ r.text }}
                  </option>
                </select>
                <ul v-if="v2.role" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Staff Id</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.staffId }]"
                  type="text"
                  placeholder="Staff Id"
                  v-model="editingUser.staffId"
                />
                <ul v-if="v2.staffId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateUser"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-view-mapping"
      role="dialog"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>View Mapping - </b>
              {{ editingUser.lastName + "" + editingUser.otherNames }}
            </h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div
              v-if="editingUser.role && editingUser.role.isDistributorManager"
              class="form-group row"
            >
              <label class="col-12 col-sm-12 col-form-label">Managed Distributors</label>

              <div class="col-12" style="max-height: 200px; overflow-y: scroll">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 50%">Distributor Name</th>
                      <th style="width: 25%">State</th>
                      <th style="width: 25%">Date Added</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="u in userMappings.distributorMapping" :key="u.id">
                      <td>{{ u.distributorName }}</td>
                      <td>{{ u.state }}</td>
                      <td>{{ formatDate(u.dateCreated) }}</td>
                    </tr>
                    <tr
                      v-if="
                        userMappings &&
                          userMappings.salesTeamMapping &&
                          !userMappings.distributorMapping.length
                      "
                    >
                      <td colspan="3">
                        <div class="text-center">No matching records</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="editingUser.role && editingUser.role.isGroupManager" class="form-group row">
              <label class="col-12 col-form-label">Managed Groups</label>

              <div class="col-12" style="max-height: 200px; overflow-y: scroll">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 50%">Group Name</th>
                      <th style="width: 50%">Date Added</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="u in userMappings.groupMapping" :key="u.id">
                      <td>{{ u.groupName }}</td>
                      <td>{{ formatDate(u.dateCreated) }}</td>
                    </tr>
                    <tr
                      v-if="
                        userMappings &&
                          userMappings.salesTeamMapping &&
                          !userMappings.groupMapping.length
                      "
                    >
                      <td colspan="2">
                        <div class="text-center">No matching records</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="editingUser.role && editingUser.role.isStateManager" class="form-group row">
              <label class="col-12 col-form-label">Managed States</label>

              <div class="col-12" style="max-height: 200px; overflow-y: scroll">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 50%">State Name</th>
                      <th style="width: 50%">Date Added</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="u in userMappings.stateMapping" :key="u.id">
                      <td>{{ u.stateName }}</td>
                      <td>{{ formatDate(u.dateCreated) }}</td>
                    </tr>
                    <tr
                      v-if="
                        userMappings &&
                          userMappings.salesTeamMapping &&
                          !userMappings.stateMapping.length
                      "
                    >
                      <td colspan="2">
                        <div class="text-center">No matching records</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div
              v-if="
                editingUser.role &&
                  (editingUser.role.canManageDistributorManagers ||
                    editingUser.role.canManageStateManagers ||
                    editingUser.role.canManageGroupManagers)
              "
              class="form-group row"
            >
              <label class="col-12 col-form-label">Managed Team</label>

              <div class="col-12" style="max-height: 200px; overflow-y: scroll">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 40%">Staff Name</th>
                      <th style="width: 20%">Role</th>
                      <th style="width: 20%">Status</th>
                      <th style="width: 20%">Date Added</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="u in userMappings.salesTeamMapping" :key="u.id">
                      <td>{{ u.staffName }}</td>
                      <td>{{ u.role }}</td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-primary': u.status == 'Pending' },
                            { 'badge-success': u.status == 'Active' },
                            { 'badge-danger': u.status == 'Disabled' },
                          ]"
                        >
                          {{ u.status == "Pending" ? "Invitation sent" : u.status }}
                        </span>
                      </td>
                      <td>{{ formatDate(u.dateCreated) }}</td>
                    </tr>
                    <tr
                      v-if="
                        userMappings &&
                          userMappings.salesTeamMapping &&
                          !userMappings.salesTeamMapping.length
                      "
                    >
                      <td colspan="4">
                        <div class="text-center">No matching records</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-assign-distributor"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Assign Distributor</b>
            </h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <div v-if="distributorAssignmentError" class="col-12 alert alert-danger">
                {{ distributorAssignmentError }}
              </div>
              <div class="col-12">
                <input
                  class="form-control"
                  placeholder="Search for a distributor"
                  type="text"
                  v-model="searchFilters.searchDistributors"
                />

                <div v-if="filteredAssignments.length == 0" class="col-12">
                  <div class="text-center">No matching records</div>
                </div>
                <div
                  v-else
                  class="dd col-12"
                  style="
                    height: 320px;
                    overflow-y: scroll;
                    border: 1px solid #eee;
                  "
                >
                  <ol class="dd-list">
                    <li v-for="item in filteredAssignments" :key="item.text">
                      <div class="dd-handle">
                        <input
                          type="checkbox"
                          :name="item.text"
                          :checked="item.selected"
                          @click="item.selected = !item.selected"
                        />
                        <label :for="item.text"
                          ><b>{{ item.text }}</b></label
                        >
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="assignDistributors"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-assign-group"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Assign Group</b>
            </h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <div v-if="groupAssignmentError" class="col-12 alert alert-danger">
                {{ groupAssignmentError }}
              </div>
              <div class="col-12">
                <input
                  class="form-control"
                  placeholder="Search for a group"
                  type="text"
                  v-model="searchFilters.searchGroups"
                />

                <div v-if="filteredAssignments.length == 0" class="col-12">
                  <div class="text-center">No matching records</div>
                </div>
                <div
                  v-else
                  class="dd col-12"
                  style="
                    height: 320px;
                    overflow-y: scroll;
                    border: 1px solid #eee;
                  "
                >
                  <ol class="dd-list">
                    <li v-for="item in filteredAssignments" :key="item.text">
                      <div class="dd-handle">
                        <input
                          type="checkbox"
                          :name="item.text"
                          :checked="item.selected"
                          @click="item.selected = !item.selected"
                        />
                        <label :for="item.text"
                          ><b>{{ item.text }}</b></label
                        >
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="assignGroups"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-assign-state"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Assign State</b>
            </h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <div v-if="stateAssignmentError" class="col-12 alert alert-danger">
                {{ stateAssignmentError }}
              </div>
              <div class="col-12">
                <input
                  class="form-control"
                  placeholder="Search for a state"
                  type="text"
                  v-model="searchFilters.searchStates"
                />

                <div v-if="filteredAssignments.length == 0" class="col-12">
                  <div class="text-center">No matching records</div>
                </div>
                <div
                  v-else
                  class="dd col-12"
                  style="
                    height: 320px;
                    overflow-y: scroll;
                    border: 1px solid #eee;
                  "
                >
                  <ol class="dd-list">
                    <li v-for="item in filteredAssignments" :key="item.text">
                      <div class="dd-handle">
                        <input
                          type="checkbox"
                          :name="item.text"
                          :checked="item.selected"
                          @click="item.selected = !item.selected"
                        />
                        <label :for="item.text"
                          ><b>{{ item.text }}</b></label
                        >
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="assignStates"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-assign-team"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <b>Add Managed Team Member</b>
            </h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <div v-if="teamAssignmentError" class="col-12 alert alert-danger">
                {{ teamAssignmentError }}
              </div>

              <div class="col-12">
                <label class="col-12 col-form-label">Add Criteria</label>
                <select
                  class="col-12 form-control"
                  placeholder="Select an option"
                  v-model="searchFilters.managerSearchCriteria"
                  @change="onChange($event)"
                >
                  <option value="">Select an option</option>
                  <option value="all">All Managers</option>
                  <option value="distributor">Distributor Managers</option>
                  <option value="group">Group Managers</option>
                  <option value="state">State Managers</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <div class="col-12">
                <input
                  class="form-control"
                  placeholder="Search for a team member"
                  type="text"
                  v-model="searchFilters.searchTeams"
                />
                &nbsp;<br />
                <div
                  class="dd col-12"
                  style="
                    height: 320px;
                    overflow-y: scroll;
                    border: 1px solid #eee;
                  "
                >
                  <ol class="dd-list">
                    <li v-for="item in filteredAssignments" :key="item.text">
                      <div class="dd-handle">
                        <input
                          type="checkbox"
                          :name="item.text"
                          :checked="item.selected"
                          @click="item.selected = !item.selected"
                        />
                        <label :for="item.text"
                          ><b>{{ item.text }}</b></label
                        >
                      </div>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="assignTeams"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-delete" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3 class="p-1"><b>Confirmation</b></h3>
              <p>
                Are you sure you want to
                {{ modalUser.status == "Inactive" ? "enable" : "disable" }} this user:<br />
                <strong> {{ modalUser.lastName }} </strong>?
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px"
              :disabled="isBusyModal"
              class="btn btn-warning"
              type="button"
              @click.prevent="updateStatus"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Filter</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select class="form-control" placeholder="Select an option" v-model="filter.status">
                  <option value="0">All Users</option>
                  <option value="active">Active</option>
                  <option value="inactive">InActive</option>
                  <option value="pending">Others</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Roles</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select class="form-control" placeholder="Select an option" v-model="filter.role">
                  <option value="0">All Roles</option>
                  <option v-for="r in roles" :key="r.id" :value="r.id">
                    {{ r.text }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Other filters</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by name, email or phone number..."
                  v-model="filter.qry"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              :disabled="isBusyModal"
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getUsers"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-resend" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3>Resend Invite</h3>
              <p>
                You are about to resend an invite to this user:
                <strong> {{ modalUser.lastName }} </strong>?
              </p>
            </div>
            <!-- <div v-if="enableInviteOption" class="mt-2 form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <div
                  class="custom-control custom-radio"
                  :class="[{ 'is-invalid': v4.selectedOption }]"
                  v-for="option in inviteOptions"
                  :key="option.value"
                >
                  <input
                    type="radio"
                    :id="option.value"
                    name="customRadio"
                    class="custom-control-input"
                    :value="option.value"
                    v-model="selectedOption"
                  />
                  <label class="custom-control-label" :for="option.value">
                    {{ option.name }}
                  </label>
                </div>
                <ul v-if="v4.selectedOption" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div> -->
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="attemptResendInvite"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.dd {
  margin: 0;
  padding: 0;
  margin-left: 12px;
  list-style: none;
  line-height: 20px;
  max-width: 600px;
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list-inner {
  padding-left: 30px;
}

.dd-item {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  line-height: 20px;
}

.dd-handle {
  display: block;
  margin: 5px 0;
  padding: 0px 10px;
  text-decoration: none;
  background: #fff;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}
</style>
<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";
import Select2 from "@/components/Select2";
import CreateSalesUser from "../components/User/CreateSalesUser.vue";
import InviteSalesUser from "../components/User/InviteSalesUser.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsTeam",
  components: {
    Select2,
    CreateSalesUser,
    InviteSalesUser,
  },

  created() {
    if (!this.hasPermission("Sales_Team_View")) {
      this.$router.push("/restricted");
    }
    this.getUsers();
    this.getRoles();
  },

  data() {
    return {
      filter: {
        status: "0",
        role: "0",
        qry: "",
      },

      searchFilters: {
        searchDistributors: "",
        searchGroups: "",
        searchStates: "",
        searchTeams: "",
        managerSearchCriteria: "",
      },

      isBusy: false,
      isBusyModal: false,

      editingUser: {
        id: null,
        emailAddress: null,
        phoneNumber: null,
        lastName: null,
        otherNames: null,
        roleId: null,
        staffId: null,
      },

      modalUser: {
        id: null,
        emailAddress: null,
        lastName: null,
        otherNames: null,
      },

      userMappings: [],

      v2: {
        phoneNumber: false,
        phoneNumberValid: false,
        emailAddressValid: false,
        lastname: false,
        othernames: false,
        role: false,
      },

      users: [],
      roles: [],
      assignments: [],
      managers: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,

      v8: {
        selectedOption: false,
      },

      selectedOption: "",
      assignmentType: "",
      distributorAssignmentError: null,
      stateAssignmentError: null,
      groupAssignmentError: null,
      teamAssignmentError: null,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },

    enableInviteOption: function() {
      return this.modalUser.emailAddress && this.modalUser.phoneNumber;
    },
    computedKey() {
      return this.assignments.length;
    },
    filteredAssignments() {
      let vm = this;
      var searchParam = null;
      if (vm.assignmentType === "Distributor") searchParam = vm.searchFilters.searchDistributors;
      else if (vm.assignmentType === "Group") searchParam = vm.searchFilters.searchGroups;
      if (vm.assignmentType === "State") searchParam = vm.searchFilters.searchStates;
      if (vm.assignmentType === "Team") searchParam = vm.searchFilters.searchTeams;

      if (!searchParam) {
        return vm.assignments;
      }
      return vm.sortedAssignments.filter((a) => {
        return a.text.toLowerCase().indexOf(searchParam.toLowerCase()) != -1;
      });
    },
    sortedAssignments: function() {
      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;

        return 0;
      }

      var sortedArr = this.assignments.sort(compare);

      return sortedArr;
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        role: "0",
        qry: "",
      };
    },

    handleCreateUser() {
      $("#mod-add-manual").modal("hide");
      this.getUsers();
    },

    onChange(event) {
      this.assignments = [];
      this.searchFilters.searchTeams = "";
      var val = event.target.value;
      if (val && val != "") {
        this.getManagers(this.editingUser.id, val);
      }
    },

    viewAdd() {
      $("#mod-add").modal("show");
    },

    viewAddManual() {
      $("#mod-add-manual").modal("show");
    },

    viewEdit(u) {
      this.editingUser = u;
      $("#mod-edit").modal("show");
    },

    viewMapping(u) {
      this.userMappings = [];
      this.editingUser = u;
      this.getUserMappings(u.id);
      $("#mod-view-mapping").modal("show");
    },

    viewAssignDistributors(u) {
      this.searchFilters.searchDistributors = "";
      this.assignmentType = "Distributor";
      this.assignments = [];
      this.editingUser = u;
      this.getAssignments(u.id, "Distributor");
      $("#mod-assign-distributor").modal("show");
    },

    viewAssignStates(u) {
      this.searchFilters.searchStates = "";
      this.assignmentType = "State";
      this.assignments = [];
      this.editingUser = u;
      this.getAssignments(u.id, "State");
      $("#mod-assign-state").modal("show");
    },

    viewAssignGroups(u) {
      this.searchFilters.searchGroups = "";
      this.assignmentType = "Group";
      this.assignments = [];
      this.editingUser = u;
      this.getAssignments(u.id, "Group");
      $("#mod-assign-group").modal("show");
    },

    viewAssignTeam(u) {
      this.searchFilters.searchTeams = "";
      this.searchFilters.managerSearchCriteria = "";
      this.assignmentType = "Team";
      this.assignments = [];
      this.editingUser = u;
      //this.getAssignments(u.id, 'Team');
      $("#mod-assign-team").modal("show");
    },

    viewRemove(u) {
      this.modalUser = u;
      $("#mod-delete").modal("show");
    },

    viewResend(u) {
      this.modalUser = u;
      $("#mod-resend").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    async updateUser() {
      if (this.validateUpdate()) {
        this.isBusyModal = true;
        this.isBusy = true;

        let data = {
          id: this.editingUser.id,
          phoneNumber: this.editingUser.phoneNumber,
          emailAddress: this.editingUser.emailAddress,
          lastName: this.editingUser.lastName,
          otherNames: this.editingUser.otherNames,
          roleId: this.editingUser.roleId,
          staffId: this.editingUser.staffId,
        };

        $("#mod-edit").modal("hide");

        await this.$http
          .put("/SalesUsers/update/" + this.editingUser.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function(err) {});

        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    async attemptResendInvite() {
      // if (this.enableInviteOption && this.validateResendInvite())
      this.resendInvite();

      //if (!this.enableInviteOption) this.resendInvite();
    },

    onInviteCompleteHandler() {
      $("#mod-add").modal("hide");
      this.getUsers();
    },

    async resendInvite() {
      this.isBusyModal = true;
      $("#mod-resend").modal("hide");

      await this.$http
        .get("/SalesUsers/resendInvite/" + this.modalUser.id + "?type=" + this.selectedOption)
        .then(function(res) {
          this.isBusyModal = false;
          if (res.ok && res.body) {
            this.$toast.success("Invite sent successfully.", {
              icon: false,
              rtl: false,
            });
          }
        })
        .catch(function() {
          this.isBusyModal = false;
        });
    },

    validateResendInvite() {
      this.v8 = {
        selectedOption: false,
      };

      let isValid = true;

      if (!this.selectedOption) {
        this.v8.selectedOption = true;
        isValid = false;
      }

      return isValid;
    },

    validateUpdate() {
      this.v2 = {
        phoneNumber: false,
        phoneNumberValid: false,
        emailAddressValid: false,
        lastname: false,
        othernames: false,
        role: false,
      };
      let isValid = true;

      if (!this.editingUser.phoneNumber) {
        this.v2.phoneNumber = true;
        isValid = false;
      }

      if (this.editingUser.phoneNumber && !helper.validatePhone(this.editingUser.phoneNumber)) {
        this.v2.phoneNumberValid = true;
        isValid = false;
      }

      if (this.editingUser.emailAddress && !helper.validateEmail(this.editingUser.emailAddress)) {
        this.v2.emailAddressValid = true;
        isValid = false;
      }

      if (!this.editingUser.lastName) {
        this.v2.lastname = true;
        isValid = false;
      }

      if (!this.editingUser.otherNames) {
        this.v2.othernames = true;
        isValid = false;
      }

      if (!this.editingUser.roleId) {
        this.v2.role = true;
        isValid = false;
      }

      return isValid;
    },

    assignDistributors() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateAssignment("distributor")) {
        $("#mod-assign-distributor").modal("hide");

        var selectedIds = this.assignments
          .filter((obj) => obj.selected == true)
          .map((ele) => ele.id);

        let data = {
          userId: this.editingUser.id,
          mappingType: "Distributor",
          mappingValues: selectedIds,
        };

        this.$http
          .post("/SalesUsers/mapSalesUser/", data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },
    assignStates() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateAssignment("state")) {
        $("#mod-assign-state").modal("hide");

        var selectedIds = this.assignments
          .filter((obj) => obj.selected == true)
          .map((ele) => ele.id);

        let data = {
          userId: this.editingUser.id,
          mappingType: "State",
          mappingValues: selectedIds,
        };

        this.$http
          .post("/SalesUsers/mapSalesUser/", data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },
    assignGroups() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateAssignment("group")) {
        $("#mod-assign-group").modal("hide");

        var selectedIds = this.assignments
          .filter((obj) => obj.selected == true)
          .map((ele) => ele.id);

        let data = {
          userId: this.editingUser.id,
          mappingType: "Group",
          mappingValues: selectedIds,
        };

        this.$http
          .post("/SalesUsers/mapSalesUser/", data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },
    assignTeams() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateAssignment("team")) {
        $("#mod-assign-team").modal("hide");

        var selectedIds = this.assignments
          .filter((obj) => obj.selected == true)
          .map((ele) => ele.id);

        let data = {
          userId: this.editingUser.id,
          mappingType: "Team",
          mappingValues: selectedIds,
        };

        this.$http
          .post("/SalesUsers/mapSalesUser/", data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getUsers();
          })
          .catch(function() {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      } else {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },
    validateAssignment(type) {
      let isValid = true;

      this.teamAssignmentError = null;
      this.stateAssignmentError = null;
      this.distributorAssignmentError = null;
      this.groupAssignmentError = null;

      var selectedIds = this.assignments.filter((obj) => obj.selected == true);
      if (selectedIds.length == 0) {
        if (type === "distributor")
          this.distributorAssignmentError = "Please select at least one record for assignment";
        if (type === "state")
          this.stateAssignmentError = "Please select at least one record for assignment";
        if (type === "group")
          this.groupAssignmentError = "Please select at least one record for assignment";
        if (type === "team")
          this.teamAssignmentError = "Please select at least one record for assignment";
        isValid = false;
      }

      return isValid;
    },

    updateStatus() {
      this.isBusyModal = true;
      let data = {
        id: this.modalUser.id,
        status:
          this.modalUser.status == "Inactive"
            ? this.modalUser.username
              ? "Active"
              : "Pending"
            : "Inactive",
      };

      $("#mod-delete").modal("hide");

      this.$http
        .put("/SalesUsers/updateStatus/" + data.id, data)
        .then(function(res) {
          this.isBusyModal = false;
          this.getUsers();
        })
        .catch(function() {
          this.isBusyModal = false;
        });

      this.getUsers();
    },

    async getUsers() {
      this.isBusy = true;
      this.isBusyModal = true;

      await this.$http
        .get(
          "/SalesUsers/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&status=" + this.filter.status : "") +
            (this.filter.role != "0" ? "&role=" + this.filter.role : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function(res) {
          this.users = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");

      this.isBusy = false;
      this.isBusyModal = false;
    },
    getUserMappings(userId) {
      var a = true;

      this.$http
        .get("/SalesUsers/getMappings/" + userId)
        .then(function(res) {
          this.userMappings = res.body;
        })
        .catch(function() {});
    },
    getAssignments(userId, mappingType) {
      var a = true;

      this.$http
        .get(
          "/SalesUsers/getMappingsItemList/" +
            this.user.parentId +
            "/" +
            userId +
            "?mappingType=" +
            mappingType
        )
        .then(function(res) {
          this.assignments = res.body;
        })
        .catch(function() {});
    },

    getManagers(userId, managerType) {
      var a = true;

      this.$http
        .get(
          "/SalesUsers/getMappingsItemList/" +
            this.user.parentId +
            "/" +
            userId +
            "?mappingType=team&managerType=" +
            managerType
        )
        .then(function(res) {
          this.assignments = res.body;
        })
        .catch(function() {});
    },

    getRoles() {
      this.$http
        .get("/salesroles/getRoleListNoPaging?parentId=" + this.user.parentId)
        .then(function(res) {
          this.roles = res.body;
        })
        .catch(function() {});
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    prev() {
      this.page -= 1;
      this.getUsers();
    },

    next() {
      this.page += 1;
      this.getUsers();
    },
  },
};
</script>
