<template>
   <div
      class="modal fade colored-header colored-header-primary"
      id="mod-global"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3 class="p-1">Confirmation</h3>
              <p>
                Do you want to switch to
                {{ isGlobal ? "Default" : "Global" }} View?
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 140px"
              class="btn btn-warning"
              type="button"
              @click.prevent="toggleGlobal"
            >
            Yes, proceed
            </button>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
export default {
    props:["isGlobal"],
    methods:{
        toggleGlobal(){
            this.$emit("toggleGlobal")
        }
    }
}
</script>