<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-secondary btn-rounded btn-space" @click="viewFilter">
            <span class="icon icon-left mdi mdi-tune text-primary"></span> Filters
          </button>

          <!-- <button class="btn btn-secondary btn-rounded btn-space float-right" @click="viewExport">
                      <span class="icon icon-left mdi mdi-arrow-top-right text-primary"></span> Export
                    </button>-->
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive" style="overflow: visible">
                <table class="table table-fw-widget dataTable no-footer text-nowrap">
                  <thead>
                    <tr>
                      <th style="width:10%;">Order Date</th>
                      <th style="width:10%;">Order Number</th>
                      <th style="width:10%;">Channel</th>
                      <th style="width:10%;">Distributor</th>
                      <th style="width:5%;">Total</th>
                      <th style="width:10%;"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="o in orders" :key="o.id">
                      <td>{{ o.orderDate ? formatDate(o.orderDate) : formatDate(o.created) }}</td>
                      <td>
                        <a href="#" @click.prevent="details(o.id)">{{ o.orderNumber }}</a>
                      </td>
                      <td>{{ o.source }}</td>
                      <td>{{ o.seller.name }}</td>
                      <td>&#8358; {{ formatMoney(o.total) }}</td>
                      <td>
                        <button
                          class="btn btn-outline-primary btn-sm dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                          <a class="dropdown-item" href="#" @click.prevent="details(o.id)">
                            View Details
                          </a>
                          <div v-if="o.status != 'Canceled'" class="dropdown-divider"></div>
                          <a
                            class="dropdown-item"
                            href="#"
                            @click.prevent="editDraft(o.id)"
                            v-if="o.status != 'Canceled'"
                          >
                            Edit Draft
                          </a>

                          <div v-if="o.status == 'Draft'" class="dropdown-divider"></div>
                          <a
                            v-if="o.status == 'Draft'"
                            class="dropdown-item"
                            href="#"
                            @click.prevent="confirmDelete(o.id)"
                            style="color: red"
                          >
                            Delete Draft Order
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!orders.length">
                      <td colspan="10">
                        <div class="text-center" style="padding-top:50px;">
                          <span style="font-size: 4.615rem;" class="mdi mdi-basket"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom:100px;">
                          You have not made any orders.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">{{ count }} order{{ count != 1 ? "s" : "" }}</div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="['paginate_button', 'page-item', 'previous', { disabled: !hasPrev }]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev">Previous</a>
                    </li>
                    <li
                      :class="['paginate_button', 'page-item', 'previous', { disabled: !hasNext }]"
                    >
                      <a href="#" class="page-link" @click.prevent="next">Next</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select2 placeholder="Select an option" v-model="filter.status">
                  <option value="0">All Orders</option>
                  <option value="Pending">Pending</option>
                  <option value="Fulfilled">Fulfilled</option>
                  <option value="Canceled">Canceled</option>
                </select2>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Date range</label>
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.dateStart"
                />
                <!-- <date-picker
                                  v-model="filter.dateStart"
                                  :config="options"
                                  placeholder="Select a date"
                                ></date-picker>-->
              </div>
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.dateEnd"
                />
                <!-- <date-picker v-model="filter.dateEnd" :config="options" placeholder="Select a date"></date-picker> -->
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Buyer Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by buyer name..."
                  v-model="filter.maker"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Order Number</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by order number..."
                  v-model="filter.orderNumber"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px;"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px;"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px;"
              class="btn btn-primary md-close"
              type="button"
              @click="getOrders"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <GeneralDeleteCaution
      :id="DeleteId"
      @proceed="deleteDraftOrder"
      :isBusy="isBusyModal"
      text="draft"
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
// import DatePicker from "vue-bootstrap-datetimepicker";

export default {
  name: "ordersDraft",
  components: {
    Select2,
    GeneralDeleteCaution,
    GeneralDeleteMessage,
    // DatePicker
  },
  created() {
    this.getOrders();
  },
  data() {
    return {
      filter: {
        status: "0",
        orderNumber: "",
        maker: "",
        dateStart: moment()
          .subtract(6, "days")
          .format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
      },
      isBusy: false,
      isBusyModal: false,
      orders: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
      options: {
        format: "YYYY-MM-DD",
        icons: {
          time: "mdi mdi-time",
          date: "mdi mdi-calendar",
          up: "mdi mdi-chevron-up",
          down: "mdi mdi-chevron-down",
          previous: "mdi mdi-chevron-left",
          next: "mdi mdi-chevron-right",
          today: "mdi mdi-screenshot",
          clear: "mdi mdi-trash",
          close: "mdi mdi-remove",
        },
      },
      local: {
        dow: 0, // Sunday is the first day of the week
        hourTip: "Select Hour", // tip of select hour
        minuteTip: "Select Minute", // tip of select minute
        secondTip: "Select Second", // tip of select second
        yearSuffix: "", // suffix of head year
        monthsHead: "January_February_March_April_May_June_July_August_September_October_November_December".split(
          "_"
        ), // months of head
        months: "Jan_Feb_Mar_Apr_May_Jun_Jul_Aug_Sep_Oct_Nov_Dec".split("_"), // months of panel
        weeks: "Su_Mo_Tu_We_Th_Fr_Sa".split("_"), // weeks
        cancelTip: "cancel",
        submitTip: "confirm",
      },
      deletingOrder: {},
      DeleteId: "",
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    reset() {
      this.filter = {
        status: "0",
        orderNumber: "",
        maker: "",
        dateStart: moment()
          .subtract(6, "days")
          .format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
      };
    },
    viewExport() {
      //   $("#mod-export").modal("show");
    },
    viewFilter() {
      $("#mod-filter").modal("show");
    },
    details(id) {
      this.$router.push(`/merchant/orders/${id}?isDraft=true`);
    },
    editDraft(id) {
      this.$router.push("/merchant/orders/edit/" + id);
    },
    async getOrders() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Orders/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            "&draft=true" +
            (this.filter.orderNumber != "" ? "&orderNumber=" + this.filter.orderNumber : "") +
            (this.filter.maker != "" ? "&buyer=" + this.filter.maker : "") +
            (this.filter.status != "0" ? "&status=" + this.filter.status : "") +
            (this.filter.dateStart != ""
              ? "&dateStart=" + moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
              : "") +
            (this.filter.dateEnd != ""
              ? "&dateEnd=" + moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:5")
              : "") +
            "&makerId=" +
            this.user.id
        )
        .then(function(res) {
          this.orders = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
    formatDate(date) {
      return moment(date).format("ll");
    },
    prev() {
      this.page -= 1;
      this.getOrders();
    },
    next() {
      this.page += 1;
      this.getOrders();
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    goToPayment(id) {
      this.$router.push("/payments/" + id);
    },

    confirmDelete(id) {
      this.DeleteId = id;
      $("#mod-confirm-delete").modal("show");
    },

    showDeleteMsg() {
      $("#mod-delete-msg").modal("show");
    },

    cancelConfirm() {
      this.DeleteId = "";
      $("#mod-confirm-delete").modal("hide");
    },

    async deleteDraftOrder() {
      this.isBusyModal = true;
      try {
        const res = await this.$http.delete(`/Orders/${this.DeleteId}`);
        if (res.ok) {
          this.$toast.success("Order deleted successfully", {
            icon: true,
            rtl: false,
          });
          this.getOrders();
          this.cancelConfirm();
        }
      } catch (error) {}
      this.isBusyModal = false;
    },
  },
};
</script>
