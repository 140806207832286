<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-border-color card-border-color-primary">
          <div class="card-header">&nbsp;</div>
          <div class="card-body">
              <div class="row">
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/volume-by-customer">Volume By Customer</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/volume-by-product">Volume By Product</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/volume-by-product-category">Volume By Category</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/daily-sales-by-order"> Orders </router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/daily-sales-by-order-status">Sales By Order Status </router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/inventory-by-product">Inventory By Product</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/inventory-by-warehouse">Inventory By Warehouse </router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/inventory-by-batch">Inventory By Product Batch </router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/inventory-by-product-category">Inventory By Category</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/reconciliation-by-status">Reconciliation (Product)</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/reconciliation-by-order-number">Reconciliation (Order Number)</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/total-credit-sales-ytd"> Total Credit Sales - YTD</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/total-payment-collection-ytd">Total Payment Collection - YTD</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/total-cash-credit-ratio">Total Cash Credit Ratio </router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/my-customers-report">New Customer Acqusition YTD</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/delisted-customers-ytd-report">Delisted Customers YTD</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/active-credit-sales-report">Active Credit Sales</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/active-cash-collections-report">
                                          Active Cash Collections
                                      </router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/active-cash-credit-branch">Active Cash Credit (Branch)</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/active-cash-credit-customer">Active Cash Credit (Customer)</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/30_day_credit_outlets">Total 30 Day Credit (Customer)</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/60_day_credit_outlets">Total 60 Day Credit ( Customer )</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/90_day_credit_outlets">Total 90 Day Credit (Customer)</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/active_cash_collections_introducer"> Active Cash Collections(Sales Agent) </router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/active_cash_collections_branch">Active Cash Collections (Branch) </router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/active_credit_sales_branch">Active Credit Sales (Branch) </router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/active_credit_sales_introducer"> Active Credit Sales (Sales Agent) </router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/active_orders_customer">Active Orders( Customer )</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/active_orders_orderstatus">Active Orders( Order Status )</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/monthly_sales_introducer">Monthly Sales (Sales Agent)</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em">
                                      <router-link to="/monthly_collections_introducer">Monthly Collections (Sales Agent)</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
                  <div class="col-md-6 col-sm col-lg-3 mb-2">
                      <div style="background-color: #f7f7f7; border-radius: 10px"
                           class="p-2">
                          <i class="feather icon-file-text font-large-2 float-left"
                             aria-hidden="true"
                             style="color: #d9a300"></i>
                          <span>
                              <strong>
                                  <font size="3.8em"><router-link to="/product_performance_by_percentage">Product Performance By Percentage</router-link>
                                  </font>
                              </strong>
                              <p>Click to generate Report</p>
                          </span>
                          <div class="clearfix"></div>
                      </div>
                  </div>
              </div>
          </div>
          <h3 class="text-center" style="padding-bottom: 100px"></h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "reports",
};
</script>