<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="p-2 card d-flex justify-content-between flex-row align-items-center">
          <div class="d-flex align-items-center">
            <button
              style="color: #234D80"
              class="btn btn-link btn-rounded btn-space"
              @click.prevent="goBack"
            >
              Go back
            </button>
            <p class="stock-warehouse-name">{{ locationName }}</p>
          </div>
          <button
            class="btn btn-rounded btn-primary btn-space text-white"
            @click="viewAdd"
            style=" width: 140px"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span>
            Add Product
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div
          :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]"
        >
          <div class="card-heade p-2">
            <h4>PRODUCT TRANSFERS</h4>
          </div>
          <div class="col-sm-12 table-responsive">
            <table class="table table-fw-widget dataTable no-footer">
              <thead>
                <tr>
                  <th style="width: 20%">Transfer From</th>
                  <th style="width: 20%">Transfer To</th>
                  <th style="width: 15%">Product</th>
                  <th style="width: 13%">Unit Price x QTY</th>
                  <th style="width: 10%">Stock Value</th>
                  <th style="width: 15%">Transferred By</th>
                  <th style="width: 7%"></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in transferDetails" :key="index">
                  <td>{{ locationName }}</td>
                  <td>{{ item.location }}</td>
                  <td>{{ item.name }}</td>
                  <td>&#8358;{{ item.price }} X {{ item.quantity }}</td>
                  <td>&#8358;{{ formatMoney(item.quantity * item.price) }}</td>
                  <td>{{ user.displayName }}</td>
                  <td>
                    <button
                      style="color: #FF6767"
                      class="btn btn-link btn-rounded btn-space"
                      @click.prevent="remove(index)"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
                <tr v-if="!transferDetails.length">
                  <td colspan="12">
                    <div class="text-center" style="padding-top: 50px">
                      <span style="font-size: 4.615rem" class="mdi mdi-crosshairs-gps"></span>
                    </div>
                    <div class="text-center">No products selected</div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div
              class="d-flex justify-content-between align-items-center"
              v-if="transferDetails.length"
            >
              <div>
                <p>Total Items: {{ transferDetails.length }}</p>
                <p>Total Stock Value: &#8358;{{ formatMoney(total) }}</p>
              </div>
              <div>
                <button
                  style="width: 100px"
                  class="btn btn-secondary mr-2"
                  type="button"
                  @click.prevent="deleteAll"
                >
                  Cancel
                </button>
                <button
                  style="width: 100px"
                  :disabled="isBusy || !transferDetails.length"
                  class="btn btn-primary"
                  type="button"
                  @click.prevent="confirmTransfer"
                >
                  <span v-if="isBusyModal">Busy...</span>
                  <span v-else>Transfer</span>
                </button>
              </div>
            </div>
          </div>

          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66">
              <circle
                class="circle"
                fill="none"
                stroke-width="4"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <strong>Create New Stock Transfer</strong>
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click.prevent="onAddCancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-5 col-form-label text-sm font-weight-bold">
                Product/Unit</label
              >
              <div class="col-12 col-sm-6 col-lg-6">
                <select
                  placeholder="Select Product/Unit"
                  class="form-control"
                  v-model="newItem.productIndex"
                  @input="onProductSelect($event)"
                >
                  <option v-for="(p, index) in filteredProducts" :key="p.id" :value="index">
                    {{ p.name }} ({{ p.unit }}, {{ p.quantity }}pcs)
                  </option>
                </select>
                <ul v-if="v2.productId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
                <span
                  v-if="canShowCaution"
                  class="bg-warning text-white px-1 rounded"
                  style="padding: 0.2rem"
                >
                  <i class="fa fa-warning mt-1"></i>
                  <small>Product is <b>Low</b> on stock.</small>
                </span>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-5 col-form-label text-sm font-weight-bold">
                Available Quantity & Cost Per unit</label
              >
              <div
                class="col-12 col-sm-6 col-lg-6 d-flex align-items-center justify-content-between"
              >
                <input
                  class="form-control"
                  type="number"
                  style="width: 88px"
                  readonly
                  v-model.number="newItem.availableQuantity"
                  disabled
                />
                <p class="m-0">X</p>
                <p
                  class="form-control m-0"
                  style="width: 88px; background-color: #F5F5F1; cursor: default"
                >
                  &#8358;{{ formatMoney(newItem.productPrice) }}
                </p>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-5 col-form-label text-sm font-weight-bold">
                Quantity of products to be transferred</label
              >
              <div class="col-12 col-sm-6 col-lg-6">
                <input
                  :class="['form-control', { 'is-invalid': v2.quantity || v2.quantityError }]"
                  type="number"
                  v-model.number="newItem.quantity"
                  :disabled="isQuantityLoading"
                  @input="onQuantityChange($event)"
                />
                <ul v-if="v2.quantity" class="parsley-errors-list filled">
                  <li class="parsley-required">
                    Enter a number greater than 0
                  </li>
                </ul>
                <ul v-if="v2.quantityError" class="parsley-errors-list filled">
                  <li class="parsley-required">
                    Enter a number lower than available quantity
                  </li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-5 col-form-label text-sm font-weight-bold"
                >Transfer to:</label
              >
              <div class="col-12 col-sm-6 col-lg-6">
                <select
                  :class="['form-control', { 'is-invalid': v2.locationId }]"
                  placeholder="Select an option"
                  v-model="newItem.locationId"
                  @input="getSubLocationsById($event)"
                >
                  <option v-for="loc in Locations" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>

                <ul v-if="v2.locationId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-5 col-form-label text-sm font-weight-bold">
                Storage Area:</label
              >
              <div class="col-12 col-sm-6 col-lg-6">
                <select
                  :class="['form-control', { 'is-invalid': v2.subLocationId }]"
                  placeholder="Select an option"
                  v-model="newItem.subLocationId"
                  :disabled="!newItem.locationId"
                >
                  <option v-for="loc in filteredSubLocations" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>

                <ul v-if="v2.subLocationId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click.prevent="onAddCancel"
            >
              Cancel
            </button>
            <button
              style="width: 127px"
              :disabled="isBusyModal || isQuantityLoading"
              class="btn btn-primary"
              type="button"
              @click.prevent="add"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add Product</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="mod-confirm" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <h4 class="modal-title p-2">
            <strong>Confirm Stock Transfer</strong>
          </h4>
          <div class="modal-body p-2">
            <div class="row mb-2">
              <p class="col-12 col-sm-4 stocktext" style="font-weight: 500">Transfer from:</p>
              <p class="col-12 col-sm-8 col-lg-8 text-right stocktext font-weight-bolder">
                {{ locationName }}
              </p>
            </div>
            <div class="row mb-3">
              <p class="col-12 col-sm-4 stocktext" style="font-weight: 500">Transferred By:</p>
              <p class="col-12 col-sm-8 col-lg-8 text-right stocktext font-weight-bolder">
                {{ user.displayName }}
              </p>
            </div>
            <div class="row mb-2 stockRow" v-for="(item, index) in transferDetails" :key="index">
              <p class="col-12 col-sm-4 stocktext font-weight-bolder">{{ item.name }}</p>
              <p
                class="col-12 col-sm-8 col-lg-8 text-right"
                style="font-weight: 500; color: #29BF6A"
              >
                Stock value: &#8358;{{ formatMoney(item.quantity * item.price) }}
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              @click.prevent="cancel"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="makeTransfer"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Confirm</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
// import CautionModal from "../components/CautionModal";
import SystemPermissions from "@/permissions";
import Multiselect from "vue-multiselect";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsStockTransferCreate",

  components: {
    Select2,
    Multiselect,
  },

  data() {
    return {
      subLocationId: this.$route.params.id,
      locationName: "",
      Locations: [],
      SubLocations: [],
      filteredSubLocations: [],
      canShowCaution: false,
      isQuantityLoading: false,
      isBusy: false,
      isBusyModal: false,
      products: [],
      filteredProducts: [],
      transferDetails: [],
      total: 0.0,
      newItem: {},
      v2: {
        productId: false,
        quantity: false,
        quantityError: false,
        locationId: false,
        subLocationId: false,
      },
    };
  },

  async created() {
    if (!this.hasPermission("Stock_Transfer_Create")) {
      this.$router.push("/restricted");
    }

    const id = this.$route.params.id;

    this.getSubLocations();
    this.getLocations();

    if (id) {
      this.subLocationId = id;
      await this.initialize();
    }
  },

  // mounted() {
  //   // if (!this.$route.params.id) {
  //   let options = { backdrop: "static", keyboard: false };
  //   $("#mod-add").modal(options, "show");
  //   // }
  // },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  watch: {
    transferDetails: {
      handler(newVal, oldVal) {
        const reducer = (a, b) => a + b;
        let newTotal = this.transferDetails
          .map((el) => +el.price * +el.quantity)
          .reduce(reducer, 0);
        this.total = newTotal;
      },
      deep: true,
    },
  },

  methods: {
    async getLocations() {
      await this.$http
        .get("/Locations/getListNoPaging?active=true&parentId=" + this.user.parentId)
        .then(function(res) {
          this.Locations = res.body;
        })
        .catch(function() {});
    },

    goBack() {
      this.$router.push("/stocktransfer");
    },

    checkQuantity(product) {
      if (this.transferDetails.length < 1)
        return (this.newItem.availableQuantity = product.quantity);
      const reducer = (a, b) => a + b;
      const totalQuantity = this.transferDetails
        .map((el) => {
          if (el.id === product.productId) return el.quantity;
        })
        .reduce(reducer, 0);
      this.newItem.availableQuantity = product.quantity - totalQuantity;
    },

    async getSubLocations() {
      await this.$http
        .get(
          "/SubLocations/getListNoPaging?active=true&parentId=" +
            this.user.parentId +
            "&locationId="
        )
        .then(function(res) {
          this.SubLocations = res.body;
        })
        .then((res) => {
          const subLocation = this.SubLocations.find((el) => el.id === this.subLocationId);
          this.locationName = `${subLocation.location.name}, ${subLocation.name}`;
        })
        .catch(function() {});
    },

    async makeTransfer() {
      this.isBusyModal = true;
      const payload = {
        sourceSubLocationId: this.subLocationId,
        transfers: this.transferDetails.map((el) => {
          return {
            destinationSubLocationId: el.subLocationId,
            productId: el.id,
            quantity: el.quantity,
            inventoryId: el.inventoryId,
          };
        }),
      };

      try {
        const response = await this.$http.post("/Inventories/stockTransfer", payload);
        if (response.ok) {
          this.$toast.success("Products transferred successfully", {
            icon: true,
            rtl: false,
          });
          this.cancel();
          this.$router.push("/stocktransfer");
        }
      } catch (error) {}
      this.isBusyModal = false;
    },

    getSubLocationsById(e) {
      this.newItem.locationId = e.target.value;
      const res = this.SubLocations.filter(
        (el) => el.location.id === e.target.value && el.id !== this.subLocationId
      );
      this.filteredSubLocations = res;
    },

    deleteAll() {
      this.transferDetails = [];
    },

    cancel() {
      $("#mod-confirm").modal("hide");
    },

    async initialize() {
      if (this.subLocationId) {
        this.isBusy = true;
        this.isBusyModal = true;

        await this.$http
          .get(`/SubLocations/${this.subLocationId}/products`)
          .then(function(res) {
            this.products = res.body;
          })
          .catch(function() {});

        this.isBusyModal = false;
        this.isBusy = false;

        this.viewAdd();
      }
    },

    viewAdd() {
      this.newItem = {
        productIndex: null,
        quantity: null,
        availableQuantity: null,
        productPrice: null,
        productId: null,
      };

      this.v2 = {
        productId: false,
        quantity: false,
        quantityError: false,
        locationId: false,
        subLocationId: false,
      };

      var fProducts = [];

      var details = this.transferDetails;

      this.products.forEach(function(e) {
        if (!details.some((s) => s.inventoryId == e.inventoryId)) {
          fProducts.push(e);
        }
      });

      this.filteredProducts = fProducts;

      $("#mod-add").modal("show");
    },

    confirmTransfer() {
      $("#mod-confirm").modal("show");
    },
    add() {
      if (this.validateAdd()) {
        const newLocation = this.Locations.find((el) => el.id === this.newItem.locationId);
        const newSubLocation = this.SubLocations.find((el) => el.id === this.newItem.subLocationId);
        const p = {
          name: this.filteredProducts[this.newItem.productIndex].name,
          quantity: this.newItem.quantity,
          price: this.filteredProducts[this.newItem.productIndex].price,
          location: `${newLocation.name}, ${newSubLocation.name}`,
          id: this.newItem.productId,
          locationId: this.newItem.locationId,
          subLocationId: this.newItem.subLocationId,
          inventoryId: this.newItem.inventoryId,
        };
        this.transferDetails.push(p);
        $("#mod-add").modal("hide");
        this.canShowCaution = false;
      }
    },

    validateAdd() {
      this.v2 = {
        productId: false,
        quantity: false,
        quantityError: false,
        locationId: false,
        subLocationId: false,
      };

      this.transferDetails.push(p);

      $("#mod-add").modal("hide");
      this.canShowCaution = false;
    },

    validateAdd() {
      this.v2 = {
        productId: false,
        quantity: false,
        quantityError: false,
        locationId: false,
        subLocationId: false,
      };
      var isValid = true;

      if (this.newItem.productIndex < 0) {
        this.v2.productId = true;
        isValid = false;
      }

      if (Number(this.newItem.quantity) <= 0) {
        this.v2.quantity = true;
        isValid = false;
      }

      if (Number(this.newItem.quantity) > this.newItem.availableQuantity) {
        this.v2.quantityError = true;
        isValid = false;
      }

      if (!this.newItem.locationId) {
        this.v2.locationId = true;
        isValid = false;
      }

      if (!this.newItem.subLocationId) {
        this.v2.subLocationId = true;
        isValid = false;
      }

      return isValid;
    },

    remove(index) {
      this.transferDetails.splice(index, 1);
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    async onProductSelect(event) {
      this.newItem.productIndex = event.target.value;
      this.isQuantityLoading = true;
      const product = this.filteredProducts[event.target.value];
      if (product) {
        this.checkQuantity(product);
        this.newItem.productPrice = product.price;
        this.newItem.productId = product.productId;
        this.newItem.inventoryId = product.inventoryId;
      }
      this.isQuantityLoading = false;
    },

    onQuantityChange(event) {
      const product = this.filteredProducts[this.newItem.productIndex];

      this.canShowCaution =
        this.newItem.availableQuantity - event.target.value <= product.minimumStockLevel;
    },

    onAddCancel() {
      this.canShowCaution = false;
    },
  },
};
</script>

<style scoped>
.stock-warehouse-name {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #373737;
  margin: 0;
  text-transform: uppercase;
}

.stocktext {
  color: #373737;
  margin: 0;
}

.stockRow {
  border-bottom: 1px solid rgba(248, 248, 248, 1);
  padding-bottom: 16px;
}

.stockRow:last-of-type {
  margin-bottom: 0 !important;
  border: none;
}
</style>
