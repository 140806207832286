<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <h4 class="p-1">
            <b>{{ account.name }}</b>
          </h4>
          <div class="btn-group">
            <button
              v-if="!account.active"
              class="btn btn-success"
              :disabled="sendingInvite"
              @click.prevent="resendInvite"
            >
              <span
                v-if="sendingInvite"
                class="spinner-border spinner-border-sm"
                role="status"
              >
                <span class="sr-only">Loading...</span>
              </span>
              <span v-else>Resend Invite</span>
            </button>

            <router-link
              class="btn btn-space btn-primary btn-rounded float-right"
              to="/sales-user-distributors"
            >
              <span class="icon icon-left mdi mdi-arrow-left text-white"></span>
              Back
            </router-link>
          </div>
        </div>
        <div class="card-body">
          <h4>System</h4>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Code:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.code }}
            </div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Status:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              <span class="badge badge-pill badge-secondary">
                {{ account.active ? "Active" : "Inactive" }}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Created:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ formatDate(account.created) }}
            </div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Last Updated:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ formatDate(account.updated) }}
            </div>
          </div>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Primary Email:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.emailAddress }}
            </div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Source ID:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.sourceId }}
            </div>
          </div>

          <hr />

          <h4>Business profile</h4>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Business Name:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.name }}
            </div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Parent:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.parent ? account.parent.name : "N/A" }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Business Description:</label
            >
            <div class="col-12 col-sm-9 col-lg-9 col-form-label text-sm-left">
              {{ account.description ? account.description : "Not Available" }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >RC Number:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.rcNumber ? account.rcNumber : "Not Available" }}
            </div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Tax Identification Number:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.tin }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Website:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.website }}
            </div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Business Email Address:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.emailAddress }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Business Phone Number:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.phoneNumber }}
            </div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Business Address:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.address ? account.address : "Not Available" }}
            </div>
          </div>

          <hr />

          <h4>Contact information</h4>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Contact Person's Name:</label
            >
            <div class="col-12 col-sm-8 col-lg-6 col-form-label text-sm-left">
              {{
                account.contact.name ? account.contact.name : "Not Available"
              }}
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Email Address:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{
                account.contact.emailAddress
                  ? account.contact.emailAddress
                  : "Not Available"
              }}
            </div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Phone Number:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{
                account.contact.phoneNumber
                  ? account.contact.phoneNumber
                  : "Not Available"
              }}
            </div>
          </div>

          <hr />

          <h4>Bank information</h4>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Bank:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.bank.name ? account.bank.name : "Not Available" }}
            </div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Account Number:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{ account.bank.accountNumber }}
            </div>
          </div>

          <hr />

          <h4>Others</h4>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Associated Manufacturers:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{
                manufacturers.length
                  ? manufacturers.map((e) => e.parentName).join(", ")
                  : "Not Available"
              }}
            </div>

            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Discounts:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              {{
                account.discount
                  ? `${account.discount.name} - ${
                      account.discount.type != "Percent" ? "&#8358;" : ""
                    } ${formatMoney(account.discount.value)}${
                      account.discount.type == "Percent" ? "%" : ""
                    }`
                  : "Not Available"
              }}
            </div>
          </div>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Groups:</label
            >
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                {{
                account.groups && account.groups.length
                  ? account.groups.map((e) => e.name).join(", ")
                  : "Not Available"
                }}
            </div>
          </div>
        </div>
        <div
          class="modal fade colored-header colored-header-primary"
          id="mod-edit-source-id"
          role="dialog"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header modal-header-colored">
                <h4 class="modal-title p-1"><b>Update Source ID</b></h4>
                <button
                  class="close md-close"
                  type="button"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  <span class="mdi mdi-close"></span>
                </button>
              </div>
              <div class="modal-body">
                <div class="form-group row">
                  <div class="col-12 col-sm-12 col-lg-12">
                    <label
                      class="
                        col-12 col-sm-12
                        text-sm-center
                        alert alert-primary
                      "
                    >
                      Please ensure it is a unique ID.
                    </label>
                  </div>
                </div>

                <div class="form-group row" v-if="!v_edit_source_id.is_valid">
                  <div class="col-12 col-sm-12 col-lg-12">
                    <label
                      class="col-12 col-sm-12 text-sm-center alert alert-danger"
                      >{{ v_edit_source_id.text }}</label
                    >
                  </div>
                </div>

                <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"
                    >Source ID</label
                  >
                  <div class="col-12 col-sm-8 col-lg-8">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': v_edit_source_id.sourceId },
                      ]"
                      type="text"
                      v-model="editSourceId.sourceId"
                    />
                    <ul
                      v-if="v_edit_source_id.sourceId"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Enter a valid source ID.</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  style="width: 100px"
                  class="btn btn-secondary"
                  type="button"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  style="width: 130px"
                  :disabled="isBusyModal"
                  class="btn btn-primary"
                  type="button"
                  @click.prevent="postEditSourceId"
                >
                  <span v-if="isBusyModal">Busy...</span>
                  <span v-else>Update</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit-admin-details"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Admin Details</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Email Address</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.email }]"
                  type="text"
                  v-model="updateAdminDetails.email"
                />
                <ul v-if="v1.email" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";

export default {
  name: "distributorDetails",
  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      manufacturers: {},
      account: {
        parent: {},
        contact: {},
        bank: {},
        groups: [],
        discount: {},
      },
      sendingInvite: false,
      editSourceId: {
        sourceId: null,
      },
      v_edit_source_id: {
        sourceId: 0,
        is_valid: true,
        text: "",
      },
      firstAdminUser: {},
      updateAdminDetails: {
        email: null,
        id: null,
      },
      v1: {
        email: false,
      },
    };
  },
  created() {
    this.getMerchant();
    //this.getDistributorUnregisteredAdmin();
  },
  computed: {
    backPath: function () {
      return this.$store.state.prevRoute;
    },
  },
  methods: {
    viewEditAdminDetails() {
      this.updateAdminDetails = {
        email: this.firstAdminUser.emailAddress,
        id: this.firstAdminUser.id,
      };
      $("#mod-edit-admin-details").modal("show");
    },
    viewEditSourceId() {
      this.v_edit_source_id = {
        sourceId: false,
        is_valid: true,
        text: "",
      };

      this.editSourceId = {
        sourceId: this.account.sourceId,
      };

      $("#mod-edit-source-id").modal("show");
    },
    async getMerchant() {
      this.isBusy = true;
      let id = this.$route.params.id;

      await this.$http
        .get("/Accounts/getById/" + id)
        .then(function (res) {
          this.account = res.body;
          this.getParents(id);
        })
        .catch(function () {});

      this.isBusy = false;
    },
    async getParents(id) {
      await this.$http
        .get("/Accounts/getParents/" + id)
        .then(function (res) {
          this.manufacturers = res.body;
        })
        .catch(function () {});
    },
    formatDate(date) {
      return date ? moment(date).format("ll") : "";
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    async postEditSourceId() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateEditSourceId()) {
        $("#mod-edit-source-id").modal("hide");

        var data = {
          id: this.account.id,
          sourceId: this.editSourceId.sourceId,
        };

        await this.$http
          .put("/Accounts/updateSourceId", data)
          .then(function (res) {
            this.isBusyModal = false;
            window.location.reload();
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    async getDistributorUnregisteredAdmin() {
      let id = this.$route.params.id;
      await this.$http
        .get("/Users/getDistributorUnregisteredAdmin/" + id)
        .then(function (res) {
          if (res.statusCode === 200) this.firstAdminUser = res.body;
        })
        .catch(function () {});
    },

    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        emailAddress: this.updateAdminDetails.email,
        id: this.updateAdminDetails.id,
      };

      if (this.validateUpdate()) {
        await this.$http
          .put("/Users/updateDistributorFirstAdminEmail/" + data.id, data)
          .then(function (res) {
            $("#mod-edit-admin-details").modal("hide");
            this.isBusyModal = false;
            this.$toast.success("Update successful", {
              icon: false,
              rtl: false,
            });
            window.location.reload();
          })
          .catch(function () {});
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateUpdate() {
      this.v1 = {
        email: false,
      };
      var isValid = true;

      if (!this.updateAdminDetails.email) {
        this.v1.email = true;
        isValid = false;
      }

      return isValid;
    },

    async resendInvite() {
      this.isBusy = true;
      this.sendingInvite = true;

      await this.$http
        .post("/Accounts/resendInvite/" + this.account.id)
        .then(function (res) {
          this.isBusy = false;
          this.sendingInvite = false;
          if (res.ok) {
            this.$toast.success(res.body, {
              icon: false,
              rtl: false,
            });
          }
        })
        .catch(function () {
          this.isBusy = false;
          this.sendingInvite = false;
        });
    },
    validateEditSourceId() {
      this.v_edit_source_id = {
        sourceId: false,
        is_valid: true,
        text: "",
      };
      var isValid = true;

      if (!this.editSourceId.sourceId) {
        this.v_edit_source_id.sourceId = true;
        isValid = false;
      }

      return isValid;
    },
  },
};
</script>