<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-product-add"
    role="dialog"
  >
    <div class="modal-dialog">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong>{{ isEdit ? "Update Item" : "Add Item" }}</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="cancel"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Select product</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <select
                :disabled="isEdit"
                class="form-control"
                :class="{
                  'is-invalid': $v.newItem.productId.$error,
                }"
                v-model.trim="newItem.productId"
                @input="setProduct($event.target.value)"
                placeholder="Select a product"
              >
                <option v-for="p in preparedProducts" :key="p.id" :value="p.id">
                  {{ p.name }}
                </option>
              </select>
              <span
                class="invalid-feedback"
                v-if="!$v.newItem.productId.required"
              >
                Please select a product
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Quantity</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.newItem.quantity.$error,
                }"
                type="number"
                placeholder="Enter the quantity you needed"
                v-model.trim="newItem.quantity"
                @input="setQuantity($event.target.value)"
              />
              <span
                class="invalid-feedback"
                v-if="!$v.newItem.quantity.required"
              >
                Please enter a quantity
              </span>
              <span
                class="invalid-feedback"
                v-if="!$v.newItem.quantity.minValue"
              >
                Quantity must be greater than
                {{ $v.newItem.quantity.$params.minValue.min - 1 }}.
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Price</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                class="form-control"
                type="number"
                readonly
                v-model.trim="newItem.price"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-secondary"
            type="button"
            data-dismiss="modal"
            @click="cancel"
          >
            Cancel
          </button>
          <button class="btn btn-primary" type="submit">
            <span v-if="isBusyModal">Busy...</span>
            <span v-else-if="isEdit">Update Item</span>
            <span v-else>Add Item</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { minValue, required } from "vuelidate/lib/validators";
export default {
  name: "AddItem",
  props: ["edit", "groupId", "products", "filteredProducts"],

  data() {
    return {
      isBusyModal: false,
      manufacturerProducts: [],
      orderId: "",
      newItem: {
        productId: "",
        quantity: 0,
        price: 0,
      },
    };
  },

  computed: {
    isEdit: function () {
      return this.edit !== null;
    },

    user: function () {
      return this.$store.state.currentUser;
    },

    preparedProducts() {
      if (this.isEdit) {
        return this.products;
      } else {
        return this.filteredProducts;
      }
    },
  },

  validations: {
    newItem: {
      productId: {
        required,
      },

      quantity: {
        required,
        minValue: minValue(1),
      },
    },
  },

  watch: {
    edit: function (value) {
      if (value) {
        this.setQuantity(value.quantity);
        this.setProduct(value.productId);
      }
    },
  },

  created() {
    this.orderId = this.$route.params.id;
  },

  methods: {
    setQuantity(value) {
      this.newItem.quantity = value;
      this.$v.newItem.quantity.$touch();
    },

    setProduct(value) {
      this.newItem.productId = value;
      this.$v.newItem.productId.$touch();
      this.getDistributorPrice(value);
    },

    async getDistributorPrice(id) {
      this.priceLoading = true;
      try {
        if (this.groupId) {
          const response = await this.$http.get(
            `/Groups/price/${this.groupId}/${id}`
          );

          if (response.status === 200)
            this.newItem.price = response.body.distributorPrice;

          if (this.newItem.price === 0) this.getProductPrice(id);
        } else {
          this.getProductPrice(id);
        }

        this.priceLoading = false;
      } catch (error) {
        this.priceLoading = false;
      }
    },

    getProductPrice(id) {
      const product = this.products.find((p) => p.id === id);
      this.newItem.price = product.distributorPrice;
    },

    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isEdit
          ? this.$emit("onItemUpdated", { ...this.newItem })
          : this.$emit("onItemAdded", { ...this.newItem });
        this.reset();
      }
    },

    reset() {
      this.newItem.productId = "";
      this.newItem.quantity = 0;
      this.newItem.price = 0;
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },

    cancel() {
      this.reset();
      this.$emit("cancel");
    },
  },
};
</script>