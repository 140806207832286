/* eslint-disable */

const helper = {
  hasPermission(permissionList, permission) {
    return permissionList.includes(permission);
  },

  validateEmail(email) {
    let regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regEx.test(String(email).toLowerCase());
  },

  validateUrl(url) {
    let regEx = /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/;
    return regEx.test(String(url).toLowerCase());
  },

  validatePhone(phone) {
    let regEx = /^[0]\d{10}$|[\+]?[234]\d{12}$/;
    return regEx.test(String(phone).toLowerCase());
  },

  validatePIN(pin) {
    let regEx = /^\d{4}$/;
    return regEx.test(String(pin).toLowerCase());
  },

  validateNuban(acct) {
    let regEx = /^\d{10}$/;
    return regEx.test(String(acct).toLowerCase());
  },

  validateLength(string, len) {
    return string.length >= len ? true : false;
  },

  encodeString(string) {
    return btoa(string);
  },

  decodeString(encodedString) {
    try {
      return atob(encodedString);
    } catch (err) {
      return null;
    }
  },

  getRandomString() {
    return (
      Math.random()
        .toString(36)
        .substring(2, 15) +
      Math.random()
        .toString(36)
        .substring(2, 15)
    );
  },

  formatMoney(val) {
    if (!val) {
      return "0.00";
    }
    let parts = val.toString().split(".");
    let num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (parts[1] ? "." + parts[1] : ".00");

    return num;
  },

  formatNumber(val) {
    if (!val) {
      return "0";
    }
    let parts = val.toString().split(".");
    let num = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    return num;
  },

  formatDateString(date) {
    if (!date) {
      return "";
    }
    var date = new Date(date);
    let strdate = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
    return strdate;
  },

  getBanks() {
    return [
      "Access Bank",
      "Citi Bank Nigeria Limited",
      "Coronation Merchant Bank",
      "Ecobank Nigeria",
      "FBNQuest Merchant Bank",
      "Fidelity Bank",
      "First Bank of Nigeria",
      "First City Monument Bank",
      "FSDH Merchant Bank",
      "Guaranty Trust Bank",
      "Heritage Banking Company Limited",
      "Jaiz Bank",
      "Keystone Bank Limited",
      "Nova Merchant Bank",
      "Polaris Bank",
      "Providus Bank Limited",
      "Stanbic IBTC Bank",
      "Standard Chartered",
      "Sterling Bank",
      "SunTrust Bank Nigeria Limited",
      "Union Bank of Nigeria",
      "United Bank for Africa",
      "Unity Bank",
      "Wema Bank",
      "Zenith Bank",
    ];
  },

  getBankCodes() {
    return [
      "044", //"Access Bank",
      "023", //"Citi Bank Nigeria Limited",
      "559", //"Coronation Merchant Bank",
      "050", //"Ecobank Nigeria",
      "560", //"FBNQuest Merchant Bank",
      "070", //"Fidelity Bank",
      "011", //"First Bank of Nigeria Limited",
      "214", //"First City Monument Bank",
      "501", //"FSDH Merchant Bank",
      "058", //"Guaranty Trust Bank",
      "030", //"Heritage Banking Company Limited",
      "301", //"Jaiz Bank",
      "082", //"Keystone Bank Limited",
      "561", //"Nova Merchant Bank",
      "076", //Polaris Bank,
      "101", //"Providus Bank Limited",
      "221", //"Stanbic IBTC Bank",
      "068", //"Standard Chartered",
      "232", //"Sterling Bank",
      "100", //"SunTrust Bank Nigeria Limited",
      "032", //"Union Bank of Nigeria",
      "033", //"United Bank for Africa",
      "215", //"Unity Bank",
      "035", //"Wema Bank",
      "057", //"Zenith Bank"
    ];
  },

  getStates() {
    return [
      "Abia",
      "Adamawa",
      "Akwa Ibom",
      "Anambra",
      "Bauchi",
      "Bayelsa",
      "Benue",
      "Borno",
      "Cross River",
      "Delta",
      "Ebonyi",
      "Edo",
      "Ekiti",
      "Enugu",
      "Gombe",
      "Imo",
      "Jigawa",
      "Kaduna",
      "Kano",
      "Katsina",
      "Kebbi",
      "Kogi",
      "Kwara",
      "Lagos",
      "Nasarawa",
      "Niger",
      "Ogun",
      "Ondo",
      "Osun",
      "Oyo",
      "Plateau",
      "Rivers",
      "Sokoto",
      "Taraba",
      "Yobe",
      "Zamfara",
      "FCT",
    ];
  },

  getLgas(state) {
    let lgas = [];

    switch (state) {
      case "Abia":
        lgas = [
          "Aba North",
          "Aba South",
          "Arochukwu",
          "Bende",
          "Ikwuano",
          "Isiala Ngwa North",
          "Isiala Ngwa South",
          "Isuikwuato",
          "Obi Ngwa",
          "Obi Ngwa",
          "Ohafia",
          "Osisioma",
          "Ugwunagbo",
          "Ukwa East",
          "Ukwa West",
          "Umu Nneochi",
          "Umuahia North",
          "Umuahia South",
        ];

        break;
      case "Adamawa":
        lgas = [
          "Demsa",
          "Fufure",
          "Ganye",
          "Gayuk",
          "Gombi",
          "Grie",
          "Hong",
          "Jada",
          "Lamurde",
          "Madagali",
          "Maiha",
          "Mayo Belwa",
          "Michika",
          "Mubi North",
          "Mubi South",
          "Numan",
          "Shelleng",
          "Song",
          "Toungo",
          "Yola North",
          "Yola South",
        ];

        break;

      case "Akwa Ibom":
        lgas = [
          "Abak",
          "Eastern Obolo",
          "Eket",
          "Esit Eket",
          "Essien Udim",
          "Etim Ekpo",
          "Etinan",
          "Ibeno",
          "Ibesikpo Asutan",
          "Ibiono-Ibom",
          "Ika",
          "Ikono",
          "Ikot Abasi",
          "Ikot Ekpene",
          "Ini",
          "Itu",
          "Mbo",
          "Mkpat-Enin",
          "Nsit-Atai",
          "Nsit-Ibom",
          "Nsit-Ubium",
          "Obot Akara",
          "Obot Akara",
          "Okobo",
          "Onna",
          "Oron",
          "Oruk Anam",
          "Udung-Uko",
          "Ukanafun",
          "Uruan",
          "Urue-Offong/Oruko",
          "Uyo",
        ];

        break;
      case "Anambra":
        lgas = [
          "Aguata",
          "Anambra East",
          "Anambra West",
          "Anaocha",
          "Awka North",
          "Awka South",
          "Ayamelum",
          "Dunukofia",
          "Ekwusigo",
          "Idemili North",
          "Idemili South",
          "Ihiala",
          "Njikoka",
          "Nnewi North",
          "Nnewi South",
          "Ogbaru",
          "Onitsha North",
          "Onitsha South",
          "Orumba North",
          "Orumba South",
          "Oyi",
        ];

        break;
      case "Bauchi":
        lgas = [
          "Alkaleri",
          "Bauchi",
          "Bogoro",
          "Damban",
          "Darazo",
          "Dass",
          "Gamawa",
          "Ganjuwa",
          "Giade",
          "Itas/Gadau",
          "Jama'are",
          "Katagum",
          "Kirfi",
          "Misau",
          "Ningi",
          "Shira",
          "Tafawa Balewa",
          "Toro",
          "Warji",
          "Zaki",
        ];

        break;
      case "Bayelsa":
        lgas = [
          "Brass",
          "Ekeremor",
          "Kolokuma/Opokuma",
          "Nembe",
          "Ogbia",
          "Sagbama",
          "Southern Ijaw",
          "Yenagoa",
        ];

        break;
      case "Benue":
        lgas = [
          "Ado",
          "Agatu",
          "Apa",
          "Buruku",
          "Gboko",
          "Guma",
          "Gwer East",
          "Gwer West",
          "Katsina-Ala",
          "Konshisha",
          "Kwande",
          "Logo",
          "Makurdi",
          "Obi",
          "Obi",
          "Ogbadibo",
          "Ohimini",
          "Oju",
          "Okpokwu",
          "Oturkpo",
          "Tarka",
          "Ukum",
          "Ushongo",
          "Vandeikya",
        ];

        break;
      case "Borno":
        lgas = [
          "Abadam",
          "Askira/Uba",
          "Bama",
          "Bayo",
          "Biu",
          "Chibok",
          "Damboa",
          "Dikwa",
          "Gubio",
          "Guzamala",
          "Gwoza",
          "Hawul",
          "Jere",
          "Kaga",
          "Kala/Balge",
          "Konduga",
          "Kukawa",
          "Kwaya Kusar",
          "Mafa",
          "Magumeri",
          "Maiduguri",
          "Marte",
          "Mobbar",
          "Monguno",
          "Ngala",
          "Nganzai",
          "Shani",
        ];

        break;
      case "Cross River":
        lgas = [
          "Abi",
          "Akamkpa",
          "Akpabuyo",
          "Bakassi",
          "Bekwarra",
          "Biase",
          "Boki",
          "Calabar Municipal",
          "Calabar South",
          "Etung",
          "Ikom",
          "Obanliku",
          "Obubra",
          "Obubra",
          "Obudu",
          "Odukpani",
          "Ogoja",
          "Yakuur",
          "Yala",
        ];

        break;
      case "Delta":
        lgas = [
          "Aniocha North",
          "Aniocha South",
          "Bomadi",
          "Burutu",
          "Ethiope East",
          "Ethiope West",
          "Ika North East",
          "Ika South",
          "Isoko North",
          "Isoko South",
          "Ndokwa East",
          "Ndokwa West",
          "Okpe",
          "Oshimili North",
          "Oshimili South",
          "Patani",
          "Sapele",
          "Udu",
          "Ughelli North",
          "Ughelli South",
          "Ukwuani",
          "Uvwie",
          "Warri North",
          "Warri South",
          "Warri South West",
        ];

        break;
      case "Ebonyi":
        lgas = [
          "Abakaliki",
          "Afikpo North",
          "Afikpo South (Edda)",
          "Ebonyi",
          "Ezza North",
          "Ezza South",
          "Ikwo",
          "Ishielu",
          "Ivo",
          "Izzi",
          "Ohaozara",
          "Ohaukwu",
          "Onicha",
        ];

        break;
      case "Edo":
        lgas = [
          "Akoko-Edo",
          "Benin",
          "Egor",
          "Esan Central",
          "Esan North-East",
          "Esan South-East",
          "Esan West",
          "Etsako Central",
          "Etsako East",
          "Etsako West",
          "Igueben",
          "Ikpoba Okha",
          "Oredo",
          "Orhionmwon",
          "Ovia North-East",
          "Ovia South-West",
          "Owan East",
          "Owan West",
          "Uhunmwonde",
        ];
        break;

      case "Ekiti":
        lgas = [
          "Ado Ekiti",
          "Efon",
          "Ekiti East",
          "Ekiti South-West",
          "Ekiti West",
          "Emure",
          "Gbonyin",
          "Ido Osi",
          "Ijero",
          "Ikere",
          "Ikole",
          "Ilejemeje",
          "Irepodun/Ifelodun",
          "Ise/Orun",
          "Moba",
          "Oye",
        ];

        break;
      case "Enugu":
        lgas = [
          "Aninri",
          "Awgu",
          "Enugu East",
          "Enugu North",
          "Enugu South",
          "Ezeagu",
          "Igbo Etiti",
          "Igbo Eze North",
          "Igbo Eze South",
          "Isi Uzo",
          "Nkanu East",
          "Nkanu West",
          "Nsukka",
          "Oji River",
          "Udenu",
          "Udi",
          "Uzo-Uwani",
        ];

        break;
      case "FCT":
        lgas = ["Abaji", "Bwari", "Gwagwalada", "Kuje", "Kwali", "Municipal Area Council"];

        break;
      case "Gombe":
        lgas = [
          "Akko",
          "Balanga",
          "Billiri",
          "Dukku",
          "Funakaye",
          "Gombe",
          "Kaltungo",
          "Kwami",
          "Nafada",
          "Shongom",
          "Yamaltu/Deba",
        ];

        break;
      case "Imo":
        lgas = [
          "Aboh Mbaise",
          "Ahiazu Mbaise",
          "Ehime Mbano",
          "Ezinihitte",
          "Ideato North",
          "Ideato South",
          "Ihitte/Uboma",
          "Ikeduru",
          "Isiala Mbano",
          "Isu",
          "Mbaitoli",
          "Ngor Okpala",
          "Njaba",
          "Nkwerre",
          "Nwangele",
          "Obowo",
          "Obowo",
          "Oguta",
          "Ohaji/Egbema",
          "Okigwe",
          "Orlu",
          "Orsu",
          "Oru East",
          "Oru West",
          "Owerri Municipal",
          "Owerri North",
          "Owerri West",
          "Unuimo",
        ];

        break;
      case "Jigawa":
        lgas = [
          "Auyo",
          "Babura",
          "Biriniwa",
          "Birnin Kudu",
          "Buji",
          "Dutse",
          "Gagarawa",
          "Garki",
          "Gumel",
          "Guri",
          "Gwaram",
          "Gwiwa",
          "Hadejia",
          "Jahun",
          "Kafin Hausa",
          "Kaugama",
          "Kazaure",
          "Kiri Kasama",
          "Kiyawa",
          "Maigatari",
          "Malam Madori",
          "Miga",
          "Ringim",
          "Roni",
          "Sule Tankarkar",
          "Taura",
          "Yankwashi",
        ];

        break;
      case "Kaduna":
        lgas = [
          "Birnin Gwari",
          "Chikun",
          "Giwa",
          "Igabi",
          "Ikara",
          "Jaba",
          "Jema'a",
          "Kachia",
          "Kaduna North",
          "Kaduna South",
          "Kagarko",
          "Kajuru",
          "Kaura",
          "Kauru",
          "Kubau",
          "Kudan",
          "Lere",
          "Makarfi",
          "Sabon Gari",
          "Sanga",
          "Soba",
          "Zangon Kataf",
          "Zaria",
        ];

        break;
      case "Kano":
        lgas = [
          "Ajingi",
          "Albasu",
          "Bagwai",
          "Bebeji",
          "Bichi",
          "Bunkure",
          "Dala",
          "Dambatta",
          "Dawakin Kudu",
          "Dawakin Tofa",
          "Doguwa",
          "Fagge",
          "Gabasawa",
          "Garko",
          "Garun Mallam",
          "Gaya",
          "Gezawa",
          "Gwale",
          "Gwarzo",
          "Kabo",
          "Kano Municipal",
          "Karaye",
          "Kibiya",
          "Kiru",
          "Kumbotso",
          "Kunchi",
          "Kura",
          "Madobi",
          "Makoda",
          "Minjibir",
          "Nasarawa",
          "Rano",
          "Rimin Gado",
          "Rogo",
          "Shanono",
          "Sumaila",
          "Takai",
          "Tarauni",
          "Tofa",
          "Tsanyawa",
          "Tudun Wada",
          "Ungogo",
          "Warawa",
          "Wudil",
        ];

        break;
      case "Katsina":
        lgas = [
          "Bakori",
          "Batagarawa",
          "Batsari",
          "Baure",
          "Bindawa",
          "Charanchi",
          "Dan Musa",
          "Dandume",
          "Danja",
          "Daura",
          "Dutsi",
          "Dutsin Ma",
          "Faskari",
          "Funtua",
          "Ingawa",
          "Jibia",
          "Kafur",
          "Kaita",
          "Kankara",
          "Kankia",
          "Katsina",
          "Kurfi",
          "Kusada",
          "Mai'Adua",
          "Malumfashi",
          "Mani",
          "Mashi",
          "Matazu",
          "Musawa",
          "Rimi",
          "Sabuwa",
          "Safana",
          "Sandamu",
          "Zango",
        ];

        break;
      case "Kebbi":
        lgas = [
          "Aleiro",
          "Arewa Dandi",
          "Argungu",
          "Augie",
          "Bagudo",
          "Birnin Kebbi",
          "Bunza",
          "Dandi",
          "Fakai",
          "Gwandu",
          "Jega",
          "Kalgo",
          "Koko/Besse",
          "Maiyama",
          "Ngaski",
          "Sakaba",
          "Shanga",
          "Suru",
          "Wasagu/Danko",
          "Yauri",
          "Zuru",
        ];

        break;
      case "Kogi":
        lgas = [
          "Adavi",
          "Ajaokuta",
          "Ankpa",
          "Bassa",
          "Dekina",
          "Ibaji",
          "Idah",
          "Igalamela Odolu",
          "Ijumu",
          "Kabba/Bunu",
          "Kogi",
          "Lokoja",
          "Mopa Muro",
          "Ofu",
          "Ogori/Magongo",
          "Okehi",
          "Okene",
          "Olamaboro",
          "Omala",
          "Yagba East",
          "Yagba West",
        ];

        break;
      case "Kwara":
        lgas = [
          "Asa",
          "Baruten",
          "Edu",
          "Ekiti",
          "Ifelodun",
          "Ilorin East",
          "Ilorin South",
          "Ilorin West",
          "Irepodun",
          "Isin",
          "Kaiama",
          "Moro",
          "Offa",
          "Oke Ero",
          "Oyun",
          "Pategi",
        ];

        break;
      case "Lagos":
        lgas = [
          "Agege",
          "Ajeromi-Ifelodun",
          "Alimosho",
          "Amuwo-Odofin",
          "Apapa",
          "Badagry",
          "Epe",
          "Eti Osa",
          "Ibeju-Lekki",
          "Ifako-Ijaiye",
          "Ikeja",
          "Ikorodu",
          "Kosofe",
          "Lagos Island",
          "Lagos Mainland",
          "Mushin",
          "Ojo",
          "Oshodi-Isolo",
          "Shomolu",
          "Surulere",
        ];

        break;
      case "Nasarawa":
        lgas = [
          "Akwanga",
          "Awe",
          "Doma",
          "Karu",
          "Keana",
          "Keffi",
          "Kokona",
          "Lafia",
          "Nasarawa",
          "Nasarawa Egon",
          "Obi",
          "Obi",
          "Toto",
          "Wamba",
        ];

        break;
      case "Niger":
        lgas = [
          "Agaie",
          "Agwara",
          "Bida",
          "Borgu",
          "Bosso",
          "Chanchaga",
          "Edati",
          "Gbako",
          "Gurara",
          "Katcha",
          "Kontagora",
          "Lapai",
          "Lavun",
          "Magama",
          "Mariga",
          "Mashegu",
          "Mokwa",
          "Moya",
          "Paikoro",
          "Rafi",
          "Rijau",
          "Shiroro",
          "Suleja",
          "Tafa",
          "Wushishi",
        ];

        break;
      case "Ogun":
        lgas = [
          "Abeokuta North",
          "Abeokuta South",
          "Ado-Odo/Ota",
          "Ewekoro",
          "Ifo",
          "Ijebu East",
          "Ijebu North",
          "Ijebu North East",
          "Ijebu Ode",
          "Ikenne",
          "Imeko Afon",
          "Ipokia",
          "Obafemi Owode",
          "Odeda",
          "Odogbolu",
          "Ogun Waterside",
          "Remo North",
          "Shagamu",
          "Yewa North",
          "Yewa South",
        ];

        break;
      case "Ondo":
        lgas = [
          "Akoko North-East",
          "Akoko North-West",
          "Akoko South-East",
          "Akoko South-West",
          "Akure North",
          "Akure South",
          "Ese Odo",
          "Idanre",
          "Ifedore",
          "Ilaje",
          "Ile Oluji/Okeigbo",
          "Irele",
          "Odigbo",
          "Okitipupa",
          "Ondo East",
          "Ondo West",
          "Ose",
          "Owo",
        ];

        break;
      case "Osun":
        lgas = [
          "Aiyedaade",
          "Aiyedire",
          "Atakunmosa East",
          "Atakunmosa West",
          "Boluwaduro",
          "Boripe",
          "Ede North",
          "Ede South",
          "Egbedore",
          "Ejigbo",
          "Ife Central",
          "Ife East",
          "Ife North",
          "Ife South",
          "Ifedayo",
          "Ifelodun",
          "Ila",
          "Ilesa East",
          "Ilesa West",
          "Irepodun",
          "Irewole",
          "Isokan",
          "Iwo",
          "Obokun",
          "Obokun",
          "Odo Otin",
          "Ola Oluwa",
          "Olorunda",
          "Oriade",
          "Orolu",
          "Osogbo",
        ];

        break;
      case "Oyo":
        lgas = [
          "Afijio",
          "Akinyele",
          "Atiba",
          "Atisbo",
          "Egbeda",
          "Ibadan North",
          "Ibadan North-East",
          "Ibadan North-West",
          "Ibadan South-East",
          "Ibadan South-West",
          "Ibarapa Central",
          "Ibarapa East",
          "Ibarapa North",
          "Ido",
          "Irepo",
          "Iseyin",
          "Itesiwaju",
          "Iwajowa",
          "Kajola",
          "Lagelu",
          "Ogbomosho North",
          "Ogbomosho South",
          "Ogo Oluwa",
          "Olorunsogo",
          "Oluyole",
          "Ona Ara",
          "Orelope",
          "Ori Ire",
          "Oyo East",
          "Oyo West",
          "Saki East",
          "Saki West",
          "Surulere",
        ];

        break;
      case "Plateau":
        lgas = [
          "Barkin Ladi",
          "Bassa",
          "Bokkos",
          "Jos East",
          "Jos North",
          "Jos South",
          "Kanam",
          "Kanke",
          "Langtang North",
          "Langtang South",
          "Mangu",
          "Mikang",
          "Pankshin",
          "Qua'an Pan",
          "Riyom",
          "Shendam",
          "Wase",
        ];

        break;
      case "Rivers":
        lgas = [
          "Abua/Odual",
          "Ahoada East",
          "Ahoada West",
          "Akuku-Toru",
          "Andoni",
          "Asari-Toru",
          "Bonny",
          "Degema",
          "Eleme",
          "Emuoha",
          "Etche",
          "Gokana",
          "Ikwerre",
          "Khana",
          "Obio/Akpor",
          "Obio/Akpor",
          "Ogba/Egbema/Ndoni",
          "Ogu/Bolo",
          "Okrika",
          "Omuma",
          "Opobo/Nkoro",
          "Oyigbo",
          "Port Harcourt",
          "Tai",
        ];

        break;
      case "Sokoto":
        lgas = [
          "Binji",
          "Bodinga",
          "Dange Shuni",
          "Gada",
          "Goronyo",
          "Gudu",
          "Gwadabawa",
          "Illela",
          "Isa",
          "Kebbe",
          "Kware",
          "Rabah",
          "Sabon Birni",
          "Shagari",
          "Silame",
          "Sokoto North",
          "Sokoto South",
          "Tambuwal",
          "Tangaza",
          "Tureta",
          "Wamako",
          "Wurno",
          "Yabo",
        ];

        break;
      case "Taraba":
        lgas = [
          "Ardo Kola",
          "Bali",
          "Donga",
          "Gashaka",
          "Gassol",
          "Ibi",
          "Jalingo",
          "Karim Lamido",
          "Kumi",
          "Lau",
          "Sardauna",
          "Takum",
          "Ussa",
          "Wukari",
          "Yorro",
          "Zing",
        ];

        break;
      case "Yobe":
        lgas = [
          "Bade",
          "Bursari",
          "Damaturu",
          "Fika",
          "Fune",
          "Geidam",
          "Gujba",
          "Gulani",
          "Jakusko",
          "Karasuwa",
          "Machina",
          "Nangere",
          "Nguru",
          "Potiskum",
          "Tarmuwa",
          "Yunusari",
          "Yusufari",
        ];

        break;
      case "Zamfara":
        lgas = [
          "Anka",
          "Bakura",
          "Birnin Magaji/Kiyaw",
          "Bukkuyum",
          "Bungudu",
          "Chafe",
          "Gummi",
          "Gusau",
          "Kaura Namoda",
          "Maradun",
          "Maru",
          "Shinkafi",
          "Talata Mafara",
          "Zurmi",
        ];

        break;
      default:
        break;
    }

    return lgas;
  },

  getBankCode(bankName) {
    var index = this.getBanks().indexOf(bankName);
    return this.getBankCodes()[index];
  },
};

export { helper };
