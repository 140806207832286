<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <span class="feather icon-filter text-white"></span> Filter
          </button>
          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
            v-if="hasPermission('Inventory_SubLocation_Create')"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> New Storage Area
          </button>
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    text-nowrap
                    table-striped table-hover-animation
                  "
                >
                  <thead>
                    <tr>
                      <th style="width: 20%">Name</th>
                      <th style="width: 20%">Storage Manager</th>
                      <th style="width: 20%">Linked Warehouse</th>
                      <th style="width: 10%">Status</th>
                      <th style="width: 20%">Created</th>
                      <th style="width: 10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l in SubLocations" :key="l.id">
                      <td>
                        <span v-if="l.name">{{ l.name }}</span>
                        <span v-if="l.code">({{ l.code }})</span>
                      </td>
                      <td>
                        {{ l.manager ? l.manager.displayName : "N/A" }}
                      </td>
                      <td>
                        {{ l.location.name }}
                      </td>
                      <td>
                        <span
                          :class="[
                            'badge',
                            ' badge-pill',
                            { 'badge-success': l.active },
                            { 'badge-danger': !l.active },
                          ]"
                        >
                          {{ l.active ? "Active" : "Inactive" }}
                        </span>
                      </td>
                      <td>{{ formatDate(l.created) }}</td>
                      <td>
                        <button
                          class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div class="dropdown-menu px-1" aria-labelledby="dropdownMenu2">
                          <div v-if="hasPermission('Inventory_SubLocation_Update')">
                            <a
                              class="dropdown-item"
                              v-if="l.location.parentId == user.parentId"
                              @click.prevent="viewEdit(l)"
                            >
                              <i class="feather icon-edit"></i> &nbsp;Edit
                            </a>
                          </div>
                          <a
                            class="dropdown-item"
                            href="#"
                            v-if="hasPermission('Inventory_SubLocation_Delete')"
                            style="color: red"
                            @click.prevent="confirmDelete(l.id)"
                          >
                            Delete Storage Area
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!Locations.length">
                      <td colspan="6">
                        <div class="text-center" style="padding-top: 50px">
                          <span style="font-size: 4.615rem" class="mdi mdi-crosshairs-gps"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any storage areas.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} storage area{{ count != 1 ? "s" : "" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Create new storage area</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.name }]"
                  type="text"
                  v-model="createSubLocation.name"
                />
                <ul v-if="v1.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Warehouse</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="createSubLocation.locationId"
                >
                  <option v-for="loc in Locations" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>
                <ul v-if="v1.locationId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Storage Manager</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  :disabled="isBusy || isSameManager"
                  v-model="createSubLocation.managerId"
                >
                  <option v-for="p in warehouseManagers" :key="p.id" :value="p.id">
                    {{ p.displayName }}
                  </option>
                </select>
                <div class="col-12 col-sm-8 col-lg-10 p-0" v-if="checkManager">
                  <label
                    class="custom-control custom-checkbox custom-control-inline d-flex align-items-center"
                  >
                    <input
                      class="custom-control-input"
                      type="checkbox"
                      @change="sameManagerActive($event)"
                    />
                    <span class="custom-control-label">Same As Warehouse Manager</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input class="form-control" type="text" v-model="createSubLocation.code" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="createSubLocation.active"
                  />
                  <span class="custom-control-label">This storage area is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 180px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Add storage area</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-edit" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Storage Area</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="cancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Warehouse</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="updateSubLocation.locationId"
                >
                  <option v-for="loc in Locations" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>
                <ul v-if="v2.locationId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Storage Manager</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  :disabled="isBusy || isSameManager || updateSubLocation.isChecked"
                  v-model="updateSubLocation.managerId"
                >
                  <option v-for="p in warehouseManagers" :key="p.id" :value="p.id">
                    {{ p.displayName }}
                  </option>
                </select>
                <div class="col-12 col-sm-8 col-lg-10 p-0" v-if="checkUpdateManager">
                  <label
                    class="custom-control custom-checkbox custom-control-inline d-flex align-items-center"
                  >
                    <input
                      class="custom-control-input"
                      type="checkbox"
                      v-model="updateSubLocation.isChecked"
                      @change="sameUpdateManagerActive($event)"
                    />
                    <span class="custom-control-label">Same As Warehouse Manager</span>
                  </label>
                </div>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Code</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input class="form-control" type="text" v-model="updateSubLocation.code" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v2.name }]"
                  type="text"
                  v-model="updateSubLocation.name"
                />
                <ul v-if="v2.name" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Active</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="updateSubLocation.active"
                  />
                  <span class="custom-control-label">This storage area is active</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="cancel"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Filter</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Warehouse</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.locationId"
                >
                  <option value="0">All Warehouses</option>
                  <option v-for="loc in Locations" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select class="form-control" placeholder="Select an option" v-model="filter.status">
                  <option value="0">All Storage areas</option>
                  <option value="True">Active</option>
                  <option value="False">Disabled</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Other filters</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by code or name..."
                  v-model="filter.qry"
                />
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input class="custom-control-input" type="checkbox" v-model="filter.showForMe" />
                  <span class="custom-control-label">My Storage Areas Only</span>
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getSubLocations"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <GeneralDeleteCaution
      :id="DeleteId"
      @proceed="deleteStorageArea"
      :isBusy="isBusyModal"
      text="storage area"
    />
    <GeneralDeleteMessage
      message="You cannot delete this storage area because there is an inventory 
      or reconcile order transaction history associated with it."
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
import Pagination from "../components/Pagination.vue";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsSubLocations",

  components: {
    Select2,
    GeneralDeleteCaution,
    GeneralDeleteMessage,
    Pagination,
  },

  created() {
    if (!this.hasPermission("Inventory_SubLocation_View")) {
      this.$router.push("/restricted");
    }
    this.getSubLocations();
    this.getLocations();
    this.getWarehouseManagers();
  },

  watch: {},

  data() {
    return {
      filter: {
        status: "0",
        locationId: "0",
        qry: "",
        showForMe: false,
      },
      isSameManager: false,
      isBusy: false,
      warehouseManagers: [],
      isBusyModal: false,
      locationManagerId: "",

      createSubLocation: {
        code: "",
        name: "",
        active: "",
        locationId: "",
        managerId: "",
      },

      updateSubLocation: {
        code: "",
        name: "",
        active: true,
        id: "",
        locationId: "",
        isChecked: false,
        managerId: "",
      },

      editSubLocation: {
        code: "",
        name: "",
        active: true,
        id: "",
        locationId: "",
        managerId: "",
      },

      v1: {
        name: false,
        locationId: false,
      },

      v2: {
        name: false,
        locationId: false,
      },

      SubLocations: [],
      Locations: [],
      DeleteId: "",
      count: 0,
      page: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
    checkManager() {
      if (this.createSubLocation.locationId) {
        const filterManager = this.Locations.find(
          (el) => el.id === this.createSubLocation.locationId
        );
        this.locationManagerId = filterManager.managerId;
        return filterManager.managerId;
      }
    },
    checkUpdateManager() {
      if (this.updateSubLocation.locationId) {
        const filterManager = this.Locations.find(
          (el) => el.id === this.updateSubLocation.locationId
        );
        this.locationManagerId = filterManager.managerId;
        return filterManager.managerId;
      }
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        locationId: "0",
        qry: "",
        showForMe: false,
      };
    },

    confirmDelete(id) {
      this.DeleteId = id;
      $("#mod-confirm-delete").modal("show");
    },

    showDeleteMsg() {
      $("#mod-delete-msg").modal("show");
    },

    cancelConfirm() {
      this.DeleteId = "";
      $("#mod-confirm-delete").modal("hide");
    },
    async deleteStorageArea() {
      this.isBusyModal = true;
      try {
        const res = await this.$http.delete(`/SubLocations/delete/${this.DeleteId}`);
        if (res.ok) {
          this.$toast.success("Storage area deleted successfully", {
            icon: true,
            rtl: false,
          });
          this.getSubLocations();
          this.cancelConfirm();
        }
      } catch (error) {
        this.cancelConfirm();
        this.showDeleteMsg();
      }
      this.isBusyModal = false;
    },

    sameManagerActive(e) {
      if (e.target.checked) {
        this.isSameManager = true;
        this.createSubLocation.managerId = this.locationManagerId;
      } else {
        this.isSameManager = false;
      }
    },

    sameUpdateManagerActive(e) {
      if (e.target.checked) {
        this.isSameManager = true;
        this.updateSubLocation.managerId = this.locationManagerId;
      } else {
        this.isSameManager = false;
      }
    },
    async getWarehouseManagers() {
      this.isBusy = true;

      await this.$http
        .get("/Users/getWarehouseManager/")
        .then(function(res) {
          this.warehouseManagers = res.body;
          this.isBusy = false;
        })
        .catch(function() {});
      this.isBusy = false;
    },

    cancel() {
      const currentSubLocation = this.SubLocations.find((l) => l.id === this.editSubLocation.id);
      currentSubLocation.name = this.editSubLocation.name;
      currentSubLocation.code = this.editSubLocation.code;
      currentSubLocation.managerId = this.editSubLocation.managerId;
      currentSubLocation.active = this.editSubLocation.active;
      currentSubLocation.id = this.editSubLocation.id;
      currentSubLocation.locationId = this.editSubLocation.locationId;
      this.isSameManager = false;
      // this.locationManagerId = "";
      $("#mod-edit").modal("hide");
    },
    viewExport() {
      //   $("#mod-export").modal("show");
    },
    viewAdd() {
      this.createSubLocation = {
        code: "",
        name: "",
        active: false,
        locationId: "",
        managerId: "",
      };
      this.v1 = {
        name: false,
        locationId: false,
      };
      $("#mod-add").modal("show");
    },
    viewEdit(c) {
      var editValues = {
        code: c.code,
        name: c.name,
        active: c.active,
        id: c.id,
        locationId: c.locationId,
        managerId: c.managerId,
      };
      this.editSubLocation = editValues;
      this.updateSubLocation = c;
      $("#mod-edit").modal("show");
    },
    viewFilter() {
      $("#mod-filter").modal("show");
    },
    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        code: this.createSubLocation.code,
        name: this.createSubLocation.name,
        active: this.createSubLocation.active,
        parentId: this.user.parentId,
        locationId: this.createSubLocation.locationId,
        managerId: this.createSubLocation.managerId,
      };

      if (this.validateCreate()) {
        await this.$http
          .post("/SubLocations/create", data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});
        $("#mod-add").modal("hide");

        this.getSubLocations();
      }
      this.isSameManager = false;
      this.createSubLocation.managerId = "";
      this.isBusy = false;
      this.isBusyModal = false;
    },
    validateCreate() {
      this.v1 = {
        name: false,
        locationId: false,
      };
      var isValid = true;

      if (!this.createSubLocation.name) {
        this.v1.name = true;
        isValid = false;
      }

      if (!this.createSubLocation.locationId) {
        this.v1.categoryId = true;
        isValid = false;
      }

      return isValid;
    },
    async update() {
      this.isBusyModal = true;
      this.isBusy = true;

      var data = {
        code: this.updateSubLocation.code,
        name: this.updateSubLocation.name,
        active: this.updateSubLocation.active,
        id: this.updateSubLocation.id,
        locationId: this.updateSubLocation.locationId,
        parentId: this.user.parentId,
        managerId: this.updateSubLocation.managerId,
      };

      if (this.validateUpdate()) {
        $("#mod-edit").modal("hide");

        await this.$http
          .put("/SubLocations/update/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
          })
          .catch(function() {});

        this.getSubLocations();
      }
      this.isSameManager = false;
      this.updateSubLocation.managerId = "";
      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateUpdate() {
      this.v2 = {
        name: false,
        locationId: false,
      };

      var isValid = true;

      if (!this.updateSubLocation.name) {
        this.v2.name = true;
        isValid = false;
      }

      if (!this.updateSubLocation.locationId) {
        this.v2.locationId = true;
        isValid = false;
      }

      return isValid;
    },

    async getSubLocations() {
      this.isBusy = true;

      await this.$http
        .get(
          "/SubLocations/getList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&active=" + this.filter.status : "") +
            (this.filter.locationId != "0" ? "&locationId=" + this.filter.locationId : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.filter.showForMe != "" ? "&showForMe=" + this.filter.showForMe : "")
        )
        .then(function(res) {
          this.SubLocations = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
    getLocations() {
      var t = "Location";
      var a = true;

      this.$http
        .get("/Locations/getListNoPaging?active=" + a + "&parentId=" + this.user.parentId)
        .then(function(res) {
          this.Locations = res.body;
        })
        .catch(function() {});
    },
    formatDate(date) {
      return moment(date).format("lll");
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    prev() {
      this.page -= 1;
      this.getSubLocations();
    },
    next() {
      this.page += 1;
      this.getSubLocations();
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getSubLocations();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getSubLocations();
    },
  },
};
</script>
