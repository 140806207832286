<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <b> {{ campaignName }} Members</b>
          <div class="btn-group pull-right">
            <button
              class="btn btn-success btn-rounded btn-space float-right"
              @click="viewAdd"
            >
              <span class="icon icon-left mdi mdi-plus text-white"></span> Add
              member
            </button>

            <router-link
              class="btn btn-space btn-primary btn-rounded float-right"
              to="/campaigns"
            >
              <span class="icon icon-left mdi mdi-arrow-left text-white"></span>
              Back
            </router-link>
          </div>
        </div>

        <div class="table-responsive">
          <table
            class="
              table table-fw-widget
              dataTable
              no-footer
              table-striped table-hover-animation
              mt-1
            "
          >
            <thead>
              <tr>
                <th style="width: 55%">Name</th>
                <th style="width: 20%">Member Type</th>
                <th style="width: 15%">Joined</th>
                <th style="width: 10%"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="m in members" :key="m.id">
                <td>{{ m.memberName }}</td>
                <td>{{ m.type }}</td>
                <td>{{ formatDate(m.joined) }}</td>
                <td>
                  <div class="text-right">
                    <button
                      class="btn btn-danger btn-sm btn-rounded btn-space"
                      @click.prevent="remove(m.id)"
                    >
                      <i class="fa fa-times"></i> &nbsp;Remove
                    </button>
                  </div>
                </td>
              </tr>
              <tr v-if="!members.length">
                <td colspan="4">
                  <div class="text-center">No members</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>

      <add-new-member
        @onMemberAddComplete="onMemberAddCompleteHandler"
        :campaignId="campaignId"
      />
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";
import AddNewMember from "../components/Campaian/AddNewMember.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "campaignsMembers",

  components: {
    Select2,
    AddNewMember,
  },

  created() {
    if (!this.hasPermission("Campaign_View_Members")) {
      this.$router.push("/restricted");
    }
    this.campaignId = this.$route.params.id;
    this.getCampaign();
    this.getMembers();
  },

  data() {
    return {
      campaignId: null,
      campaignName: "",
      isBusy: false,
      isBusyModal: false,
      members: [],
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    viewAdd() {
      $("#mod-add").modal("show");
    },

    remove(id) {
      this.isBusy = true;

      this.$http
        .delete("/Campaigns/deleteMember/" + id)
        .then(function (res) {
          this.isBusy = false;
          this.getMembers();
        })
        .catch(function () {
          this.isBusy = false;
        });
    },

    getCampaign() {
      this.isBusy = true;
      this.$http
        .get("/Campaigns/getById/" + this.campaignId)
        .then(function (res) {
          this.campaignName = res.body.name;
          this.isBusy = false;
        })
        .catch(function () {
          this.isBusy = false;
        });
    },

    getMembers() {
      this.isBusy = true;

      this.$http
        .get("/Campaigns/getMembers/" + this.campaignId)
        .then(function (res) {
          this.members = res.body;
          this.isBusy = false;
        })
        .catch(function () {
          this.isBusy = false;
        });
    },

    onMemberAddCompleteHandler() {
      $("#mod-add").modal("hide");
      this.getMembers();
    },

    formatDate(date) {
      return moment(date).format("ll");
    },
  },
};
</script>