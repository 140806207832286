<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Invite new users</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="reset"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Email address</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                :class="[
                  'form-control',

                  { 'is-invalid': $v.invitedUser.emailAddress.$error },
                ]"
                type="email"
                placeholder="Email address of user"
                autocomplete="off"
                v-model.trim="$v.invitedUser.emailAddress.$model"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.emailAddress.required"
              >
                Field is required.
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.emailAddress.email"
              >
                Email is invalid.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Role</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <select
                :class="[
                  'form-control',

                  { 'is-invalid': $v.invitedUser.role.$error },
                ]"
                placeholder="Select an option"
                v-model="invitedUser.role"
              >
                <option v-for="r in roles" :key="r.id" :value="r.text">
                  {{ r.text }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.role.required"
              >
                Field is required.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Staff Id</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                class="form-control"
                type="text"
                placeholder="Staff Id of user"
                v-model="invitedUser.staffId"
              />
            </div>
          </div>
          <div class="form-group row" v-if="invitedUser.role == 'Sales'">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Sales Access Id</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                :class="[
                  'form-control',

                  { 'is-invalid': $v.invitedUser.salesAccessId.$error },
                ]"
                type="text"
                placeholder="Sales Access Id of user"
                v-model.trim="$v.invitedUser.salesAccessId.$model"
              />
              <div
                class="invalid-feedback"
                v-if="
                  invitedUser.role === 'Sales' &&
                    !$v.invitedUser.salesAccessId.required
                "
              >
                Field is required.
              </div>
            </div>
          </div>
          <div class="form-group row" v-if="user.type != 'Manufacturer'">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Branch</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <select
                :class="[
                  'form-control',

                  { 'is-invalid': $v.invitedUser.branch.$error },
                ]"
                placeholder="Select an option"
                v-model.trim="$v.invitedUser.branch.$model"
              >
                <option v-for="br in branches" :key="br.id" :value="br.id">
                  {{ br.name }}
                </option>
              </select>
              <div
                class="invalid-feedback"
                v-if="!$v.invitedUser.branch.required"
              >
                Field is required.
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-secondary"
            type="button"
            data-dismiss="modal"
            @click="reset"
          >
            Cancel
          </button>
          <button
            style="width: 100px"
            :disabled="isInviting"
            class="btn btn-primary"
            type="button"
            @click.prevent="invite"
          >
            <span
              v-if="isInviting"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else>Invite</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, email } from "vuelidate/lib/validators";

export default {
  name: "InviteUser",
  props: ["roles", "branches"],

  data() {
    return {
      isInviting: false,
      invitedUser: {
        type: "email",
        emailAddress: "",
        role: "",
        branch: "",
        staffId: "",
        salesAccessId: "",
      },
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  validations() {
    let invitedUser = {
      emailAddress: { required, email },
      role: { required },
    };

    if (this.user.type != "Manufacturer") {
      invitedUser = { ...invitedUser, branch: { required } };
    }

    if (this.invitedUser.role === "Sales") {
      return {
        invitedUser: { ...invitedUser, salesAccessId: { required } },
      };
    } else {
      return {
        invitedUser: invitedUser,
      };
    }
  },

  methods: {
    async invite() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isInviting = true;

        let data = {
          emailAddress: this.invitedUser.emailAddress,
          identifierType: this.invitedUser.type,
          userType: this.user.type,
          name: this.invitedUser.name,
          role: this.invitedUser.role,
          parentId: this.user.parentId,
          deliveryAddress: "N/A",
          branchId: this.invitedUser.branch,
          staffId: this.invitedUser.staffId,
          salesAccessId: this.invitedUser.salesAccessId,
          createdBy: this.user.displayName,
        };

        try {
          const response = await this.$http.post("/Users/invite", data);
          if (response.ok) {
            this.$toast.success("User invited successfully", {
              icon: true,
              rtl: false,
            });
            this.reset();
            this.$emit("onInviteComplete");
          }
        } catch (error) {}
        this.isInviting = false;
      }
    },

    reset() {
      this.invitedUser = {
        type: "email",
        emailAddress: "",
        role: "",
        branch: "",
        staffId: "",
        salesAccessId: "",
      };

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },
  },
};
</script>

<style></style>
