<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <div class="col-3 col-md-2">
            <input
              class="form-control mr-sm-2"
              type="text"
              v-model="search"
              placeholder="Search"
              aria-label="Search"
              @input="getNotifications"
            />
          </div>
          <div class="col-3 col-md-2">
            <select
              class="form-control"
              placeholder="Select an option"
              @change="onFilter($event.target.value)"
            >
              <option value=""></option>
              <option value="true">Read</option>
              <option value="false">UnRead</option>
            </select>
          </div>
        </div>
        <div class="card-body d-flex flex-column align-items-center">
          <div class="col-sm-12 col-md-10">
            <NotificationCard
              v-for="n in filteredNotifications"
              :key="n.id"
              :notification="n"
              @onDeleteComplete="getNotifications"
              @onCardClicked="submitRead"
            />
          </div>
          <div class="mt-5">
            <nav aria-label="Page navigation example">
              <ul class="pagination">
                <li class="page-item" :class="[{ disabled: !hasPrev }]">
                  <a class="page-link" href="#" aria-label="Previous">
                    <span aria-hidden="true">
                      <i class="mdi mdi-arrow-left" />
                    </span>
                  </a>
                </li>
                <li class="page-item" :class="[{ disabled: !hasNext }]">
                  <a class="page-link" href="#" aria-label="Next">
                    <span aria-hidden="true"><i class="mdi mdi-arrow-right"/></span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import NotificationCard from "../components/NotificationCard.vue";
export default {
  components: { NotificationCard },

  data() {
    return {
      search: "",
      isBusy: false,
      notifications: [],
      filteredNotifications: [],
      status: "",
      pageSize: 25,
      pageNumber: 0,
      hasNext: false,
      hasPrev: false,
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  mounted() {
    this.getNotifications();
  },

  methods: {
    async getNotifications() {
      try {
        this.isBusy = true;
        const response = await this.$http.get(
          `/Notification?Search=${this.search}&Read=${this.status}&PageNumber=${this.pageNumber}&PageSize=${this.pageSize}`
        );

        if (response.ok) {
          this.filteredNotifications = this.notifications = response.body.data;
          this.pageNumber = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
        }

        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    async submitRead(id) {
      // console.log(id);
      const response = await this.$http.post("/notification/read/" + id);
      if (response.body) {
        this.getNotifications();
      }
    },

    async onFilter(value) {
      this.status = value;
      this.getNotifications();
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    prev() {
      this.pageNumber -= 1;
      this.getNotifications();
    },

    next() {
      this.pageNumber += 1;
      this.getNotifications();
    },
  },
};
</script>

<style>
.card-body {
  margin-top: 20px;
  background-color: #f8f8f8;
}
</style>
