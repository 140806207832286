<template>
  <div class="onboarding-body">
    <div class="onboarding-card">
      <img src="/assets/img/logo-xx.png" alt="logo" width="175" />
      <p class="onboarding-header">Forgot Password</p>
      <p class="onboarding-desc">Please enter your registered email to reset your password.</p>
      <form @submit.prevent="forgotPassword">
        <div>
          <label class="merchant-login-label mt-2"> EMAIL ADDRESS* </label>
          <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.email }]">
            <input type="text" placeholder="Enter email address" v-model="email" />
          </div>
          <p v-if="credentialValidation.email">
            <img src="/assets/img/error-icon-new.svg" alt="error" />
            <span class="login-error">Email cannot be empty</span>
          </p>
          <p v-if="credentialValidation.emailValid">
            <img src="/assets/img/error-icon-new.svg" alt="error" />
            <span class="login-error">Input a valid email</span>
          </p>
        </div>

        <button type="submit" class="login-submit-btn" :disabled="isBusy">
          <span v-if="isBusy" class="spinner-border spinner-border-sm" role="status">
            <i class="sr-only"></i>
          </span>
          <span v-else>
            Recover Password
          </span>
        </button>
      </form>
    </div>
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-growtrade-forgotPassword-modal"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1">
              <strong>Check your mailbox</strong>
            </h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="cancel"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="parent-div success-div">
                <svg width="88" height="87" viewBox="0 0 88 87" fill="none">
                  <path
                    d="M73.9055 40.1262L82.9726 34.5691C82.8912 34.3678 82.7958 34.1725 82.6871 33.9846C82.3304 33.3513 81.8495 32.7964 81.2733 32.3533L73.9055 26.7663L54.7655 12.2346L47.289 6.56596C46.3411 5.85137 45.1863 5.46484 43.9993 5.46484C42.8122 5.46484 41.6575 5.85137 40.7096 6.56596L33.233 12.2346L14.093 26.7663L6.72523 32.3533C6.14906 32.7964 5.66822 33.3513 5.31148 33.9846C5.07851 34.3688 4.90439 34.7858 4.79492 35.2216L43.3114 58.5892L73.9055 40.1262Z"
                    fill="#3C3480"
                  />
                  <path
                    d="M73.9062 26.7661V14.9531C73.9062 14.2321 73.6198 13.5405 73.1099 13.0307C72.6001 12.5208 71.9086 12.2344 71.1875 12.2344H16.8125C16.0914 12.2344 15.3999 12.5208 14.8901 13.0307C14.3802 13.5405 14.0938 14.2321 14.0938 14.9531V40.8615L43.3122 58.5891L73.9062 40.126V26.7661Z"
                    fill="white"
                  />
                  <path d="M43.3125 58.5898L81.4103 81.7019L43.3125 58.5898Z" fill="#3C3480" />
                  <path d="M73.9062 40.1274L82.9733 34.5703L73.9062 40.1274Z" fill="#3C3480" />
                  <path
                    d="M73.9085 40.1274L43.3145 58.5905L81.4123 81.6998C82.0427 81.1937 82.5509 80.552 82.8992 79.8224C83.2475 79.0929 83.427 78.2942 83.4242 77.4858V36.6909C83.4278 35.9601 83.2748 35.2371 82.9756 34.5703L73.9085 40.1274Z"
                    fill="#5234AA"
                  />
                  <path
                    d="M14.0939 40.8641L4.79578 35.2227C4.64761 35.6978 4.57423 36.1931 4.57828 36.6908V77.4856C4.57828 78.9277 5.15116 80.3108 6.17089 81.3305C7.19062 82.3502 8.57367 82.9231 10.0158 82.9231H77.9845C79.233 82.9207 80.4426 82.4887 81.4102 81.6997L14.0939 40.8627V40.8641Z"
                    fill="#675CE8"
                  />
                  <path
                    d="M82.9747 34.5703L73.9077 40.1274V66.6108C73.9083 68.176 73.57 69.7228 72.916 71.1448C72.262 72.5668 71.3079 73.8304 70.1191 74.8486L81.4115 81.6998C82.0419 81.1937 82.5501 80.552 82.8984 79.8224C83.2467 79.0929 83.4261 78.2942 83.4233 77.4858V36.6909C83.4269 35.9601 83.274 35.2371 82.9747 34.5703Z"
                    fill="#3E3881"
                  />
                  <path
                    d="M63.0312 77.4848H4.57812C4.57813 78.927 5.151 80.31 6.17073 81.3297C7.19046 82.3495 8.57351 82.9223 10.0156 82.9223H77.9844C79.2328 82.9199 80.4424 82.4879 81.41 81.6989L70.1177 74.8477C68.1493 76.55 65.6336 77.4862 63.0312 77.4848Z"
                    fill="#675CE8"
                  />
                  <rect x="19.5508" y="25" width="33.4746" height="3" rx="1.5" fill="#E1E1E1" />
                  <rect x="19.5" y="32" width="38.9121" height="3" rx="1.5" fill="#E1E1E1" />
                  <rect x="19.5" y="39" width="43.1602" height="3" rx="1.5" fill="#E1E1E1" />
                </svg>
              </div>
            </div>
            <p class="text-center text-bold">
              We have sent you a reset password link <br />
              to your email address.
            </p>
          </div>
          <div class="modal-footer">
            <button
              class="resend-button"
              :disabled="isBusy"
              @click.prevent="resendVerification"
              type="button"
            >
              <span v-if="isBusy" class="spinner-border spinner-border-sm" role="status">
                <i class="sr-only"></i>
              </span>
              <span v-else>
                Resend email verification
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { helper } from "@/helper";

export default {
  name: "distributor-forgot-password",
  components: {},

  data() {
    return {
      email: null,
      credentialValidation: {
        email: false,
        emailValid: false,
      },
      isBusy: false,
    };
  },

  mounted() {},

  beforeCreate() {},

  created() {},

  computed: {
    // user: function() {
    //   return this.$store.state.currentUser;
    // },
  },

  methods: {
    cancel() {
      $("#mod-growtrade-forgotPassword-modal").modal("hide");
    },
    async forgotPassword() {
      this.isBusy = true;

      if (this.validateUser()) {
        this.isBusy = true;

        await this.$http
          .get("/Users/resetPassword/" + this.email)
          .then(function(res) {
            $("#mod-growtrade-forgotPassword-modal").modal("show");

            // this.$router.push("/login");
          })
          .catch(function() {
            this.isBusy = false;
          });

        this.isBusy = false;
      } else {
        this.isBusy = false;
      }
    },

    async resendVerification() {
      this.isBusy = true;

      await this.$http
        .get("/Users/resetPassword/" + this.email)
        .then(function(res) {
          this.isBusy = false;
          // this.$router.push("/login");
        })
        .catch(function() {
          this.isBusy = false;
        });
    },

    validateUser() {
      this.credentialValidation = {
        email: false,
        emailValid: false,
      };

      let isValid = true;

      if (!this.email) {
        this.credentialValidation.email = true;
        isValid = false;
      }

      if (this.email && !helper.validateEmail(this.email)) {
        this.credentialValidation.emailValid = true;
        isValid = false;
      }

      return isValid;
    },
  },
};
</script>

<style scoped>
.modal-footer {
  justify-content: center !important;
}

.resend-button {
  border: none;
  outline: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #38369a;
  background: transparent;
}

.onboarding-body {
  position: relative;
  background-color: #f6f6f6;
  min-width: 100vw;
  min-height: 100vh;
  padding: 48px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-card {
  width: 524px;
  background-color: #fff;
  padding: 48px;
}

.onboarding-header {
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #373737;
  margin: 32px 0 8px;
}

.onboarding-desc {
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: rgba(24, 25, 31, 0.5);
  padding-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 24px;
}

.merchant-login-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #18191f;
  padding: 0;
  margin-bottom: 16px;
}

.merchant-input-div {
  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
}

.merchant-input-div:focus-within {
  border: 1px solid #18191f;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}

.togglePassword {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  line-height: 17px;
  color: #38369a;
}
input {
  border: none;
  outline: none;
  width: 100%;
}

.login-submit-btn {
  background: #675ce8;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  outline: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin: 32px 0 8px;
  border: none;
}

.signup-btn {
  border: none;
  font-size: 18px;
  font-weight: 700;
  padding: 0;
  line-height: 28px;
  outline: none;
  background: transparent;
  color: #001688;
}

.signup-text {
  font-size: 18px;
  color: rgba(24, 25, 31, 0.5);
  margin-bottom: 60px;
}

.onboarding-helper {
  font-size: 14px;
  line-height: 142.52%;
  color: rgba(24, 25, 31, 0.7);
  margin-top: 8px;
}

.onboarding-helper span {
  font-weight: 700;
}

.is-invalid {
  border: 1px solid #ff6767;
}

.login-error {
  font-size: 12px;
  line-height: 15px;
  color: #ff6767;
  margin-left: 5px;
}

.parent-div {
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 32px auto 34px;
  width: 110px;
  height: 110px;
}

.text-bold {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
@media screen and (max-width: 767px) {
  .merchant-input-div,
  .login-submit-btn {
    width: 100%;
  }
}
</style>
