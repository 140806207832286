<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-add-group"
    role="dialog"
  >
    <div class="modal-dialog">
      <form class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <b>Add Groups To Distributor</b>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Search for group</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <multiselect
                v-model="value"
                label="name"
                track-by="name"
                :options="groups"
                @select="onSelect"
              ></multiselect>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Group Association</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <div>
                <b>Distributor Price (DP) Association</b>
                <span
                  v-if="priceOptionLoading"
                  class="spinner-border text-success spinner-border-sm"
                  role="status"
                >
                  <i class="sr-only">Loading...</i>
                </span>
                <ul v-else class="list-group list-group-flush">
                  <li
                    v-for="g in prices.distributorPrices"
                    :key="g.id"
                    class="list-group-item"
                  >
                    {{ g.productName + " / " + g.amount }}
                  </li>
                </ul>
              </div>
              <div class="mt-2">
                <b> Recommended Retail Price (RRP) Association </b>
                <span
                  v-if="priceOptionLoading"
                  class="spinner-border text-success spinner-border-sm"
                  role="status"
                >
                  <i class="sr-only">Loading...</i>
                </span>

                <ul v-else class="list-group list-group-flush">
                  <li
                    v-for="g in prices.retailPrices"
                    :key="g.id"
                    class="list-group-item"
                  >
                    {{ g.productName + " / " + g.amount }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            style="width: 100px"
            class="btn btn-danger"
            type="button"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            :disabled="isBusyModal"
            class="btn btn-primary"
            @click.prevent="submit"
            type="submit"
          >
            <span v-if="isBusyModal">Busy...</span>
            <span v-else>Link Group</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Multiselect from "vue-multiselect";
export default {
  name: "AddDistributorGroup",

  components: { Multiselect },

  props: ["distributorId"],

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      value: "",
      groupId: "",
      groups: [],
      groupPriceOptions: [],
      priceOptionLoading: false,
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },

    prices: function () {
      const distributorPrices = [];
      const retailPrices = [];

      this.groupPriceOptions.forEach((element) => {
        retailPrices.push({
          id: element.id,
          productName: element.product.name,
          amount: element.priceOption.retailPrice,
        });
        distributorPrices.push({
          id: element.id,
          productName: element.product.name,
          amount: element.priceOption.distributorPrice,
        });
      });
      return { distributorPrices, retailPrices };
    },
  },

  mounted() {
    this.getGroups();
  },

  methods: {
    async getGroups() {
      this.isBusy = true;
      this.isBusyModal = true;
      try {
        const response = await this.$http.get(
          "/Groups/getListNoPaging?active=true"
        );

        if (response.ok) this.groups = response.body;

        this.isBusy = false;
        this.isBusyModal = false;
      } catch (error) {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    onSelect(option) {
      this.groupId = option.id;
      this.getgroupPriceOptions(option.id);
    },

    async getgroupPriceOptions(id) {
      this.isBusy = true;
      this.priceOptionLoading = true;

      try {
        const response = await this.$http.get(
          "/Groups/getPriceOptionsNoPaging/" + id
        );

        if (response.ok && response.status === 200) {
          this.groupPriceOptions = response.body;
        }

        this.isBusy = false;
        this.priceOptionLoading = false;
      } catch (error) {
        this.isBusy = false;
        this.priceOptionLoading = false;
      }
    },

    async submit() {
      this.isBusy = true;
      this.isBusyModal = true;

      const data = {
        type: "Distributor",
        groupId: this.groupId,
        MemberId: this.$route.params.id,
      };

      try {
        const response = await this.$http.post("/Groups/addMember", data);
        if (response.ok) if (response.ok) this.$emit("hide");

        this.isBusy = false;
        this.isBusyModal = false;
      } catch (error) {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },
  },
};
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>