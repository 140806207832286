<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div
          :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            ' card-table',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]"
        >
          <div class="card-header">
            <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
              <i class="feather icon-filter text-white"></i> Filters
            </button>
            <button
              v-if="hasPermission('Global_View_Customers')"
              class="btn btn-info btn-rounded"
              @click.prevent="viewGlobal"
            >
              <span>
                <i class="mdi mdi-earth text-white"></i>
                Switch to {{ isGlobal ? "Default" : "Global" }}
              </span>
            </button>
            <button
              class="btn btn-primary btn-rounded btn-space float-right"
              @click="viewAdd"
              v-if="hasPermission('Customer_Create')"
            >
              <i class="feather icon-user-plus"></i> &nbsp;New customer
            </button>
          </div>
          <div class="card-body">
            <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
              <div class="row be-datatable-body">
                <div class="col-sm-12 table-responsive">
                  <table
                    class="
                      table table-fw-widget
                      dataTable
                      no-footer
                      text-nowrap
                      table-striped table-hover-animation
                    "
                  >
                    <thead class="">
                      <tr>
                        <th style="width: 15%">Name</th>
                        <th style="width: 15%">Branch</th>
                        <th style="width: 10%">Type</th>
                        <th style="width: 10%">Account Balance</th>
                        <th style="width: 15%">Account Manager</th>
                        <th style="width: 15%">Created By</th>
                        <th style="width: 10%">Created</th>
                        <th style="width: 10%">Registration Status</th>
                        <th style="width: 25%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="u in salesReps" :key="u.id">
                        <td>
                          <span v-if="u.displayName">
                            <b>
                              <a href="#" @click.prevent="details(u.id)">
                                {{ u.displayName }}
                              </a>
                            </b>
                            <br />
                          </span>
                          {{ u.emailAddress ? u.emailAddress : u.phoneNumber }}
                        </td>
                        <td>{{ u.branch ? u.branch.name : "N/A" }}</td>
                        <td>{{ u.customerType }}</td>
                        <td>{{ formatMoney(u.balance) }}</td>
                        <td>
                          {{ u.introducer ? u.introducer.displayName : "N/A" }}
                        </td>
                        <td>
                          {{ u.createdBy ? u.createdBy : "N/A" }}
                        </td>
                        <td>{{ formatDate(u.created) }}</td>
                        <td>
                          <span
                            :class="[
                              'badge',
                              'badge-pill',
                              { 'badge-primary': u.status == 'Pending' },
                              { 'badge-success': u.status == 'Active' },
                              { 'badge-danger': u.status == 'Disabled' },
                            ]"
                          >
                            {{
                              u.status == "Pending"
                                ? "Invitation sent"
                                : u.status == "Active"
                                ? "Completed"
                                : u.status
                            }}
                          </span>
                        </td>
                        <td>
                          <button
                            class="
                              btn btn-outline-primary btn-sm
                              dropdown-toggle
                            "
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            Actions
                          </button>
                          <div class="dropdown-menu px-1" aria-labelledby="dropdownMenu2">
                            <a
                              class="dropdown-item"
                              href="#"
                              v-if="hasPermission('Customer_Enable_Disable')"
                              @click.prevent="viewRemove(u)"
                            >
                              {{ u.status == "Inactive" ? "Enable" : "Disable" }}
                            </a>
                            <a
                              class="dropdown-item"
                              href="#"
                              @click.prevent="viewResend(u)"
                              v-if="u.status == 'Pending' && hasPermission('Customer_Send_Invite')"
                            >
                              Resend Invite
                            </a>
                            <router-link
                              v-if="hasPermission('Orders_Create')"
                              class="dropdown-item"
                              :to="{
                                path: `orders/create/${u.id}`,
                              }"
                              ><i class="feather icon-shopping-cart"></i> &nbsp; Order</router-link
                            >
                            <a class="dropdown-item" href="#" @click.prevent="details(u.id)">
                              View Customer Details
                            </a>
                            <router-link
                              v-if="hasPermission('View_Customer_Account')"
                              class="dropdown-item"
                              :to="{
                                path: `customers/account/${u.id}`,
                              }"
                            >
                              View Customer Account</router-link
                            >
                            <a
                              class="dropdown-item"
                              href="#"
                              @click.prevent="viewGeoTagDetails(u.phoneNumber)"
                            >
                              View Geo-tag Info
                            </a>
                            <a
                              v-if="u.status == 'Pending' && hasPermission('Customer_Delete')"
                              class="dropdown-item"
                              href="#"
                              @click.prevent="confirmDelete(u.id)"
                              style="color: red"
                            >
                              Delete Customer
                            </a>
                          </div>
                        </td>
                      </tr>
                      <tr v-if="!salesReps.length">
                        <td colspan="8">
                          <div class="text-center" style="padding-top: 50px">
                            <span
                              style="font-size: 4.615rem"
                              class="mdi mdi-account-card-details"
                            ></span>
                          </div>
                          <h3 class="text-center" style="padding-bottom: 100px">
                            You don't have any customers.
                          </h3>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <Pagination
                    :limit="pageSize"
                    :count="pageCount"
                    :page="page"
                    :itemsCount="count"
                    @changePage="gotoPage"
                    @changeLimit="changeLimit"
                  />
                </div>
              </div>
              <div class="row be-datatable-footer">
                <div class="col-sm-5">
                  <div class="dataTables_info">{{ count }} customer{{ count != 1 ? "s" : "" }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="be-spinner">
            <svg width="40px" height="40px" viewBox="0 0 66 66">
              <circle
                class="circle"
                fill="none"
                stroke-width="4"
                stroke-linecap="round"
                cx="33"
                cy="33"
                r="30"
              ></circle>
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-geotag-details"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">
              Geo-Tag Information -
              {{ geoTagDetails ? geoTagDetails.storeName : "" }}
            </h3>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body" v-if="!geoTagDetails.storeName">
            <div class="form-group row">
              <div class="col-12 col-sm-12 col-lg-12 text-center">
                <span class="col-12 alert alert-primary"
                  ><i class="fa fa-spinner fa-spin"></i> Please wait...</span
                >
              </div>
            </div>
          </div>
          <div class="modal-body" v-else>
            <div class="form-group row">
              <div class="col-12 col-sm-12 col-lg-12" v-if="geoTagDetails.storeImageString">
                <img
                  style="
                    margin: auto;
                    display: block;
                    border: 2px solid #eee;
                    border-radius: 10px;
                    width: 200px;
                    height: auto;
                  "
                  v-bind:src="geoTagDetails.storeImageString"
                />
              </div>
              <div class="col-12 col-sm-12 col-lg-12" v-else>
                <img
                  style="
                    margin: auto;
                    display: block;
                    width: 100px;
                    height: auto;
                  "
                  src="/assets/img/avatar.png"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Store Name</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  disabled
                  v-model="geoTagDetails.storeName"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Longitude</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  disabled
                  v-model="geoTagDetails.longitude"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Latitude</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input class="form-control" type="text" disabled v-model="geoTagDetails.latitude" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Geo-Tag Date</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input class="form-control" type="text" disabled v-model="geoTagDetails.tagDate" />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Created By</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  disabled
                  v-model="geoTagDetails.createdBy"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-filter" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Status</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select2 placeholder="Select an option" v-model="filter.status">
                  <option value="0">All Customers</option>
                  <option value="active">Active</option>
                  <option value="inactive">Disabled</option>
                  <option value="pending">Others</option>
                </select2>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Customer Type</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select2 placeholder="Select an option" v-model="filter.customerType">
                  <option value="0">All Customers</option>
                  <option value="In-house">In-house</option>
                  <option value="Community">Community</option>
                </select2>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Other filters</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  class="form-control"
                  type="text"
                  placeholder="Filter by name, email or phone number..."
                  v-model="filter.qry"
                />
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Sort by</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select2 placeholder="Select an option" v-model="filter.sortColumn">
                  <option value="name">Name</option>
                  <option value="status">Registration Status</option>
                  <option value="customertype">Customer Type</option>
                </select2>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Sort Direction</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select2 placeholder="Select an option" v-model="filter.sortDirection">
                  <option value="asc">Ascending</option>
                  <option value="desc">Descending</option>
                </select2>
              </div>
            </div>

            <!--<div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                            <div class="col-12 col-sm-8 col-lg-8">
                                <label class="custom-control custom-checkbox custom-control-inline">
                                    <input class="custom-control-input"
                                           type="checkbox"
                                           v-model="filter.showForMe" />
                                    <span class="custom-control-label">My Customers Only</span>
                                </label>
                            </div>
                        </div>-->
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getCustomers"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>

    <invite-customer @onInviteComplete="onInviteCompleteHandler" />

    <div class="modal fade colored-header colored-header-primary" id="mod-delete" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3 class="p-1">Confirmation</h3>
              <p>
                Are you sure you want to
                {{ modalUser.status == "Inactive" ? "enable" : "disable" }}
                this customer:
                <strong> {{ modalUser.displayName || modalUser.emailAddress }} </strong>?
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 160px"
              :disabled="isBusyModal"
              class="btn btn-warning"
              type="button"
              @click.prevent="updateStatus"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-resend" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <h3 class="p-1">Resend Invite</h3>
              <p>
                You are about to resend an invite to this customer:
                <strong> {{ modalUser.displayName || modalUser.emailAddress }} </strong>?
              </p>
            </div>

            <div v-if="enableInviteOption" class="mt-2 form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <div
                  class="custom-control custom-radio"
                  :class="[{ 'is-invalid': v4.selectedOption }]"
                  v-for="option in inviteOptions"
                  :key="option.value"
                >
                  <input
                    type="radio"
                    :id="option.value"
                    name="customRadio"
                    class="custom-control-input"
                    :value="option.value"
                    v-model="selectedOption"
                  />
                  <label class="custom-control-label" :for="option.value">
                    {{ option.name }}
                  </label>
                </div>
                <ul v-if="v4.selectedOption" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer text-center">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 160px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="attemptResendInvite"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade colored-header colored-header-primary" id="mod-global" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <!-- <div class="text-warning"><span class="modal-main-icon mdi mdi-alert"></span></div> -->
              <h3 class="p-1">Confirmation</h3>
              <p>
                Do you want to switch to
                {{ isGlobal ? "Default" : "Global" }} View?
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 140px"
              :disabled="isBusyModal"
              class="btn btn-warning"
              type="button"
              @click.prevent="toggleGlobal"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-update-credit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Set credit limits for {{ updatingCustomer.displayName }}</h3>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">Credit Limit</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <label class="custom-control custom-checkbox custom-control-inline">
                  <input
                    class="custom-control-input"
                    type="checkbox"
                    v-model="updatingCustomer.hasCredit"
                  />
                  <span class="custom-control-label"
                    >This customer has access to credit facilities</span
                  >
                </label>
              </div>
            </div>
            <div class="form-group row" v-if="updatingCustomer.hasCredit">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v3.creditLimit }]"
                  type="number"
                  v-model="updatingCustomer.creditLimit"
                />
                <ul v-if="v3.creditLimit" class="parsley-errors-list filled">
                  <li class="parsley-required">Enter a valid number.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="updateCreditLimits"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <GeneralDeleteCaution
      :id="DeleteId"
      @proceed="deleteCustomer"
      :isBusy="isBusyModal"
      text="customer"
    />
    <GeneralDeleteMessage
      message='You cannot delete this customer because there is a payment or order transaction history associated with the customer. Consider disabling the customer instead"'
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import GeneralDeleteCaution from "../components/GeneralDeleteCaution.vue";
import GeneralDeleteMessage from "../components/GeneralDeleteMessage.vue";
import SystemPermissions from "@/permissions";
import InviteCustomer from "../components/User/InviteCustomer.vue";
import Pagination from "../components/Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "salesReps",
  components: {
    Select2,
    InviteCustomer,
    GeneralDeleteCaution,
    GeneralDeleteMessage,
    Pagination,
  },

  data() {
    return {
      filter: {
        status: "0",
        customerType: "0",
        qry: "",
        sortColumn: "name",
        sortDirection: "asc",
        showForMe: false,
      },

      modalUser: {
        emailAddress: null,
        displayName: null,
      },

      updatingCustomer: {},

      v3: {
        creditLimit: false,
      },

      v4: {
        selectedOption: false,
      },

      isBusy: false,
      isBusyModal: false,
      salesReps: [],
      geoTagDetails: {},
      count: 0,
      page: 0,
      isGlobal: false,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
      customerExists: false,
      inviteOptions: [
        { name: "SMS", value: "sms" },
        { name: "Email Address", value: "email" },
      ],
      selectedOption: "",
      DeleteId: "",
    };
  },

  created() {
    if (!this.hasPermission("Customer_View")) {
      this.$router.push("/restricted");
    }

    var user = this.$store.state.currentUser;
    var preferredView = this.$auth.getPreferredViewForCustomer(user.id);
    if (preferredView) {
      if (preferredView == "global") this.isGlobal = true;
      else if (preferredView == "default") this.isGlobal = false;
    }

    this.getCustomers();
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },

    enableInviteOption: function() {
      return this.modalUser.emailAddress && this.modalUser.phoneNumber;
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        customerType: "0",
        qry: "",
        sortColumn: "name",
        sortDirection: "asc",
        showForMe: false,
      };
    },

    confirmDelete(id) {
      this.DeleteId = id;
      $("#mod-confirm-delete").modal("show");
    },

    showDeleteMsg() {
      $("#mod-delete-msg").modal("show");
    },

    cancelConfirm() {
      this.DeleteId = "";
      $("#mod-confirm-delete").modal("hide");
    },

    async deleteCustomer() {
      this.isBusyModal = true;
      try {
        const res = await this.$http.delete(`/Users/deleteCustomer/${this.DeleteId}`);
        if (res.ok) {
          this.$toast.success("Customer deleted successfully", {
            icon: true,
            rtl: false,
          });
          this.getCustomers();
          this.cancelConfirm();
        }
      } catch (error) {
        this.cancelConfirm();
        this.showDeleteMsg();
      }
      this.isBusyModal = false;
    },

    viewAdd() {
      $("#mod-add").modal("show");
    },

    viewRemove(u) {
      this.modalUser = u;
      $("#mod-delete").modal("show");
    },

    viewResend(u) {
      this.modalUser = u;
      $("#mod-resend").modal("show");
    },

    viewCreditLimit(u) {
      this.updatingCustomer = u;
      $("#mod-update-credit").modal("show");
    },

    viewExport() {
      // $("#mod-export").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    onInviteCompleteHandler() {
      $("#mod-add").modal("hide");
      this.getCustomers();
    },

    async attemptResendInvite() {
      if (this.enableInviteOption && this.validateResendInvite()) this.resendInvite();

      if (!this.enableInviteOption) this.resendInvite();
    },

    onChangePage(pageOfItems) {
      // update page of items
      this.pageOfItems = pageOfItems;
    },

    async resendInvite() {
      this.isBusyModal = true;
      await this.$http
        .get("/Users/resendInvite/" + this.modalUser.id + "?type=" + this.selectedOption)
        .then(function(res) {
          this.isBusyModal = false;
          if (res.ok && res.body) {
            this.$toast.success("Invite sent successfully.", {
              icon: false,
              rtl: false,
            });
          }
        })
        .catch(function() {
          this.isBusyModal = false;
        });
      $("#mod-resend").modal("hide");
    },

    validateResendInvite() {
      this.v4 = {
        selectedOption: false,
      };

      let isValid = true;

      if (!this.selectedOption) {
        this.v4.selectedOption = true;
        isValid = false;
      }

      return isValid;
    },

    viewGlobal() {
      $("#mod-global").modal("show");
    },

    updateCreditLimits() {
      this.isBusyModal = true;

      if (this.validateCreditLimits()) {
        var data = {
          id: this.updatingCustomer.id,
          creditLimit: this.updatingCustomer.creditLimit,
          hasCredit: this.updatingCustomer.hasCredit,
        };

        $("#mod-update-credit").modal("hide");

        this.$http
          .put("/users/updateCreditLimits/" + data.id, data)
          .then(function(res) {
            this.isBusyModal = false;
            this.getMerchants();
          })
          .catch(function() {
            this.isBusyModal = false;
          });
      } else {
        this.isBusyModal = false;
      }
    },

    validateCreditLimits() {
      this.v3 = {
        creditLimit: false,
      };
      var isValid = true;

      if (Number(this.updatingCustomer.creditLimit) < 0) {
        this.v3.creditLimit = true;
        isValid = false;
      }

      return isValid;
    },

    updateStatus() {
      this.isBusyModal = true;
      let data = {
        id: this.modalUser.id,
        status:
          this.modalUser.status == "Inactive"
            ? this.modalUser.username
              ? "Active"
              : "Pending"
            : "Inactive",
      };

      $("#mod-delete").modal("hide");

      this.$http
        .put("/Users/updateStatus/" + data.id, data)
        .then(function(res) {
          this.isBusyModal = false;
          this.getCustomers();
        })
        .catch(function() {
          this.isBusyModal = false;
        });
    },

    details(id) {
      this.$router.push("/customers/" + id);
    },

    viewGeoTagDetails(phoneNumber) {
      this.geoTagDetails = {};
      $("#mod-geotag-details").modal("show");
      this.getGeoTagDetails(phoneNumber);
    },

    async getCustomers() {
      this.isBusy = true;

      await this.$http
        .get(
          "/Users/getCustomerList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.status != "0" ? "&status=" + this.filter.status : "") +
            (this.filter.customerType != "0" ? "&customerType=" + this.filter.customerType : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.filter.sortColumn != "" ? "&sortColumn=" + this.filter.sortColumn : "") +
            (this.filter.sortDirection != "" ? "&sortDirection=" + this.filter.sortDirection : "") +
            "&showForMe=" +
            !this.isGlobal
        )
        .then(function(res) {
          this.salesReps = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    async getGeoTagDetails(phoneNumber) {
      await this.$http
        .get("/Retailers/getByPhoneNumber/" + phoneNumber)
        .then(function(res) {
          var r = res.body;

          var obj = {
            storeName: r.storeName,
            latitude: r.latitude,
            longitude: r.longitude,
            tagDate: this.formatDate(r.created),
            createdBy: r.introducer ? r.introducer.displayName : "",
            storeImageString:
              r.storeImageString && r.storeImageString != ""
                ? "data:image/png;base64," + r.storeImageString
                : "",
          };
          this.geoTagDetails = obj;
        })
        .catch(function() {
          $("#mod-geotag-details").modal("hide");
        });
    },

    formatDate(date) {
      return moment(date).format("ll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    toggleGlobal() {
      $("#mod-global").modal("hide");
      this.isGlobal = !this.isGlobal;

      this.$auth.setPreferredViewForCustomer(this.isGlobal ? "global" : "default", this.user.id);

      this.getCustomers();
    },

    prev() {
      this.page -= 1;
      this.getCustomers();
    },

    next() {
      this.page += 1;
      this.getCustomers();
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getCustomers();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getCustomers();
    },
  },
};
</script>
