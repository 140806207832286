<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-post-payment-filter"
    role="dialog"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong>Post payment</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
            @click="clearFilter"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Customer</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <multiselect
                v-model="paymentsFilter.customerId"
                label="text"
                track-by="id"
                :options="customers"
                @select="setCustomer"
              ></multiselect>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Payment Channel</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <select
                :value="paymentsFilter.paymentChannelId"
                @input="setPaymentChannel"
                class="form-control"
                placeholder="Select a payment channel"
              >
                <option
                  v-for="br in paymentChannels"
                  :key="br.id"
                  :value="br.id"
                >
                  {{ prepareDetail(br) }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >Start Date</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <date-picker
                @input="setStartDate"
                class="form-control"
                :value="paymentsFilter.startDate"
                :config="options"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-md-4 col-form-label text-sm-right"
              >End Date</label
            >
            <div class="col-12 col-sm-8 col-lg-7">
              <date-picker
                @input="setEndDate"
                class="form-control"
                :value="paymentsFilter.endDate"
                :config="endOptions"
              />
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="clearFilter" type="button">
            Clear
          </button>
          <button class="btn btn-danger" type="button" data-dismiss="modal">
            Cancel
          </button>
          <button
            class="btn btn-primary"
            @click.prevent="getPayments"
            type="button"
            data-dismiss="modal"
          >
            <span
              v-if="payments.loading"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else> Submit </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters } from "vuex";
import datePicker from "vue-bootstrap-datetimepicker";
import Multiselect from "vue-multiselect";
export default {
  components: { datePicker, Multiselect },
  data() {
    return {
      paymentChannels: [],
      customers: [],

      options: {
        format: "YYYY-MM-DD",
      },

      endOptions: {
        format: "YYYY-MM-DD",
      },
    };
  },

  computed: {
    ...mapGetters(["paymentsFilter", "payments"]),
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  created() {
    this.setDateTime();
    this.getCustomers();
    this.getPaymentChannels();
  },

  methods: {
    ...mapActions(["getPayments"]),

    setCustomer(value) {
      this.paymentsFilter.customerId = value;
      this.updateFilter(this.paymentsFilter);
    },

    setPaymentChannel(value) {
      this.paymentsFilter.paymentChannelId = value.target.value;
      this.updateFilter(this.paymentsFilter);
    },

    setStartDate(date) {
      this.paymentsFilter.startDate = date;
      this.updateFilter(this.paymentsFilter);
    },

    setEndDate(date) {
      this.paymentsFilter.endDate = date;
      this.updateFilter(this.paymentsFilter);
    },

    async getCustomers() {
      try {
        const response = await this.$http.get(
          "/Users/getItemListOfCustomers/" + this.user.parentId
        );

        if (response.ok) {
          this.customers = response.body;
        }
      } catch (error) {}
    },

    async getPaymentChannels() {
      try {
        const response = await this.$http.get(
          "/PaymentChannels/getListNoPaging?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        );

        if (response.ok) this.paymentChannels = response.body;
      } catch (error) {}
    },

    clearFilter() {
      this.$store.commit("clearPaymentsFilter");
      this.setDateTime();
    },

    setDateTime() {
      const format = "YYYY-MM-DD";
      const date = new Date();
      this.paymentsFilter.startDate = moment(
        new Date(date.getFullYear(), date.getMonth(), 1)
      ).format(format);

      this.paymentsFilter.endDate = moment(Date.now()).format(format);
      this.updateFilter(this.paymentsFilter);
    },

    updateFilter(filter) {
      this.$store.commit("updatePaymentFilter", filter);
    },

    prepareDetail(value) {
      const name = value.paymentChannelTypeName;
      let result = "";
      if (name) {
        result += name;

        if (name.toLowerCase() === "bank") {
          result += "/ " + value.bankName + "/ " + value.accountNumber;
        } else if (name.toLowerCase() === "pos") {
          result += "/ " + value.bankName + "/ " + value.terminalId;
        } else if (name.toLowerCase() === "cryptocurrency") {
          result += "/ " + value.bankName + "/ " + value.walletAddress;
        }
      }
      return result;
    },
  },
};
</script>

<style></style>
