<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header d-flex justify-content-between">
          <h4>Editing Sales Order {{ purchaseOrder.orderNumber }}</h4>
          <button class="btn btn-success btn-rounded btn-space" @click="goBack">
            <i class="mdi icon mdi-backburger text-white"></i>
            Back
          </button>
        </div>
        <div class="card-body bg-white">
          <div class="col">
            <div class="row">
              <div class="col-md-9">
                <div class="form-group row">
                  <label for="inputPassword" class="col-sm-3 col-form-label"
                    >Order for:</label
                  >
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control-plaintext"
                      :value="
                        purchaseOrder.parent ? purchaseOrder.parent.name : ''
                      "
                    />
                  </div>
                </div>
                <div
                  v-if="purchaseOrder.deliveryAddress"
                  class="form-group row"
                >
                  <label for="inputPassword" class="col-sm-3 col-form-label"
                    >Delivery Address:</label
                  >
                  <div class="col-sm-9">
                    <textarea
                      class="form-control"
                      readonly="true"
                      :value="purchaseOrder.deliveryAddress"
                    ></textarea>
                  </div>
                </div>
                <div v-if="purchaseOrder.contact.name" class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label"
                    >Contact Name</label
                  >
                  <div class="col-12 col-sm-8 col-lg-4">
                    <input
                      class="form-control"
                      type="text"
                      readonly="true"
                      :value="purchaseOrder.contact.name"
                    />
                  </div>
                </div>
                <div
                  v-if="purchaseOrder.contact.phoneNumber"
                  class="form-group row"
                >
                  <label class="col-12 col-sm-3 col-form-label"
                    >Contact Phone</label
                  >
                  <div class="col-12 col-sm-8 col-lg-4">
                    <input
                      class="form-control"
                      type="tel"
                      readonly="true"
                      :value="purchaseOrder.contact.phoneNumber"
                    />
                  </div>
                </div>
                <div v-if="purchaseOrder.contact.email" class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label"
                    >Contact Email</label
                  >
                  <div class="col-12 col-sm-8 col-lg-4">
                    <input
                      class="form-control"
                      type="email"
                      readonly="true"
                      :value="purchaseOrder.contact.email"
                    />
                  </div>
                </div>
                <div
                  v-if="purchaseOrder.specialInstructions"
                  class="form-group row"
                >
                  <label class="col-12 col-sm-3 col-form-label"
                    >Special Instructions</label
                  >
                  <div class="col-12 col-sm-9 col-lg-9">
                    <textarea
                      class="form-control"
                      readonly="true"
                      :value="purchaseOrder.specialInstructions"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label"
                    >Creation Date</label
                  >
                  <div class="col-12 col-sm-8 col-lg-4">
                    <input
                      class="form-control"
                      type="text"
                      readonly
                      :value="formatDate(purchaseOrder.created)"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label">Status</label>
                  <div class="col-12 col-sm-8 col-lg-4">
                    <span
                      :class="[
                        'badge',
                        ' badge-pill',
                        {
                          'badge-success': purchaseOrder.status === 'confirmed',
                        },
                        {
                          'badge-danger': purchaseOrder.status === 'withdrawn',
                        },
                        {
                          'badge-secondary': purchaseOrder.status === 'draft',
                        },
                        {
                          'badge-warning': purchaseOrder.status === 'submitted',
                        },
                        {
                          'badge-dark': purchaseOrder.status === 'recalled',
                        },
                      ]"
                      >{{
                        purchaseOrder.status ? purchaseOrder.status : ""
                      }}</span
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label">Total</label>
                  <div class="col-12 col-sm-8 col-lg-4">
                    <input
                      class="form-control"
                      type="text"
                      readonly
                      :value="formatMoney(subTotal)"
                    />
                  </div>
                </div>
                <div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label"
                      >Total Order Amount</label
                    >
                    <div class="col-12 col-sm-8 col-lg-4">
                      <input
                        class="form-control"
                        type="text"
                        readonly
                        :value="total"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ItemDetails
            :items="addedOrderDetails"
            @performEdit="onEdit"
            @onRemoveHandler="onDetailRemoveHandler"
          />
          <SalesOrderItem
            v-if="addedItems.length > 0"
            :items="addedItems"
            @onRemove="onRemoveHandler"
          />
          <div class="col mt-4">
            <div class="row justify-content-end">
              <div class="col col-md-2 align-self-end">
                <div class="d-flex justify-content-between mt-3">
                  <button
                    type="button"
                    class="btn btn-secondary"
                    @click="goBack"
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="isBusyModal"
                    @click.prevent="onUpdateClick"
                  >
                    <span
                      v-if="isBusyModal"
                      class="spinner-border spinner-border-sm"
                      role="status"
                    >
                      <i class="sr-only">Loading...</i>
                    </span>
                    <span v-else> Update</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddEditProduct
      :products="manufacturerProducts"
      :filteredProducts="filteredProducts"
      :edit="product"
      @onAddEditComplete="onAddEditComplete"
      :groupId="groupId"
      @onItemAdded="onItemAddHandler"
      @onItemUpdated="onItemUpdatedHandler"
      @cancel="onCancel"
    />
    <ApplyOrderItem :orderItems="filteredOrderItems" @applyItem="onItemApply" />
    <UpdateOrderCaution
      :orderNumber="purchaseOrder.orderNumber"
      @onProceedClick="submit"
    />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
import { helper } from "@/helper";
import Quantity from "../../components/SettingsPurchaseOrder/Quantity";
import StageOptions from "../../components/SettingsPurchaseOrder/StageOptions";
import ItemDetails from "../../components/SettingsPurchaseOrder/PurchaseOrderItemDetails";
import SalesOrderItem from "../../components/SettingsPurchaseOrder/SalesOrderItem.vue";
import AddEditProduct from "../../components/SettingsPurchaseOrder/AddProduct";
import ApplyOrderItem from "../../components/SettingsPurchaseOrder/ApplyOrderItem.vue";
import UpdateOrderCaution from "../../components/SettingsPurchaseOrder/UpdateOrderCaution.vue";

export default {
  components: {
    Quantity,
    StageOptions,
    ItemDetails,
    AddEditProduct,
    ApplyOrderItem,
    SalesOrderItem,
    UpdateOrderCaution,
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      orderId: "",
      groupId: "",
      manufacturerProducts: [],
      filteredProducts: [],
      purchaseOrder: {
        contact: {},
      },
      addedOrderDetails: [],
      product: null,
      stageCautionData: {},
      orderItems: [],
      filteredOrderItems: [],
      addedItems: [],
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },

    subTotal() {
      let result = 0;
      this.addedOrderDetails.forEach((item) => {
        result += item.quantity * item.price;
      });
      return result;
    },

    total() {
      let result = this.subTotal;
      this.addedItems.forEach((item) => {
        if (item.type === 0) {
          result -=
            item.subType === 0
              ? item.flatAmount
              : this.calculatePercentageAmount(item.percentage, this.subTotal);
        }

        if (item.type === 1) {
          result += this.calculatePercentageAmount(
            item.percentage,
            this.subTotal
          );
        }

        if (item.type === 2) {
          if (item.method === 0) {
            result +=
              item.subType === 0
                ? item.flatAmount
                : this.calculatePercentageAmount(
                    item.percentage,
                    this.subTotal
                  );
          }
          if (item.method === 1) {
            result -=
              item.subType === 0
                ? item.flatAmount
                : this.calculatePercentageAmount(
                    item.percentage,
                    this.subTotal
                  );
          }
        }
      });
      return this.formatMoney(result);
    },
  },

  created() {
    this.orderId = this.$route.params.id;
    this.getOrderItems();
    this.editPurchaseOrder();
  },

  methods: {
    ...mapActions(["getPurchaseOrder"]),
    getPurchaseOrder() {
      this.$store.dispatch("getPurchaseOrder", "enterprise");
    },

    async editPurchaseOrder() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/edit/" + this.orderId
        );

        if (response.ok) {
          this.purchaseOrder = response.body;
          this.prepareDetailSet(this.purchaseOrder.details);
          this.prepareItemSet(this.purchaseOrder.orderItems);
          this.getGroup(this.purchaseOrder.parentId);
        }

        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    async getGroup(parentId) {
      this.isBusy = true;

      try {
        const response = await this.$http.get(`/Groups/getGroup/${parentId}`);
        if (response.ok) {
          this.groupId = response.body;
          this.getManufacturerProducts();
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    calculatePercentageAmount(percent, amount) {
      return (percent / 100) * amount;
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    onEdit(product) {
      this.product = product;

      // eslint-disable-next-line no-undef
      $("#mod-product-add").modal("show");
    },

    onCancel() {
      (this.product = null),
        // eslint-disable-next-line no-undef
        $("#mod-product-add").modal("hide");
    },

    onAddEditComplete() {
      // eslint-disable-next-line no-undef
      $("#mod-product-add").modal("hide");

      this.onComplete();
    },

    onComplete() {
      this.editPurchaseOrder();
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    async getManufacturerProducts() {
      this.isBusyModal = true;
      try {
        const response = await this.$http.get(
          "/Products/getListNoPagingForManufacturer?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.groupId ? "&groupId=" + this.groupId : "")
        );
        if (response.ok) {
          this.manufacturerProducts = this.filteredProducts = response.body;
          this.performFilter();
        }

        this.isBusyModal = false;
      } catch (error) {
        this.isBusyModal = false;
      }
    },

    performFilter() {
      this.addedOrderDetails.forEach((element) => {
        this.filteredProducts = this.filteredProducts.filter(
          (product) => product.id !== element.id
        );
      });
    },

    performItemFilter() {
      this.addedItems.forEach((element) => {
        this.filteredOrderItems = this.filteredOrderItems.filter(
          (item) => item.id !== element.id
        );
      });
    },

    onItemAddHandler(newItem) {
      const product = this.manufacturerProducts.find(
        (product) => product.id === newItem.productId
      );

      this.filteredProducts = this.filteredProducts.filter(
        (product) => product.id !== newItem.productId
      );

      this.prepareItem(product, newItem);
    },

    onDetailRemoveHandler(itemId) {
      const product = this.manufacturerProducts.find(
        (product) => product.id === itemId
      );

      this.filteredProducts.push(product);
      this.addedOrderDetails = this.addedOrderDetails.filter(
        (x) => x.id !== itemId
      );
    },

    onItemUpdatedHandler(newItem) {
      this.filteredProducts = this.filteredProducts.filter(
        (product) => product.id !== newItem.productId
      );

      this.prepareUpdateItem(newItem);
    },

    prepareUpdateItem(newItem) {
      this.addedOrderDetails.forEach((item) => {
        if (item.id === newItem.productId) {
          item.quantity = newItem.quantity;
          item.price = newItem.price;
        }
      });
      this.product = null;

      // eslint-disable-next-line no-undef
      $("#mod-product-add").modal("hide");
    },

    prepareItem(product, newItem) {
      const preparedItem = {
        quantity: newItem.quantity,
        description: `${product.name} (${product.code}) - ${newItem.price}/${product.unit}`,
        price: newItem.price,
        id: product.id,
      };

      this.addedOrderDetails.push(preparedItem);

      // eslint-disable-next-line no-undef
      $("#mod-product-add").modal("hide");
    },

    prepareItemSet(orderItems) {
      this.addedItems = [];
      orderItems.forEach((element) => {
        this.orderItems.forEach((sub) => {
          if (element.orderItemId === sub.id) this.addedItems.push(sub);
        });
      });

      this.performItemFilter();
    },

    prepareDetailSet(productsDetails) {
      this.addedOrderDetails = [];
      productsDetails.forEach((element) => {
        const preparedItem = {
          quantity: element.quantity,
          price: element.price,
          id: element.productId,
          description: element.description,
        };

        this.addedOrderDetails.push(preparedItem);
      });
    },

    onUpdateClick() {
      // eslint-disable-next-line no-undef
      $("#mod-update-order-caution").modal("show");
    },

    async submit() {
      // eslint-disable-next-line no-undef
      $("#mod-update-order-caution").modal("hide");

      this.isBusy = true;
      this.isBusyModal = true;

      try {
        const data = {
          details: this.addedOrderDetails,
          orderItems: this.addedItems,
        };

        const response = await this.$http.put(
          "/PurchaseOrders/manufacturer/update/" + this.purchaseOrder.id,
          data
        );

        if (response.status === 201 && response.ok) {
          this.getPurchaseOrder();
          this.goBack();
        }
        this.isBusy = false;
        this.isBusyModal = false;
      } catch (error) {
        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    async getOrderItems() {
      this.isBusyModal = true;
      try {
        const response = await this.$http.get("/PurchaseOrders/orderItem/list");
        if (response.ok) {
          this.orderItems = this.filteredOrderItems = response.body;
        }

        this.isBusyModal = false;
      } catch (error) {
        this.isBusyModal = false;
      }
    },

    onItemApply(itemId) {
      // eslint-disable-next-line no-undef
      $("#mod-apply-order-item").modal("hide");
      const item = this.orderItems.find((x) => x.id === itemId);
      this.addedItems.push(item);

      this.filteredOrderItems = this.filteredOrderItems.filter(
        (item) => item.id !== itemId
      );
    },

    onRemoveHandler(itemId) {
      this.addedItems = this.addedItems.filter((x) => x.id !== itemId);
    },
  },
};
</script>

<style>
</style>