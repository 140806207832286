<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="cardHeader p-2">
            <button
              style="color: #234D80"
              class="btn btn-link btn-rounded btn-space"
              @click.prevent="goBack"
            >
              Go back
            </button>
            <p class="m-0 font-weight-bold" style="color: #373737">Transfer details</p>
          </div>
          <div class="p-2 d-flex justify-content-between flex-row align-items-center">
            <div>
              <p class="font-weight-bold">Transfer ID</p>
              <p>{{ transferDetails.referenceId }}</p>
            </div>
            <div>
              <p class="font-weight-bold">Originating warehouse</p>
              <p>{{ transferDetails.origin.location.name }}, {{ transferDetails.origin.name }}</p>
            </div>
            <div>
              <p class="font-weight-bold">Transfer Date</p>
              <p>{{ formatDate(transferDetails.createdAt) }}</p>
            </div>
            <div>
              <p class="font-weight-bold">Transferred By</p>
              <p>{{ transferDetails.transferredBy.displayName }}</p>
            </div>
            <div>
              <p class="font-weight-bold">Stock Value</p>
              <p>&#8358;{{ formatMoney(transferDetails.stockValue) }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div
          :class="[
            'card',
            'card-border-color',
            'card-border-color-primary',
            'be-loading',
            { 'be-loading-active': isBusy },
          ]"
        >
          <div class="card-heade p-2">
            <h4>PRODUCT TRANSFERS</h4>
          </div>
          <div class="col-sm-12 table-responsive">
            <table class="table table-fw-widget dataTable no-footer">
              <thead>
                <tr>
                  <th style="width: 20%">Product</th>
                  <th style="width: 15%">Product Code</th>
                  <th style="width: 15%">Unit Price x QTY</th>
                  <th style="width: 20%">Stock Value</th>
                  <th style="width: 30%">Destination Warehouse</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in transferDetails.products" :key="index">
                  <td>{{ item.product.name }}</td>
                  <td>{{ item.product.code }}</td>
                  <td>
                    &#8358;{{ formatMoney(item.product.retailPrice) }} X
                    {{ item.quantityTransfered }}
                  </td>
                  <td>
                    &#8358;{{ formatMoney(item.product.retailPrice * item.quantityTransfered) }}
                  </td>
                  <td>
                    {{ item.destinationInventory.subLocation.location.name }},
                    {{ item.destinationInventory.subLocation.name }}
                  </td>
                </tr>
                <tr v-if="!transferDetails.products.length">
                  <td colspan="6">
                    <div class="text-center">No products available</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsStockTransferCreate",

  data() {
    return {
      transferId: this.$route.params.id,
      isBusy: false,
      isBusyModal: false,
      order: {},
      sellerId: null,
      deliveryAddress: null,
      transferDetails: [],
    };
  },

  async created() {
    if (!this.hasPermission("Stock_Transfer_View")) {
      this.$router.push("/restricted");
    }

    const id = this.$route.params.id;

    if (id) {
      await this.initialize();
    }
  },

  mounted() {
    if (!this.$route.params.id) {
      let options = { backdrop: "static", keyboard: false };
      $("#mod-customer").modal(options, "show");
    }
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  methods: {
    goBack() {
      this.$router.push("/stocktransfer");
    },

    formatDate(date) {
      return moment(date).format("ll");
    },
    async initialize() {
      if (this.transferId) {
        this.isBusy = true;
        this.isBusyModal = true;

        try {
          const res = await this.$http.get(`/Inventories/stockTransfer/${this.transferId}`);
          const reducer = (a, b) => a + b;
          const val = res.body.products
            .map((el) => +el.product.retailPrice * +el.quantityTransfered)
            .reduce(reducer, 0);
          this.transferDetails = res.body;
          this.transferDetails.stockValue = val;
        } catch (error) {}

        this.isBusyModal = false;
        this.isBusy = false;
      }
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },
    formatNumber(val) {
      return helper.formatNumber(val);
    },
  },
};
</script>

<style scoped>
.cardHeader {
  height: 54px;
  background: #f6f6f6;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.card p {
  color: #373737 !important;
}
</style>
