<template>
  <button
    class="btn btn-primary btn-sm btn-rounded btn-space mr-1"
    :disabled="submitLoading"
    @click.prevent="onSubmitClick"
  >
    <span
      v-if="submitLoading"
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <i class="sr-only">Loading...</i>
    </span>
    <span v-else> Submit </span>
  </button>
</template>

<script>
export default {
  name: "SwitchPurchaseOrder",
  props: ["purchaseOrder"],

  data() {
    return {
      submitLoading: false,
    };
  },

  methods: {
    onSubmitClick() {
      this.$emit("showAction", {
        purchaseNumber: this.purchaseOrder.orderNumber,
        action: "submit",
        owner: this.purchaseOrder.id,
      });
    },

    async switchPurchaseOrder() {
      this.submitLoading = true;
      this.isBusy = true;
      try {
        const response = await this.$http.put(
          `/PurchaseOrders/updateStatus/${this.purchaseOrder.id}/submitted`
        );

        if (response.ok && response.status === 200) this.$emit("onComplete");
        this.submitLoading = false;
        this.isBusy = true;
      } catch (error) {
        this.submitLoading = false;
        this.isBusy = true;
      }
    },
  },
};
</script>

<style>
</style>