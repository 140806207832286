<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <!-- <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <span class="feather icon-filter text-white"></span> Filter
          </button> -->
          <div />
          <div />
          <div />
          <button
            v-if="hasPermission('Global_View_Stock_Transfer')"
            class="btn btn-info btn-rounded"
            @click.prevent="viewGlobal()"
            :disabled="isBusy"
          >
            <span>
              <i class="mdi mdi-earth text-white"></i>
              Switch to {{ isGlobal ? "Default" : "Global" }}
            </span>
          </button>
          <div />

          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
            v-if="hasPermission('Stock_Transfer_Create')"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> New Stock Transfer
          </button>
        </div>
        <div class="card-body">
          <div
            class="
              dataTables_wrapper
              container-fluid
              dt-bootstrap4
              no-footer
              table-striped
            "
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer text-nowrap">
                  <thead>
                    <tr>
                      <th style="width: 10%">Transfer ID</th>
                      <th style="width: 30%">Originating Warehouse / Storage Area</th>
                      <th style="width: 10%">Total Items</th>
                      <th style="width: 15%">Total Stock Value</th>
                      <th style="width: 15%">Transferred By</th>
                      <th style="width: 15%">Status</th>
                      <th style="width: 15%">Transfer Date</th>
                      <th style="width: 5%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l in StockTransfers" :key="l.id">
                      <td>
                        {{ l.referenceId || "N/A" }}
                      </td>
                      <td>{{ l.origin.location.name }}, {{ l.origin.name }}</td>
                      <td>
                        {{ l.quantityTransfered }}
                      </td>
                      <td>&#8358;{{ formatMoney(l.totalStock) }}</td>
                      <td>
                        {{ l.transferredBy.displayName }}
                      </td>
                      <td>
                        {{ l.status != "REVERSED" ? "COMPLETED" : l.status }}
                      </td>
                      <td>{{ formatDateWithTime(l.created) }}</td>
                      <td>
                        <button
                          class="btn btn-outline-primary btn-sm dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Actions
                        </button>
                        <div class="dropdown-menu px-1" aria-labelledby="dropdownMenu2">
                          <a class="dropdown-item" href="#" @click.prevent="viewDetails(l.id)">
                            View Details
                          </a>
                          <div
                            class="dropdown-divider"
                            v-if="hasPermission('Stock_Transfer_Update')"
                          ></div>
                          <a
                            class="dropdown-item"
                            href="#"
                            v-if="hasPermission('Stock_Transfer_Update')"
                            @click.prevent="confirmReverse(l.id)"
                            style="color: red"
                          >
                            Reverse
                          </a>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!StockTransfers.length">
                      <td colspan="12">
                        <div class="text-center" style="padding-top: 50px">
                          <span style="font-size: 4.615rem" class="mdi mdi-crosshairs-gps"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any stock transfers.
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }}
                  {{ count != 1 ? "Stock Transfers" : "Stock Transfer" }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div class="modal fade colored-header colored-header-primary" id="mod-add" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Create New Stock Transfer</b></h4>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label font-weight-bold">Transfer from</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="createStockTransfer.locationId"
                  @input="getSubLocations($event)"
                >
                  <option v-for="loc in Locations" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>

                <ul v-if="v1.locationId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label font-weight-bold"
                >Select storage area</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  :disabled="!createStockTransfer.locationId || isLocationBusy"
                  v-model="createStockTransfer.subLocationId"
                >
                  <option v-for="loc in SubLocations" :key="loc.id" :value="loc.id">
                    {{ loc.name }}
                  </option>
                </select>

                <ul v-if="v1.subLocationId" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Done</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="mod-confirm" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-4">
            <p class="text-center">Are you sure you want to reverse this transaction</p>
            <div class="mt-3 d-flex justify-content-between align-items-center">
              <button
                style="width: 100px"
                class="btn btn-secondary"
                type="button"
                @click.prevent="cancelConfirm"
              >
                Cancel
              </button>
              <button
                style="width: 100px"
                :disabled="isBusyModal"
                class="btn btn-primary"
                type="button"
                @click.prevent="reverseTransfer"
              >
                <span v-if="isBusyModal">Busy...</span>
                <span v-else>Confirm</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade colored-header colored-header-primary" id="mod-global" role="dialog">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body">
            <div class="text-center">
              <!-- <div class="text-warning"><span class="modal-main-icon mdi mdi-alert"></span></div> -->
              <h3 class="p-1">Confirmation</h3>
              <p>
                Do you want to switch to
                {{ isGlobal ? "Default" : "Global" }} View?
              </p>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 140px"
              :disabled="isBusyModal"
              class="btn btn-warning"
              type="button"
              @click.prevent="toggleGlobal"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Yes, proceed</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";
import Pagination from "../components/Pagination.vue";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsStockTransfer",
  components: {
    Select2,
    Pagination,
  },

  created() {
    if (!this.hasPermission("Stock_Transfer_View")) {
      this.$router.push("/restricted");
    }

    var user = this.$store.state.currentUser;
    const preferredView = this.$auth.getPreferredViewForStockTransfer(user.id);
    if (preferredView) {
      if (preferredView == "global") this.isGlobal = true;
      else if (preferredView == "default") this.isGlobal = false;
    }

    this.getLocations();
    this.getTransfers();
  },

  data() {
    return {
      filter: {
        status: "0",
        locationId: "0",
        qry: "",
        showForMe: false,
      },
      isBusy: false,
      isBusyModal: false,
      isLocationBusy: false,
      isGlobal: false,
      createStockTransfer: {
        locationId: null,
        subLocationId: null,
      },
      v1: {
        locationId: null,
        subLocationId: null,
      },
      StockTransfers: [],
      Locations: [],
      SubLocations: [],
      ProductId: "",
      Products: [],
      count: 0,
      page: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
    };
  },
  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    reset() {
      this.filter = {
        locationId: "0",
        qry: "",
        showForMe: false,
      };
    },

    cancel() {
      $("#mod-edit").modal("hide");
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    toggleGlobal() {
      $("#mod-global").modal("hide");
      this.isGlobal = !this.isGlobal;

      this.$auth.setPreferredViewForStockTransfer(
        this.isGlobal ? "global" : "default",
        this.user.id
      );

      this.getTransfers();
    },

    viewAdd() {
      this.createStockTransfer = {
        locationId: null,
        subLocationId: null,
      };
      this.v1 = {
        locationId: null,
        subLocationId: null,
      };
      $("#mod-add").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    confirmReverse(id) {
      this.ProductId = id;
      $("#mod-confirm").modal("show");
    },

    cancelConfirm() {
      this.ProductId = "";
      $("#mod-confirm").modal("hide");
    },

    viewGlobal() {
      $("#mod-global").modal("show");
    },

    async reverseTransfer() {
      this.isBusyModal = true;
      try {
        const res = await this.$http.put(`/Inventories/stockTransfer/reverse/${this.ProductId}`);
        if (res.ok) {
          this.$toast.success("Stock transfer reversed successfully", {
            icon: true,
            rtl: false,
          });
          this.getTransfers();
          this.cancelConfirm();
        }
      } catch (error) {}
      this.isBusyModal = false;
    },

    viewDetails(id) {
      this.$router.push(`/stocktransfer/view/${id}`);
    },

    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateCreate()) {
        $("#mod-add").modal("hide");
        this.$router.push(`/stockTransfer/create/${this.createStockTransfer.subLocationId}`);
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateCreate() {
      this.v1 = {
        locationId: null,
        subLocationId: null,
      };
      var isValid = true;

      if (!this.createStockTransfer.locationId) {
        this.v1.locationId = true;
        isValid = false;
      }

      if (!this.createStockTransfer.subLocationId) {
        this.v1.subLocationId = true;
        isValid = false;
      }

      return isValid;
    },

    async getTransfers() {
      this.isBusy = true;
      console.log("page", this.page, this.pageSize);
      try {
        const res = await this.$http.get(
          "/Inventories/stockTransfer?pageNumber=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            (this.filter.locationId != "0" ? "&locationId=" + this.filter.locationId : "") +
            (this.filter.qry != "" ? "&query=" + this.filter.qry : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.filter.showForMe != "" ? "&showForMe=" + this.filter.showForMe : "") +
            "&showForMe=" +
            !this.isGlobal
        );
        if (res.ok) {
          const updatedTransfers = res.body.data.map((el) => {
            const totalStock = el.products
              .map((prod) => +prod.product.retailPrice * +prod.quantityTransfered)
              .reduce((a, b) => a + b, 0);

            return { ...el, totalStock };
          });
          this.StockTransfers = updatedTransfers;
          this.page = res.body.pageNumber;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        }
      } catch (error) {}
    },

    getLocations() {
      var t = "Location";
      var a = true;

      this.$http
        .get("/Locations/getListNoPaging?active=" + a + "&parentId=" + this.user.parentId)
        .then(function(res) {
          this.Locations = res.body;
        })
        .catch(function() {});
    },
    getSubLocations(e) {
      this.isLocationBusy = true;
      var t = "Location";
      var a = true;

      this.$http
        .get(
          "/SubLocations/getListNoPaging?active=" +
            a +
            "&parentId=" +
            this.user.parentId +
            "&locationId=" +
            e.target.value
        )
        .then(function(res) {
          this.SubLocations = res.body;
        })
        .catch(function() {});
      this.isLocationBusy = false;
    },
    getProducts() {
      var a = true;

      this.$http
        .get(
          "/Products/getListNoPagingForDistributor?active=" + a + "&parentId=" + this.user.parentId
        )
        .then(function(res) {
          this.Products = res.body;
        })
        .catch(function() {});
    },

    formatDate(date) {
      return moment(date).format("ll");
    },
    formatDateWithTime(date) {
      return moment(date).format("lll");
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    formatNumber(val) {
      return helper.formatNumber(val);
    },
    prev() {
      this.page -= 1;
      this.getTransfers();
    },
    next() {
      this.page += 1;
      this.getTransfers();
    },

    gotoPage(page) {
      this.page = page;
      this.getTransfers();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getTransfers();
    },
  },
};
</script>
