var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal fade colored-header colored-header-primary",attrs:{"id":"mod-upload-sales-edit","role":"dialog"}},[_c('div',{staticClass:"modal-dialog"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header modal-header-colored"},[_vm._m(0),_c('button',{staticClass:"close md-close",attrs:{"type":"button","data-dismiss":"modal","aria-hidden":"true"},on:{"click":_vm.reset}},[_c('span',{staticClass:"mdi mdi-close"})])]),_c('div',{staticClass:"modal-body"},[(_vm.error)?_c('div',{staticClass:"form-group row"},[_c('div',{staticClass:"col-12 col-sm-12 col-lg-12"},[_c('label',{staticClass:"col-12 col-sm-12 text-sm-center alert alert-danger"},[_vm._v(_vm._s(_vm.error))])])]):_vm._e(),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-4 col-form-label text-sm-right"},[_vm._v("Sales Order Number")]),_c('div',{staticClass:"col-12 col-sm-7 col-lg-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.salesOrder.salesOrder),expression:"salesOrder.salesOrder"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.salesOrder.salesOrder.$error,
              },attrs:{"type":"number","placeholder":"Enter sales order number"},domProps:{"value":(_vm.salesOrder.salesOrder)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.salesOrder, "salesOrder", $event.target.value)},function($event){return _vm.setSalesOrder($event.target.value)}]}}),(!_vm.$v.salesOrder.salesOrder.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" Sales Order Number is required! ")]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-4 col-form-label text-sm-right"},[_vm._v("Customer Code")]),_c('div',{staticClass:"col-12 col-sm-7 col-lg-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.salesOrder.customerCode),expression:"salesOrder.customerCode"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.salesOrder.customerCode.$error,
              },attrs:{"type":"text","placeholder":"Enter Customer Code"},domProps:{"value":(_vm.salesOrder.customerCode)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.salesOrder, "customerCode", $event.target.value)},function($event){return _vm.setCustomerCode($event.target.value)}]}}),(!_vm.$v.salesOrder.customerCode.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" Customer Code is required! ")]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-4 col-form-label text-sm-right"},[_vm._v("Customer Full Name")]),_c('div',{staticClass:"col-12 col-sm-7 col-lg-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.salesOrder.customerFullName),expression:"salesOrder.customerFullName"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.salesOrder.customerFullName.$error,
              },attrs:{"type":"text","placeholder":"Enter Customer Full Name"},domProps:{"value":(_vm.salesOrder.customerFullName)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.salesOrder, "customerFullName", $event.target.value)},function($event){return _vm.setCustomerFullName($event.target.value)}]}}),(!_vm.$v.salesOrder.customerFullName.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" Customer Full Name is required! ")]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-4 col-form-label text-sm-right"},[_vm._v("Product Code")]),_c('div',{staticClass:"col-12 col-sm-7 col-lg-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.salesOrder.productCode),expression:"salesOrder.productCode"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.salesOrder.productCode.$error,
              },attrs:{"type":"text","placeholder":"Enter Product Code"},domProps:{"value":(_vm.salesOrder.productCode)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.salesOrder, "productCode", $event.target.value)},function($event){return _vm.setProductCode($event.target.value)}]}}),(!_vm.$v.salesOrder.productCode.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" Product Code is required! ")]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-4 col-form-label text-sm-right"},[_vm._v("Product Price(NGN)")]),_c('div',{staticClass:"col-12 col-sm-7 col-lg-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.salesOrder.productPrice),expression:"salesOrder.productPrice"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.salesOrder.productPrice.$error,
              },attrs:{"type":"text","placeholder":"Enter Product Price"},domProps:{"value":(_vm.salesOrder.productPrice)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.salesOrder, "productPrice", $event.target.value)},function($event){return _vm.setProductPrice($event.target.value)}]}}),(!_vm.$v.salesOrder.productPrice.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" Product Price is required! ")]):_vm._e()])]),_c('div',{staticClass:"form-group row"},[_c('label',{staticClass:"col-12 col-sm-4 col-form-label text-sm-right"},[_vm._v("Quantity Ordered")]),_c('div',{staticClass:"col-12 col-sm-7 col-lg-7"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.salesOrder.quantityOrdered),expression:"salesOrder.quantityOrdered"}],staticClass:"form-control",class:{
                'is-invalid': _vm.$v.salesOrder.quantityOrdered.$error,
              },attrs:{"type":"text","placeholder":"Enter Quantity"},domProps:{"value":(_vm.salesOrder.quantityOrdered)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.$set(_vm.salesOrder, "quantityOrdered", $event.target.value)},function($event){return _vm.setQuantity($event.target.value)}]}}),(!_vm.$v.salesOrder.quantityOrdered.required)?_c('span',{staticClass:"invalid-feedback"},[_vm._v(" Quantity is required! ")]):_vm._e()])])]),_c('div',{staticClass:"modal-footer"},[_c('button',{staticClass:"btn btn-secondary",staticStyle:{"width":"100px"},attrs:{"type":"button","data-dismiss":"modal"},on:{"click":_vm.reset}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"btn btn-primary",staticStyle:{"width":"100px"},attrs:{"disabled":_vm.isValidating,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.update.apply(null, arguments)}}},[(_vm.isValidating)?_c('span',[_vm._v("Validating..")]):_c('span',[_vm._v("Update")])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('h4',{staticClass:"modal-title p-1"},[_c('b',[_vm._v("Edit Sales Order")])])}]

export { render, staticRenderFns }