<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-rename-stage"
    role="dialog"
  >
    <div class="modal-dialog modal-dialog-centered">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Rename Stage</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Name</label
            >
            <div class="col-12 col-sm-8 col-lg-9">
              <input
                class="form-control"
                :class="{
                  'is-invalid': $v.name.$error,
                }"
                type="text"
                placeholder="Enter item name"
                v-model.trim="name"
                @input="setName($event.target.value)"
              />
              <span class="invalid-feedback" v-if="!$v.name.required">
                Name is required.
              </span>
              <span class="invalid-feedback" v-if="!$v.name.minLength">
                Name must have at least
                {{ $v.name.$params.minLength.min }}
                letters.
              </span>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" type="button" data-dismiss="modal">
            Cancel
          </button>
          <button :disabled="isBusyModal" class="btn btn-primary" type="submit">
            <span v-if="isBusyModal">Busy..</span>
            <span v-else>Submit</span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minLength } from "vuelidate/lib/validators";

export default {
  props: ["stage"],

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      name: "",
    };
  },

  validations: {
    name: {
      required,
      minLength: minLength(3),
    },
  },

  watch: {
    stage: function (value) {
      this.setName(value.name);
    },
  },

  methods: {
    setName(value) {
      this.name = value;
      this.$v.name.$touch();
    },

    async submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.isBusy = true;
        this.isBusyModal = true;

        try {
          const data = {
            name: this.name,
          };

          let response = await this.$http.put(
            "/PurchaseOrders/orderStage/rename/" + this.stage.id,
            data
          );

          if (response.ok) this.$emit("onRenameComplete");

          this.isBusy = false;
          this.isBusyModal = false;
        } catch (error) {
          this.isBusy = false;
          this.isBusyModal = false;
        }
      }
    },
  },
};
</script>

<style>
</style>