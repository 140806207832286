<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-rrp-filter"
    role="dialog"
  >
    <div class="modal-dialog">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1"><b>Filter</b></h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Product</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                class="form-control"
                type="text"
                placeholder="Filter by name"
                v-model="name"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Code</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <input
                class="form-control"
                type="text"
                placeholder="Filter by product code"
                v-model="code"
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right"
              >Status</label
            >
            <div class="col-12 col-sm-8 col-lg-8">
              <select
                class="form-control"
                v-model="active"
                placeholder="Select an option"
              >
                <option value="empty">Select status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary" @click="clear" type="button">
            Clear
          </button>
          <button
            style="width: 100px"
            class="btn btn-secondary"
            type="button"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button style="width: 100px" class="btn btn-primary" type="submit">
            Submit
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "FilterDistributorPriceAssociation",

  data() {
    return {
      name: "",
      code: "",
      active: "empty",
    };
  },

  methods: {
    submit() {
      this.$emit("onFilter", {
        name: this.name,
        code: this.code,
        active: this.active,
      });
    },

    clear() {
      this.name = "";
      this.code = "";
      this.active = "empty";
      this.submit();
    },
  },
};
</script>
