<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <div class="btn-group">
            <router-link
              class="btn btn-space btn-danger btn-rounded float-right"
              :to="backUrl"
            >
              <span class="icon icon-left mdi mdi-arrow-left text-white"></span>
              Back
            </router-link>
            <button
              class="btn btn-success btn-rounded btn-space"
              @click="viewFilter"
            >
              <span class="feather icon-filter text-white"></span> Filter
            </button>
          </div>

          <button
            class="btn btn-primary btn-rounded btn-space float-right"
            @click="viewAdd"
            v-if="hasPermission('Expense_Post')"
          >
            <span class="icon icon-left mdi mdi-plus text-white"></span> Adjust
            Expense
          </button>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <h4>Expense Details</h4>

                <div class="col-sm-12 table-responsive">
                  <table class="table table-fw-widget dataTable no-footer">
                    <thead>
                      <tr>
                        <th style="width: 20%">Category</th>
                        <th style="width: 15%">Amount</th>
                        <th style="width: 15%">Date</th>
                        <th style="width: 15%">Status</th>
                        <th style="width: 20%">Notes</th>
                        <th style="width: 15%">Created</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {{
                            expense.expenseCategory
                              ? expense.expenseCategory.name
                              : ""
                          }}
                        </td>
                        <td style="color: green; font-weight: bold">
                          {{ formatMoney(expense.amount) }}
                        </td>
                        <td>{{ formatDate(expense.expenseDate) }}</td>
                        <td>{{ expense.status }}</td>
                        <td>{{ expense.notes }}</td>
                        <td>{{ formatDate(expense.created) }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="col-sm-12 table-responsive">
                <h4>Expense History</h4>
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 15%">Amount</th>
                      <th style="width: 15%">Date</th>
                      <th style="width: 15%">Status</th>
                      <th style="width: 30%">Notes</th>
                      <th style="width: 15%">Created</th>
                      <th style="width: 10%"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="l in expenseHistory" :key="l.id">
                      <td
                        v-if="l.amount < 0"
                        style="color: red; font-weight: bold"
                      >
                        {{ formatMoney(l.amount) }}
                      </td>
                      <td v-else style="color: green; font-weight: bold">
                        {{ formatMoney(l.amount) }}
                      </td>
                      <td>{{ formatDate(l.expenseDate) }}</td>
                      <td>{{ l.status }}</td>
                      <td>{{ l.notes }}</td>
                      <td>{{ formatDate(l.created) }}</td>
                      <td>
                        <div
                          class="text-right"
                          v-if="hasPermission('Expense_Delete')"
                        >
                          <button
                            v-if="l.status == 'SUBMITTED'"
                            class="btn btn-success btn-sm btn-rounded btn-space"
                            @click.prevent="onDeleteAdjustment(l)"
                          >
                            <span v-if="isBusy">Please wait...</span>
                            <span v-else>Delete</span>
                          </button>
                        </div>
                      </td>
                    </tr>
                    <tr v-if="!expenseHistory.length">
                      <td colspan="6">
                        <div class="text-center" style="padding-top: 50px">
                          <span
                            style="font-size: 4.615rem"
                            class="mdi mdi-crosshairs-gps"
                          ></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          You don't have any submitted expenses.
                        </h3>
                      </td>
                    </tr>
                    <tr v-else>
                      <td colspan="6">
                        <div class="text-center" style="padding-top: 50px">
                          Total Expense Amount:
                          <span style="font-weight: bold; color: green">{{
                            formatMoney(totalAdjustmentAmount)
                          }}</span>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} adjusted expense{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-add"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Adjust Expense</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row" v-if="!v1.is_valid">
              <div class="col-12 col-sm-12 col-lg-12">
                <label
                  class="col-12 col-sm-12 text-sm-center alert alert-danger"
                  >{{ v1.text }}</label
                >
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Adjustment Amount</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <input
                  :class="['form-control', { 'is-invalid': v1.amount }]"
                  type="number"
                  placeholder="Enter amount"
                  v-model="adjustExpense.amount"
                />
                <ul v-if="v1.amount" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Notes</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <textarea
                  :class="['form-control', { 'is-invalid': v1.notes }]"
                  v-model="adjustExpense.notes"
                ></textarea>
                <ul v-if="v1.notes" class="parsley-errors-list filled">
                  <li class="parsley-required">Required.</li>
                </ul>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 130px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="create"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Submit</span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Status</label
              >
              <div class="col-12 col-sm-8 col-lg-8">
                <select
                  class="form-control"
                  placeholder="Select an option"
                  v-model="filter.status"
                >
                  <option value="0">All Expenses</option>
                  <option value="SUBMITTED">SUBMITTED</option>
                  <option value="DELETED">DELETED</option>
                </select>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right"
                >Date range</label
              >
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.dateStart"
                />
              </div>
              <div class="col-12 col-sm-4 col-lg-4">
                <input
                  type="date"
                  class="form-control"
                  placeholder="Select a date"
                  v-model="filter.dateEnd"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getExpenseHistoryByExpenseId"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
    <UploadCaution
      :text="modalText"
      :action="action"
      @proceedToRemove="deleteAdjustment"
    />
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";
import UploadCaution from "../components/UploadCaution";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "settingsExpenses",
  components: {
    Select2,
    UploadCaution,
  },

  created() {
    if (!this.hasPermission("Expense_View")) {
      this.$router.push("/restricted");
    }
    this.getExpense();
    this.getExpenseHistoryByExpenseId();
  },
  watch: {},
  data() {
    return {
      filter: {
        status: "0",
        dateStart: moment().subtract(6, "days").format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
      },
      isBusy: false,
      isBusyModal: false,
      adjustExpense: {},
      v1: {
        amount: false,
        notes: false,
        expenseDate: false,
        is_valid: true,
        text: "",
      },
      expense: [],
      expenseHistory: [],
      count: 0,
      page: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
      modalText: "",
      action: "",
      adjustMent: "",
    };
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
    backUrl: function () {
      var url = "/manage-expenses";

      var startDate = this.$route.query.sd ? this.$route.query.sd : "";
      var endDate = this.$route.query.ed ? this.$route.query.ed : "";
      var status = this.$route.query.st ? this.$route.query.st : "";
      var expenseStartDate = this.$route.query.eds ? this.$route.query.eds : "";
      var expenseEndDate = this.$route.query.ede ? this.$route.query.ede : "";

      url =
        url +
        "?sd=" +
        startDate +
        "&ed=" +
        endDate +
        "&st=" +
        status +
        "&eds=" +
        expenseStartDate +
        "&ede=" +
        expenseEndDate;

      return url;
    },

    totalAdjustmentAmount: function () {
      let amount = this.expense.amount;
      this.expenseHistory.forEach((element) => {
        amount =
          element.amount > 0
            ? amount + element.amount
            : amount - this.convertToPositive(element.amount);
      });
      return amount;
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        dateStart: moment().subtract(6, "days").format("YYYY-MM-DD"),
        dateEnd: moment().format("YYYY-MM-DD"),
      };
    },

    convertToPositive(a) {
      if (a < 0) {
        a = a * -1;
      }
      return a;
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

    viewAdd() {
      var id = this.$route.params.id;

      this.v1 = {
        amount: false,
        notes: false,
        expenseDate: false,
        is_valid: true,
        text: "",
      };
      this.adjustExpense = {
        expenseId: id,
        status: "SUBMITTED",
        amount: null,
        notes: null,
      };

      $("#mod-add").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    async create() {
      this.isBusyModal = true;
      this.isBusy = true;

      if (this.validateCreate()) {
        var id = this.$route.params.id;
        $("#mod-add").modal("hide");

        await this.$http
          .put("/Expenses/adjust/" + id, this.adjustExpense)
          .then(function (res) {
            this.isBusyModal = false;
            this.getExpenseHistoryByExpenseId();
            this.adjustExpense = {};
          })
          .catch(function () {
            this.isBusy = false;
            this.isBusyModal = false;
          });
      }

      this.isBusy = false;
      this.isBusyModal = false;
    },

    validateCreate() {
      this.v1 = {
        status: false,
        amount: false,
        notes: false,
        is_valid: true,
        text: "",
      };
      var isValid = true;

      if (!this.adjustExpense.amount) {
        this.v1.amount = true;
        isValid = false;
      }

      if (!this.adjustExpense.notes) {
        this.v1.notes = true;
        isValid = false;
      }

      if (!this.adjustExpense.status) {
        this.v1.status = true;
        isValid = false;
      }

      return isValid;
    },

    onDeleteAdjustment(adjustMent) {
      this.modalText = "Are you sure you want to delete this adjustment?";
      this.action = "remove";
      this.adjustMent = adjustMent;
      $("#mod-expense-caution").modal("show");
    },

    async deleteAdjustment() {
      $("#mod-expense-caution").modal("hide");
      this.isBusy = true;
      await this.$http
        .put("/Expenses/deleteAdjustedExpense/" + this.adjustMent.id, null)
        .then(function (res) {
          this.isBusy = false;
        })
        .catch(function () {
          this.isBusy = false;
        });

      this.getExpenseHistoryByExpenseId();
    },

    getExpense() {
      this.isBusy = true;
      var id = this.$route.params.id;

      this.$http
        .get("/Expenses/getById/" + id)
        .then(function (res) {
          this.expense = res.body;
          this.isBusy = false;
        })
        .catch(function () {
          this.isBusy = false;
        });
    },

    async getExpenseHistoryByExpenseId() {
      this.isBusy = true;

      var id = this.$route.params.id;

      await this.$http
        .get(
          "/Expenses/getAdjustedList?page=" +
            this.page +
            "&pageSize=" +
            this.pageSize +
            "&expenseId=" +
            id +
            (this.filter.status != "0" ? "&status=" + this.filter.status : "") +
            (this.filter.dateStart != ""
              ? "&dateStart=" +
                moment(this.filter.dateStart).format("YYYY-MM-DD 00:00:00")
              : "") +
            (this.filter.dateEnd != ""
              ? "&dateEnd=" +
                moment(this.filter.dateEnd).format("YYYY-MM-DD 23:59:59")
              : "") +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        )
        .then(function (res) {
          this.expenseHistory = res.body.data;
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.isBusy = false;
        })
        .catch(function () {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    prev() {
      this.page -= 1;
      this.getExpenseHistoryByExpenseId();
    },

    next() {
      this.page += 1;
      this.getExpenseHistoryByExpenseId();
    },
  },
};
</script>