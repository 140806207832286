<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div :class="['card', 'card-border-color', 'card-border-color-primary', 'be-loading', {'be-loading-active':isBusy}]">
                    <div class="card-heade p-2">
                        <div class="btn-group float-right">
                            <router-link v-if="hasPermission('Customer_Update')" class="btn btn-space btn-success btn-rounded"
                                        :to="'/customers/edit/' + customer.id">
                                         <!--:to="'/customers/' + customer.id + '/edit'">-->
                                <span class="icon icon-left mdi mdi-pencil text-white"></span> Edit customer
                            </router-link>
                            <router-link class="btn btn-space btn-primary btn-rounded" to="/customers">
                                <span class="icon icon-left mdi mdi-arrow-left text-white"></span> Back
                            </router-link>
                        </div>
                        <h4>Customer Details - <b>{{ customer.displayName }}</b></h4>
                    </div>
                    <div class="card-body">
                        <h4><b>System</b></h4>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Id:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ customer.id }}</div>
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Status:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                                <span :class="['badge', 'badge-pill', { 'badge-primary':customer.status=='Pending' }, { 'badge-success':customer.status=='Active' }, { 'badge-danger':customer.status=='Disabled' }]">{{ customer.status }}</span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Created:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ formatDate(customer.created) }}</div>
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Last Updated:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ formatDate(customer.updated) }}</div>
                        </div>

                        <hr>

                        <h4><b>Profile</b></h4>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Name:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ customer.displayName }}</div>
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Parent:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ customer.parent? customer.parent.name : 'N/A' }}</div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Email Address:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ customer.emailAddress }}</div>
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Phone Number:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ customer.phoneNumber }}</div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Delivery Address:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ customer.deliveryAddress }}</div>
                        </div>
                        <hr>

                        <h4><b>Others</b></h4>

                        <!-- <div class="form-group row">
                          <label class="col-12 col-sm-3 col-form-label text-sm-right">Credit Limit:</label>
                          <div
                            class="col-12 col-sm-9 col-lg-9 col-form-label text-sm-left"
                          >{{ customer.hasCredit? `&#8358; ${formatMoney(customer.creditLimit)}` : 'Not Available' }}</div>
                        </div> -->

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Discounts:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ customer.discount? `${customer.discount.name} - ${customer.discount.type!='Percent'?'&#8358;':''} ${formatMoney(customer.discount.value)}${customer.discount.type=='Percent'?'%':''}` : 'Not Available' }}</div>

                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Branch:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ customer.branch?customer.branch.name : 'N/A' }}</div>
                        </div>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Groups:</label>
                            <div class="col-12 col-sm-9 col-lg-9 col-form-label text-sm-left">{{ customer.groups.length? customer.groups.map(e => e.name).join(", "): 'Not Available'}}</div>
                        </div>
                    </div>
                    <div class="be-spinner">
                        <svg width="40px" height="40px" viewBox="0 0 66 66">
                            <circle class="circle"
                                    fill="none"
                                    stroke-width="4"
                                    stroke-linecap="round"
                                    cx="33"
                                    cy="33"
                                    r="30"></circle>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */

    import moment from "moment";
    import { helper } from "@/helper";
    import SystemPermissions from '@/permissions'

    export default {
        mixins: [SystemPermissions.Mixin],
        name: "customersDetails",
        data() {
            return {
                isBusy: false,
                customer: {
                    parent: {},
                    contact: {},
                    bank: {},
                    groups: [],
                    discount: {}
                }
            };
        },
        created() {
            if (!this.hasPermission('Customer_View')) {
                this.$router.push("/restricted");
            }
            this.getSalesRep();
        },
        computed: {
            backPath: function () {
                return this.$store.state.prevRoute;
            }
        },
        methods: {
            getSalesRep() {
                var id = this.$route.params.id;

                this.$http
                    .get("/Users/getById/" + id)
                    .then(function (res) {
                        this.customer = res.body;
                    })
                    .catch(function () { });
            },
            formatDate(date) {
                if (date == null) {
                    return "N/A";
                }
                return moment(date).format("ll");
            },
            formatMoney(val) {
                return helper.formatMoney(val);
            }
        }
    };
</script>