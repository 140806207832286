<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card card-border-color card-border-color-primary">
          <div class="card-header">
            <router-link
              class="btn btn-space btn-primary btn-rounded float-right"
              :to="'/customers/' + userProfile.id"
            >
              <span class="icon icon-left mdi mdi-arrow-left text-white"></span>
              Back
            </router-link>
          </div>
          <div class="card-body">
            <form>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Branch</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <select
                    placeholder="Branch"
                    v-model="userProfile.branchId"
                    :class="['form-control', { 'is-invalid': v1.branch }]"
                  >
                    <option v-for="br in Branches" :key="br.id" :value="br.id">
                      {{ br.name }}
                    </option>
                  </select>
                  <ul v-if="v1.branch" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Introducer</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <select
                    placeholder="Introducer"
                    v-model="userProfile.introducerId"
                    :class="['form-control', { 'is-invalid': v1.introducer }]"
                  >
                    <option v-for="c in Introducers" :key="c.id" :value="c.id">
                      {{ c.text }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-9 col-sm-3 col-form-label text-sm-right"
                  >Customer Display Name</label
                >
                <div class="col-9 col-sm-6 col-lg-6">
                  <input
                    :class="['form-control', { 'is-invalid': v1.displayName }]"
                    type="text"
                    v-model="userProfile.displayName"
                  />
                  <ul v-if="v1.displayName" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Email Address</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="[
                      'form-control',
                      { 'is-invalid': v1.emailAddressValid },
                    ]"
                    type="email"
                    v-model="userProfile.emailAddress"
                  />

                  <ul
                    v-if="v1.emailAddressValid"
                    class="parsley-errors-list filled"
                  >
                    <li class="parsley-required">
                      Enter a valid email address.
                    </li>
                  </ul>
                </div>
              </div>
              <div class="form-group row">
                <label class="col-12 col-sm-3 col-form-label text-sm-right"
                  >Phone Number</label
                >
                <div class="col-12 col-sm-8 col-lg-6">
                  <input
                    :class="[
                      'form-control',
                      { 'is-invalid': v1.phoneNumber },
                      { 'is-invalid': v1.phoneNumberValid },
                    ]"
                    type="text"
                    v-model="userProfile.phoneNumber"
                  />
                  <ul v-if="v1.phoneNumber" class="parsley-errors-list filled">
                    <li class="parsley-required">Required.</li>
                  </ul>
                  <ul
                    v-if="v1.phoneNumberValid"
                    class="parsley-errors-list filled"
                  >
                    <li class="parsley-required">
                      Enter a valid phone number.
                    </li>
                  </ul>
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-12 col-sm-3 col-form-label text-sm-right"
                ></label>
                <div class="col-12 col-sm-8 col-lg-6">
                  <button
                    :disabled="isBusy"
                    style="width: 200px"
                    class="btn btn-space btn-success"
                    @click.prevent="update"
                  >
                    <span v-if="isBusy">Busy...</span>
                    <span v-else>Save changes</span>
                  </button>
                  &nbsp;
                  <router-link
                    class="btn btn-space btn-secondary"
                    :to="'/customers/' + userProfile.id"
                    >Cancel</router-link
                  >
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "customersEdit",
  components: {
    Select2,
  },
  data() {
    return {
      isBusy: false,
      userProfile: {},
      Branches: [],
      Introducers: [],
      v1: {
        branch: false,
        introducer: false,
        displayName: false,
        phoneNumber: false,
        emailAddress: false,
        phoneNumberValid: false,
        emailAddressValid: false,
      },
    };
  },
  created() {
    if (!this.hasPermission("Customer_Update")) {
      this.$router.push("/restricted");
    }
    this.getUserProfile();
    this.getBranches();
    this.getIntroducers();
  },
  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },
  methods: {
    getUserProfile() {
      var id = this.$route.params.id;

      this.$http
        .get("/Users/getById/" + id)
        .then(function (res) {
          this.userProfile = {
            id: res.body.id,
            branchId: res.body.branch ? res.body.branch.id : null,
            introducerId: res.body.introducer ? res.body.introducer.id : null,
            displayName: res.body.displayName,
            phoneNumber: res.body.phoneNumber,
            emailAddress: res.body.emailAddress,
          };
        })
        .catch(function () {});
    },
    update() {
      this.isBusy = true;

      if (this.validateUpdate()) {
        var data = {
          id: this.userProfile.id,
          displayName: this.userProfile.displayName,
          phoneNumber: this.userProfile.phoneNumber,
          emailAddress: this.userProfile.emailAddress,
          branchId: this.userProfile.branchId,
          introducerId: this.userProfile.introducerId,
        };
        this.$http
          .put("/Users/updateCustomerInfo/" + data.id, data)
          .then(function (res) {
            this.$router.push("/customers/" + data.id);
          })
          .catch(function () {
            this.isBusy = false;
          });
      } else {
        this.isBusy = false;
      }
    },
    validateUpdate() {
      this.v1 = {
        branch: false,
        introducer: false,
        displayName: false,
        phoneNumber: false,
        emailAddress: false,
        phoneNumberValid: false,
        emailAddressValid: false,
      };
      var isValid = true;

      if (!this.userProfile.branchId) {
        this.v1.branch = true;
        isValid = false;
      }

      if (!this.userProfile.displayName) {
        this.v1.displayName = true;
        isValid = false;
      }

      if (
        this.userProfile.emailAddress &&
        !helper.validateEmail(this.userProfile.emailAddress)
      ) {
        this.v1.emailAddressValid = true;
        isValid = false;
      }

      if (!this.userProfile.phoneNumber) {
        this.v1.phoneNumber = true;
        isValid = false;
      }

      if (
        this.userProfile.phoneNumber &&
        !helper.validatePhone(this.userProfile.phoneNumber)
      ) {
        this.v1.phoneNumberValid = true;
        isValid = false;
      }

      return isValid;
    },
    async getIntroducers() {
      await this.$http
        .get("/Users/getItemListOfIntroducers/" + this.user.parentId)
        .then(function (res) {
          this.Introducers = res.body;
        })
        .catch(function () {});
    },
    getBranches() {
      var a = true;

      this.$http
        .get(
          "/Branches/getListNoPaging?active=" +
            a +
            "&parentId=" +
            this.user.parentId
        )
        .then(function (res) {
          this.Branches = res.body;
        })
        .catch(function () {});
    },
  },
};
</script>