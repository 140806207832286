<template>
  <div class="onboarding-body">
    <div v-if="step === 2" class="onboarding-back" @click="changeStep(1)">
      <svg width="10" height="18" viewBox="0 0 10 18" fill="none">
        <path
          d="M9.86172 2.225L8.37839 0.75L0.136719 9L8.38672 17.25L9.86172 15.775L3.08672 9L9.86172 2.225Z"
          fill="#18191F"
          fill-opacity="0.5"
        />
      </svg>
      <p class="onboarding-back-text">BACK</p>
    </div>
    <div class="text-right onboarding-meta">
      <p class="onboarding-meta-step">
        STEP 0{{ step }}/{{ client === "distributor" ? "02" : "01" }}
      </p>
      <p class="onboarding-meta-title">
        {{ step === 1 ? "Personal Information" : "Business Information" }}
      </p>
    </div>
    <div class="onboarding-card">
      <img src="/assets/img/logo-xx.png" alt="logo" width="175" />
      <p class="onboarding-header">
        {{ step === 1 ? "Tell Us About Yourself" : "Tell Us About Your Business" }}
      </p>
      <p class="onboarding-desc">
        {{
          step === 1
            ? "Please enter your user information to create your account."
            : "Please enter your business information to create your account."
        }}
      </p>
      <div v-if="step === 1">
        <div v-if="client === 'distributor'">
          <distributor-registration-form
            @saveDistributorData="saveDistributorData"
            :payload="payload"
          ></distributor-registration-form>
        </div>
        <div v-if="client === 'merchant'">
          <merchant-registration-form></merchant-registration-form>
        </div>
      </div>
      <div v-else>
        <distributor-business-details :payload="payload"></distributor-business-details>
      </div>
    </div>
    <registration-status-modal></registration-status-modal>
  </div>
</template>

<script>
/* eslint-disable */
import DistributorRegistrationForm from "../components/GrowtradeOnboarding/DistributorRegistrationForm";
import MerchantRegistrationForm from "../components/GrowtradeOnboarding/MerchantRegistrationForm.vue";
import DistributorBusinessDetails from "../components/GrowtradeOnboarding/DistributorBusinessDetails.vue";
import RegistrationStatusModal from "../components/GrowtradeOnboarding/RegistrationStatusModal.vue";
import { helper } from "@/helper";

export default {
  name: "growtrade-register",
  components: {
    DistributorRegistrationForm,
    MerchantRegistrationForm,
    DistributorBusinessDetails,
    RegistrationStatusModal,
  },

  data() {
    return {
      client: null,
      step: 1,
      payload: {},
    };
  },

  beforeCreate() {
    const client = this.$route.query.client;

    if (client !== "distributor" && client !== "merchant") {
      this.$router.push(`/growtrade-onboarding`);
    }
  },

  created() {
    const client = this.$route.query.client;
    this.client = client;
  },

  computed: {
    // user: function() {
    //   return this.$store.state.currentUser;
    // },
  },

  methods: {
    changeStep(step) {
      this.step = step;
    },

    saveDistributorData(data) {
      this.payload = { ...data };
      this.changeStep(2);
    },
  },
};
</script>
<style scoped>
.onboarding-body {
  position: relative;
  background-color: #f6f6f6;
  min-width: 100vw;
  min-height: 100vh;
  padding: 48px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.onboarding-card {
  width: 524px;
  background-color: #fff;
  padding: 48px;
}

.onboarding-header {
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: #373737;
  margin: 32px 0 8px;
}

.onboarding-desc {
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: rgba(24, 25, 31, 0.5);
  padding-bottom: 16px;
  border-bottom: 1px solid #f5f5f5;
  margin-bottom: 24px;
}

.onboarding-meta {
  position: absolute;
  right: 48px;
  top: 48px;
}

.onboarding-back {
  position: absolute;
  left: 48px;
  top: 48px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.onboarding-back svg {
  width: 10px;
}

.onboarding-back-text {
  font-weight: 700;
  font-size: 14px;
  line-height: 28px;
  color: #a6a6a6;
  margin-left: 14px;
  margin-bottom: 0;
}

.onboarding-meta-step {
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #a6a6a6;
  margin-bottom: 8px;
}

.onboarding-meta-title {
  font-size: 18px;
  line-height: 22px;
  font-weight: 700;
  color: rgba(24, 25, 31, 0.5);
}

@media screen and (max-width: 767px) {
  .onboarding-card {
    width: 90%;
    padding: 24px;
  }
}
</style>
