<template>
  <button
    class="btn btn-danger btn-sm btn-rounded btn-space mr-1"
    data-toggle="tooltip"
    title="Delete"
    :disabled="deleteLoading"
    @click.prevent="deleteAssociation"
  >
    <span
      v-if="deleteLoading"
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <i class="sr-only">Loading...</i>
    </span>
    <span v-else>
      <i class="icon mdi mdi-delete text-white"></i>
    </span>
  </button>
</template>

<script>
export default {
  name: "DeleteGroup",
  props: ["id"],

  data() {
    return {
      deleteLoading: false,
    };
  },

  methods: {
    async deleteAssociation() {
      this.deleteLoading = true;
      this.isBusy = true;
      try {
        const response = await this.$http.delete(
          "/Groups/deleteMember/" + this.id
        );

        if (response.ok && response.status === 200) this.$emit("onComplete");
        this.deleteLoading = false;
        this.isBusy = true;
      } catch (error) {
        this.deleteLoading = false;
        this.isBusy = true;
      }
    },
  },
};
</script>
