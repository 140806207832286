<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
          <div class="card-header">
              <h4 class="p-1">
                  <!--<b>System Settings</b>-->
              </h4>
              <div class="btn-group">
                  <button v-if="settings.parentId == user.parentId"
                          class="btn btn-success btn-sm btn-rounded btn-space"
                          @click.prevent="viewEdit(settings)">
                      <i class="feather icon-edit"></i> &nbsp;Edit
                  </button>
              </div>
          </div>
          <div class="card-body">

              <h4>Invoice Settings</h4>
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Send Invoice Automatically:</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      <span v-if="settings.sendInvoiceAutomatically" class="badge badge-pill badge-success">
                          {{ settings.sendInvoiceAutomatically ? "YES" : "NO" }}
                      </span>
                      <span v-else class="badge badge-pill badge-secondary">
                          {{ settings.sendInvoiceAutomatically ? "YES" : "NO" }}
                      </span>
                  </div>
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Send Invoice Manually:</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      <span v-if="settings.sendInvoiceManually" class="badge badge-pill badge-success">
                          {{ settings.sendInvoiceManually ? "YES" : "NO" }}
                      </span>
                      <span v-else class="badge badge-pill badge-secondary">
                          {{ settings.sendInvoiceManually ? "YES" : "NO" }}
                      </span>
                  </div>
              </div>

              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Send Auto Invoice Via SMS:</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      <span v-if="settings.sendAutomaticInvoiceViaSms" class="badge badge-pill badge-success">
                          {{ settings.sendAutomaticInvoiceViaSms ? "YES" : "NO" }}
                      </span>
                      <span v-else class="badge badge-pill badge-secondary">
                          {{ settings.sendAutomaticInvoiceViaSms ? "YES" : "NO" }}
                      </span>
                  </div>
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Send Manual Invoice Via SMS:</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      <span v-if="settings.sendManualInvoiceViaSms" class="badge badge-pill badge-success">
                          {{ settings.sendManualInvoiceViaSms ? "YES" : "NO" }}
                      </span>
                      <span v-else class="badge badge-pill badge-secondary">
                          {{ settings.sendManualInvoiceViaSms ? "YES" : "NO" }}
                      </span>
                  </div>
              </div>

              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Send Auto Invoice Via Email:</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      <span v-if="settings.sendAutomaticInvoiceViaEmail" class="badge badge-pill badge-success">
                          {{ settings.sendAutomaticInvoiceViaEmail ? "YES" : "NO" }}
                      </span>
                      <span v-else class="badge badge-pill badge-secondary">
                          {{ settings.sendAutomaticInvoiceViaEmail ? "YES" : "NO" }}
                      </span>
                  </div>
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Send Manual Invoice Via Email:</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      <span v-if="settings.sendManualInvoiceViaEmail" class="badge badge-pill badge-success">
                          {{ settings.sendManualInvoiceViaEmail ? "YES" : "NO" }}
                      </span>
                      <span v-else class="badge badge-pill badge-secondary">
                          {{ settings.sendManualInvoiceViaEmail ? "YES" : "NO" }}
                      </span>
                  </div>
              </div>

              <hr />

              <h4>Others</h4>
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Default Product Expiry Notification (Days):</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      {{ settings.defaultProductExpiryNotification }}
                  </div>
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Automatic Order Fulfillment Reconciliation:</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      <span v-if="settings.autoOrderFulfillmentReconciliation" class="badge badge-pill badge-success">
                          {{ settings.autoOrderFulfillmentReconciliation ? "YES" : "NO" }}
                      </span>
                      <span v-else class="badge badge-pill badge-secondary">
                          {{ settings.autoOrderFulfillmentReconciliation ? "YES" : "NO" }}
                      </span>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Allow Geo-Fencing:</label>
                  <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                      <span v-if="settings.allowGeoFencing" class="badge badge-pill badge-success">
                          {{ settings.allowGeoFencing ? "YES" : "NO" }}
                      </span>
                      <span v-else class="badge badge-pill badge-secondary">
                          {{ settings.allowGeoFencing ? "YES" : "NO" }}
                      </span>
                  </div>
              </div>










              <!--<div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
        <div class="row be-datatable-body">
            <div class="col-sm-12 table-responsive">
                <table class="
          table table-fw-widget
          dataTable
          no-footer
          table-responsiv table-striped table-hover-animation
        ">
                    <thead>
                        <tr>
                            <th style="width: 10%">Send Invoice Automatically</th>
                            <th style="width: 10%">Send Auto Invoice Via SMS</th>
                            <th style="width: 10%">Send Auto Invoice Via Email</th>
                            <th style="width: 10%">Send Invoice Manually</th>
                            <th style="width: 10%">Send Manual Invoice Via SMS</th>
                            <th style="width: 10%">Send Manual Invoice Via Email</th>
                            <th style="width: 15%">
                                Default Product Expiry Notification (Days)
                            </th>
                            <th style="width: 10%">Created</th>
                            <th style="width: 15%"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                {{ settings.sendInvoiceAutomatically ? "YES" : "NO" }}
                            </td>
                            <td>
                                {{ settings.sendAutomaticInvoiceViaSms ? "YES" : "NO" }}
                            </td>
                            <td>
                                {{
                settings.sendAutomaticInvoiceViaEmail ? "YES" : "NO"
                                }}
                            </td>
                            <td>{{ settings.sendInvoiceManually ? "YES" : "NO" }}</td>
                            <td>
                                {{ settings.sendManualInvoiceViaSms ? "YES" : "NO" }}
                            </td>
                            <td>
                                {{ settings.sendManualInvoiceViaEmail ? "YES" : "NO" }}
                            </td>
                            <td>{{ settings.defaultProductExpiryNotification }}</td>
                            <td>{{ formatDate(settings.created) }}</td>
                            <td>
                                <div class="text-right"
                                     v-if="hasPermission('System_Settings_Update')">
                                    <button v-if="settings.parentId == user.parentId"
                                            class="btn btn-success btn-sm btn-rounded btn-space"
                                            @click.prevent="viewEdit(settings)">
                                        <i class="feather icon-edit"></i> &nbsp;Edit
                                    </button>
                                </div>
                            </td>
                        </tr>
                        <tr v-if="!settings">
                            <td colspan="9">
                                <div class="text-center" style="padding-top: 50px">
                                    <span style="font-size: 4.615rem"
                                          class="mdi mdi-crosshairs-gps"></span>
                                </div>
                                <h3 class="text-center" style="padding-bottom: 100px">
                                    No system settings to display.
                                </h3>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>-->
          </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-edit"
      role="dialog"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h4 class="modal-title p-1"><b>Edit Settings</b></h4>
            <button
              class="close md-close"
              type="button"
              data-dismiss="modal"
              aria-hidden="true"
              @click="reset"
            >
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
              <div class="col-12" v-if="errorMessage">
                  <div class="alert alert-danger">
                      <i class="feather icon-info"></i> {{ errorMessage }}
                  </div>
              </div>

              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="checkbox"
                                 v-model="updateSettings.sendInvoiceAutomatically" />
                          <span class="custom-control-label">Send Invoice Automatically</span>
                      </label>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="checkbox"
                                 v-model="updateSettings.sendAutomaticInvoiceViaSms"
                                 :disabled="!updateSettings.sendInvoiceAutomatically" />
                          <span class="custom-control-label">Send Automatic Invoice Via SMS</span>
                      </label>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="checkbox"
                                 v-model="updateSettings.sendAutomaticInvoiceViaEmail"
                                 :disabled="!updateSettings.sendInvoiceAutomatically" />
                          <span class="custom-control-label">Send Automatic Invoice Via Email</span>
                      </label>
                  </div>
              </div>

              <hr />

              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="checkbox"
                                 v-model="updateSettings.sendInvoiceManually" />
                          <span class="custom-control-label">Send Invoice Manually</span>
                      </label>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="checkbox"
                                 v-model="updateSettings.sendManualInvoiceViaSms"
                                 :disabled="!updateSettings.sendInvoiceManually" />
                          <span class="custom-control-label">Send Manual Invoice Via SMS</span>
                      </label>
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="checkbox"
                                 v-model="updateSettings.sendManualInvoiceViaEmail"
                                 :disabled="!updateSettings.sendInvoiceManually" />
                          <span class="custom-control-label">Send Manual Invoice Via Email</span>
                      </label>
                  </div>
              </div>

              <hr />

              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right">Default Product Expiry Notification (Days)</label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <input :class="['form-control']"
                             type="number"
                             v-model="updateSettings.defaultProductExpiryNotification" />
                  </div>
              </div>
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="checkbox"
                                 v-model="updateSettings.allowGeoFencing" />
                          <span class="custom-control-label">Allow Geo-Fencing</span>
                      </label>
                  </div>
              </div>


              <hr />
              <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label text-sm-right"></label>
                  <div class="col-12 col-sm-8 col-lg-8">
                      <b>Order Fulfillment Reconciliation</b><br /><br />
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="radio"
                                 name="autoOrderReconciliation"
                                 value="auto"
                                 v-model="orderFulfillmentReconciliationType" />
                          <span class="custom-control-label">
                              <b class="">Auto</b><br />
                              Select this option if you want fulfilled
                              orders to automatically deplete inventory
                              from the oldest to the newest
                          </span>

                      </label>
                      <br />
                      <label class="custom-control custom-checkbox custom-control-inline">
                          <input class="custom-control-input"
                                 type="radio"
                                 name="autoOrderReconciliation"
                                 value="manual"
                                 v-model="orderFulfillmentReconciliationType" />
                          <span class="custom-control-label">
                              <b class="">Manual</b><br />
                              Select this option if you want to
                              manually reconcile fulfilled orders to
                              inventory
                          </span>

                      </label>
                  </div>
              </div>

          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary"
              type="button"
              data-dismiss="modal"
              @click="reset"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              :disabled="isBusyModal"
              class="btn btn-primary"
              type="button"
              @click.prevent="update"
            >
              <span v-if="isBusyModal">Busy...</span>
              <span v-else>Update</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";
import Select2 from "@/components/Select2";
import SystemPermissions from "@/permissions";

export default {
  mixins: [SystemPermissions.Mixin],
  name: "systemSettings",
  components: {
    Select2,
  },
  created() {
    if (!this.hasPermission("System_Settings_View")) {
      this.$router.push("/restricted");
    }
    this.getSystemSettings();
  },
  data() {
    return {
      filter: {
        status: "0",
        qry: "",
        showForMe: false,
      },
      isBusy: false,
      isBusyModal: false,
      updateSettings: {
        sendInvoiceAutomatically: null,
        sendAutomaticInvoiceViaEmail: null,
        sendAutomaticInvoiceViaSms: null,
        sendInvoiceManually: null,
        sendManualInvoiceViaEmail: null,
          sendManualInvoiceViaSms: null,
          defaultProductExpiryNotification: null,
          autoOrderFulfillmentReconciliation: null,
          allowGeoFencing: null,
        id: null,
        },
        orderFulfillmentReconciliationType: '',
      settings: {},
      errorMessage: null,
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

  watch: {
    "updateSettings.sendInvoiceAutomatically": function (newVal, oldVal) {
      if (oldVal !== null && newVal === false) {
        this.updateSettings.sendAutomaticInvoiceViaEmail = false;
        this.updateSettings.sendAutomaticInvoiceViaSms = false;
      }
    },
    "updateSettings.sendInvoiceManually": function (newVal, oldVal) {
      if (oldVal !== null && newVal === false) {
        this.updateSettings.sendManualInvoiceViaEmail = false;
        this.updateSettings.sendManualInvoiceViaSms = false;
      }
    },
  },

  methods: {
    reset() {
      this.filter = {
        status: "0",
        qry: "",
        showForMe: false,
      };
    },

    viewExport() {
      //   $("#mod-export").modal("show");
    },

      viewEdit() {
          if (this.settings.autoOrderFulfillmentReconciliation) {
              this.orderFulfillmentReconciliationType = "auto";
          } else {
              this.orderFulfillmentReconciliationType = "manual";
          }
      $("#mod-edit").modal("show");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    async update() {
      if (
        this.updateSettings.sendInvoiceAutomatically &&
        !this.updateSettings.sendAutomaticInvoiceViaEmail &&
        !this.updateSettings.sendAutomaticInvoiceViaSms
      ) {
        this.errorMessage =
          "Please select at least one means of automatic notification";
      } else if (
        this.updateSettings.sendInvoiceManually &&
        !this.updateSettings.sendManualInvoiceViaEmail &&
        !this.updateSettings.sendManualInvoiceViaSms
      ) {
        this.errorMessage =
          "Please select at least one means of manual notification";
      } else if (!this.updateSettings.defaultProductExpiryNotification) {
        this.errorMessage =
          "Default Product Expiry Notification field is required";
      } else {
        this.errorMessage = null;
        this.isBusyModal = true;
          this.isBusy = true;

          var autoReconciliation = false;
          if (this.orderFulfillmentReconciliationType == 'auto') {
              autoReconciliation = true;
          }

        var data = {
          sendInvoiceAutomatically:
            this.updateSettings.sendInvoiceAutomatically,
          sendAutomaticInvoiceViaEmail:
            this.updateSettings.sendAutomaticInvoiceViaEmail,
          sendAutomaticInvoiceViaSms:
            this.updateSettings.sendAutomaticInvoiceViaSms,
          sendInvoiceManually: this.updateSettings.sendInvoiceManually,
          sendManualInvoiceViaEmail:
            this.updateSettings.sendManualInvoiceViaEmail,
            sendManualInvoiceViaSms: this.updateSettings.sendManualInvoiceViaSms,
            defaultProductExpiryNotification:
                this.updateSettings.defaultProductExpiryNotification,
            autoOrderFulfillmentReconciliation:
                autoReconciliation,
            allowGeoFencing: this.updateSettings.allowGeoFencing,
          id: this.updateSettings.id,
          parentId: this.user.parentId,
        };

        $("#mod-edit").modal("hide");

        await this.$http
          .put("/SystemSettings/update/" + data.id, data)
          .then(function (res) {
            this.isBusyModal = false;
          })
          .catch(function () {});

        this.reset();

        this.getSystemSettings();

        this.isBusy = false;
        this.isBusyModal = false;
      }
    },

    async getSystemSettings() {
      this.isBusy = true;

      await this.$http
        .get("/SystemSettings/getByAccount?parentId=" + this.user.parentId)
        .then(function (res) {
          this.settings = { ...res.body };
          this.updateSettings = { ...res.body };
        })
        .catch(function () {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },

    formatDate(date) {
      return moment(date).format("lll");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    reset() {
      this.updateSettings = {
        sendInvoiceAutomatically: null,
        sendAutomaticInvoiceViaEmail: null,
        sendAutomaticInvoiceViaSms: null,
        sendInvoiceManually: null,
        sendManualInvoiceViaEmail: null,
          sendManualInvoiceViaSms: null,
          defaultProductExpiryNotification: null,
          autoOrderFulfillmentReconciliation: null,
          allowGeoFencing: null,
        id: null,
      };
    },
  },
};
</script>