<template>
  <button
    class="btn btn-secondary btn-sm btn-rounded btn-space mr-1"
    data-toggle="tooltip"
    title="Hide"
    :disabled="hideLoading"
    @click.prevent="onHideClick"
  >
    <span
      v-if="hideLoading"
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <i class="sr-only">Loading...</i>
    </span>
    <span v-else-if="stage.show"> Hide Stage</span>
    <span v-else>UnHide Stage </span>
  </button>
</template>

<script>
export default {
  name: "HideStage",
  props: ["stage"],

  data() {
    return {
      hideLoading: false,
    };
  },

  methods: {
    onHideClick() {
      this.$emit("showHideCaution", {
        id: this.stage.id,
        text: this.stage.show
          ? "Stage will be hidden from the distributor and will not be shown during processing."
          : "Stage will make it visible to the distributor if used during processing.",
      });
    },

    async hideStage() {
      this.hideLoading = true;

      try {
        const response = await this.$http.put(
          "/PurchaseOrders/orderStage/hide/" + this.stage.id
        );

        if (response.ok && response.status === 200) this.$emit("onComplete");
        this.hideLoading = false;
      } catch (error) {
        this.hideLoading = false;
      }
    },
  },
};
</script>