<template>
  <div class="flex justify-content-between flex-mobile" v-if="itemsCount > 0">
    <div class="flex align-items-center mobile-10">
      <p>Show</p>
      <select v-model="mutateLimit" @change="onChange($event)">
        <option value="10">10</option>
        <option value="20">20</option>
        <option value="50">50</option>
        <option value="100">100</option>
      </select>
      <p>records</p>
    </div>
    <paginate
      v-model="mutatePage"
      v-if="pageCount > 1"
      :page-count="pageCount"
      :page-range="3"
      :margin-pages="2"
      :click-handler="clickCallback"
      :prev-text="'Prev'"
      :next-text="'Next'"
      :container-class="'pagination'"
      :page-class="'page-item'"
    >
    </paginate>
  </div>
</template>

<script>
import Paginate from "vuejs-paginate";

export default {
  name: "Pagination",
  props: ["limit", "count", "page", "itemsCount"],
  components: {
    Paginate,
  },

  data() {
    return {
      mutateLimit: this.limit,
      mutatePage: this.page,
      pageCount: this.count,
    };
  },

  watch: {
    count: {
      immediate: true,
      handler() {
        this.pageCount = this.count;
      },
    },
  },
  methods: {
    onChange(event) {
      this.$emit("changeLimit", event.target.value);
    },

    clickCallback: function(pageNum) {
      this.$emit("changePage", pageNum);
    },
  },
};
</script>

<style scoped>
.flex {
  display: flex;
}
p {
  margin: 0;
}
select {
  margin: 0 10px;
}

@media screen and (max-width: 767px) {
  .flex-mobile {
    flex-direction: column;
    justify-content: flex-start;
  }
  .mobile-10 {
    margin-bottom: 15px;
  }
}
</style>

<style>
.pagination {
  display: flex;
  align-items: center;
}
.pagination .page-item {
  margin: 0 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .page-item.active {
  background-color: #7367f0;
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.pagination .page-item.active a {
  color: #fff;
}

.pagination .disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
</style>
