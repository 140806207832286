import excel from "exceljs";
import * as fs from "file-saver";

/**
 *
 * @param {{ title: string, headers: string[], data: any[] }} excelData
 * @param {string} businessName
 * @param {string} businessAddress
 * @param {string[]} numericHeaders
 */
function exportExcel(
  excelData,
  businessName,
  businessAddress,
  numericHeaders = []
) {
  //Title, Header & Data
  const title = excelData.title;
  const header = excelData.headers;
  const data = excelData.data;

  //Create a workbook with a worksheet
  let workbook = new excel.Workbook();

  let worksheet = workbook.addWorksheet(
    title.length > 25 ? title.substring(0, 25) : title
  );

  //name & row
  worksheet.addRow([businessName]);
  worksheet.addRow([businessAddress]);
  worksheet.addRow([]);

  worksheet.getRow(1).font = {  size: 14, bold: true };

  //Adding Header Row
  let headerRow = worksheet.addRow(header);
  headerRow.eachCell((cell, number) => {
    cell.fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "" }, //D3D3D3
      bgColor: { argb: "" },
    };
    cell.font = {
      bold: true,
      color: { argb: "000000" },
      size: 10,
    };
  });

  // numberFormat
  const numFmt = "#,##0.00";

  // get numericHeader indices
  let headerIndices = [];
  numericHeaders.forEach((element) => {
    const idx = header.indexOf(element);
    if (idx != -1) {
      headerIndices.push(idx);
    }
  });

  // set numeric columns
  headerIndices.forEach((idx) => {
    worksheet.getColumn(idx + 1).numFmt = numFmt;
  });

  // Adding Data with Conditional Formatting
  data.forEach((d) => {
    let rowdata = Object.values(d);
    let row = worksheet.addRow(rowdata);
  });

  //Generate & Save Excel File
  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });
    fs.saveAs(blob, title + ".xlsx");
  });
}

export { exportExcel };