<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          ' card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <button class="btn btn-space btn-danger btn-rounded" @click="goBack">
            <span class="icon icon-left mdi mdi-arrow-left text-white"></span>
            Back
          </button>
          <!-- <button class="btn btn-success btn-rounded btn-space" @click="viewFilter">
            <i class="feather icon-filter text-white"></i> Filters
          </button> -->
        </div>
        <div class="card-body">
          <div class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer">
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table class="table table-fw-widget dataTable no-footer">
                  <thead>
                    <tr>
                      <th style="width: 25%">Product Name</th>
                      <th style="width: 20%">Category</th>
                      <th style="width: 25%">Description</th>
                      <th style="width: 15%">Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="o in products" :key="o.id">
                      <td>{{ o.name }} ({{ o.code }})</td>

                      <td>
                        {{ o.manufacturerProduct.category.name }} / {{ o.manufacturerProduct.name }}
                      </td>
                      <td>{{ o.description }}</td>
                      <td>
                        {{ `&#8358;${formatMoney(o.retailPrice)}` }} /
                        {{ o.manufacturerProduct.unit }}
                      </td>
                    </tr>
                    <tr v-if="!products.length">
                      <td colspan="10">
                        <div class="text-center" style="padding-top: 50px">
                          <span style="font-size: 4.615rem" class="mdi mdi-basket"></span>
                        </div>
                        <h3 class="text-center" style="padding-bottom: 100px">
                          No product available
                        </h3>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <Pagination
                  :limit="pageSize"
                  :count="pageCount"
                  :page="page"
                  :itemsCount="count"
                  @changePage="gotoPage"
                  @changeLimit="changeLimit"
                />
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5" v-if="products.length">
                <div class="dataTables_info">{{ count }} product{{ count != 1 ? "s" : "" }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            />
          </svg>
        </div>
      </div>
    </div>

    <div
      class="modal fade colored-header colored-header-primary"
      id="mod-filter"
      role="dialog"
      tabindex="-1"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header modal-header-colored">
            <h3 class="modal-title">Filter</h3>
            <button class="close md-close" type="button" data-dismiss="modal" aria-hidden="true">
              <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">State</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select class="form-control" placeholder="Select an option" v-model="filter.state">
                  <option disabled selected>Select State</option>
                  <option v-for="(el, index) in states" :key="index" :value="el">{{ el }}</option>
                </select>
              </div>
            </div>
            <div class="form-group row">
              <label class="col-12 col-sm-3 col-form-label text-sm-right">LGA</label>
              <div class="col-12 col-sm-8 col-lg-8">
                <select class="form-control" placeholder="Select an option" v-model="filter.lga">
                  <option v-for="(el, index) in lgas" :key="index" :value="el">{{ el }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              data-dismiss="modal"
            >
              Cancel
            </button>
            <button
              style="width: 100px"
              class="btn btn-secondary md-close"
              type="button"
              @click="reset"
            >
              Reset
            </button>
            <button
              style="width: 100px"
              class="btn btn-primary md-close"
              type="button"
              @click="getProducts"
            >
              Search
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import Pagination from "../components/Pagination.vue";

export default {
  name: "DistributorProducts",
  components: {
    Pagination,
  },

  mounted: function() {},

  created() {
    // if (!this.hasPermission("Orders_View")) {
    //   this.$router.push("/restricted");
    // }
    const id = this.$route.params.id;

    console.log(id);

    // this.filter = {
    //   state,
    //   lga,
    // };

    this.getProducts(id);
  },

  data() {
    return {
      filter: {
        state: "",
        lga: "",
      },

      isBusy: false,
      isBusyModal: false,
      count: 0,
      page: 1,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,

      products: [],
      states: helper.getStates(),
      search: "",
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },

    lgas: function() {
      return helper.getLgas(this.filter.state);
    },
  },

  updated() {},

  methods: {
    reset() {
      this.filter = {
        state: "",
        lga: "",
      };
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    viewFilter() {
      $("#mod-filter").modal("show");
    },

    prev() {
      this.page -= 1;
      this.getProducts();
    },

    next() {
      this.page += 1;
      this.getProducts();
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    gotoPage(page) {
      this.page = page - 1;
      this.getProducts();
    },

    changeLimit(limit) {
      this.pageSize = limit;
      this.getProducts();
    },

    async getProducts(id) {
      this.isBusy = true;

      await this.$http
        .get(`/Merchant/distributor/${id}/product`)
        .then(function(res) {
          this.products = res.body.data ? res.body.data : [];
          this.page = res.body.page;
          this.pageSize = res.body.pageSize;
          this.count = res.body.count;
          this.hasPrev = res.body.hasPrev;
          this.hasNext = res.body.hasNext;
          this.pageCount = Math.ceil(res.body.count / res.body.pageSize);
          this.isBusy = false;
        })
        .catch(function() {});

      $("#mod-filter").modal("hide");
      this.isBusy = false;
    },
  },
};
</script>

<style scoped>
.search-distro {
  width: 238px;
  height: 38px;
  background: #fcfcfc;
  border: 1px solid #bebebe;
  border-radius: 5px;
  padding: 0 10px;
}
</style>
