<template>
  <div>
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <div class="btn-group" role="group" aria-label="Basic example">
            <button
              class="btn btn-success btn-rounded btn-space mb-2"
              @click="goBack"
            >
              <i class="mdi icon mdi-backburger text-white"></i>
              Back
            </button>
            <button
              v-print="'#printMe'"
              class="btn btn-primary btn-rounded btn-space mb-2"
            >
              <i class="icon icon-left mdi mdi-plus text-white"></i>
              Print
            </button>
          </div>
          <button
            v-print="'#printMe2'"
            class="btn btn-success btn-rounded btn-space mb-2"
          >
            <i class="icon icon-left mdi mdi-printer text-white"></i>
            Print to POS
          </button>
        </div>
      </div>
      <div id="printing">
        <div id="printMe" class="card-body">
          <div class="row justify-content-end">
            <div class="col col-md-4 mb-5">
              <h2 class="font-weight-bolder">PURCHASE ORDER</h2>
              <h4 class="font-weight-bolder">
                PO Number:- {{ details.orderNumber }}
              </h4>
              <h4 class="font-weight-bolder">
                PO Date: {{ formatDate(details.updated) }}
              </h4>

              <h4 class="font-weight-bolder">
                Status:
                <span
                  :class="[
                    'badge',
                    ' badge-pill',
                    {
                      'badge-success': details.status === 'confirmed',
                    },
                    {
                      'badge-danger': details.status === 'withdrawn',
                    },
                    {
                      'badge-secondary': details.status === 'draft',
                    },
                    {
                      'badge-warning': details.status === 'submitted',
                    },
                    {
                      'badge-dark': details.status === 'recalled',
                    },
                  ]"
                  >{{
                    details.stage ? details.stage.name : details.status
                  }}</span
                >
              </h4>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col mt-3">
                <h4 class="font-weight-bold">Purchase from:</h4>
                <h2 class="font-weight-bolder">
                  {{ details.sellerBusinessName }}
                </h2>
                <h4 class="font-weight-bold">Supplier Address from:</h4>
                <h4>{{ details.sellerAddress }}</h4>

                <h4 class="mt-2">{{ details.sellerPhoneNumber }}</h4>
                <h4>{{ details.sellerEmailAddress }}</h4>
                <h4>
                  <b>Contact Name: {{ details.sellerContactName }} </b>
                </h4>
              </div>
              <div class="col mt-3">
                <h4 class="font-weight-bold">Dispatch to:</h4>
                <h2 class="font-weight-bolder">
                  {{ details.buyerName }}
                </h2>
                <h4 class="font-weight-bold">Delivery Address:</h4>
                <h4>
                  Delivery Address:
                  {{ details.buyerAddress }}
                </h4>

                <h4 class="mt-2">{{ details.buyerPhoneNumber }}</h4>
                <h4>{{ details.buyerEmailAddress }}</h4>

                <h4 class="mt-1">
                  <b>Contact Details: </b>
                </h4>
                <h3>
                  {{ details.buyerContactName }}
                  <br />
                  {{ details.buyerContactPhoneNumber }}
                  <br />
                  {{ details.buyerContactEmailAddress }}
                </h3>
              </div>
            </div>
          </div>
          <h3 class="font-weight-bold mt-5">Purchase Order Details</h3>
          <div class="table-responsive">
            <table class="table table-hover table-sm">
              <thead class="thead-light">
                <tr>
                  <th scope="col">Item #</th>
                  <th scope="col">Description</th>
                  <th scope="col">Quantity</th>
                  <th scope="col">Total</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in items" :key="item.id">
                  <td>{{ index + 1 }}</td>
                  <td>{{ item.description }}</td>
                  <td>{{ item.quantity }}</td>
                  <td>&#8358;{{ formatMoney(item.quantity * item.price) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="col mt-5">
            <div class="row justify-content-end">
              <div class="col col-md-3 align-self-end">
                <div class="row justify-content-betweeen">
                  <div class="col-6">
                    <b class="text-uppercase">Sub-total</b>
                  </div>
                  <div class="col-6">
                    <p>&#8358; {{ total }}</p>
                  </div>
                </div>
                <div class="row justify-content-betweeen">
                  <div class="col-6">
                    <b class="text-uppercase">Total Payable</b>
                  </div>
                  <div class="col-6">
                    <p>&#8358; {{ total }}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <b>Special Instructions</b>
          <p>{{ details.specialInstructions }}</p>
        </div>
      </div>
    </div>
    <div id="printMe2" class="card-body">
      <div class="col" id="printout">
        <div class="col">
          <h2 class="font-weight-bolder">PURCHASE ORDER</h2>
          <h4 class="font-weight-bolder">
            PO Number:- {{ details.orderNumber }}
          </h4>
          <h4 class="font-weight-bolder">
            PO Date: {{ formatDate(details.created) }}
          </h4>

          <h4 class="font-weight-bolder">
            Status:
            <span
              :class="[
                'badge',
                ' badge-pill',
                {
                  'badge-success': details.status === 'confirmed',
                },
                {
                  'badge-danger': details.status === 'withdrawn',
                },
                {
                  'badge-secondary': details.status === 'draft',
                },
                {
                  'badge-warning': details.status === 'submitted',
                },
                {
                  'badge-dark': details.status === 'recalled',
                },
              ]"
              >{{ details.status ? details.status : "" }}</span
            >
          </h4>
        </div>
      </div>
      <div class="col">
        <div class="col" id="printout">
          <div class="mb-3">
            <h4 class="font-weight-bold">Purchase from:</h4>
            <h2 class="font-weight-bolder">
              {{ details.sellerBusinessName }}
            </h2>
            <h4 class="font-weight-bold">Supplier Address from:</h4>
            <h4>{{ details.sellerAddress }}</h4>

            <h4 class="mt-2">{{ details.sellerPhoneNumber }}</h4>
            <h4>{{ details.sellerEmailAddress }}</h4>
            <h4>
              <b>Contact Name: {{ details.sellerContactName }} </b>
            </h4>
          </div>
          <div>
            <h4 class="font-weight-bold">Dispatch to:</h4>
            <h2 class="font-weight-bolder">
              {{ details.buyerName }}
            </h2>
            <h4 class="font-weight-bold">Delivery Address:</h4>
            <h4>
              Delivery Address:
              {{ details.buyerAddress }}
            </h4>

            <h4 class="mt-2">{{ details.buyerPhoneNumber }}</h4>
            <h4>{{ details.buyerEmailAddress }}</h4>

            <h4 class="mt-1">
              <b>Contact Details: </b>
            </h4>
            <h3>
              {{ details.buyerContactName }}
              <br />
              {{ details.buyerContactPhoneNumber }}
              <br />
              {{ details.buyerContactEmailAddress }}
            </h3>
          </div>
          <div></div>
        </div>
      </div>
      <h4 class="font-weight-bolder">Cash Recipes</h4>
      <h3 class="font-weight-bold mt-5" id="table-title">
        Purchase Order Details
      </h3>
      <div class="table-responsive">
        <table class="table table-hover table-sm">
          <thead class="thead-light">
            <tr>
              <th scope="col">Description</th>
              <th scope="col">Qty.</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in items" :key="item.id">
              <td>{{ item.description }}</td>
              <td>{{ item.quantity }}</td>
              <td>&#8358;{{ formatMoney(item.quantity * item.price) }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col mt-5" style="width: 88mm" id="table-title">
        <div class="row justify-content-end">
          <div class="col col-md-3 align-self-end" style="font-size: 0.7em">
            <div class="row justify-content-betweeen">
              <div class="col-6">
                <b class="text-uppercase">Sub-total</b>
              </div>
              <div class="col-6">
                <p>&#8358; {{ total }}</p>
              </div>
            </div>
            <div class="row justify-content-betweeen">
              <div class="col-6">
                <b class="text-uppercase">Total Payable</b>
              </div>
              <div class="col-6">
                <p>&#8358; {{ total }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <b style="font-size: 0.7em; margin-left: 2rem">Special Instructions</b>
      <p style="font-size: 0.7em; margin-left: 2rem">
        {{ specialInstructions }}
      </p>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { helper } from "@/helper";

export default {
  data() {
    return {
      isBusy: false,
      orderId: "",
      items: [],
      details: {},
      sellerBusinessName: "",
      sellerAddress: "",
      sellerPhoneNumber: "",
      sellerEmailAddress: "",
      sellerContactName: "",
      buyerName: "",
      buyerAddress: "",
      buyerPhoneNumber: "",
      buyerEmailAddress: "",
      buyerContactName: "",
      buyerContactPhoneNumber: "",
      buyerContactEmail: "",
      specialInstructions: "",
      status: "",
      created: "",
      orderNumber: "",
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },

    total: function () {
      let amount = 0;
      this.items.forEach((i) => (amount += i.quantity * i.price));
      return amount;
    },
  },

  created() {
    this.orderId = this.$route.params.id;
  },

  mounted() {
    this.viewOrder();
  },

  methods: {
    async viewOrder() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/view/" + this.orderId
        );

        if (response.ok) {
          this.items = [];
          this.details = response.body;
          this.items = response.body.details;
        }

        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
      // console.log(this.details);
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },
  },
};
</script>

<style scoped>
@media print {
  #printout {
    width: 88mm;
    text-align: center;
  }
  table {
    width: 88mm;
    margin-left: 2rem;
  }
  #table-title {
    margin-left: 2rem;
  }
  button {
    display: none;
    visibility: hidden;
  }
  #printing {
    display: none !important;
    visibility: hidden !important;
  }
  #printMe2 {
    visibility: visible !important;
  }
}
#printMe2 {
  visibility: hidden;
}
</style>
