<template>
  <div id="paymentInvoice" style="display: none" class="card-body bg-white text-dark">
    <div class="container my-2">
      <div class="row">
        <div class="col-md-12">
          <div class="invoice-title">
            <div class="row">
              <div class="col-md-4">
                <img class="logo-img" :src="url" alt="logo" max-width="138" height="37" />
              </div>
              <div class="col-md-8 text-right">
                <address>
                  <p style="font-size: 14px; margin: 0 0 8px 0">
                    <span style="color: #9c9c9c">Date:</span> {{ formatDate(order.created) }}
                  </p>
                  <p style="font-size: 14px; margin: 0">
                    <span style="color: #9c9c9c">RN:</span> {{ order.orderNumber }}
                  </p>
                </address>
              </div>
            </div>
            <hr />
          </div>
          <div class="row mt-3 mb-4">
            <div class="col-md-6">
              <address style="width: 200px">
                <p
                  style="font-size: 18px; line-height: 23px; font-weight: bold; margin-bottom: 8px"
                >
                  {{ order.seller ? order.seller.name : "NA" }}
                </p>
                <p style="font-size: 14px; margin: 0 0 8px 0">
                  {{ order.seller ? order.seller.phoneNumber : "NA" }}
                </p>
                <p style="font-size: 14px; margin: 0 0 8px 0">
                  {{ order.seller ? order.seller.emailAddress : "NA" }}
                </p>
                <p style="font-size: 14px; margin: 0 0 8px 0">
                  {{ order.seller ? order.seller.address : "NA" }}
                </p>
              </address>
            </div>
            <div class="col-md-6 text-right">
              <address>
                <p
                  style="font-size: 18px; line-height: 23px; font-weight: bold; margin-bottom: 8px"
                >
                  To: {{ order.maker ? order.maker.displayName : "N/A" }}
                </p>
                <p style="font-size: 14px; margin: 0 0 8px 0">
                  <span style="color: #9c9c9c">Number:</span>
                  {{ order.maker ? order.maker.phoneNumber : "N/A" }}
                </p>
                <p style="font-size: 14px; margin: 0">
                  <span style="color: #9c9c9c">Address:</span>
                  {{ order.maker ? order.maker.deliveryAddress : "N/A" }}
                </p>
              </address>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="panel panel-default">
            <div
              class="panel-heading pl-2 mb-2"
              style="height: 71px; background: rgba(221, 221, 221, 0.15);display: flex; align-items: center"
            >
              <h3 class="panel-title">
                <strong>Order summary</strong>
              </h3>
            </div>
            <div class="panel-body">
              <div class="table-responsive">
                <table class="table table-condensed table-borderless">
                  <thead style="height: 75px; background: #ddedaa26; color: #373737">
                    <tr>
                      <td style="width: 45%">
                        Item Description
                      </td>
                      <td style="width: 20%; text-align: center" class="text-center">
                        Price (NGN)
                      </td>
                      <td style="width: 15%; text-align: center" class="text-center">
                        Quantity
                      </td>
                      <td style="width: 20%; text-align: right" class="text-right">
                        Amount (NGN)
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in order.details" :key="index">
                      <td>{{ item.code }} - {{ item.description }}</td>
                      <td class="text-center font-weight-light" style="text-align: center">
                        &#8358; {{ formatMoney(item.unitPrice) }}
                      </td>
                      <td class="text-center font-weight-light" style="text-align: center">
                        {{ item.quantity }}
                      </td>
                      <td class="text-right font-weight-light" style="text-align: right">
                        &#8358;
                        {{ formatMoney(item.quantity * item.unitPrice) }}
                      </td>
                    </tr>

                    <tr>
                      <td colspan="3">&nbsp;</td>
                    </tr>

                    <tr>
                      <td class="thick-line"></td>
                      <td class="thick-line"></td>
                      <td class="thick-line" style="color: #373737">
                        Subtotal
                      </td>
                      <td class="thick-line text-right" style="text-align: right; color: #373737">
                        <strong>&#8358; {{ formatMoney(order.subtotal) }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="no-line">
                        Please make all transfers to {{ account ? account.accountName : "NA" }}
                        <span v-if="bank">
                          - {{ account.accountNumber }} {{ account.bankName }}
                        </span>
                        . All cheques should be made out to
                        {{ account ? account.accountName : "NA" }}
                        <span v-if="bank">
                          - {{ account.accountNumber }} {{ account.bankName }}
                        </span>
                      </td>
                      <td class="no-line"></td>
                      <td class="no-line" style="color: #373737">
                        Discount
                      </td>
                      <td class="no-line text-right" style="text-align: right; color: #373737">
                        <strong>&#8358; {{ formatMoney(order.discount) }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="no-line"></td>
                      <td class="no-line"></td>
                      <td class="no-line" style="color: #373737">
                        Payment Due
                      </td>
                      <td class="no-line text-right" style="text-align: right; color: #373737">
                        <strong>&#8358; {{ formatMoney(order.total) }}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div class="py-2 mt-1" style="border-top: 1px solid rgba(55, 55, 55, 0.5);">
                <p style="font-size: 14px; margin: 0">Notes or Comments</p>
              </div>
              <hr />
              <div class="row">
                <div class="col-md-6">
                  <img src="https://app.wekurnect.ng/assets/img/logo.png" alt="img" />
                </div>
                <div class="col-md-6 text-right">
                  Invoice generated from weKurnect
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { helper } from "@/helper";
export default {
  name: "payment-invoice",
  props: ["order", "bank", "defaultAccount"],
  components: {},

  data() {
    return {
      url: null,
      account: {},
    };
  },

  computed: {
    user: function() {
      return this.$store.state.currentUser;
    },
  },

  watch: {
    bank: function(value) {
      if (value) {
        const baseUrl = process.env.VUE_APP_BASE_API_URL.split("/v1")[0];
        this.url = baseUrl + value.logoURL;
      }
    },
    defaultAccount: function(value) {
      if (value) {
        this.account = value;
      }
    },
  },

  methods: {
    formatMoney(val) {
      return helper.formatMoney(val);
    },
    formatDate(date) {
      return moment(date).format("ll");
    },
  },
};
</script>
