<template>
  <div class="row">
    <div class="col-lg-12">
      <div
        :class="['card', 'card-border-color', 'card-border-color-primary', 'be-loading', {'be-loading-active':isBusy}]"
      >
          <div class="card-header">
              <h4 class="p-1"><b>{{ account.name }}</b></h4>
              <div class="btn-group">
                  <router-link class="btn btn-space btn-primary btn-rounded float-right"
                               :to="'/manufacturers/' + account.id + '/edit'">
                      <span class="icon icon-left mdi mdi-pencil text-white"></span> Edit manufacturer
                  </router-link>

                  <router-link class="btn btn-space btn-primary btn-rounded float-right"
                               to="/manufacturers">
                      <span class="icon icon-left mdi mdi-arrow-left text-white"></span> Back
                  </router-link>
              </div>
          </div>
        <div class="card-body">
          <h4>System</h4>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Id:</label>
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ account.id }}</div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Status:</label>
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
              <span class="badge badge-pill badge-secondary">
                {{ account.active? 'Active' : 'Inactive'
                }}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Created:</label>
            <div
              class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left"
            >{{ formatDate(account.created) }}</div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Last Updated:</label>
            <div
              class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left"
            >{{ formatDate(account.updated) }}</div>
          </div>

          <hr>

          <h4>Business profile</h4>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Name:</label>
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ account.name }}</div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Parent:</label>
            <div
              class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left"
            >{{ account.parent? account.parent.name : 'N/A' }}</div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Description:</label>
            <div class="col-12 col-sm-9 col-lg-9 col-form-label text-sm-left">{{ account.description }}</div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">RC Number:</label>
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ account.rcNumber }}</div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Tax Identification Number:</label>
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ account.tin }}</div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Website:</label>
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ account.website }}</div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Email Address:</label>
            <div
              class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left"
            >{{ account.emailAddress }}</div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Phone Number:</label>
            <div
              class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left"
            >{{ account.phoneNumber }}</div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Business Address:</label>
            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ account.address }}</div>
          </div>

          <hr>

          <h4>Contact information</h4>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Contact Person's Name:</label>
            <div
              class="col-12 col-sm-9 col-lg-9 col-form-label text-sm-left"
            >{{ account.contact.name }}</div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Email Address:</label>
            <div
              class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left"
            >{{ account.contact.emailAddress }}</div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Phone Number:</label>
            <div
              class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left"
            >{{ account.contact.phoneNumber }}</div>
          </div>

          <hr>

          <h4>Bank information</h4>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Bank:</label>
            <div
              class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left"
            >{{ account.bank.name }}</div>
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Account Number:</label>
            <div
              class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left"
            >{{ account.bank.accountNumber }}</div>
          </div>

          <!-- <hr>

          <h4>Others</h4>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Credit Limit:</label>
            <div
              class="col-12 col-sm-9 col-lg-9 col-form-label text-sm-left"
            >{{ account.hasCredit? `&#8358; ${formatMoney(account.creditLimit)}` : 'Not Available' }}</div>
          </div>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Discounts:</label>
            <div
              class="col-12 col-sm-9 col-lg-9 col-form-label text-sm-left"
            >{{ account.discount? `${account.discount.name} - ${account.discount.type!='Percent'?'&#8358;':''} ${formatMoney(account.discount.value)}${account.discount.type=='Percent'?'%':''}` : 'Not Available' }}</div>
          </div>

          <div class="form-group row">
            <label class="col-12 col-sm-3 col-form-label text-sm-right">Groups:</label>
            <div
              class="col-12 col-sm-9 col-lg-9 col-form-label text-sm-left"
            >{{ account.groups.length? account.groups.map(e => e.name).join(", "): 'Not Available'}}</div>
          </div>-->
        </div>
        <div class="be-spinner">
          <svg width="40px" height="40px" viewBox="0 0 66 66">
            <circle
              class="circle"
              fill="none"
              stroke-width="4"
              stroke-linecap="round"
              cx="33"
              cy="33"
              r="30"
            ></circle>
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import moment from "moment";
import { helper } from "@/helper";

export default {
  name: "manufacturerDetails",
  data() {
    return {
      isBusy: false,
      account: {
        parent: {},
        contact: {},
        bank: {},
        groups: [],
        discount: {}
      }
    };
  },
  created() {
    this.getMerchant();
  },
  computed: {
    backPath: function() {
      return this.$store.state.prevRoute;
    }
  },
  methods: {
    getMerchant() {
      var id = this.$route.params.id;

      this.$http
        .get("/Accounts/getById/" + id)
        .then(function(res) {
          this.account = res.body;
        })
        .catch(function() {});
    },
    formatDate(date) {
      return date ? moment(date).format("ll") : "";
    },
    formatMoney(val) {
      return helper.formatMoney(val);
    }
  }
};
</script>