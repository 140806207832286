<template>
  <form @submit.prevent="register">
    <div>
      <label class="merchant-login-label"> FIRST NAME* </label>
      <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.firstName }]">
        <input type="text" placeholder="Enter first name" v-model="firstName" />
      </div>
      <p v-if="credentialValidation.firstName">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">First name cannot be empty</span>
      </p>
    </div>

    <div>
      <label class="merchant-login-label mt-2"> LAST NAME* </label>
      <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.lastName }]">
        <input type="text" placeholder="Enter last name" v-model="lastName" />
      </div>
      <p v-if="credentialValidation.lastName">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Last name cannot be empty</span>
      </p>
    </div>

    <div>
      <label class="merchant-login-label mt-2"> USERNAME* </label>
      <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.username }]">
        <input type="text" placeholder="Enter Username" v-model="username" />
      </div>
      <p class="onboarding-helper">You will need this to <span>Log in</span></p>
      <p v-if="credentialValidation.username">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Username cannot be empty</span>
      </p>
    </div>

    <div>
      <label class="merchant-login-label mt-2"> MOBILE NUMBER* </label>
      <div
        :class="[
          'merchant-input-div',
          { 'is-invalid': credentialValidation.phone || credentialValidation.phoneValid },
        ]"
      >
        <p class="country-code">+234</p>
        <input type="tel" placeholder="Enter your mobile number" v-model="phone" />
      </div>
      <p v-if="credentialValidation.phone">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Phone number cannot be empty</span>
      </p>
      <p v-if="credentialValidation.phoneValid">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Input a valid phone number (11 digits)</span>
      </p>
    </div>

    <div>
      <label class="merchant-login-label mt-2"> DISTRIBUTOR CODE </label>
      <div class="merchant-input-div">
        <input type="text" placeholder="Enter distributor code" v-model="distributorCode" />
      </div>
      <p class="onboarding-helper">If you have a distributor's code, please input</p>
    </div>

    <div class="mt-2">
      <label class="merchant-login-label"> CREATE PASSWORD* </label>
      <div
        :class="[
          'merchant-input-div',
          {
            'is-invalid': credentialValidation.password || credentialValidation.passwordLength,
          },
        ]"
      >
        <input :type="passwordType" placeholder="Enter Password" v-model="password" />
        <button type="button" class="togglePassword" @click.prevent="togglePassword">
          Show
        </button>
      </div>
      <p v-if="credentialValidation.password">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Password cannot be empty</span>
      </p>
      <p v-if="credentialValidation.passwordLength">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Password must be at least 6 characters</span>
      </p>
    </div>

    <p class="mt-2">
      By clicking the "Create My Account" button, you agree to
      <span style="font-weight: 700">GrowTrade's{{ " " }}</span>
      <span style="color:#38369a">Terms Of Acceptable Use</span>
    </p>
    <button type="submit" class="login-submit-btn">
      <span v-if="isBusy" class="spinner-border spinner-border-sm" role="status">
        <i class="sr-only"></i>
      </span>
      <span v-else>
        Create My Account
      </span>
    </button>
    <p class="signup-text">
      Already have an account?
      <button type="button" class="signup-btn" @click="goToLogin">Sign In</button>
    </p>
  </form>
</template>

<script>
/* eslint-disable */
import { helper } from "@/helper";

export default {
  name: "merchant-registration-form",
  components: {},

  data() {
    return {
      checked: false,
      passwordType: "password",
      username: null,
      password: null,
      firstName: null,
      lastName: null,
      phone: null,
      distributorCode: null,
      credentialValidation: {
        username: false,
        password: false,
        passwordLength: false,
        firstName: false,
        lastName: false,
        phone: false,
        phoneValid: false,
      },
      isBusy: false,
    };
  },

  mounted() {},

  beforeCreate() {},

  created() {},

  computed: {
    // user: function() {
    //   return this.$store.state.currentUser;
    // },
  },

  methods: {
    togglePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },

    async register() {
      this.isBusy = true;

      if (this.validateUserInfo()) {
        let data = {
          username: this.username,
          password: this.password,
          phoneNumber: this.phone,
          firstName: this.firstName,
          lastName: this.lastName,
          distributorCode: this.distributorCode,
        };

        console.log(data);

        await this.$http
          .post(`/Merchant`, data)
          .then(function(res) {
            $("#mod-growtrade-onboarding-status").modal("show");

            this.isBusy = false;
          })
          .catch(function() {
            this.isBusy = false;
          });
      } else {
        this.isBusy = false;
      }
    },

    validateUserInfo() {
      this.credentialValidation = {
        username: false,
        password: false,
        passwordLength: false,
        firstName: false,
        lastName: false,
        phone: false,
        phoneValid: false,
      };

      let isValid = true;

      if (!this.username) {
        this.credentialValidation.username = true;
        isValid = false;
      }

      if (!this.firstName) {
        this.credentialValidation.firstName = true;
        isValid = false;
      }

      if (!this.lastName) {
        this.credentialValidation.lastName = true;
        isValid = false;
      }

      if (!this.phone) {
        this.credentialValidation.phone = true;
        isValid = false;
      }

      if (this.phone && !helper.validatePhone(this.phone)) {
        this.credentialValidation.phoneValid = true;
        isValid = false;
      }

      if (!this.password) {
        this.credentialValidation.password = true;
        isValid = false;
      }

      if (this.password && !helper.validateLength(this.password, 6)) {
        this.credentialValidation.passwordLength = true;
        isValid = false;
      }

      return isValid;
    },

    goToLogin() {
      this.$router.push(`/growtrade-login?client=merchant`);
    },
  },
};
</script>

<style scoped>
.merchant-login-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #18191f;
  padding: 0;
  margin-bottom: 16px;
}

.merchant-input-div {
  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
}

.merchant-input-div:focus-within {
  border: 1px solid #18191f;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}

.togglePassword {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  line-height: 17px;
  color: #38369a;
}
input {
  border: none;
  outline: none;
  width: 100%;
}

.login-submit-btn {
  background: #675ce8;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  outline: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin: 16px 0 8px;
  border: none;
}

.signup-btn {
  border: none;
  font-size: 18px;
  font-weight: 700;
  padding: 0;
  line-height: 28px;
  outline: none;
  background: transparent;
  color: #001688;
}

.signup-text {
  font-size: 18px;
  color: rgba(24, 25, 31, 0.5);
  margin-bottom: 60px;
}

.country-code {
  margin: 0 10px 0 0;
}

.onboarding-helper {
  font-size: 14px;
  line-height: 142.52%;
  color: rgba(24, 25, 31, 0.7);
  margin-top: 8px;
}

.onboarding-helper span {
  font-weight: 700;
}

.is-invalid {
  border: 1px solid #ff6767;
}

.login-error {
  font-size: 12px;
  line-height: 15px;
  color: #ff6767;
  margin-left: 5px;
}
@media screen and (max-width: 767px) {
  .merchant-input-div,
  .login-submit-btn {
    width: 100%;
  }
}
</style>
