<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header d-flex justify-content-between">
          <p class="card-title">
            Manage price options for
            {{ product.name }}
          </p>
          <div class="btn-group" role="group" aria-label="Basic example">
            <button
                    v-if="hasPermission('Price_Option_Create')"
              class="btn btn-primary btn-rounded btn-space"
              @click="addOption"
            >
              <i class="icon icon-left mdi mdi-plus text-white"></i>
              Add Price Option
            </button>
            <button class="btn btn-info btn-rounded btn-space" @click="filter">
              <i class="feather icon-filter text-white"></i>
              Filter
            </button>
            <button
              class="btn btn-success btn-rounded btn-space"
              @click="goBack"
            >
              <i class="mdi icon mdi-backburger text-white"></i>
              Back
            </button>
          </div>
        </div>
        <div class="card-body">
          <div
            class="dataTables_wrapper container-fluid dt-bootstrap4 no-footer"
          >
            <div class="row be-datatable-body">
              <div class="col-sm-12 table-responsive">
                <table
                  class="
                    table table-fw-widget
                    dataTable
                    no-footer
                    table-responsiv table-striped table-hover-animation
                  "
                >
                  <thead>
                    <tr>
                      <th style="width: 8%">Name</th>
                      <th style="width: 16%">Description</th>
                      <th style="width: 10%">Distributor Price</th>
                      <th style="width: 10%">Retail Price</th>
                      <th style="width: 10%">Added</th>
                      <th style="width: 6%">Status</th>
                      <th style="width: 4%">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="op in priceOptions" :key="op.id">
                      <td>{{ op.name }}</td>
                      <td>{{ op.description }}</td>
                      <td>&#8358; {{ formatMoney(op.distributorPrice) }}</td>
                      <td>&#8358; {{ formatMoney(op.retailPrice) }}</td>
                      <td>{{ formatDate(op.created) }}</td>
                      <td>
                        <SwitchStatus
                          :optionPrice="op"
                          @onComplete="onActionComplete"
                        />
                      </td>
                      <td>
                        <div class="d-flex">
                          <button
                    v-if="hasPermission('Price_Option_Update')"
                            data-toggle="tooltip"
                            title="Edit"
                            class="
                              btn btn-success btn-sm btn-rounded btn-space
                              mr-1
                            "
                            @click.prevent="editPriceOption(op)"
                          >
                            <i
                              class="
                                icon
                                mdi mdi-circle-edit-outline
                                text-white
                              "
                            ></i>
                          </button>
                          <DeletePriceOption
                    v-if="hasPermission('Price_Option_Delete')"
                            :id="op.id"
                            @onComplete="onActionComplete"
                            @showCaution="onDelete"
                            :ref="op.id"
                          />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="row be-datatable-footer">
              <div class="col-sm-5">
                <div class="dataTables_info">
                  {{ count }} Distributor price{{ count != 1 ? "s" : "" }}
                </div>
              </div>
              <div class="col-sm-7">
                <div class="dataTables_paginate paging_simple_numbers">
                  <ul class="pagination">
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasPrev },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="prev"
                        >Previous</a
                      >
                    </li>
                    <li
                      :class="[
                        'paginate_button',
                        'page-item',
                        'previous',
                        { disabled: !hasNext },
                      ]"
                    >
                      <a href="#" class="page-link" @click.prevent="next"
                        >Next</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddEdit
      :priceOption="priceOption"
      :showUpdate="showUpdate"
      @hide="hideModal"
      @onComplete="onActionComplete"
      :productId="product.id"
    />
    <DeleteCaution :id="id" @proceed="deletePriceOption" entity="DP" />
    <PriceOptionFilter @onFilter="performFiltering" />
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";
import { helper } from "@/helper";
import AddEdit from "../components/PriceOption/AddEditPriceOption";
import DeletePriceOption from "../components/ManageOptionPrice/DeletePriceOption";
import DeleteCaution from "../components/DeleteCaution.vue";
import PriceOptionFilter from "../components/PriceOption/Filter";
    import SwitchStatus from "../components/ManageOptionPrice/SwitchPriceOptionStatus";
    import SystemPermissions from "@/permissions";

    export default {
        mixins: [SystemPermissions.Mixin],
  components: {
    AddEdit,
    DeleteCaution,
    PriceOptionFilter,
    DeletePriceOption,
    SwitchStatus,
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      name: "",
      active: "",
      count: 0,
      pageNumber: 1,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
      showUpdate: false,
      priceOptions: [],
      priceOption: null,
      activeLoading: false,
      deleteLoading: false,
      id: null,
      product: {},
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },
  },

        created() {
            if (!this.hasPermission("Price_Option_View")) {
                this.$router.push("/restricted");
            }
    this.productId = this.$route.params.id;
    this.getProduct();
    this.getPriceOptions();
  },

  methods: {
    async getProduct() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/Products/getByIdForManufacturer/" + this.productId
        );
        if (response.ok) this.product = response.body;
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    addOption() {
      $("#mod-price-option-add").modal("show");
    },

    editPriceOption(priceOption) {
      this.showUpdate = true;
      this.priceOption = priceOption;
      $("#mod-price-option-add").modal("show");
    },

    onDelete(id) {
      this.id = id;
      $("#mod-delete-caution").modal("show");
    },

    onActionComplete() {
      this.getPriceOptions();
    },

    async deletePriceOption(id) {
      $("#mod-delete-caution").modal("hide");
      const componenet = this.$refs[id][0];
      componenet.deleteDistributorPrice();
    },

    filter() {
      $("#mod-dp-filter").modal("show");
    },

    hideModal() {
      $("#mod-price-option-add").modal("hide");
      this.showUpdate = false;
      this.priceOption = null;
    },

    onActionComplete() {
      this.hideModal();
      this.getPriceOptions();
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    async getPriceOptions() {
      try {
        this.isBusy = true;

        const response = await this.$http.get(
          "/PriceOptions/getList?pageNumber=" +
            this.pageNumber +
            "&pageSize=" +
            this.pageSize +
            "&name=" +
            this.name +
            "&active=" +
            this.active +
            "&ProductId=" +
            this.productId
        );
        if (response.ok && response.status === 200) {
          this.priceOptions = response.body.data;
          this.pageNumber = response.body.pageNumber;
          this.pageSize = response.body.pageSize;
          this.count = response.body.count;
          this.hasPrev = response.body.hasPrev;
          this.hasNext = response.body.hasNext;
        }
      } catch (error) {
        this.isBusy = false;
      }
    },

    async performFiltering(filteringOptions) {
      this.name = filteringOptions.name;
      this.active =
        filteringOptions.active == "empty"
          ? ""
          : filteringOptions.active == "true";

      this.getPriceOptions();
      $("#mod-dp-filter").modal("hide");
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    prev() {
      this.pageNumber -= 1;
      this.getPriceOptions();
    },

    next() {
      this.pageNumber += 1;
      this.getPriceOptions();
    },
  },
};
</script>

<style>
</style>