<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header d-flex justify-content-between">
          <h4>Viewing Sales Order {{ purchaseOrder.orderNumber }}</h4>
          <button class="btn btn-success btn-rounded btn-space" @click="goBack">
            <i class="mdi icon mdi-backburger text-white"></i>
            Back
          </button>
        </div>
        <div class="card-body bg-white">
          <div class="col">
            <div class="row">
              <div class="col-md-9">
                <div class="form-group row">
                  <label for="inputPassword" class="col-sm-3 col-form-label"
                    >Order for:</label
                  >
                  <div class="col-sm-5">
                    <input
                      type="text"
                      class="form-control-plaintext"
                      :value="purchaseOrder.parent.name"
                    />
                  </div>
                </div>
                <div
                  v-if="purchaseOrder.deliveryAddress"
                  class="form-group row"
                >
                  <label for="inputPassword" class="col-sm-3 col-form-label"
                    >Delivery Address:</label
                  >
                  <div class="col-sm-9">
                    <textarea
                      class="form-control"
                      readonly="true"
                      :value="purchaseOrder.deliveryAddress"
                    ></textarea>
                  </div>
                </div>
                <div v-if="purchaseOrder.contact.name" class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label"
                    >Contact Name</label
                  >
                  <div class="col-12 col-sm-8 col-lg-4">
                    <input
                      class="form-control"
                      type="text"
                      readonly="true"
                      :value="purchaseOrder.contact.name"
                    />
                  </div>
                </div>
                <div
                  v-if="purchaseOrder.contact.phoneNumber"
                  class="form-group row"
                >
                  <label class="col-12 col-sm-3 col-form-label"
                    >Contact Phone</label
                  >
                  <div class="col-12 col-sm-8 col-lg-4">
                    <input
                      class="form-control"
                      type="tel"
                      readonly="true"
                      :value="purchaseOrder.contact.phoneNumber"
                    />
                  </div>
                </div>
                <div v-if="purchaseOrder.contact.email" class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label"
                    >Contact Email</label
                  >
                  <div class="col-12 col-sm-8 col-lg-4">
                    <input
                      class="form-control"
                      type="email"
                      readonly="true"
                      :value="purchaseOrder.contact.email"
                    />
                  </div>
                </div>
                <div
                  v-if="purchaseOrder.specialInstructions"
                  class="form-group row"
                >
                  <label class="col-12 col-sm-3 col-form-label"
                    >Special Instructions</label
                  >
                  <div class="col-12 col-sm-9 col-lg-9">
                    <textarea
                      class="form-control"
                      readonly="true"
                      :value="purchaseOrder.specialInstructions"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label"
                    >Creation Date</label
                  >
                  <div class="col-12 col-sm-8 col-lg-4">
                    <input
                      class="form-control"
                      type="text"
                      readonly
                      :value="formatDate(purchaseOrder.created)"
                    />
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label">Status</label>
                  <div class="col-12 col-sm-8 col-lg-4">
                    <span
                      :class="[
                        'badge',
                        ' badge-pill',
                        {
                          'badge-success': purchaseOrder.status === 'confirmed',
                        },
                        {
                          'badge-danger': purchaseOrder.status === 'withdrawn',
                        },
                        {
                          'badge-secondary': purchaseOrder.status === 'draft',
                        },
                        {
                          'badge-warning': purchaseOrder.status === 'submitted',
                        },
                        {
                          'badge-dark': purchaseOrder.status === 'recalled',
                        },
                      ]"
                      >{{
                        purchaseOrder.status ? purchaseOrder.status : ""
                      }}</span
                    >
                  </div>
                </div>
                <div class="form-group row">
                  <label class="col-12 col-sm-3 col-form-label">Total</label>
                  <div class="col-12 col-sm-8 col-lg-4">
                    <input
                      class="form-control"
                      type="text"
                      readonly
                      :value="formatMoney(subTotal)"
                    />
                  </div>
                </div>
                <div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label"
                      >Total Order Amount</label
                    >
                    <div class="col-12 col-sm-8 col-lg-4">
                      <input
                        class="form-control"
                        type="text"
                        readonly
                        :value="total"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h3>Process Activities</h3>
            <div class="row">
              <div class="col-sm-12">
                <div>
                  <span
                    v-if="isActivityLoading"
                    class="spinner-border text-success spinner-border-sm"
                    role="status"
                  >
                    <i class="sr-only">Loading...</i>
                  </span>
                  <p
                    v-else
                    class="font-weight-bold font-italic"
                    v-for="activity in activities"
                    :key="activity.id"
                  >
                    {{ activity.performedBy }} {{ activity.actionName }} on
                    {{
                      activity.actionName.toLowerCase().includes("stage")
                        ? formatDate(activity.actionDate)
                        : formatDate(activity.date)
                    }}.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <ItemDetails :items="addedOrderDetails" :isView="true" />
          <SalesOrderItem
            v-if="addedItems.length > 0"
            :items="addedItems"
            :isView="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { helper } from "@/helper";
import Amount from "../components/SettingsPurchaseOrder/Amount";
import Quantity from "../components/SettingsPurchaseOrder/Quantity";
import ItemDetails from "../components/SettingsPurchaseOrder/PurchaseOrderItemDetails";
import SalesOrderItem from "../components/SettingsPurchaseOrder/SalesOrderItem.vue";
import { mapActions } from "vuex";

export default {
  components: {
    Amount,
    Quantity,
    ItemDetails,
    SalesOrderItem,
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      isActivityLoading: false,
      activities: [],
      orderId: "",
      manufacturerProducts: [],
      filteredProducts: [],
      purchaseOrder: {
        parent: {},
        contact: {},
      },
      addedOrderDetails: [],
      stageCautionData: {},
      orderItems: [],
      addedItems: [],
    };
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },

    subTotal() {
      let result = 0;
      this.addedOrderDetails.forEach((item) => {
        result += item.quantity * item.price;
      });
      return result;
    },

    total() {
      let result = this.subTotal;
      this.addedItems.forEach((item) => {
        if (item.type === 0) {
          result -=
            item.subType === 0
              ? item.amount
              : this.calculatePercentageAmount(item.percentage, this.subTotal);
        }

        if (item.type === 1) {
          result += this.calculatePercentageAmount(
            this.percentage,
            this.subTotal
          );
        }

        if (item.type === 2) {
          if (item.method === 0) {
            result +=
              item.subType === 0
                ? item.amount
                : this.calculatePercentageAmount(
                    item.percentage,
                    this.subTotal
                  );
          }
          if (item.method === 1) {
            result -=
              item.subType === 0
                ? item.amount
                : this.calculatePercentageAmount(
                    item.percentage,
                    this.subTotal
                  );
          }
        }
      });
      return this.formatMoney(result);
    },
  },

  created() {
    this.orderId = this.$route.params.id;
    this.getOrderItems();
    this.editPurchaseOrder();
    this.getActivies();
  },

  methods: {
    ...mapActions(["getPurchaseOrder"]),
    getPurchaseOrder() {
      this.$store.dispatch("getPurchaseOrder");
    },
    async getActivies() {
      this.isActivityLoading = true;
      try {
        const response = await this.$http.get(
          `/Activity/${this.purchaseOrder.id}/${1}`
        );

        if (response.ok) {
          this.activities = response.body;
          // console.log(this.activities);
        }
        this.isActivityLoading = false;
      } catch (error) {
        this.isActivityLoading = false;
      }
    },
    async editPurchaseOrder() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/edit/" + this.orderId
        );

        if (response.ok) {
          this.purchaseOrder = response.body;
          this.prepareDetailSet(this.purchaseOrder.details);
          this.prepareItemSet(this.purchaseOrder.orderItems);
          this.getManufacturerProducts();
          if (this.purchaseOrder.id) {
            this.getActivies();
          }
        }

        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    calculatePercentageAmount(percent, amount) {
      return (percent / 100) * amount;
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    async getManufacturerProducts() {
      this.isBusyModal = true;
      try {
        const response = await this.$http.get(
          "/Products/getListNoPagingForManufacturer?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "")
        );
        if (response.ok) {
          this.manufacturerProducts = this.filteredProducts = response.body;
          this.performFilter();
        }

        this.isBusyModal = false;
      } catch (error) {
        this.isBusyModal = false;
      }
    },

    performFilter() {
      this.addedOrderDetails.forEach((element) => {
        this.filteredProducts = this.filteredProducts.filter(
          (product) => product.id !== element.id
        );
      });
    },

    prepareItem(product, newItem) {
      const preparedItem = {
        quantity: newItem.quantity,
        description: `${product.name} (${product.code}) - ${product.retailPrice}/${product.unit}`,
        price: product.retailPrice,
        id: product.id,
      };

      this.addedOrderDetails.push(preparedItem);

      // eslint-disable-next-line no-undef
      $("#mod-product-add").modal("hide");
    },

    prepareItemSet(orderItems) {
      this.addedItems = [];
      orderItems.forEach((element) => {
        this.orderItems.forEach((sub) => {
          if (element.orderItemId === sub.id) this.addedItems.push(sub);
        });
      });
    },

    prepareDetailSet(productsDetails) {
      this.addedOrderDetails = [];
      productsDetails.forEach((element) => {
        const preparedItem = {
          quantity: element.quantity,
          price: element.price,
          id: element.productId,
          description: element.description,
        };

        this.addedOrderDetails.push(preparedItem);
      });
    },

    async getOrderItems() {
      this.isBusyModal = true;
      try {
        const response = await this.$http.get("/PurchaseOrders/orderItem/list");
        if (response.ok) {
          this.orderItems = response.body;
        }

        this.isBusyModal = false;
      } catch (error) {
        this.isBusyModal = false;
      }
    },
  },
};
</script>

<style>
</style>
