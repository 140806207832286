<template>
  <div
    @click="submitRead"
    class="p-2 p-sm-1 d-flex align-items-center border-bottom bg-white mt-1"
  >
    <div
      class="dropdown-list-image mr-2 rounded-circle"
      :class="backgroundSelector"
    >
      <span class="text-white">
        {{ notification.title.substring(0, 1) }}
      </span>
    </div>
    <div class="font-weight-bold" :class="statusBackgroundSelector">
      <div class="text-truncate">{{ notification.title }}</div>
      <div class="small">
        {{ notification.description }}
      </div>
    </div>
    <span class="action ml-auto mb-auto">
      <div>{{ formatDate(notification.created) }}</div>
      <div class="mt-1 text-muted">{{ days }}</div>
    </span>
  </div>
</template>

<script>
import moment from "moment";

export default {
  props: ["notification"],

  computed: {
    days() {
      const days = moment(new Date()).diff(this.notification.created, "days");
      return days > 0 ? `${days} day${days > 1 ? "s" : ""} ago` : "Today";
    },

    backgroundSelector() {
      let background = "bg-success";
      if (this.notification.title === "Minimum Stock Level")
        background = "bg-warning";

      if (this.notification.title === "Inventory Product Expire.")
        background = "bg-danger";
      return background;
    },

    statusBackgroundSelector() {
      let background = "text-dark";
      if (this.notification.read) background = "text-secondary";
      return background;
    },
  },

  methods: {
    async deleteNotification(id) {
      try {
        this.isBusy = true;
        const response = await this.$http.delete("/notification/" + id);
        if (response.ok) this.$emit("onDeleteComplete");
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    async submitRead() {
      if (!this.notification.read)
        this.$emit("onCardClicked", this.notification.id);
    },
  },
};
</script>

<style>
.dropdown-list-image {
  display: flex;
  width: 2.8rem;
  height: 2.8rem;
  align-items: center;
  justify-content: center;
}

.action {
  width: 7%;
}
</style>