<template>
  <div class="row">
    <div class="col">
      <div
        :class="[
          'card',
          'card-border-color',
          'card-border-color-primary',
          'card-table',
          'be-loading',
          { 'be-loading-active': isBusy },
        ]"
      >
        <div class="card-header">
          <h5 class="card-title">PO Number - {{ order.orderNumber }}</h5>
        </div>
        <div class="card-body p-3">
          <div class="row">
            <form @submit.prevent="submit" class="col">
              <div class="row">
                <div class="col-md-9">
                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label"
                      >Order for:</label
                    >
                    <div class="col-sm-5">
                      <input
                        type="text"
                        class="form-control-plaintext"
                        :value="user.parent.name"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label for="inputPassword" class="col-sm-3 col-form-label"
                      >Delivery Address:</label
                    >
                    <div class="col-sm-9">
                      <textarea
                        class="form-control"
                        :class="{
                          'is-invalid': $v.order.deliveryAddress.$error,
                        }"
                        :readonly="isSubmitted"
                        :value="order.deliveryAddress"
                        @input="setAddress($event.target.value)"
                      ></textarea>
                      <span
                        class="invalid-feedback"
                        v-if="!$v.order.deliveryAddress.minLength"
                      >
                        Delivery Address must have at least
                        {{ $v.order.deliveryAddress.$params.minLength.min }}
                        letters.
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label"
                      >Contact Name</label
                    >
                    <div class="col-12 col-sm-8 col-lg-4">
                      <input
                        class="form-control"
                        type="text"
                        :readonly="isSubmitted"
                        :class="{
                          'is-invalid': $v.order.contact.name.$error,
                        }"
                        :value="order.contact.name"
                        @input="setContactName($event.target.value)"
                      />
                      <span
                        class="invalid-feedback"
                        v-if="!$v.order.contact.name.minLength"
                      >
                        Name must have at least
                        {{ $v.order.contact.name.$params.minLength.min }}
                        letters.
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label"
                      >Contact Phone</label
                    >
                    <div class="col-12 col-sm-8 col-lg-4">
                      <input
                        class="form-control"
                        :class="{
                          'is-invalid': $v.order.contact.phoneNumber.$error,
                        }"
                        type="tel"
                        :readonly="isSubmitted"
                        :value="order.contact.phoneNumber"
                        @input="setContactPhone($event.target.value)"
                      />
                      <span
                        class="invalid-feedback"
                        v-if="!$v.order.contact.phoneNumber.minLength"
                      >
                        Phone number is not valid
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label"
                      >Contact Email</label
                    >
                    <div class="col-12 col-sm-8 col-lg-4">
                      <input
                        class="form-control"
                        type="email"
                        :readonly="isSubmitted"
                        :value="order.contact.email"
                        @input="setContactEmail($event.target.value)"
                      />
                      <span
                        class="invalid-feedback"
                        v-if="!$v.order.contact.email.minLength"
                      >
                        Email is not valid
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label"
                      >Special Instructions</label
                    >
                    <div class="col-12 col-sm-9 col-lg-9">
                      <textarea
                        class="form-control"
                        :class="{
                          'is-invalid': $v.order.specialInstructions.$error,
                        }"
                        :readonly="isSubmitted"
                        :value="order.specialInstructions"
                        @input="setSpecialInstructions($event.target.value)"
                      ></textarea>
                      <span
                        class="invalid-feedback"
                        v-if="!$v.order.specialInstructions.minLength"
                      >
                        Special instructions must be at least
                        {{ $v.order.specialInstructions.$params.minLength.min }}
                        characters.
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label"
                      >Creation Date</label
                    >
                    <div class="col-12 col-sm-8 col-lg-4">
                      <input
                        class="form-control"
                        type="text"
                        readonly
                        :value="formatDate(orderResponse.created)"
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label">Status</label>
                    <div class="col-12 col-sm-8 col-lg-4">
                      <span
                        :class="[
                          'badge',
                          ' badge-pill',
                          {
                            'badge-success':
                              orderResponse.status === 'confirmed',
                          },
                          {
                            'badge-danger':
                              orderResponse.status === 'withdrawn',
                          },
                          {
                            'badge-secondary': orderResponse.status === 'draft',
                          },
                          {
                            'badge-warning':
                              orderResponse.status === 'submitted',
                          },
                          {
                            'badge-dark': orderResponse.status === 'recalled',
                          },
                        ]"
                        >{{
                          orderResponse.status ? orderResponse.status : ""
                        }}</span
                      >
                    </div>
                  </div>
                  <div v-if="!isConfirm" class="form-group row">
                    <label class="col-12 col-sm-3 col-form-label">Total</label>
                    <div class="col-12 col-sm-8 col-lg-4">
                      <input
                        class="form-control"
                        type="text"
                        readonly
                        :value="subTotal"
                      />
                    </div>
                  </div>
                  <div v-else>
                    <div class="form-group row">
                      <label class="col-12 col-sm-3 col-form-label"
                        >Total Order Amount</label
                      >
                      <div class="col-12 col-sm-8 col-lg-4">
                        <input
                          class="form-control"
                          type="text"
                          readonly
                          :value="subTotal"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-12 col-sm-3 col-form-label"
                        >Discount Applied</label
                      >
                      <div class="col-12 col-sm-8 col-lg-4">
                        <input
                          class="form-control"
                          type="text"
                          readonly
                          :value="subTotal"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-12 col-sm-3 col-form-label"
                        >Shipping fees</label
                      >
                      <div class="col-12 col-sm-8 col-lg-4">
                        <input
                          class="form-control"
                          type="text"
                          readonly
                          :value="subTotal"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-12 col-sm-3 col-form-label"
                        >Taxes</label
                      >
                      <div class="col-12 col-sm-8 col-lg-4">
                        <input
                          class="form-control"
                          type="text"
                          readonly
                          :value="subTotal"
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <h3 class="col-12 col-sm-3">
                        <b>TO PAY</b>
                      </h3>
                      <h3 class="col-12 col-sm-8 col-lg-4">
                        <b> {{ subTotal }}</b>
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
              <ViewPurchaseOrderDetail
                :items="addedItems"
                :disableEdit="isSubmitted"
                :confirmed="isConfirm"
                :draft="isDraft"
                @performEdit="onEditClickHandler"
                @onDeleteItem="onDeleteItemHandler"
              />
              <div
                v-if="isPaymentLoading"
                class="d-flex justify-content-center"
              >
                <div class="spinner-border text-success" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
              <ViewPaymentDetail
                v-else-if="paymentDetail != null"
                :paymentDetail="paymentDetail"
                :purchaseOrderId="orderId"
              />
              <div class="col mt-4">
                <div class="row justify-content-end">
                  <div
                    class="col align-self-end"
                    :class="{
                      'col-md-3': isSubmitted,
                      'col-md-4': !isSubmitted,
                    }"
                  >
                    <div class="d-flex justify-content-between mt-3">
                      <button
                        type="button"
                        class="btn btn-secondary"
                        @click="goBack"
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger"
                        @click="deletePurchaseOrder"
                        :disabled="isDeleting"
                      >
                        <span v-if="isDeleting">Busy...</span>
                        <span v-else>Delete</span>
                      </button>
                      <button
                        type="submit"
                        class="btn btn-primary"
                        :disabled="isBusyModal || withdrawLoading"
                      >
                        <span v-if="isBusyModal">Busy...</span>
                        <span v-else-if="!isSubmitted"
                          >Generate Purchase Order</span
                        >
                        <span v-else> Withdraw PO </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <StatusModal @proceed="onProceed" />
    <AddItem
      :products="manufacturerProducts"
      :filteredProducts="filteredProducts"
      @onItemAdded="onItemAddHandler"
      @onItemUpdated="onItemUpdatedHandler"
      @onCancel="onCancelHandler"
      :edit="itemEdit"
      :groupId="groupId"
    />
    <AddPaymentDetails :id="orderId" @uploadComplete="onUploadComplete" />
  </div>
</template>

<script>
import { required, minLength, helpers, email } from "vuelidate/lib/validators";
import moment from "moment";
import { helper } from "@/helper";

import ViewPurchaseOrderDetail from "../../components/PurchaseOrder/ViewPurchaseOrderDetail";
import StatusModal from "../../components/PurchaseOrder/StatusModal";
import AddItem from "../../components/PurchaseOrder/AddItem";
import AddPaymentDetails from "../../components/PurchaseOrder/AddPaymentDetails";
import ViewPaymentDetail from "../../components/PurchaseOrder/ViewPaymentDetail";

// eslint-disable-next-line no-useless-escape
const phone = helpers.regex("alpha", /^[0]\d{10}$|[\+]?[234]\d{12}$/);

export default {
  components: {
    ViewPurchaseOrderDetail,
    StatusModal,
    AddItem,
    AddPaymentDetails,
    ViewPaymentDetail,
  },

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      orderId: "",
      isPaymentLoading: false,
      addedItems: [],
      manufacturerProducts: [],
      filteredProducts: [],
      total: 0,
      groupId: "",
      order: {
        orderNumber: "",
        deliveryAddress: "",
        created: "",
        total: 0,
        contact: {
          name: "",
          phoneNumber: "",
          email: "",
        },

        specialInstructions: "",
        status: "",
      },
      paymentDetail: null,
      orderResponse: {},
      itemEdit: null,
      isDeleting: false,
      withdrawLoading: false,
    };
  },

  validations: {
    order: {
      deliveryAddress: {
        required,
        minLength: minLength(3),
      },

      contact: {
        name: {
          minLength: minLength(3),
        },

        phoneNumber: {
          phone,
        },

        email: {
          email,
        },
      },

      specialInstructions: {
        minLength: minLength(5),
      },
    },
  },

  computed: {
    user: function () {
      return this.$store.state.currentUser;
    },

    isSubmitted: function () {
      return this.orderResponse.status === "submitted";
    },

    isConfirm: function () {
      return this.order.status === "confirmed";
    },

    isDraft: function () {
      return this.order.status === "draft";
    },

    subTotal() {
      let result = 0;
      this.addedItems.forEach((item) => {
        result += item.quantity * item.price;
      });
      return this.formatMoney(result);
    },
  },

  watch: {
    orderResponse: function (value) {
      this.order.orderNumber = value.orderNumber;
      this.setAddress(value.deliveryAddress);
      this.setContactName(value.contact.name);
      this.setContactPhone(value.contact.phoneNumber);
      this.setContactEmail(value.contact.emailAddress);
      this.setSpecialInstructions(value.specialInstructions);

      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },
  },

  created() {
    this.orderId = this.$route.params.id;
    this.getPurchaseOrder();
  },

  mounted() {
    this.getGroup();
  },

  methods: {
    setAddress(value) {
      this.order.deliveryAddress = value;
      this.$v.order.deliveryAddress.$touch();
    },

    setContactName(value) {
      this.order.contact.name = value;
      this.$v.order.contact.name.$touch();
    },

    setContactPhone(value) {
      this.order.contact.phoneNumber = value;
      this.$v.order.contact.phoneNumber.$touch();
    },

    setContactEmail(value) {
      this.order.contact.email = value;
      this.$v.order.contact.email.$touch();
    },

    setSpecialInstructions(value) {
      this.order.specialInstructions = value;
      this.$v.order.specialInstructions.$touch();
    },

    onEditClickHandler(item) {
      this.itemEdit = item;

      // eslint-disable-next-line no-undef
      $("#mod-item-add").modal("show");
    },

    async getGroup() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(`/Groups/getGroup/`);
        if (response.ok) {
          this.groupId = response.body;
          this.getManufacturerProducts();
        }

        this.isBusy = true;
      } catch (error) {
        this.isBusy = false;
      }
    },

    async getManufacturerProducts() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/Products/getListNoPagingForManufacturer?active=true" +
            (this.user.parentId ? "&parentId=" + this.user.parentId : "") +
            (this.groupId ? "&groupId=" + this.groupId : "")
        );
        if (response.ok) {
          this.manufacturerProducts = this.filteredProducts = response.body;
          this.performFilter();
        }

        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    performFilter() {
      this.addedItems.forEach((element) => {
        this.filteredProducts = this.filteredProducts.filter(
          (product) => product.id !== element.id
        );
      });
    },

    onItemAddHandler(newItem) {
      const product = this.manufacturerProducts.find(
        (product) => product.id === newItem.productId
      );

      this.filteredProducts = this.filteredProducts.filter(
        (product) => product.id !== newItem.productId
      );

      this.prepareItem(product, newItem);
    },

    onItemUpdatedHandler(newItem) {
      this.filteredProducts = this.filteredProducts.filter(
        (product) => product.id !== newItem.productId
      );

      this.prepareUpdateItem(newItem);
    },

    prepareUpdateItem(newItem) {
      this.addedItems.forEach((item) => {
        if (item.id === newItem.productId) {
          item.quantity = newItem.quantity;
        }
      });

      // eslint-disable-next-line no-undef
      $("#mod-item-add").modal("hide");
    },

    prepareItem(product, newItem) {
      const preparedItem = {
        quantity: newItem.quantity,
        description: `${product.name} (${product.code}) - ${newItem.price}/${product.unit}`,
        price: newItem.price,
        id: product.id,
      };

      this.addedItems.push(preparedItem);

      // eslint-disable-next-line no-undef
      $("#mod-item-add").modal("hide");
    },

    onDeleteItemHandler(id) {
      const product = this.manufacturerProducts.find(
        (product) => product.id === id
      );

      this.addedItems = this.addedItems.filter((item) => item.id !== id);
      this.filteredProducts.push(product);
    },

    formatMoney(val) {
      return helper.formatMoney(val);
    },

    async getPurchaseOrder() {
      this.isBusy = true;
      try {
        const response = await this.$http.get(
          "/PurchaseOrders/get/" + this.orderId
        );

        if (response.ok) {
          this.orderResponse = response.body.purchaseOrder;
          this.prepareItemSet(response.body.purchaseOrderDetails);
          await this.getPaymentDetail();
        }
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    formatDate(date) {
      if (date) return moment(date).format("lll");
      else return "";
    },

    onCancelHandler() {
      this.itemEdit = null;
    },

    async submit() {
      if (this.isSubmitted) {
        this.switchPurchaseOrder();
      } else {
        this.isBusy = true;
        this.isBusyModal = true;

        try {
          const data = {
            orderNumber: this.orderResponse.orderNumber,
            deliveryAddress: this.order.deliveryAddress,
            details: this.addedItems,
            date: Date.now(),
            orderContact: this.order.contact,
            specialInstructions: this.order.specialInstructions,
          };

          const response = await this.$http.put(
            "/PurchaseOrders/update/" + this.orderResponse.id,
            data
          );

          if (response.status === 200 && response.ok) this.goBack();
          this.isBusy = false;
          this.isBusyModal = false;
        } catch (error) {
          this.isBusy = false;
          this.isBusyModal = false;
        }
      }
    },

    async onProceed() {
      // eslint-disable-next-line no-undef
      $("#mod-status").modal("hide");
      this.isBusy = true;

      try {
        this.isBusy = false;
      } catch (error) {
        this.isBusy = false;
      }
    },

    async switchPurchaseOrder() {
      this.withdrawLoading = true;
      this.isBusy = true;

      const status = "withdrawn";
      try {
        const response = await this.$http.put(
          `/PurchaseOrders/updateStatus/${this.orderId}/${status}`
        );

        if (response.ok && response.status === 200) this.goBack();
        this.withdrawLoading = false;
        this.isBusy = false;
      } catch (error) {
        this.withdrawLoading = false;
        this.isBusy = false;
      }
    },

    prepareItemSet(productsDetails) {
      productsDetails.forEach((element) => {
        const preparedItem = {
          quantity: element.quantity,
          description: `${element.product.name} (${element.product.code}) - ${element.price}/${element.product.unit}`,
          price: element.price,
          id: element.product.id,
        };

        this.addedItems.push(preparedItem);
      });
    },

    async deletePurchaseOrder() {
      this.isBusy = true;
      this.isDeleting = true;
      try {
        const response = await this.$http.delete(
          "/PurchaseOrders/delete/" + this.orderId
        );

        if (response.ok && response.status === 200) this.goBack();

        this.isBusy = false;
        this.isDeleting = false;
      } catch (error) {
        this.isBusy = false;
        this.isDeleting = false;
      }
    },

    onUploadComplete() {
      this.getPaymentDetail();
      // eslint-disable-next-line no-undef
      $("#mod-upload-details").modal("hide");
    },

    async getPaymentDetail() {
      this.isBusy = true;
      this.isLoading = true;

      try {
        const response = await this.$http.get(
          "/PurchaseOrders/paymentDetail/" + this.orderId
        );

        if (response.ok && response.body) {
          this.paymentDetail = response.body;
        }

        this.isBusy = false;
        this.isLoading = false;
      } catch (error) {
        this.isBusy = false;
        this.isLoading = false;
      }
    },

    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push("/");
    },
  },
};
</script>

<style>
</style>