<template>
  <div class="be-splash-screen">
    <div class="be-wrapper be-login">
      <div class="be-content">
        <div class="main-content container-fluid">
          <div class="splash-container">
            <div class="card card-border-color card-border-color-primary">
              <div class="card-header">
                <img
                  class="logo-img"
                  src="/assets/img/logo-xx.png"
                  alt="logo"
                  width="200"
                  height="60"
                />
                <h4>Register Your Account</h4>
                <span class="splash-description"
                  >Please enter your user information to create your
                  account.</span
                >
              </div>
              <div class="card-body">
                <form>
                  <div class="form-group">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.username.$error },
                      ]"
                      id="username"
                      type="text"
                      placeholder="Username"
                      autocomplete="off"
                      v-model.trim="$v.user.username.$model"
                    />
                    <div
                      class="invalid-feedback"
                      v-if="
                        !$v.user.username.required && $v.user.username.$dirty
                      "
                    >
                      Field is required.
                    </div>
                    <div
                      class="invalid-feedback"
                      v-if="!$v.user.username.alphaNumAndDotValidator"
                    >
                      Username must not include space or special characters!
                    </div>
                  </div>
                  <div class="form-group">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.lastName.$error },
                      ]"
                      type="text"
                      placeholder="Last Name"
                      v-model.trim="$v.user.lastName.$model"
                    />
                    <ul
                      v-if="
                        !$v.user.lastName.required && $v.user.lastName.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.otherNames.$error },
                      ]"
                      type="text"
                      placeholder="Other Names"
                      v-model.trim="$v.user.otherNames.$model"
                    />
                    <ul
                      v-if="
                        !$v.user.otherNames.required &&
                        $v.user.otherNames.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      v-model="user.emailAddress"
                      readonly
                      placeholder="Email Address"
                    />
                  </div>
                  <div class="form-group">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.phoneNumber.$error },
                      ]"
                      type="text"
                      v-model.trim="$v.user.phoneNumber.$model"
                      placeholder="Phone Number"
                    />
                    <ul
                      v-if="
                        !$v.user.phoneNumber.required &&
                        $v.user.phoneNumber.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                    <ul
                      v-if="!$v.user.phoneNumber.numeric"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">
                        Enter a valid phone number.
                      </li>
                    </ul>
                  </div>
                  <div class="form-group">
                    <input
                      :class="[
                        'form-control',
                        { 'is-invalid': $v.user.password.$error },
                      ]"
                      type="password"
                      v-model.trim="$v.user.password.$model"
                      placeholder="Password"
                    />
                    <ul
                      v-if="
                        !$v.user.password.required && $v.user.password.$dirty
                      "
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">Required.</li>
                    </ul>
                    <ul
                      v-if="!$v.user.password.minLength"
                      class="parsley-errors-list filled"
                    >
                      <li class="parsley-required">
                        Enter a password greater than or equal to 6 characters.
                      </li>
                    </ul>
                  </div>
                  <div class="form-group login-submit">
                    <button
                      :disabled="isBusy"
                      class="btn btn-primary"
                      @click.prevent="update"
                    >
                      <span v-if="isBusy">Busy...</span>
                      <span v-else>Create account</span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

import { helper } from "@/helper";
import {
  required,
  numeric,
  minLength,
  helpers,
} from "vuelidate/lib/validators";

const alphaNumAndDotValidator = helpers.regex("alphaNumAndDot", /^[a-z\d.]*$/i);

export default {
  name: "joinSalesUser",
  created() {
    this.getUser();
  },
  data() {
    return {
      isBusy: false,
      user: {
        id: "",
        username: "",
        lastName: "",
        otherNames: "",
        emailAddress: "",
        phoneNumber: "",
        password: "",
      },
    };
  },
  validations: {
    user: {
      username: { required, alphaNumAndDotValidator },
      lastName: { required },
      otherNames: { required },
      phoneNumber: { required, numeric },
      password: { required, minLength: minLength(6) },
    },
  },
  methods: {
    getUser() {
      try {
        let json = helper.decodeString(this.$route.query.token);

        this.user = JSON.parse(json);
      } catch (err) {
        this.$router.push("/login");
      }
    },
    async update() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.isBusy = true;

        await this.$http
          .put("/SalesUsers/acceptInvite/" + this.user.id, this.user)
          .then(function (res) {
            $.gritter.add({
              title: "Account Created",
              text: "Your user account has been created. You can now login with your username and password.",
              class_name: "color success",
            });

            this.$router.push("/login");
          })
          .catch(function () {});

        this.isBusy = false;
      }
    },
  },
};
</script>