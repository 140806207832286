<template>
    <div>
        <div class="row">
            <div class="col-lg-12">
                <div :class="['card', 'card-border-color', 'card-border-color-primary', 'be-loading', {'be-loading-active':isBusy}]">
                    <div class="card-header">
                        <b>{{ campaign.name }}</b>
                        <div class="btn-group">
                            <router-link v-if="hasPermission('Campaign_Update')"
                                         class="btn btn-space btn-success btn-rounded float-right"
                                         :to="'/campaigns/' + campaign.id + '/edit'">
                                <span class="icon icon-left mdi mdi-pencil text-white"></span> Edit campaign
                            </router-link>

                            <router-link class="btn btn-space btn-primary btn-rounded float-right"
                                         to="/campaigns">
                                <span class="icon icon-left mdi mdi-arrow-left text-white"></span> Back
                            </router-link>
                        </div>
                    </div>
                    <div class="card-body">
                        <h4>System</h4>

                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Id:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ campaign.id }}</div>
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Status:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">
                                <span :class="['badge', ' badge-pill', { 'badge-success':campaign.active }, { 'badge-danger':!campaign.active }]">
                                    {{
 campaign.active? 'Active' : 'Inactive'
                                    }}
                                </span>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Created:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ formatDate(campaign.created) }}</div>
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Last Updated:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ formatDate(campaign.updated) }}</div>
                        </div>

                        <hr>

                        <h4>Details</h4>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Name:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ campaign.name }}</div>
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Parent:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ campaign.parent? campaign.parent.name : 'N/A' }}</div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Type:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ campaign.type }}</div>
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Frequency:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ campaign.frequency }}</div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Starts:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ formatDate(campaign.starts) }}</div>
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Ends:</label>
                            <div class="col-12 col-sm-3 col-lg-3 col-form-label text-sm-left">{{ formatDate(campaign.ends) }}</div>
                        </div>
                        <div class="form-group row">
                            <label class="col-12 col-sm-3 col-form-label text-sm-right">Message:</label>
                            <div class="col-12 col-sm-9 col-lg-9 col-form-label text-sm-left">{{ campaign.message }}</div>
                        </div>

                        <hr>

                        <h4>Members</h4>

                        <table class="table table-fw-widget dataTable no-footer" v-if="hasPermission('Campaign_View_Members')">
                            <thead>
                                <tr>
                                    <th style="width:65%;">Name</th>
                                    <th style="width:20%;">Member Type</th>
                                    <th style="width:15%;">Joined</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="m in members" :key="m.id">
                                    <td>{{ m.memberName }}</td>
                                    <td>{{ m.type }}</td>
                                    <td>{{ formatDate(m.joined) }}</td>
                                </tr>
                                <tr v-if="!members.length">
                                    <td colspan="4">
                                        <div class="text-center">No members</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="be-spinner">
                        <svg width="40px" height="40px" viewBox="0 0 66 66">
                            <circle class="circle"
                                    fill="none"
                                    stroke-width="4"
                                    stroke-linecap="round"
                                    cx="33"
                                    cy="33"
                                    r="30"></circle>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */

    import moment from "moment";
    import SystemPermissions from '@/permissions'

    export default {
        mixins: [SystemPermissions.Mixin],
        name: "campaignsDetails",
        data() {
            return {
                isBusy: false,
                campaign: {
                    parent: {}
                },
                members: []
            };
        },
        created() {
            if (!this.hasPermission('Campaign_View')) {
                this.$router.push("/restricted");
            }
            this.getCampaign();
            this.getMembers();
        },
        computed: {
            backPath: function () {
                return this.$store.state.prevRoute;
            },
            user: function () {
                return this.$store.state.currentUser;
            }
        },
        methods: {
            getCampaign() {
                let id = this.$route.params.id;

                this.$http
                    .get("/Campaigns/getById/" + id)
                    .then(function (res) {
                        this.campaign = res.body;
                    })
                    .catch(function () { });
            },
            getMembers() {
                this.isBusy = true;
                let id = this.$route.params.id;

                this.$http
                    .get("/Campaigns/getMembers/" + id)
                    .then(function (res) {
                        this.members = res.body;
                        this.isBusy = false;
                    })
                    .catch(function () {
                        this.isBusy = false;
                    });
            },
            formatDate(date) {
                return moment(date).format("ll");
            }
        }
    };
</script>