<template>
  <form @submit.prevent="register">
    <div>
      <label class="merchant-login-label"> BUSINESS NAME* </label>
      <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.businessName }]">
        <input type="text" placeholder="Enter business name" v-model="businessName" />
      </div>
      <p v-if="credentialValidation.businessName">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Business name cannot be empty</span>
      </p>
    </div>

    <div>
      <label class="merchant-login-label mt-2"> SELECT INDUSTRY </label>
      <div class="merchant-input-div">
        <select v-model="industry" placeholder="Select industry">
          <option v-for="(el, index) in options" :key="index" :value="el.value">{{
            el.name
          }}</option>
        </select>
      </div>
    </div>

    <div>
      <label class="merchant-login-label mt-2"> STATE* </label>
      <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.state }]">
        <select v-model="state" placeholder="Select state">
          <option disabled selected>Select State</option>
          <option v-for="(el, index) in states" :key="index" :value="el">{{ el }}</option>
        </select>
      </div>
      <p v-if="credentialValidation.state">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Please select a state</span>
      </p>
    </div>

    <div>
      <label class="merchant-login-label mt-2"> LOCAL GOVERNMENT AREA </label>
      <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.lga }]">
        <select v-model="lga" placeholder="Select lga">
          <option v-for="(el, index) in lgas" :key="index" :value="el">{{ el }}</option>
        </select>
      </div>
      <p v-if="credentialValidation.lga">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Please select a local government area</span>
      </p>
    </div>

    <div>
      <label class="merchant-login-label mt-2"> BUSINESS ADDRESS </label>
      <div :class="['merchant-input-div', { 'is-invalid': credentialValidation.businessAddress }]">
        <input type="text" placeholder="Enter business address" v-model="businessAddress" />
      </div>
      <p v-if="credentialValidation.businessAddress">
        <img src="/assets/img/error-icon-new.svg" alt="error" />
        <span class="login-error">Please input your business address</span>
      </p>
    </div>

    <p class="mt-2">
      By clicking the "Create My Account" button, you agree to
      <span style="font-weight: 700">GrowTrade's{{ " " }}</span>
      <span style="color:#38369a">Terms Of Acceptable Use</span>
    </p>

    <button type="submit" class="login-submit-btn" :disabled="isBusy">
      <span v-if="isBusy" class="spinner-border spinner-border-sm" role="status">
        <i class="sr-only"></i>
      </span>
      <span v-else> Create My Account </span>
    </button>
    <p class="signup-text">
      Already have an account?
      <button type="button" class="signup-btn" @click="goToLogin">Sign In</button>
    </p>
  </form>
</template>

<script>
/* eslint-disable */
import { helper } from "@/helper";

export default {
  name: "distributor-business-details",
  components: {},
  props: ["payload"],

  data() {
    return {
      checked: false,
      passwordType: "password",
      options: [{ name: "Tech", value: "tech" }],
      states: helper.getStates(),
      businessName: null,
      industry: null,
      state: null,
      lga: null,
      businessAddress: null,
      credentialValidation: {
        businessName: false,
        state: false,
        lga: null,
        businessAddress: null,
      },
      isBusy: false,
    };
  },

  mounted() {
    console.log("payy", this.payload);
  },

  beforeCreate() {},

  created() {},

  computed: {
    lgas: function() {
      return helper.getLgas(this.state);
    },
  },

  methods: {
    togglePassword() {
      if (this.passwordType === "password") {
        this.passwordType = "text";
      } else {
        this.passwordType = "password";
      }
    },

    async register() {
      this.isBusy = true;

      this.$emit("changeStep", 2);

      if (this.validateUserInfo()) {
        let data = {
          ...this.payload,
          businessName: this.businessName,
          // industry: this.industry,
          state: this.state,
          lga: this.lga,
          businessAddress: this.businessAddress,
        };

        console.log(data);

        await this.$http
          .post(`/Distributor`, data)
          .then(function(res) {
            $("#mod-growtrade-onboarding-status").modal("show");

            this.isBusy = false;
          })
          .catch(function() {
            this.isBusy = false;
          });

        // await this.$http
        //   .put(`/${this.user.isSalesUser ? "SalesUsers" : "Users"}/update/` + data.id, data)
        //   .then(function(res) {
        //     this.$store.commit("setCurrentUser", this.user);

        //     this.$toast.success("Your profile details have been updated.", {
        //       icon: false,
        //       rtl: false,
        //     });

        //     this.isBusyProfile = false;
        //   })
        //   .catch(function() {
        //     var currentUser = clone(this.$store.state.currentUser);
        //     this.user.phoneNumber = currentUser.phoneNumber;
        //     this.user.emailAddress = currentUser.emailAddress;
        //     this.user.displayName = currentUser.displayName;
        //     this.isBusyProfile = false;
        //   });
      } else {
        this.isBusy = false;
      }
    },

    validateUserInfo() {
      this.credentialValidation = {
        businessName: false,
        state: false,
        lga: false,
        businessAddress: false,
      };

      let isValid = true;

      if (!this.businessName) {
        this.credentialValidation.businessName = true;
        isValid = false;
      }

      if (!this.state) {
        this.credentialValidation.state = true;
        isValid = false;
      }

      if (!this.lga) {
        this.credentialValidation.lga = true;
        isValid = false;
      }

      if (!this.businessAddress) {
        this.credentialValidation.businessAddress = true;
        isValid = false;
      }

      return isValid;
    },

    goToLogin() {
      this.$router.push(`/growtrade-login?client=distributor`);
    },
  },
};
</script>

<style scoped>
.merchant-login-label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #18191f;
  padding: 0;
  margin-bottom: 16px;
}

.merchant-input-div {
  border: 1px solid #a6a6a6;
  box-sizing: border-box;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
}

.merchant-input-div:focus-within {
  border: 1px solid #18191f;
  box-shadow: 0px 4px 10px 3px rgba(0, 0, 0, 0.11);
}

.togglePassword {
  border: none;
  outline: none;
  background: transparent;
  font-size: 14px;
  line-height: 17px;
  color: #38369a;
}
input,
select {
  border: none;
  outline: none;
  width: 100%;
  height: 80%;
}

.login-submit-btn {
  background: #675ce8;
  border-radius: 8px;
  width: 426px;
  height: 64px;
  outline: none;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  margin: 16px 0 8px;
  border: none;
}

.signup-btn {
  border: none;
  font-size: 18px;
  font-weight: 700;
  padding: 0;
  line-height: 28px;
  outline: none;
  background: transparent;
  color: #001688;
}

.signup-text {
  font-size: 18px;
  color: rgba(24, 25, 31, 0.5);
  margin-bottom: 60px;
}

.is-invalid {
  border: 1px solid #ff6767;
}

.login-error {
  font-size: 12px;
  line-height: 15px;
  color: #ff6767;
  margin-left: 5px;
}
@media screen and (max-width: 767px) {
  .merchant-input-div,
  .login-submit-btn {
    width: 100%;
  }
}
</style>
