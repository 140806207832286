<template>
  <div
    class="modal fade colored-header colored-header-primary"
    id="mod-price-option-add"
    role="dialog"
  >
    <div class="modal-dialog">
      <form @submit.prevent="submit" class="modal-content">
        <div class="modal-header modal-header-colored">
          <h4 class="modal-title p-1">
            <strong v-if="showUpdate">Update Price Option</strong>
            <strong v-else>Create New Price Option</strong>
          </h4>
          <button
            class="close md-close"
            type="button"
            data-dismiss="modal"
            @click.prevent="cancel"
            aria-hidden="true"
          >
            <span class="mdi mdi-close"></span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Name</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                class="form-control"
                :class="{ 'is-invalid': $v.name.$error }"
                type="text"
                v-model.trim="$v.name.$model"
              />
              <div class="invalid-feedback" v-if="!$v.name.required">
                Field is required
              </div>
              <div class="invalid-feedback" v-if="!$v.name.minLength">
                Name must have at least
                {{ $v.name.$params.minLength.min }} letters.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Description</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <textarea
                class="form-control"
                :class="{ 'is-invalid': $v.description.$error }"
                v-model.trim="$v.description.$model"
              ></textarea>
              <div class="invalid-feedback" v-if="!$v.description.required">
                Field is required
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Distributor price</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                class="form-control"
                type="number"
                :class="{ 'is-invalid': $v.distributorPrice.$error }"
                v-model.trim="$v.distributorPrice.$model"
                step="0.01"
              />
              <div
                class="invalid-feedback"
                v-if="!$v.distributorPrice.required"
              >
                Field is required
              </div>
              <div
                class="invalid-feedback"
                v-if="!$v.distributorPrice.minValue"
              >
                Distributor price must be greater than
                {{ $v.distributorPrice.$params.minValue.min - 1 }}.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Retail price</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <input
                class="form-control"
                type="number"
                :class="{ 'is-invalid': $v.retailPrice.$error }"
                v-model.trim="$v.retailPrice.$model"
                step="0.01"
              />
              <div class="invalid-feedback" v-if="!$v.retailPrice.required">
                Field is required
              </div>
              <div class="invalid-feedback" v-if="!$v.retailPrice.minValue">
                Retail price must be greater than
                {{ $v.retailPrice.$params.minValue.min - 1 }}.
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-12 col-sm-4 col-form-label text-sm-right"
              >Active</label
            >
            <div class="col-12 col-sm-7 col-lg-7">
              <label
                class="custom-control custom-checkbox custom-control-inline"
              >
                <input
                  class="custom-control-input"
                  v-model="active"
                  type="checkbox"
                />
                <span class="custom-control-label"
                  >This price option is active</span
                >
              </label>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary"
            type="button"
            @click.prevent="cancel"
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button :disabled="isBusyModal" class="btn btn-primary" type="submit">
            <span
              v-if="isBusyModal"
              class="spinner-border spinner-border-sm"
              role="status"
            >
              <i class="sr-only">Loading...</i>
            </span>
            <span v-else-if="showUpdate">
              <i class="icon mdi mdi-delete text-white"></i>
              Update Price Option
            </span>
            <span v-else>
              <i class="icon mdi mdi-file-add text-white"></i>
              Add Price Option
            </span>
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { required, minLength, minValue } from "vuelidate/lib/validators";
export default {
  name: "AddEditPriceOption",
  props: ["priceOption", "showUpdate", "productId"],

  data() {
    return {
      isBusy: false,
      isBusyModal: false,
      id: "",
      name: "",
      description: "",
      distributorPrice: 0,
      retailPrice: 0,
      active: false,
    };
  },

  watch: {
    priceOption: function (value) {
      if (value) {
        this.id = value.id;
        this.name = value.name;
        this.description = value.description;
        this.distributorPrice = value.distributorPrice;
        this.retailPrice = value.retailPrice;
        this.active = value.active;
      }
    },
  },

  validations: {
    name: {
      required,
      minLength: minLength(3),
    },

    description: {
      required,
    },

    distributorPrice: {
      required,
      minValue: minValue(1),
    },

    retailPrice: {
      required,
      minValue: minValue(1),
    },
  },

  methods: {
    async submit() {
      if (this.showUpdate) {
        await this.update();
      } else {
        await this.create();
      }
    },

    async create() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isBusyModal = false;
        this.isBusy = false;
      } else {
        try {
          this.isBusyModal = true;
          this.isBusy = true;

          const data = {
            name: this.name,
            active: this.active,
            description: this.description,
            distributorPrice: this.distributorPrice,
            retailPrice: this.retailPrice,
            productId: this.productId,
          };

          const response = await this.$http.post("/PriceOptions/create/", data);
          if (response.status === 201 && response.statusText === "Created") {
            this.reset();
            this.$emit("onComplete");
          }

          this.isBusyModal = false;
          this.isBusy = false;
        } catch (error) {
          this.isBusyModal = false;
          this.isBusy = false;
        }
      }
    },

    async update() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isBusyModal = false;
        this.isBusy = false;
      } else {
        try {
          this.isBusyModal = true;
          this.isBusy = true;

          var data = {
            name: this.name,
            active: this.active,
            description: this.description,
            distributorPrice: this.distributorPrice,
            retailPrice: this.retailPrice,
            productId: this.productId,
          };

          const response = await this.$http.put(
            "/PriceOptions/update/" + this.id,
            data
          );

          if (response.status === 200) {
            this.reset();
            this.$emit("onComplete");
          }

          this.isBusyModal = false;
          this.isBusy = false;
        } catch (error) {
          this.isBusy = false;
          this.isBusyModal = false;
        }
      }
    },

    async reset() {
      this.id = "";
      this.name = "";
      this.description = "";
      this.distributorPrice = 0;
      this.retailPrice = 0;
      this.active = false;
      setTimeout(() => {
        this.$v.$reset();
      }, 0);
    },

    cancel() {
      this.reset();
      this.$emit("hide");
    },
  },
};
</script>

<style>
</style>