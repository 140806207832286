<template>
  <button
    data-toggle="tooltip"
    title="Edit"
    :class="[
      { 'btn-success': orderItem.active },
      { 'btn-danger': !orderItem.active },
    ]"
    class="btn btn-sm btn-rounded btn-space mr-1"
    :disabled="activeLoading"
    @click.prevent="switchStatus"
  >
    <span
      v-if="activeLoading"
      class="spinner-border spinner-border-sm"
      role="status"
    >
      <i class="sr-only">Loading...</i>
    </span>

    <span v-else>
      {{ orderItem.active ? "Active" : "Inactive" }}
    </span>
  </button>
</template>

<script>
export default {
  name: "SwitchOrderItemStatus",
  props: ["orderItem"],

  data() {
    return {
      activeLoading: false,
    };
  },

  methods: {
    async switchStatus() {
      this.activeLoading = true;
      this.isBusy = true;
      try {
        const response = await this.$http.put(
          "/PurchaseOrders/orderItem/update/" +
            this.orderItem.id +
            "/" +
            !this.orderItem.active
        );

        if (response.ok && response.status === 200) this.$emit("onComplete");
        this.activeLoading = false;
        this.isBusy = true;
      } catch (error) {
        this.activeLoading = false;
        this.isBusy = true;
      }
    },
  },
};
</script>
