import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: {},
    prevRoute: null,
    userId: {},
    roles: [],
    salesRoles: [],
    purchaseOrders: {
      purchaseOrders: [],
      count: 0,
      pageNumber: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    },

    payments: {
      loading: false,
      data: [],
      count: 0,
      pageNumber: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
    },

    accountPosting: {
      loading: false,
      data: [],
      count: 0,
      pageNumber: 0,
      pageSize: 10,
      pageCount: 0,
      hasNext: false,
      hasPrev: false,
    },

    accountPostingFilter: {
      startDate: "",
      endDate: "",
      customerId: "",
      status: "",
      paymentType: "",
      accountId: "",
      customerName: "",
      createdBy: "",
      authorizedBy: "",
      paymentChannelId: "",
    },

    paymentsFilter: {
      startDate: "",
      endDate: "",
      customerId: "",
      status: "",
      paymentChannelId: "",
    },

    transactions: {
      isLoading: false,
      data: [],
      count: 0,
      pageNumber: 0,
      pageSize: 10,
      hasNext: false,
      hasPrev: false,
    },

    transactionSummary: {
      data: {},
      isLoading: false,
    },

    transactionDownload: {
      isLoading: false,
      data: [],
      summary: {},
    },

    dates: {
      startDate: "",
      endDate: "",
    },

    transactionFilter: {
      startDate: "",
      endDate: "",
      transactionType: "",
    },

    filter: {
      startDate: "",
      endDate: "",
      customerName: "",
      poNumber: "",
      readStatus: "",
      selectedStage: "",
      downloadStatus: "",
      group: "",
    },
  },

  getters: {
    getCurrentUser: (state) => state.currentUser,
    getPrevRoute: (state) => state.prevRoute,
    getUserId: (state) => state.userId,
    purchaseOrders: (state) => {
      return state.purchaseOrders;
    },
    roles: (state) => state.roles,
    salesRoles: (state) => state.salesRoles,
    transactions: (state) => state.transactions,
    transactionDownload: (state) => state.transactionDownload,
    transactionFilter: (state) => state.transactionFilter,
    payments: (state) => state.payments,
    paymentsFilter: (state) => state.paymentsFilter,
    accountPosting: (state) => state.accountPosting,
    accountPostingFilter: (state) => state.accountPostingFilter,
  },

  actions: {
    setCurrentUser: function(context, user) {
      context.commit("setCurrentUser", user);
    },

    clearCurrentUser: function(context) {
      context.commit("clearCurrentUser");
    },

    setPrevRoute: function(context, rout) {
      context.commit("setPrevRoute", rout);
    },

    setUserId: function(context, userId) {
      context.commit("setUserId", userId);
    },

    getPurchaseOrder({ commit, state }, type) {
      Vue.http
        .get(
          `/PurchaseOrders/${type}/getList?CustomerName=${state.filter.customerName}&StartDate=${state.dates.startDate}&EndDate=${state.dates.endDate}&DownloadStatus=${state.filter.downloadStatus}&PurchaseOrderNumber=${state.filter.poNumber}&Stage=${state.filter.selectedStage}&Read=${state.filter.readStatus}&PageNumber=${state.purchaseOrders.pageNumber}&PageSize=${state.purchaseOrders.pageSize}&Group=${state.filter.group}`
        )
        .then((response) => commit("setPurchaseOrders", response.data))
        .catch((error) => commit("setPurchaseOrdersError", error));
    },

    getRoles({ commit, state }) {
      Vue.http
        .get(
          `/roles/getRoleListNoPaging?parentId=${state.currentUser.parentId}&roleType=${state.currentUser.type}`
        )
        .then((response) => commit("setRoles", response.body));
    },

    getSalesRoles({ commit, state }) {
      Vue.http
        .get(`/salesroles/getRoleListNoPaging?parentId=${state.currentUser.parentId}`)
        .then((response) => commit("setSalesRole", response.body));
    },

    getPayments({ commit, state }) {
      commit("setPaymentsLoading", true);
      Vue.http
        .get(
          `/Payments/${state.currentUser.isGlobal}?CustomerId=${state.paymentsFilter.customerId}&Status=${state.paymentsFilter.status}&StartDate=${state.paymentsFilter.startDate}&EndDate=${state.paymentsFilter.endDate}&PaymentChannelId=${state.paymentsFilter.paymentChannelId}&PageNumber=${state.payments.pageNumber}&PageSize=${state.payments.pageSize}`
        )
        .then((response) => commit("setPayments", response.body))
        .catch((error) => commit("setPaymentsLoading", false));
    },

    getAccountPostings({ commit, state }) {
      commit("loadingPostings", true);
      Vue.http
        .get(
          `/AccountPosting?CustomerId=${state.accountPostingFilter.customerId}&AccountId=${state.accountPostingFilter.accountId}&PaymentType=${state.accountPostingFilter.paymentType}&PaymentChannelId=${state.accountPostingFilter.paymentChannelId}&StartDate=${state.accountPostingFilter.startDate}&EndDate=${state.accountPostingFilter.endDate}&CreatedBy=${state.accountPostingFilter.createdBy}&AuthorizedBy=${state.accountPostingFilter.authorizedBy}&PageNumber=${state.accountPosting.pageNumber}&PageSize=${state.accountPosting.pageSize}`
        )
        .then((response) => commit("setAccountPostings", response.body))
        .catch((error) => commit("loadingPostings", false));
    },

    getTransactions({ commit, state }, owner) {
      commit("setTransactionLoading", true);
      Vue.http
        .get(
          `/Transaction/${owner}?StartDate=${state.transactionFilter.startDate}&EndDate=${
            state.transactionFilter.endDate
          }${
            state.transactionFilter.transactionType
              ? "&TransactionType=" + state.transactionFilter.transactionType
              : ""
          }&PageNumber=${state.transactions.pageNumber}&PageSize=${state.transactions.pageSize}`
        )
        .then((response) => commit("setTransaction", response.body))
        .catch((error) => commit("setTransactionLoading", false));
    },

    getTransactionSummary({ commit, state }, owner) {
      commit("setTransactionSummaryLoading", true);
      Vue.http
        .get(
          `/Transaction/summary/${owner}?StartDate=${state.transactionFilter.startDate}&EndDate=${
            state.transactionFilter.endDate
          }${
            state.transactionFilter.transactionType
              ? "&TransactionType=" + state.transactionFilter.transactionType
              : ""
          }`
        )
        .then((response) => {
          commit("setTransactionSummary", response.body);
        })
        .catch((error) => commit("setTransactionSummaryLoading", false));
    },

    downloadTransaction({ commit, state }, owner) {
      commit("setIsDownloading", true);
      Vue.http
        .get(
          `/Transaction/download/${owner}?StartDate=${state.transactionFilter.startDate}&EndDate=${
            state.transactionFilter.endDate
          }${
            state.transactionFilter.transactionType
              ? "&TransactionType=" + state.transactionFilter.transactionType
              : ""
          }`
        )
        .then((response) => commit("setDownload", response.body))
        .catch((error) => commit("setIsDownloading", false));
    },
  },

  mutations: {
    setCurrentUser: function(state, user) {
      state.currentUser = user;
    },

    setIsGlobal: function(state) {
      state.currentUser.isGlobal = !state.currentUser.isGlobal;
    },

    clearCurrentUser: function(state) {
      state.currentUser = {};
    },

    setPrevRoute: function(state, rout) {
      state.prevRoute = rout;
    },

    setUserId: function(state, userId) {
      state.userId = userId;
    },

    setRoles(state, roles) {
      state.roles = roles;
    },

    setSalesRole(state, salesRoles) {
      state.salesRoles = salesRoles;
    },

    setPayments(state, payments) {
      state.payments = { ...payments };
      state.payments.loading = false;
      state.payments.pageCount = Math.ceil(state.payments.count / state.payments.pageSize);
    },

    setAccountPostings(state, acccountPosting) {
      state.accountPosting = { ...acccountPosting };
      state.accountPosting.loading = false;
      state.accountPosting.pageCount = Math.ceil(
        state.accountPosting.count / state.accountPosting.pageSize
      );
    },

    loadingPostings(state, loading) {
      state.accountPosting.loading = loading;
    },

    setPurchaseOrders(state, purchaseOrders) {
      state.purchaseOrders.purchaseOrders = purchaseOrders.data;
      state.purchaseOrders.pageNumber = purchaseOrders.pageNumber;
      state.purchaseOrders.pageSize = purchaseOrders.pageSize;
      state.purchaseOrders.count = purchaseOrders.count;
      state.purchaseOrders.hasPrev = purchaseOrders.hasPrev;
      state.purchaseOrders.hasNext = purchaseOrders.hasNext;
    },

    setTransactionLoading(state, loading) {
      state.transactions.isLoading = loading;
    },

    setTransactionSummaryLoading(state, loading) {
      state.transactionSummary.isLoading = loading;
    },

    setPaymentsLoading(state, loading) {
      state.payments.loading = loading;
    },

    setDownload(state, transactions) {
      state.transactionDownload.data = transactions;
      state.transactionDownload.isLoading = false;
      state.transactionDownload.summary = state.transactionSummary.data;
    },

    setIsDownloading(state, loading) {
      state.transactionDownload.isLoading = loading;
    },

    setTransaction(state, transactions) {
      state.transactions.data = transactions.data;
      state.transactions.pageNumber = transactions.pageNumber;
      state.transactions.pageSize = transactions.pageSize;
      state.transactions.count = transactions.count;
      state.transactions.hasPrev = transactions.hasPrev;
      state.transactions.hasNext = transactions.hasNext;
      state.transactions.isLoading = false;
    },

    setTransactionSummary(state, transactions) {
      state.transactionSummary.data = transactions;
      state.transactionSummary.isLoading = false;
    },

    setPurchaseOrdersError(state, error) {
      state.purchaseOrders.error = error;
    },

    updateTransactionFilter(state, filter) {
      state.transactionFilter = filter;
    },

    updatePaymentFilter(state, filter) {
      state.paymentsFilter = filter;
    },

    updatePostingFilter(state, filter) {
      state.accountPostingFilter = filter;
    },

    updateFilter(state, filter) {
      state.filter = filter;
    },

    clearFilter(state) {
      state.filter = {
        startDate: "",
        endDate: "",
        customerName: "",
        poNumber: "",
        readStatus: "",
        selectedStage: "",
        downloadStatus: "",
        group: "",
      };
      state.dates = {
        startDate: "",
        endDate: "",
      };
    },

    clearTransactionFilter(state) {
      state.transactionFilter = {
        startDate: "",
        endDate: "",
        transactionType: "",
      };
    },

    clearPaymentsFilter(state) {
      state.paymentsFilter = {
        startDate: "",
        endDate: "",
        customerId: "",
        status: "",
        paymentChannelId: "",
      };
    },

    clearAccountPostingFilter(state) {
      state.accountPostingFilter = {
        startDate: "",
        endDate: "",
        customerId: "",
        status: "",
        paymentType: "",
        accountId: "",
        customerName: "",
        createdBy: "",
        authorizedBy: "",
        paymentChannelId: "",
      };
    },

    setDate(state, date) {
      state.dates = date;
    },
  },
});
